import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitEmploiTerritorialState, DictionaryModel } from "../../pages/referentiel/core/_models";
import agent from "../../api/agent";

const initialState: InitEmploiTerritorialState = {
    loading: false,
    employmentTypes: [],
    metiersCodes: [],
    gradesCodes: [],
    motifDeclarationCodes: [],
    fondementJuridiqueCodes: [],
}

export const fetchEmploiTerritorialGetEmploymentTypes = createAsyncThunk(
    'emploiTerritorialReferentiel/getEmploymentTypes',
    async () => {
        const response = await agent.emploiTerritorialReferentiel.getEmploymentTypes();
        return response as DictionaryModel[];
    }
)

export const fetchEmploiTerritorialGetMetiersCodes = createAsyncThunk(
    'emploiTerritorialReferentiel/getMetiersCodes',
    async () => {
        const response = await agent.emploiTerritorialReferentiel.getMetiersCodes();
        return response as DictionaryModel[];
    }
)

export const fetchEmploiTerritorialGetGradesCodes = createAsyncThunk(
    'emploiTerritorialReferentiel/getGradesCodes',
    async () => {
        const response = await agent.emploiTerritorialReferentiel.getGradesCodes();
        return response as DictionaryModel[];
    }
)

export const fetchEmploiTerritorialGetMotifDeclarationCodes = createAsyncThunk(
    'emploiTerritorialReferentiel/getMotifDeclarationCodes',
    async (codeTypeEmploi: string) => {
        const response = await agent.emploiTerritorialReferentiel.getMotifDeclarationCodes(codeTypeEmploi);
        return response;
    }
)

export const fetchEmploiTerritorialGetFondementJuridiqueCodes = createAsyncThunk(
    'emploiTerritorialReferentiel/getFondementJuridiqueCodes',
    async (codeTypeEmploi: string) => {
        const response = await agent.emploiTerritorialReferentiel.getFondementJuridiqueCodes(codeTypeEmploi);
        return response;
    }
)

export const fetchEmploiTerritorialGetVacancy = createAsyncThunk(
    'emploiTerritorialReferentiel/getVacancy',
    async (id: string) => {
        const response = await agent.emploiTerritorialReferentiel.getVacancy(id);
        return response;
    }
)

const emploiTerritorialReferentielSlice = createSlice({
    name: "emploiTerritorialReferentiel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmploiTerritorialGetEmploymentTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchEmploiTerritorialGetEmploymentTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.employmentTypes = [...action.payload];
            })
            .addCase(fetchEmploiTerritorialGetEmploymentTypes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchEmploiTerritorialGetMetiersCodes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchEmploiTerritorialGetMetiersCodes.fulfilled, (state, action) => {
                state.loading = false;
                state.metiersCodes = [...action.payload];
            })
            .addCase(fetchEmploiTerritorialGetMetiersCodes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchEmploiTerritorialGetGradesCodes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchEmploiTerritorialGetGradesCodes.fulfilled, (state, action) => {
                state.loading = false;
                state.gradesCodes = [...action.payload];
            })
            .addCase(fetchEmploiTerritorialGetGradesCodes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchEmploiTerritorialGetMotifDeclarationCodes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchEmploiTerritorialGetMotifDeclarationCodes.fulfilled, (state, action) => {
                state.loading = false;
                state.motifDeclarationCodes = [...action.payload];
            })
            .addCase(fetchEmploiTerritorialGetMotifDeclarationCodes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchEmploiTerritorialGetFondementJuridiqueCodes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchEmploiTerritorialGetFondementJuridiqueCodes.fulfilled, (state, action) => {
                state.loading = false;
                state.fondementJuridiqueCodes = [...action.payload];
            })
            .addCase(fetchEmploiTerritorialGetFondementJuridiqueCodes.rejected, (state, action) => {
                state.loading = false;
            })
        }
});

export const {} = emploiTerritorialReferentielSlice.actions;
export default emploiTerritorialReferentielSlice.reducer