import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { getImageFromLogo } from "./getLogo";
import './jobboard.scss';


export enum JobboardLogo {
    MONSTER,
    POLEEMPLOI,
    LINKEDIN,
    INDEED,
    CADREEMPLOI,
    REGIONJOB,
    HELLOWORK,
    APEC,
    EMPLOICOLLECTIVITES,
    EMPLOITERRITORIAL,
    CHOISIRLESERVICEPUBLIC,
    LEBONCOIN,
    SFSP,
    SANTEPUBLIQUEFRANCE,
    LAGAZETTEDESCOMMUNES,
    OPTIONCARRIERE,
    METEOJOB,
    FHF,
    TALENTCOM,
    WELCOMETOTHEJUNGLE,
    FIGARO
}

export enum JobboardType {
    FREE = 0,
    CHARGED = 1
}

export interface JobboardViewModel {
    label: string,
    type?: JobboardType,
    logo: JobboardLogo,
    isChecked: boolean,
    index: number, 
    isDiffused?: boolean,
    diffusionDate?: string
}

class Jobboard extends React.Component<{ model: JobboardViewModel, checkItemFunc: (item: JobboardViewModel) => void }> {
    state: { isChecked: boolean }

    constructor(props: { model: JobboardViewModel, checkItemFunc: (item: JobboardViewModel) => void }) {
        super(props);
        this.state = { isChecked: false };
    }

    getImage: () => string = () => {
        return getImageFromLogo(this.props.model.logo);
    }

    check: () => void = () => {
        this.props.checkItemFunc(this.props.model);

        this.setState({
            isChecked: !this.state.isChecked
        }, () => {
            this.props.model.isChecked = this.state.isChecked;
        });
    }

    render(): React.ReactNode {
        return (
            <>
                <Card className={this.props.model.isDiffused ? "jobboard-card disabled" : this.state.isChecked ? "jobboard-card checked" : "jobboard-card"} onClick={this.check}>
                    <div className={this.props.model.type == 1 ?'payment-type' : 'gratuit-type' }>
                        <span>{this.props.model.isDiffused ? `Diffusée le ${this.props.model.diffusionDate}` : this.props.model.type == 1 ?'Payant' : 'Gratuit' }</span>
                    </div>
                    <Card.Img variant="top" alt="Card image" src={this.getImage()} />
                    <Card.Body>
                        <Card.Title>Diffusez sur {this.props.model.label}</Card.Title>
                    </Card.Body>
                </Card>
            </>

        )
    }
}

export default Jobboard;
