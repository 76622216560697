import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import agent from "../../api/agent";
import {
    initMonsterState,
    DictionaryModel,
    MonsterJobCategoryVM,
    MonsterLocationVM
} from "../../pages/referentiel/core/_models";


const initialState: initMonsterState = {
    industries: [],
    jobTypes: [],
    monsterJobCategory: [],
    monsterlocations: [],
    loading: false,
}

export const fetchMonsterGetIndustriesType = createAsyncThunk(
    'monsterReferentiel/getIndustriesType',
    async () => {
        const response = await agent.monsterReferentiel.getIndustriesType();
        return response as DictionaryModel[];
    }
)
export const fetchMonsterGetJobTypes = createAsyncThunk(
    'monsterReferentiel/getJobTypes',
    async () => {
        const response = await agent.monsterReferentiel.getJobTypes();
        return response as DictionaryModel[];
    }
)
export const fetchMonsterGetJobCategoriesTypes = createAsyncThunk(
    'monsterReferentiel/getJobCategoriesTypes',
    async () => {
        const response = await agent.monsterReferentiel.getJobCategoriesTypes();
        return response as MonsterJobCategoryVM[];
    }
)

export const fetchMonsterLocationsType = createAsyncThunk(
    'monsterReferentiel/getLocationsTypes',
    async () => {
        const response = await agent.monsterReferentiel.getLocationsTypes();
        return response as MonsterLocationVM[];
    }
)

const monsterReferentielSlice = createSlice({
    name: "monsterReferentiel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMonsterGetIndustriesType.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMonsterGetIndustriesType.fulfilled, (state, action) => {
                state.loading = false;
                state.industries = [...action.payload];
            })
            .addCase(fetchMonsterGetIndustriesType.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchMonsterGetJobTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMonsterGetJobTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.jobTypes = [...action.payload];
            })
            .addCase(fetchMonsterGetJobTypes.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchMonsterGetJobCategoriesTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMonsterGetJobCategoriesTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.monsterJobCategory = [...action.payload];
            })
            .addCase(fetchMonsterGetJobCategoriesTypes.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchMonsterLocationsType.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMonsterLocationsType.fulfilled, (state, action) => {
                state.loading = false;
                state.monsterlocations = [...action.payload];
            })
            .addCase(fetchMonsterLocationsType.rejected, (state, action) => {
                state.loading = false;
            })
    },
})

export const {} = monsterReferentielSlice.actions;
export default monsterReferentielSlice.reducer