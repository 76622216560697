import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../../api/agent";
import { DictionaryModel, InitState, InitStateT, } from "../../pages/referentiel/core/_models";


export const fetchPoleEmploiContractTypes = createAsyncThunk(
    'poleEmploisContractTypes/fetchPoleEmploisContractTypes',
    async () => {
      const response = await agent.poleEmploiReferentiel.getListContractTypes()
      return response as DictionaryModel[];
    }
  )


const initialState: InitStateT<DictionaryModel> = {
    entities: [],
    loading: false,
  }
const poleEmploiContractTypesSlice = createSlice({
    name: "poleEmploisContractTypes",
    initialState,
    reducers: {
    
      },
    extraReducers:(builder) => {
        builder
        // fetchPoleEmploisContractTypes
        .addCase(fetchPoleEmploiContractTypes.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchPoleEmploiContractTypes.fulfilled, (state, action) => {
            state.loading = false;
            state.entities = [...action.payload];
        })
        .addCase(fetchPoleEmploiContractTypes.rejected, (state, action) => {
            state.loading = false;
        })
      }
})


export const {  } = poleEmploiContractTypesSlice.actions;
export default poleEmploiContractTypesSlice.reducer
