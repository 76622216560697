import React, { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import './selectJobboards.scss';
import { JobboardViewModel } from "../../referentiel/core/_models";
import Jobboard from "./jobboard";

class SelectJobboards extends React.Component<{ jobboards: JobboardViewModel[], totalChecked: number, checkItemFunc: (item: JobboardViewModel) => void }>
{
    render(): ReactNode {
        return (
            <div className="jobboard-list">
                <Row xs={1} md={4} lg={5} className="g-4 jobboard-list">
                    {this.props.jobboards.map(item => { 
                        return (<Col key={item.label}><Jobboard model={item} checkItemFunc={this.props.checkItemFunc} /></Col>) 
                        })}
                </Row>
                <Row>
                    <span className="jobboard-list-counter">{this.props.totalChecked} sélectionnés</span>
                </Row>
            </div>
        )
    }
}

export default SelectJobboards;