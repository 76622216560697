import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import agent from "../../api/agent";
import { IJobvacancyPaged, InitialStates, InitState, InputSearch, JobVacancyDiffusionVM, JobVacancyModel } from "../../pages/referentiel/core/_models";

export const fetchJobVacanciesPaged = createAsyncThunk(
    'jobVacancy/fetchJobVacanciesPaged',
    async (input : InputSearch) : Promise<IJobvacancyPaged> => {
      const response = await agent.JobVacancy.paged(input.page, input.pageSize);
      return response;
    }
  )

  export const fetchJobVacancyById = createAsyncThunk(
    'jobVacancy/fetchJobVacancyById',
    async (id : any) => {
      const response = await agent.JobVacancy.getDiffusionById(id);
      return response as any;
    }
  )
const initialState : InitialStates = {
    entities: null,
    entity: null,
    loading: false
}
const jobVacancySlice = createSlice({
    name:'jobVacancy',
    initialState,
    reducers: {
        jobVacancyAdded(state, action: PayloadAction<JobVacancyDiffusionVM>) {
          if (state.entities) {
            state.entities.items.push(action.payload);
          }
        },
        jobVacancyUpdated(state, action : PayloadAction<JobVacancyDiffusionVM>) {
          const { 
            id, 
            postingDate, 
            jobReference, 
            jobTitle, 
            companyLabel, 
            companyDescription, 
            companyUrl,
            jobDescription,
            applicantProfile,
            lastPostingDate,
            monsterProperty
        } = action.payload as JobVacancyDiffusionVM;

      const existingItem = state.entities && state.entities.items && state.entities?.items.find((item) => item.id === id);
          if (existingItem) {
            existingItem.id = id;
            existingItem.postingDate = postingDate;
            existingItem.jobReference = jobReference;
            existingItem.jobTitle = jobTitle;
            existingItem.companyLabel = companyLabel;
            existingItem.companyDescription = companyDescription;
            existingItem.companyUrl = companyUrl;
            existingItem.jobDescription = jobDescription;
            existingItem.applicantProfile = applicantProfile;
            existingItem.lastPostingDate = lastPostingDate;
            existingItem.monsterProperty = monsterProperty;
          }
        },
        jobVacancyDeleted(
          state,
          action: PayloadAction<JobVacancyModel>
        ) {
          const { id } = action.payload;
          if (state.entities) {
            state.entities.items = state.entities.items.filter(
              (item) => item.id !== id
            );
          }
        },
      },
    extraReducers:(builder) => {
        builder
        .addCase(fetchJobVacancyById.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchJobVacancyById.fulfilled, (state, action) => {
          state.loading = false;
          state.entities = action.payload;
        })
        .addCase(fetchJobVacancyById.rejected, (state) => {
          state.loading = false;
        })
        .addCase(fetchJobVacanciesPaged.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchJobVacanciesPaged.fulfilled, (state, action) => {
          state.loading = false;
          state.entities = action.payload;
        })
        .addCase(fetchJobVacanciesPaged.rejected, (state) => {
          state.loading = false;
        });
      }
})


export const { jobVacancyAdded, jobVacancyUpdated, jobVacancyDeleted } = jobVacancySlice.actions;
export default jobVacancySlice.reducer