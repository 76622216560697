import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../../api/agent";
import { InitState, JobVacancyModel, JobboardJobVacancyModel } from "../../pages/referentiel/core/_models";
import type { PayloadAction } from '@reduxjs/toolkit';


export const fetchCountries = createAsyncThunk(
    'diffusion/fetchCountries',
    async () => {
      const response = await agent.Countries.list()
      return response as JobboardJobVacancyModel[];
    }
  )


const initialState: InitState = {
    entities: [],
    loading: false,
  }
const diffusionSlice = createSlice({
    name: "diffusion",
    initialState,
    reducers: {
        diffusionAdded(state, action : PayloadAction<JobboardJobVacancyModel>) {
          state.entities.push(action.payload);
        },
        diffusionUpdated(state, action : PayloadAction<JobboardJobVacancyModel>) {
          const { 
              id, 
              publicationDate,
              jobboardsId,
              jobId,
              jobIndustry,
              jobFunction,
              jobType,
              jobContract,
              applicantDegree,
              applicantExperience,
              applicantSkill,
              applicantionUrl,
              applicantionEmail,
              country,
              area,
              departement,
              city,
              address,
              zipCode,
              salaryPer,
              salaryFrom,
              salaryTo,
              salaryCurrency,
              salaryBenefits,
              startDate,
              lastPostingDate,
              civilityId,
              partTime,
              partTimeDurationId,
              positionTypeId,
              salaryId,
              typeOffreId,
              statusJobId,
              duration,
              recruiteurEmail
          
          } = action.payload as JobboardJobVacancyModel;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            existingItem.postingDate =publicationDate;
            existingItem.postingDate =jobboardsId;
            existingItem.postingDate =jobId;
            existingItem.postingDate =jobIndustry;
            existingItem.postingDate =jobFunction;
            existingItem.postingDate =jobType;
            existingItem.postingDate =jobContract;
            existingItem.postingDate =applicantDegree;
            existingItem.postingDate =applicantExperience;
            existingItem.postingDate =applicantSkill;
            existingItem.postingDate =applicantionUrl;
            existingItem.postingDate =applicantionEmail;
            existingItem.postingDate =country;
            existingItem.postingDate =area;
            existingItem.postingDate =departement;
            existingItem.postingDate =city;
            existingItem.postingDate =address;
            existingItem.postingDate =zipCode;
            existingItem.postingDate =salaryPer;
            existingItem.postingDate =salaryFrom;
            existingItem.postingDate =salaryTo;
            existingItem.postingDate =salaryCurrency;
            existingItem.postingDate =salaryBenefits;
            existingItem.postingDate =startDate;
            existingItem.postingDate =lastPostingDate;
            existingItem.postingDate =civilityId;
            existingItem.postingDate =partTime;
            existingItem.postingDate =partTimeDurationId;
            existingItem.postingDate =positionTypeId;
            existingItem.postingDate =salaryId;
            existingItem.postingDate =typeOffreId;
            existingItem.postingDate =statusJobId;
            existingItem.postingDate =duration;
            existingItem.postingDate =recruiteurEmail;
          }
        },
        diffusionDeleted(state, action : PayloadAction<JobVacancyModel>) {
          const { id } = action.payload;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            state.entities = state.entities.filter((item) => item.id !== id);
          }
        },
      },
    extraReducers:(builder) => {
        builder
        .addCase(fetchCountries.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchCountries.fulfilled, (state, action) => {
            state.loading = false;
            state.entities = [...action.payload];
        })
        .addCase(fetchCountries.rejected, (state, action) => {
            state.loading = false;
        })
      }
})


export const { diffusionAdded, diffusionUpdated, diffusionDeleted } = diffusionSlice.actions;
export default diffusionSlice.reducer
