import { useEffect, useState } from "react";
import {Col, Row, Toast, ToastContainer,} from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  ActiveTenantJobboardVM,
  DiffusionModel,
  JobVacancyDiffusionVM
} from "../../referentiel/core/_models";
import { fetchPoleEmploiContractTypes } from "../../../store/slices/poleEmploiContractTypes.slices";
import { fetchPoleEmploiContractNatures } from "../../../store/slices/poleEmploiContractNatures.slices";
import { fetchPoleEmploiSalaryTypes } from "../../../store/slices/poleEmploiSalaryTypes.slices";
import { fetchPoleEmploiQualificationTypes } from "../../../store/slices/poleEmploiQualificationTypes.slices";
import { fetchJobboards } from "../../../store/slices/jobboard.slices";
import { fetchPoleEmploiCountry } from "../../../store/slices/poleEmploiCountry.slices";
import { fetchPoleEmploiDepartement } from "../../../store/slices/poleEmploiDepartement.slices";
import { ErrorsScopDiffusionPage } from "../../../modules/errors/ErrorsScopDiffusionPage";
import { fetchdiffusioninfo } from "../../../store/slices/diffusionbyurl.slices";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { JobboardViewModel, JobboardLogo, JobboardType } from "../components/jobboard";
import './DiffusionByUrlPage.scss'
import { FirstStep } from "./steps/firstStep";
import { SecondStep } from "./steps/secondStep";
import {
  fetchMonsterGetIndustriesType,
  fetchMonsterGetJobCategoriesTypes,
  fetchMonsterGetJobTypes,
  fetchMonsterLocationsType
} from "../../../store/slices/monsterReferentiel.slices";
import agent from "../../../api/agent";
import {jobVacancyAdded, jobVacancyUpdated} from "../../../store/slices/jobVacancy.slices";
import {fetchActiveJobboardsByTenant, fetchTenantJobboardByLabel} from "../../../store/slices/tenantJobboard.slices";
import {fetchApecGetRegionType} from "../../../store/slices/apecReferentiel.slices";
import { toggleValue } from "../../../store/slices/bool.slices";
import { formatJobboardLabel } from "../../referentiel/core/_tools";

interface jobboardOption {
  value?: string,
  label?: string
}

export const DiffusionByUrlPage = (props: any) => {
  let { id } = useParams();
  const navigate = useNavigate()
  const [queryParameters] = useSearchParams();
  let returnUrl = queryParameters.get("returnUrl");
  const [isLoading, setIsLoading] = useState(true);
  const [jobboardList, setJobboardList] = useState<JobboardViewModel[]>();
  const [diffusionInformation, setDiffusionInformation] = useState<DiffusionModel>({});
  const [currentStep, setCurrentStep] = useState(1);
  let dispatch = useAppDispatch();
  const [alertMessages, setAlertMessages] = useState<any>([])
  const [alertVariant, setAlertVariant] = useState<any>('')
  const [showAlert, setShowAlert] = useState(false);  

  const handleLists = async () => {
    await dispatch(fetchdiffusioninfo(id)).then(m => {
      setDiffusionInformation(m.payload as DiffusionModel);
    });
    await dispatch(fetchMonsterGetIndustriesType());
    await dispatch(fetchMonsterGetJobTypes());
    await dispatch(fetchMonsterGetJobCategoriesTypes());
    await dispatch(fetchPoleEmploiContractTypes());
    await dispatch(fetchPoleEmploiContractNatures());
    await dispatch(fetchPoleEmploiSalaryTypes());
    await dispatch(fetchPoleEmploiQualificationTypes());
    await dispatch(fetchPoleEmploiCountry());
    await dispatch(fetchPoleEmploiDepartement());
    await dispatch(fetchApecGetRegionType());
    await dispatch(fetchActiveJobboardsByTenant()).then(result => {
      var jobboardItems = result.payload as ActiveTenantJobboardVM[];

      setJobboardList(jobboardItems.filter(ji => ji.status).map((item: ActiveTenantJobboardVM, index: number) => {
        let viewModel: JobboardViewModel = {
          label: item.jobboardLabel!,
          index: index,
          type: item.type ?? JobboardType.CHARGED,
          logo: JobboardLogo.MONSTER,
          isChecked: false,
          isDiffused : false,
          diffusionDate: ""
        };
        if (item.jobboardCode?.toLowerCase() == "PoleEmploi".toLowerCase())
          viewModel.logo = JobboardLogo.POLEEMPLOI;
  
        if (item.jobboardCode?.toLowerCase() == "Monster".toLowerCase())
          viewModel.logo = JobboardLogo.MONSTER;
  
        if (item.jobboardCode?.toLowerCase() == "LinkedIn".toLowerCase())
          viewModel.logo = JobboardLogo.LINKEDIN;
  
        if (item.jobboardCode?.toLowerCase() == "Indeed".toLowerCase())
          viewModel.logo = JobboardLogo.INDEED;
  
        if (item.jobboardCode == "Cardremploi".toLowerCase())
          viewModel.logo = JobboardLogo.CADREEMPLOI;
  
        if (item.jobboardCode == "RegionsJob".toLowerCase())
          viewModel.logo = JobboardLogo.REGIONJOB;
  
        if (item.jobboardCode?.toLocaleLowerCase() == "APEC".toLocaleLowerCase())
          viewModel.logo = JobboardLogo.APEC;
  
        if (item.jobboardCode?.toLowerCase() == "HelloWork".toLowerCase())
          viewModel.logo = JobboardLogo.HELLOWORK;

        if (item.jobboardCode?.trim().toLocaleLowerCase() == "emploicollectivites".toLocaleLowerCase())
          viewModel.logo = JobboardLogo.EMPLOICOLLECTIVITES;

        if (item.jobboardCode?.toLowerCase() == "emploiterritorial".toLowerCase())
          viewModel.logo = JobboardLogo.EMPLOITERRITORIAL;

        if (item.jobboardCode?.toLowerCase() == "Choisirleservicepublic".toLowerCase())
          viewModel.logo = JobboardLogo.CHOISIRLESERVICEPUBLIC;

        if (item.jobboardCode?.trim() == "LeBonCoin")
          viewModel.logo = JobboardLogo.LEBONCOIN;

        if(item.jobboardCode == "sfsp")
          viewModel.logo = JobboardLogo.SFSP;

        if(item.jobboardCode == "santepubliquefrance")
          viewModel.logo = JobboardLogo.SANTEPUBLIQUEFRANCE;

        if(item.jobboardCode?.trim() == "lagazettedescommunes")
          viewModel.logo = JobboardLogo.LAGAZETTEDESCOMMUNES;
        
        if(item.jobboardCode?.trim().toLocaleLowerCase() == "Meteojob".toLocaleLowerCase())
          viewModel.logo = JobboardLogo.METEOJOB;

        if(item.jobboardCode?.trim().toLocaleLowerCase() == "optioncarriere".toLocaleLowerCase())
          viewModel.logo = JobboardLogo.OPTIONCARRIERE;

        if(item.jobboardCode?.trim().toLocaleLowerCase() == "fhf".toLocaleLowerCase())
          viewModel.logo = JobboardLogo.FHF;

        if(item.jobboardCode?.trim().toLocaleLowerCase() == "talent.com".toLocaleLowerCase())
          viewModel.logo = JobboardLogo.TALENTCOM;

        if(item.jobboardCode?.trim().toLocaleLowerCase() == "wttj".toLocaleLowerCase())
              viewModel.logo = JobboardLogo.WELCOMETOTHEJUNGLE;

        if (item.jobboardCode?.trim().toLocaleLowerCase() == "Figaro".toLocaleLowerCase())
              viewModel.logo = JobboardLogo.FIGARO;
         return viewModel;
      }));
    });
    await dispatch(fetchMonsterLocationsType());
    await dispatch(toggleValue());
    
  }

  const checkItem = (jobboardVm: JobboardViewModel) => {
    jobboardList![jobboardVm.index].isChecked = !jobboardList![jobboardVm.index].isChecked;
    setJobboardList(jobboardList);
  };

  useEffect(() => {
    handleLists();
  }, [dispatch]);

  function previousStep() {
    setCurrentStep(currentStep - 1);
  }
  
  function updateInformation(inputVm: JobVacancyDiffusionVM | undefined): void {
    setDiffusionInformation({
      ...diffusionInformation,
      jobVacancyInput: inputVm
    });
    try{
      addDiffusion(inputVm);
    }
    catch{
        console.log("ERROR")
    }
  }

  const addDiffusion = (model :any) => {
    if (model) {
        agent.JobVacancy.create(diffusionInformation.id, model).then((res: any) => {
          if (res.success) {
            dispatch(
                jobVacancyAdded(model)
            );
            setAlertMessages(Object.keys(res.messages));
            setAlertVariant('success')
            setShowAlert(true);
            if (returnUrl === null) {
              navigate('/dashboard')
            }else{
              window.location.href = returnUrl;
            }
          }
          if(!res.success)
          {
            setAlertMessages(Object.keys(res.messages));
            setAlertVariant('warning')
            setShowAlert(true);
          }
        });
    }
  }
  return (
      <div className="page-diffusion">
        {diffusionInformation.isAccess && jobboardList != undefined && (
        <>
          <Row hidden={currentStep != 1} className={`diffusion-step ${currentStep != 1 ? "step-disabled" : "" }`}>
            <Col>
              <FirstStep jobboardList={jobboardList} checkItemFunc={checkItem} validStep={() => setCurrentStep(2)} 
                diffusionInformation={diffusionInformation.jobVacancyInput} />
            </Col>
          </Row>
          <Row hidden={currentStep != 2} className={`diffusion-step ${currentStep != 2 ? "step-disabled" : "" }`}>
            <Col>
              <SecondStep 
                      updateInformationFunc={updateInformation} 
                      diffusionInformation={diffusionInformation.jobVacancyInput} 
                      jobboardList={jobboardList}                      
                      previousStep={previousStep}
                      alertMessages={alertMessages}
                      showAlert={showAlert}
                      alertVariant={alertVariant}/>
            </Col>
          </Row>
        </>
      )}
      {!diffusionInformation.isAccess && (
        <><ErrorsScopDiffusionPage isAccess={false} isLoading={isLoading} /></>
      )}
    </div>
  )
}