import { JobboardLogo } from "../../scopdiffusion/components/jobboard";

export interface InitState {
    entities: any[];
    loading: boolean;
}

export interface InitStateT<T> {
    entities: T[];
    loading: boolean;
}

export interface InitialState {
    entity: any;
    loading: boolean
}
export interface InitialStates {
    entities: IJobvacancyPaged | null;
    entity: JobVacancyDiffusionVM | null;
    loading: boolean
}

export interface JobboardModel {
    id?: string;
    label?: string;
    code?: string;
    type?: number;
    status?: any;
}
export enum JobboardType {
    FREE = 0,
    CHARGED = 1
}

export interface CountryModel {
    id?: string;
    label?: string;
    code?: string;
    designation?: string;
}

export interface JobVacancyModel {
    id?: string;
    postingDate?: string;
    jobReference?: string;
    jobTitle?: string;
    companyLabel?: string;
    companyDescription?: string;
    companyUrl?: string;
    jobDescription?: string;
    applicantProfile?: string;
    lastPostingDate?: Date;
    jobboardJobOffres?: JobboardJobVacancyModel[];
    isSpecificJobboardProrpties?: boolean;
    monsterProperty?: MonsterProperty
}

export interface JobboardJobVacancyModel {
    id?: string;
    publicationDate?: Date;
    jobboardsId?: string;
    jobId?: string;
    jobIndustry?: string;
    jobFunction?: string;
    jobType?: string;
    jobContract?: string;
    applicantDegree?: string;
    applicantExperience?: string;
    applicantSkill?: string;
    applicantionUrl?: string;
    applicantionEmail?: string;
    country?: string;
    area?: string;
    departement?: string;
    city?: string;
    address?: string;
    zipCode?: string;
    salaryPer?: string;
    salaryFrom?: number;
    salaryTo?: number;
    salaryCurrency?: string;
    salaryBenefits?: string;
    startDate?: Date;
    lastPostingDate?: Date;
    civilityId?: string;
    partTime?: boolean;
    partTimeDurationId?: string;
    positionTypeId?: string;
    salaryId?: string;
    typeOffreId?: string;
    statusJobId?: string;
    duration?: number;
    recruiteurEmail?: string;
}

export interface DiffusionModel {
    id?: string,
    jobVacancyInput?: JobVacancyDiffusionVM,
    // jobVacancyInput?: JobVacancyModel,
    isAccess?: boolean
}

export interface ReferentielModel {
    id?: string;
    label?: string;
    code?: string;
    designation?: string
}

export interface MonsterJobCategoryModel extends ReferentielModel {
    occupationId?: string;
    occupationLabel?: string;
}
export interface MonsterCountryModel extends ReferentielModel {
    parentId?: string;
}


export interface MonsterStateModel extends ReferentielModel {
    parentId?: string;
    locationId?: string;
    cityName?: string;
}
// VM 
export interface ReferentialVM {
    label?: string;
    code?: string;
}
export interface CountryVM extends ReferentielModel {
    location?: ReferentialVM
}

export interface MonsterStateVM extends ReferentielModel {
    country?: ReferentialVM;
    location?: ReferentialVM;
    cityName?: string;
}

export enum MonsterLabel {
    MonsterLocationLabel = 'Sélectionnez  le location',
    MonsterCountriesLabel = 'Sélectionnez le pays',
    MonsterIndustriesLabel = "Sélectionnez le secteur d'activité",
    MonsterJobTypesLabel = 'Sélectionnez le type de contrat',
    MonsterStatesLabel = 'Sélectionnez le state',
    MonsterJobCategoryLabel = 'Sélectionnez la catégorie offre',
    MonsterRegionLabel = 'Sélectionnez la région',
    MonsterMetier = 'Métiers',
    MonsterMetierSelect = 'Sélectionnez le(s) métier(s)',
    MonsterSecteurActivite = "Secteur d'activité",
    MonsterSecteurActiviteSelect = "Sélectionnez le(s) secteur(s) d'activité",
    MonsterContrat = "Type de contrat",
    MonsterContratSelet = "Sélectionnez le type de contrat",
    MonsterRegion = "Région",
    
}

export enum PoleEmploiLabels {
    Label = "Pole-Emploi",
    ContractTypeLabel = "Type de contrat",
    ContractTypeSelect = "Choisissez le type de contrat",
    ContractNatureLabel = "Nature du contrat",
    ContractNatureSelect = "Choisissez la nature du contrat",
    SalaryTypeLabel = "Type de salaire ",
    SalaryTypeSelect = "Choisissez le type de salaire ",
    QualificationTypeLabel = "Code qualification ",
    QualificationTypeSelect = "Choisissez le code qualification ",
    codePaysLieuTravailLabel = "Le pays",
    codePaysLieuTravailSelect = "Choisissez le pays",
    codeDepartementLieuTravailLabel = "Le département",
    codeDepartementLieuTravailSelect = "Choisissez le département",
    salaireMinLabel = "Salaire min",
    salaireMinSelect = "Choisissez le salaire min",
    salaireMaxLabel = "Salaire max",
    salaireMaxSelect = "Choisissez le salaire max",
    nbMoisSalaireLabel = "le Nb de mois / salaire",
    nbMoisSalaireSelect = "Nb de mois / salaire",
    dureeMinExpProLabel = "La duree min exp",
    dureeMinExpProSelect = "Choisissez la durée min exp",
    typeDureeExpProLabel = "Le type de la durée min exp",
    typeDureeExpProSelect = "Choisissez Le type de la  durée min exp",
    codeDureeExpProLabel = "Le code type d'expérience professionnelle",
    codeDureeExpProSelect = "Choisissez Le code type d'expérience pro",
    codeDurationaLabel = "Durée de travail hebdomadaire - nb Heures",
    nbMinutesLabel = "Nb Minutes",
    nbHeuresLabel = "Nb Heures",
    postalCodeLabel = "Code postal de l'établissement",
}

export enum LeBonCoinLabels {
    StartDateLabel = "Date de début de contrat",
    TimeTypeLabel = "Durée de travail",
    TimeTypeSelectLabel = "Choisissez une durée de travail",
    ContractTypeLabel = "Type de contrat",
    ContractTypeSelectLabel = "Choisissez un type de contrat",
    BusinessSectorLabel = "Secteur d'activité",
    BusinessSectorSelectLabel = "Choisissez un secteur d'activité",
    OccupationLabel = "Type de poste",
    OccupationSelectLabel = "Choisissez un type de poste",
    MinDurationLabel = "Durée de contrat minimum",
    MaxDurationLabel = "Durée de contrat maximum",
    DurationTypeLabel = "Fréquence de la durée",
    DurationTypeSelectLabel = "Choisissez une fréquence",
    JobLocationStreetLabel = "Localisation du poste - Rue",
    JobLocationZipCodeLabel = "Localisation du poste - Code postal",
    JobLocationCityLabel = "Localisation du poste - Ville",
    JobLocationCountryLabel = "Localisation du poste - Pays",
    MinSalaryLabel = "Salaire minimum",
    MaxSalaryLabel = "Salaire maximum",
    PerSalaryLabel = "Fréquence salaire",
    PerSalarySelectLabel = "Choisissez une fréquence de salaire",
    BenefitsSalaryLabel = "Avantages salariaux",
    ContactModeLabel = "Mode de candidature",
    ContactModeSelectLabel = "Choisissez un mode de candidature",
    ContactLabel = "Contact",
    CompanyLocationStreetLabel = "Localisation de l'entreprise - Rue",
    CompanyLocationZipCodeLabel = "Localisation de l'entreprise - Code postal",
    CompanyLocationCityLabel = "Localisation de l'entreprise - Ville",
    CompanyLocationCountryLabel = "Localisation de l'entreprise - Pays",
    CompanyContactNameLabel = "Nom du contact dans l'entreprise",
    CompanyContactPhoneNumberLabel = "Numéro de téléphone du contact dans l'entreprise",
    CompanyContactEmailLabel = "Email du contact dans l'entreprise",
    ApplicantProfileLabel = "Profil recherché",
    ApplicantSkillsLabel = "Compétences recherchées",
    DegreeLabel = "Niveau d'étude",
    DegreeSelectLabel = "Choisissez un niveau d'étude",
    ExperienceLabel = "Expérience",
    ExperienceSelectLabel = "Choisissez une expérience",
}

export enum LinkedinLabels {
    ApplyUrl = "Lien pour candidater",
    CityLabel = "Ville",
    StateLabel = "Région",
    CountryLabel = "Pays",
    PostalCodeLabel = "Code postal",
    WorkPlaceLabel = "Localisation",
    WorkPlaceSelectLabel = "Choisissez une localisation",
    IndustryLabel = "Industrie",
    IndustrySelectLabel = "Choisissez une industrie",
    ExperienceLevelLabel = "Niveau d'expérience",
    ExperienceLevelSelectLabel = "Choisissez un niveau d'expérience",
    JobFunctionLabel = "Fonction du poste",
    JobFunctionSelectLabel = "Choisissez une fonction de poste",
    JobTypeLabel = "Type du poste",
    JobTypeSelectLabel = "Choisissez un type de poste",
    SkillsLabel = "Compétences (10 maximum séparées par une virgule (,))",
    SalaryLowEndAmountLabel = "Salaire - tranche basse",
    SalaryHighEndAmountLabel = "Salaire - tranche haute",
    SalaryCurrencyLabel = "Salaire - devise",
    SalaryCurrencySelectLabel = "Choisissez une devise",
    SalaryPeriodLabel = "Salaire - fréquence",
    SalaryPeriodSelectLabel = "Choisissez une fréquence",
    SalaryTypeLabel = "Salaire - format",
    SalaryTypeSelectLabel = "Choisissez un format de salaire",
    ExpirationDateLabel = "Date d'expiration (au format MM/JJ/AAAA)"
}

export enum WelcomeToTheJungleLabels {
    LanguageLabel = "Langue de l'annonce",
    LanguageSelectLabel = "Choisissez une langue",
    ContractTypeLabel = "Type de contrat",
    ContractTypeSelectLabel = "Choisissez un type de contrat",
    ProfessionReferenceLabel = "Référence de la profession",
    ProfessionReferenceSelectLabel = "Choisissez une reférence de profession",
    CmsSiteReferenceLabel = "Référence CMS",
    CmsSiteReferenceSelectLabel = "Choisissez une référence CMS",
    EducationLevelLabel = "Niveau d'études",
    EducationLevelSelectLabel = "Choisissez un niveau d'études",
    ExperienceLevelLabel = "Niveau d'expérience",
    ExperienceLevelSelectLabel = "Choisissez un niveau d'expérience",
    RemoteLevelLabel = "Niveau de télétravail",
    RemoteLevelSelectLabel = "Choisissez un niveau de télétravail",
    SalaryCurrencyLabel = "Devise du salaire",
    SalaryCurrencySelectLabel = "Choisissez une devise",
    SalaryPeriodLabel = "Période du salaire",
    SalaryPeriodSelectLabel = "Choisissez une période",
    SalaryMin = "Salaire minimum",
    SalaryMax = "Salaire maximum",
    OfficeAddress = "Adresse de l'entreprise",
    OfficeZipCode = "Code postal de l'entreprise",
    OfficeCity = "Ville de l'entreprise",
    OfficeCountry = "Pays de l'entreprise",
    ApplyUrl = "Lien pour candidater",
    ContractDurationMin = "Durée minimum du contrat",
    ContractDurationMax = "Durée maximum du contrat",
    RecruitmentProcess = "Processus de recrutement",
    StartDate = "Date de début (au format AAAA-MM-JJ)",
    CountrySelectLabel = "Choisissez un pays"
}

export enum TalentComLabels {
    City = "Ville",
    State = "Région",
    Country = "Pays",
    StreetAddress = "Rue",
    PostalCode = "Code postal",
    ExpirationDate = "Date d'expiration (mm-jj-aaaa)",
    IsRemote = "Télétravail",
    Benefit = "Avantages",
    BenefitsSelectLabel = "Choisissez un avantage",
    Category = "Catégorie",
    CategoriesSelectLabel = "Choisissez une catégorie",
    Logo = "Lien vers le logo",
    ExperienceMin = "Expérience - minimum",
    ExperienceMax = "Expérience - maximum",
    Period = "Unité de la période",
    PeriodsSelectLabel = "Choisissez une unité de période",
    SalaryMin = "Salaire - minimum",
    SalaryMax = "Salaire - maximum",
    SalaryCurrency = "Salaire- devise",
    SalaryType = "Type de salaire",
    SalaryTypesSelectLabel = "Choisissez un type de salaire",
    JobType = "Type d'emploi",
    JobTypesSelectLabel = "Choisissez un type d'emploi",
}

export enum HelloWorkLabels {
    AgencyLabel = "Agence",
    City = "Ville",
    Advantages = "Avantages salariaux",
    Email = "Email de contact",
    Url = "Lien vers l'offre",
    AddressNumber = "Adresse - Numéro de la voie",
    AddressStreet = "Adresse - Voie",
    AddressPostalCode = "Adresse - Code postal",
    AddressDepartment = "Adresse - Département",
    AddressDistrict = "Adresse - Arrondissement",
    AddressRegion = "Adresse - Région",
    AddressLatitude = "Adresse - Latitude",
    AddressLongitude = "Adresse - Longitude",
    SalaryDisplay = "Salaire - Afficher sur l'annonce",
    SalaryCurrency = "Salaire - Devise",
    SalaryPeriodicity = "Salaire - Périodicité",
    SalaryMin = "Salaire - Minimum",
    SalaryMax = "Salaire - Maximum",
    SalaryText = "Salaire - Forme textuelle",
    CurrencySelectLabel = "Choisissez une devise",
    PeriodicitySelectLabel = "Choisissez une périodicité",
    UrlResponse = "URL de réponse à l'offre",
    CriteriaSector = "Critères - Secteur",
    CriteriaSectorSelectLabel = "Choisissez un secteur",
    CriteriaExperience = "Critères - Expérience",
    CriteriaExperienceSelectLabel = "Choisissez une expérience",
    CriteriaContract = "Critères - Contrat",
    CriteriaContractSelectLabel = "Choisissez un contrat",
    CriteriaLevel = "Critères - Niveau",
    CriteriaLevelSelectLabel = "Choisissez un niveau",
    CriteriaManagement = "Critères - Encadrement",
    CriteriaManagementSelectLabel = "Choisissez un encadrement",
    CriteriaIsFullTime = "Critères - Temps plein",
    CriteriaIsRemote = "Critères - Télétravail",
    CriteriaDuration = "Critères - Durée du contrat",
    EmailCV = "Email transmission de CV",
    MissionStartDate = "Mission - Date de début (format jj/mm/aaaa)",
    MissionEndDate = "Mission - Date de fin (format jj/mm/aaaa)",
    MissionDurationValue = "Mission - Durée",
    MissionDurationUnit = "Mission - Durée (unité)",
    MissionShift = "Mission - Shift",
    MissionDurationUnitSelectLabel = "Choisissez une unité de durée",
    MissionShiftSelectLabel = "Choisissez un type de shift"
}

export enum EmploiTerritorialLabels {
  NumberOfVacanciesLabel = "Nombre de postes",
  VacancyMissionsLabel = "Missions de l'offre (exemple: Expert en statut de la fonction publique territoriale)",
  EmploymentTypeLabel = "Type d'emploi",
  EmploymentTypeSelectLabel = "Choisissez un type d'emploi",
  IsFullTimeLabel = "Temps complet",
  WorkDurationHoursLabel = "En heures",
  WorkDurationMinutesLabel = "En minutes",
  WorkCollectivityLabel = "Libellé",
  WorkCollectivityAddressLabel = "Adresse",
  WorkCollectivityPostalCodeLabel = "Code postal",
  WorkCollectivityCityLabel = "Ville ou commune",
  ServiceDirectionPosteLabel = "Service / direction du poste",
  WorkCollectivityEmailAddressLabel = "Adresse email",
  WorkCollectivityWebSiteLabel = "Site Web",
  EmailCandidatureLabel = "Email pour les candidatures",
  JobAddressLabel = "Adresse",
  JobPostalCodeLabel = "Code postal",
  JobCityLabel = "Ville ou commune",
  VacancyApplicationURLLabel = "Lien pour candidater sur l'offre",
  VacancyPublicationEndDateLabel = "Date de fin de publicité de l'offre au format JJ/MM/AAAA (par défaut 60j max après la date de début de publication)",
  VacancyApplicationFinalDateLabel = "Date limite de candidature de l'offre au format JJ/MM/AAAA",
  DepartementsCodeLabel = "Département du lieu de travail",
  DepartementsCodeSelectLabel = "Choisissez un département",
  ActivityDomainCodeLabel = "Domaine d'activité",
  ActivityDomainCodeSelectLabel = "Choisissez un domaine d'activité",
  MetiersCodeLabel = "Métier",
  MetiersCodeSelectLabel = "Choisissez un métier",
  VacancyCategoryCodeLabel = "Code catégorie de l'offre d'emploi",
  VacancyCategoryCodeSelectLabel = "Choisissez un code catégorie de l'offre d'emploi",
  VacancyDateLabel = "Date du poste à pouvoir de l'offre au format JJ/MM/AAAA",
  GradesCode1Label = "Grade de l'offre",
  GradesCode1SelectLabel = "Choisissez un grade",
  CategoriesJuridiquesCodeLabel = "Catégorie Juridique de la collectivité",
  CategoriesJuridiquesCodeSelectLabel = "Choisissez une catégorie Juridique",
  MissionDurationMonthsLabel = "Durée de la mission (en mois)",
  SiretNumberLabel = "Numéro de SIRET de la collectivité",
  EmailContactLabel = "Email de contact",
  TelContactLabel = "Téléphone du référent interne",
  NomContactLabel = "Nom du reférent interne",
  PrenomContactLabel = "Prénom du référent interne",
  OuvertContractuelsLabel = "Ouvert aux contractuels ?",
  ContactDescriptionLabel = "Description contact",
  MotifDeclarationCodeLabel = "Motif de création ou de vacance du poste",
  MotifDeclarationCodeSelectLabel = "Choisissez un motif",
  LettreMotivationLabel = "Lettre de motivation obligatoire ?",
  FondementJuridiqueSelectLabel = "Choisissez un fondement juridique",
  FondementJuridiqueLabel = "Fondement juridique"
}

export enum MeteoJobLabels {
    ExpirationDate = "Date d'expiration de l'offre (au format aaaa-mm-jj)",
    URL = "URL de redirection",
    Emails = "Emails de redirection (séparés par une virgule)",
    Industry = "Secteur d'activité",
    IndustrySelectLabel = "Choisissez un secteur d'activité",
    Anonymous = "Masquer les informations de l'entreprise",
    ContractTypes = "Type de contrat",
    ContractTypesSelectLabel = "Choisissez un type de contrat",
    WorkSchedules = "Horaire de travail",
    WorkSchedulesSelectLabel = "Choisissez un horaire de travail",
    Travel = "Fréquence de déplacement professionnel",
    TravelsSelectLabel = "Choisissez une fréquence",
    TeleWork = "Possibilité de télétravail",
    TeleWorkSelectLabel = "Choissez une possibilité de télétravail",
    ShiftWork = "Travail posté",
    ShiftWorkSelectLabel = "Choisissez un travail posté",
    Town = "Ville",
    PostalCode = "Code postal",
    Department = "Département",
    DepartmentSelectLabel = "Choisissez un département",
    Region = "Région",
    RegionSelectLabel = "Choisissez une région",
    Country = "Pays",
    CountrySelectLabel = "Choisissez un pays",
    Start = "Date de début de contrat souhaitée (au format aaaa-mm-jj)",
    End = "Date de fin de contrat (au format aaaa-mm-jj)",
    PeriodValue = "Durée du contrat - valeur",
    PeriodUnit = "Durée du contrat - unité",
    PeriodUnitSelectLabel = "Choisissez une unité",
    Min = "Salaire minimum",
    Max = "Salaire maximum",
    Period = "Période de calcul",
    PeriodSelectLabel = "Choisissez une période de calcul",
    Currency = "Devise",
    CurrencySelectLabel = "Choisissez une devise",
    Benefits = "Primes, avantages et compléments de salaire",
    ExperienceLevels = "Niveau d'expérience",
    ExperienceLevelsSelectLabel = "Choisissez un niveau d'expérience",
    Degrees = "Niveau d'étude et diplôme",
    DegreesSelectLabel = "Choisissez un niveau/diplôme",
    Languages = "Langue",
    LanguagesSelectLabel = "Choisissez une langue",
    Level = "Niveau de pratique",
    LevelSelectLabel = "Choisissez un niveau de pratique",
    Licences = "Permis de conduire",
    LicencesSelectLabel = "Choisissez un permis de conduire",
}

export interface TenantJobboardVM {
    id?: string,
    tenantId?: string,
    tenantLabel?: string,
    jobboardCode?: string,
    jobboardId?: string,
    jobboardLabel?: string,
    status?: boolean,
    userName?: string,
    password?: string,
    credit?: number,
    siretClient?: string,
    idRecruteur?: string,
    internetDomainName?: string,
    finess_etab?: string,
    fhfToken?: string,
    figaroId?: string,
    legalNameFigaro?: string
    numeroDossier?: string,
    industryCode?: string,
    legalId?: string,
}
export interface ActiveTenantJobboardVM extends TenantJobboardVM {

    type?: JobboardType
}
export interface TenantModel {
    id?: string,
    email?: string,
    internalTenantId?: string,
    jobboards?: JobboardModel[],
    tenantJobboards?: TenantJobboardVM[]
}
export interface TenantVM {
    id?: string,
    email?: string,
    internalTenantId?: string,
    jobboards?: number,
}
export enum SubscriptionType {
    PER_CREDIT = 0,
    PER_PERIOD = 1,
}
export interface SubscriptionJobboardModel {
    id?: string,
    tenantJobboardId?: string,
    tenantId?: string,
    tenantName?: string,
    subscriptionType?: SubscriptionType,
    status?: boolean,
    createDate?: Date,
    lastModificationDate?: Date,
    startDatePeriod?: Date,
    endTimePeriod?: Date,
    numberCredit?: number
}

export interface InputSearch {
    page: number;
    pageSize: number;
}
export interface MonsterCountryVM {
    id?: string;
    label?: string;
    code?: string;
    designation?: string;
    location?: LocationVM
}
export interface LocationVM {
    label?: string;
    code?: string;
}

export interface DictionaryModel {
    id: string,
    label: string
}

export enum JobboardsLabel {
    MONSTER = "Monster",
    POLEEMPLOI = "PoleEmploi",
    EMPLOICOLLECTIVITES = "EmploiCollectivites",
    EMPLOITERRITORIAL = "Emploi territorial",
    CHOISIRLESERVICEPUBLIC = "Choisir le service public",
    LEBONCOIN = "LeBonCoin",
    LINKEDIN = "Linkedin",
    HELLOWORK = "HelloWork",
    SFSP = "Societé française de santé publique",
    SANTEPUBLIQUEFRANCE = "Santé publique France",
    LAGAZETTEDESCOMMUNES = "La gazette des communes",
    METEOJOB = "Meteojob",
    OPTIONCARRIERE = "Optioncarriere",
    FHF = "Fédération hospitalière France",
    APEC = "APEC",
    INDEED = "indeed",
    TALENTCOM = "Talent.com",
    WELCOMETOTHEJUNGLE = "Welcome to the Jungle",
    FIGARO= "Figaro"
}

export interface JobVacancyInputVM {
    postingDate?: string;
    jobReference?: string;
    jobTitle?: string;
    companyLabel?: string;
    companyDescription?: string;
    companyUrl?: string;
    jobDescription?: string;
    applicantProfile?: string;
}
export interface ResultDiffusionVM {
    id?: string,
    jobVacancyInput?: JobVacancyDiffusionVM,
    isAccess?: boolean,
    url?: string
}

export interface MonsterJobCategoryVM {
    occupationId?: string,
    occupationLabel?: string,
    code?: string,
    label?: string
}

export interface initMonsterState {
    industries: DictionaryModel[];
    jobTypes: DictionaryModel[];
    monsterJobCategory: MonsterJobCategoryVM[];
    monsterlocations: MonsterLocationVM[];
    loading: boolean;
}

export interface InitLeBonCoinState {
    timeTypes: DictionaryModel[];
    contractTypes: DictionaryModel[];
    businessSectors: DictionaryModel[];
    occupations: DictionaryModel[];
    durationTypes: DictionaryModel[];
    perSalaries: DictionaryModel[];
    contactModes: DictionaryModel[];
    experiences: DictionaryModel[];
    degrees: DictionaryModel[];
    loading: boolean;
}

export interface InitLinkedinState {
    jobTypes: DictionaryModel[];
    workPlaces: DictionaryModel[];
    experienceLevels: DictionaryModel[];
    salaryTypes: DictionaryModel[];
    salaryPeriods: DictionaryModel[];
    salaryCurrencies: DictionaryModel[];
    jobFunctions: DictionaryModel[];
    industryCodes: DictionaryModel[];
    loading: boolean;
}

export interface InitHelloWorkState {
    currencies: DictionaryModel[];
    periodicities: DictionaryModel[];
    sectors: DictionaryModel[];
    experiences: DictionaryModel[];
    contracts: DictionaryModel[];
    levels: DictionaryModel[];
    managements: DictionaryModel[];
    durationUnits: DictionaryModel[];
    shifts: DictionaryModel[];
    loading: boolean;
}

export interface InitEmploiTerritorialState {
    loading: boolean,
    employmentTypes: DictionaryModel[];
    metiersCodes: DictionaryModel[];
    gradesCodes: DictionaryModel[];
    motifDeclarationCodes: DictionaryModel[];
    fondementJuridiqueCodes: DictionaryModel[];
}

export interface InitMeteoJobState {
    industries: DictionaryModel[];
    contractTypes: DictionaryModel[];
    workSchedules: DictionaryModel[];
    travels: DictionaryModel[];
    teleworks: DictionaryModel[];
    shiftworks: DictionaryModel[];
    departments: DictionaryModel[];
    regions: DictionaryModel[];
    countries: DictionaryModel[];
    periodUnits: DictionaryModel[];
    periods: DictionaryModel[];
    currencies: DictionaryModel[];
    experienceLevels: DictionaryModel[];
    degrees: DictionaryModel[];
    languages: DictionaryModel[];
    licences: DictionaryModel[];
    levels: DictionaryModel[];
    loading: boolean;
}

export interface InitTalentComState {
    jobTypes: DictionaryModel[];
    benefits: DictionaryModel[];
    categories: DictionaryModel[];
    periods: DictionaryModel[];
    salaryTypes: DictionaryModel[];
    loading: boolean;
}

export interface InitWelcomeToTheJungleState {
    languages: DictionaryModel[];
    contractTypes: DictionaryModel[];
    professionReferences: DictionaryModel[];
    cmsSiteReferences: DictionaryModel[];
    educationLevels: DictionaryModel[];
    experienceLevels: DictionaryModel[];
    remoteLevels: DictionaryModel[];
    salaryCurrencies: DictionaryModel[];
    salaryPeriods: DictionaryModel[];
    countries: DictionaryModel[];
    loading: boolean;
}

export interface MonsterProperty {
    id?: string,
    jobVacancyId?: string,
    jobboardLabel: string,
    location?: string,
    state?: string,
    country?: string,
    city?: string,
    jobCategory?: [],
    industry?: [],
    jobTypes?: string,
    userName: string,
    password: string
}

export interface EmploiCollectivitesProperty {
    jobboardLabel: string,
    famille_de_metiers?: [],
    filieres?: [],
    cadre_d_emplois?: [],
    statut: number,
    type_de_contrat: number,
    temps_complet_partiel: number,
    niveau_d_etudes: number,
    experience: number,
    date_de_debut: Date,
    region_du_poste: number,
    departement_du_poste: string,
    ville_du_poste: string,
    description_de_la_collectivite: string,
    date_fin_de_publication: Date,
    destinataires_des_candidatures: []
}

export interface JobVacancyDiffusionVM {
    id?: string,
    postingDate?: string,
    jobReference?: string,
    jobTitle?: string,
    companyLabel?: string,
    companyDescription?: string,
    companyUrl?: string,
    jobDescription?: string,
    applicantProfile?: string,
    lastPostingDate?: string,
    recruiteurEmail?: string,
    jobIndustry?: string,
    jobFunction?: string,
    jobType?: string,
    jobContract?: string,
    applicantDegree?: string,
    applicantExperience?: string,
    applicantSkill?: string,
    address?: string,
    zipCode?: string,
    salaryPer?: string,
    salaryFrom?: string,
    salaryTo?: string,
    salaryCurrency?: string,
    salaryBenefits?: string,
    applicantionUrl?: string,
    applicantionEmail?: string,
    status?: number,
    tenantId?: string,
    creationDate?: string,
    monsterProperty?: MonsterProperty,
    poleEmploiProperty?: PoleEmploiProperty,
    leBonCoinProperty?: LeBonCoinProperty,
    emploiCollectivitesProperty?: EmploiCollectivitesProperty,
    emploiTerritorialProperty?: any,
    indeedProperty? : IndeedProperty
    linkedinProperty?: LinkedinProperty;
    helloWorkProperty?: HelloWorkProperty;
    meteoJobProperty?: MeteoJobProperty;
    talentComProperty?: TalentComProperty;
    welcomeToTheJungleProperty?: WelcomeToTheJungleProperty;
    federationHospitaliereFranceProperty? :FederationHospitaliereFranceProperty;
    figaroProperty?: FigaroProperty;
    urlPublished?: string;
    apecProperty? : ApecProperty;
    jobboardLabel?: any[];
    lastModifiedDate? : string | undefined;
    externalId?: string
}

export enum JobVacancyType {
    TOCREATE = 0,
    CREATED = 1,
    UPDATE = 2,
    PUBLISHED = 3,
    DELETED = 4,
    INPROGRESS = 5,
}

export interface IJobvacancyPaged {
    items: JobVacancyDiffusionVM[],
    totalCount: number,
    page: number,
    pageSize: number
}

export interface UserVM {
    firstName?: string,
    lastName?: string,
    email?: string,
    scopTalentId?: number
}

export interface MonsterLocationVM {
    locationId?: number,
    stateAbbrev?: string,
    stateName?: string,
    cityName?: string
}

export interface PoleEmploiProperty {
    jobboardLabel?: string,

    contractTypes? : string,
    contractNatures? : string,
    salaryTypes? : string,
    qualificationTypes? : string,
    codeDepartementLieuTravail? : string,
    codeCommuneLieuTravail? : string,
    libelleCommuneLieuTravail? : string,
    codePaysLieuTravail? : string,
    nbPostesCrees? : string,
    codeExigibiliteFormation? : string,
    dateDebutContrat? : string,
    codeComplementSalaire? : string,
    nbMoisSalaire? : string,
    dateFinpublic? : string,
    salaireMin? : string,
    salaireMax? : string,
    duration? : string,
    codeTypeExpPro? : string,
    dureeMinExpPro? : string,
    typeDureeExpPro? : string,
    userName?: string,
    password?: string,
    siretClient?: string,
    idRecruteur?: string,
    internetDomainName?: string,
    postalCode?: string,
}

export interface LeBonCoinProperty {
    morpheus_client_id: number;
    partner_unique_reference: string;
    job: LeBonCoinJob;
    application: LeBonCoinApplication;
    company?: LeBonCoinCompany;
    applicant?: LeBonCoinApplicant;
}

export interface LeBonCoinJob {
    client_reference?: string;
    title: string;
    description: string;
    start_date?: Date;
    time_type?: string;
    contract_type?: string;
    business_sector?: string;
    occupation?: string;
    contract_duration?: LeBonCoinContractDuration;
    location: LeBonCoinLocation;
    salary?: LeBonCoinSalary;
}

export interface LeBonCoinApplication {
    mode: string;
    contact: string;
}

export interface LeBonCoinCompany {
    name?: string;
    description?: string;
    url?: string;
    location?: LeBonCoinLocation;
    contact?: LeBonCoinContact;
}

export interface LeBonCoinApplicant {
    profile?: string;
    skills?: string;
    degree?: string;
    experience?: string;
}

export interface LeBonCoinContractDuration {
    min?: number;
    max?: number;
    duration_type?: string;
}

export interface LeBonCoinLocation {
    street?: string;
    zip_code?: string;
    city?: string;
    country?: string;
}

export interface LeBonCoinSalary {
    min?: number;
    max?: number;
    per?: string;
    benefits?: string;
}

export interface LeBonCoinContact {
    name?: string;
    phone_number?: string;
    email?: string;
}

export interface LeBonCoinProperty {
    morpheus_client_id: number;
    partner_unique_reference: string;
    job: LeBonCoinJob;
    application: LeBonCoinApplication;
    company?: LeBonCoinCompany;
    applicant?: LeBonCoinApplicant;
}

export interface LeBonCoinJob {
    client_reference?: string;
    title: string;
    description: string;
    start_date?: Date;
    time_type?: string;
    contract_type?: string;
    business_sector?: string;
    occupation?: string;
    contract_duration?: LeBonCoinContractDuration;
    location: LeBonCoinLocation;
    salary?: LeBonCoinSalary;
}

export interface LeBonCoinApplication {
    mode: string;
    contact: string;
}

export interface LeBonCoinCompany {
    name?: string;
    description?: string;
    url?: string;
    location?: LeBonCoinLocation;
    contact?: LeBonCoinContact;
}

export interface LeBonCoinApplicant {
    profile?: string;
    skills?: string;
    degree?: string;
    experience?: string;
}

export interface LeBonCoinContractDuration {
    min?: number;
    max?: number;
    duration_type?: string;
}

export interface LeBonCoinLocation {
    street?: string;
    zip_code?: string;
    city?: string;
    country?: string;
}

export interface LeBonCoinSalary {
    min?: number;
    max?: number;
    per?: string;
    benefits?: string;
}

export interface LeBonCoinContact {
    name?: string;
    phone_number?: string;
    email?: string;
}

export interface LinkedinProperty {
    partnerJobId: string;
    company: string;
    title: string;
    description: string;
    applyUrl: string;
    location: string;
    alternateLocations?: string[];
    city: string;
    state: string;
    country: string;
    postalCode?: string;
    industryCodes?: LinkedinPropertyIndustryCode[];
    jobFunctions?: LinkedinPropertyJobFunction[];
    experienceLevel?: string;
    jobType?: string;
    skills?: LinkedinPropertySkill[];
    salaries?: LinkedinPropertySalary[];
    workplaceTypes?: string;
    expirationDate?: string;
}

export interface LinkedinPropertyIndustryCode
{
    industryCode?: string;
}

export interface LinkedinPropertyJobFunction
{
    jobFunction?: string;
}

export interface LinkedinPropertySkill
{
    skill?: string;
}

export interface LinkedinPropertySalary
{
    highEnd?: LinkedinPropertySalaryEnd;
    lowEnd?: LinkedinPropertySalaryEnd;
    period?: string;
    type?: string;
}

export interface LinkedinPropertySalaryEnd
{
    amount?: string;
    currencyCode?: string;
}

export interface HelloWorkProperty {
    Nom_Ese: string;
    Agence?: string;
    Reference: string;
    ExternalId?: string;
    Poste: string;
    Description: string;
    DescriptionEntreprise?: string;
    DescriptionPoste?: string;
    Profil?: string;
    Ville: string;
    AdressesPostales: HelloWorkAdressePostale[];
    Salaire?: string;
    NiveauSalaire?: HelloWorkNiveauSalaire;
    Email?: string;
    Url_Reponse?: string;
    TargetUrl?: string;
    Aveugle?: string;
    DateAnnonce?: string;
    DateModification?: string;
    Criteres: HelloWorkCriteres;
    EmailSuggestionCv?: string;
    EmailContact?: string;
    Source?: HelloWorkSource;
    Mission?: HelloWorkMission;
    jobboardLabel?: string;
}

export interface HelloWorkAdressePostale {
    TexteLibre?: string;
    Numero?: string;
    Voie?: string;
    CodePostal?: string;
    Ville?: string;
    Quartier_Arrondissement?: string;
    Departement?: string;
    Region?: string;
    Pays?: string;
    Latitude?: string;
    Longitude?: string;
}

export interface HelloWorkNiveauSalaire {
    Affichable?: string;
    Devise?: string;
    Periodicite?: string;
    SalaireMin?: string;
    SalaireMax?: string;
    SalaireTexte?: string;
}

export interface HelloWorkCriteres {
    CritereSecteur?: string;
    CritereExperience?: string;
    CritereContrat?: string;
    CritereNiveau?: string;
    CritereEncadrement?: string;
    TempsComplet?: string;
    Teletravail?: string;
    DureeContrat?: string;
}

export interface HelloWorkSource {
    Nom?: string;
    IdOffer?: string;
    IdClient?: string;
}

export interface HelloWorkMission {
    StartDate?: string;
    EndDate?: string;
    Duration?: HelloWorkMissionDuration;
}

export interface HelloWorkMissionDuration {
    Value?: string;
    Unit?: string;
    Shift?: string;
}

export interface EmploiTerritorialProperty {
    jobTitle: string,
    numberOfVacancies: number,
    vacancyDescription: string,
    vacancyMissions: string,
    vacancyProfile: string,
    typeEmploisCode: string, 
    workTimeType: number,
    workDurationHours: number,
    workDurationMinutes: number,
    contactDescription?: string, 
    jobAddress?: string,
    jobPostalCode? : string,
    jobCity?: string,
    vacancyApplicationURL: string,
    vacancyPublicationStartDate: string,
    vacancyPublicationEndDate: string,
    vacancyApplicationFinalDate: string,
    metiersCode?: string,
    gradesCode1: string[],
    categoriesjuridiquesCode: string,
    missionDurationMonths?: number,
    serviceDirectionPoste?: string,
    motifDeclarationCode: string,
    emailCandidature?: string,
    datePoste: string,
    lettreMotivation?: number,
    emailContact?: string,
    nomContact?: string,
    prenomContact?: string,
    telContact?: string,
    ouvertContractuels: string,
}

export interface IndeedProperty {
    jobboardLabel?: string,
    city?: string,
    state?: string,
    country?:string,
     email? : string,
     salary? : string,
     education? : string,
     jobtype? : string,
     categories: any,   
     experience? : string,
     remotetype? : string,
     paidVersion: boolean,
}
export enum IndeedLabelType {
    EMAIL_LABEL = "Email",
    salary_label = "Salaire",
    education_label = "Education",
    jobtype_label = "Type de contrat",
    categories_label = "Catégorie",
    categories_select = "Choisissez La catégorie",
    experience_label = "Experience",
    remotetype_label = "Remote",
    remotetype_select = "Choisissez le type du travail",
}

export enum SalaryTypeIndeed {
    PER_MONTH = 1,
    PER_YEAR = 2
}

export interface MeteoJobProperty {
    reference: string;
    publicationDate?: string;
    expirationDate?: string;
    title: string;
    url: string;
    emails: string;
    company?: MeteoJobCompany;
    position: MeteoJobPosition;
    profile: MeteoJobProfile;
}

export interface MeteoJobCompany {
    name?: string;
    description?: string;
    industry?: string;
    anonymous?: boolean;
}

export interface MeteoJobPosition {
    description: string;
    contractTypes: string;
    workSchedules?: string;
    travel?: string;
    telework?: string;
    shiftwork?: string;
    location: MeteoJobLocation;
    availability?: MeteoJobAvailability;
    salary?: MeteoJobSalary;
}

export interface MeteoJobLocation {
    town?: string;
    postalCode?: string;
    departement?: string;
    region?: string;
    country?: string;
}

export interface MeteoJobAvailability {
    start?: string;
    end?: string;
    period?: MeteoJobPeriod;
}

export interface MeteoJobPeriod {
    value: number;
    unit: string;
}

export interface MeteoJobSalary {
    min?: number;
    max?: number;
    period?: string;
    currency?: string;
    benefits?: string;
}

export interface MeteoJobProfile {
    description?: string;
    experienceLevels?: string;
    degrees?: string;
    languages?: string;
    licences?: string;
}

export interface TalentComProperty {
    publisher?: string;
    publisherurl?: string;
    lastbuilddate?: string;
    job: TalentComJob[];
}

export interface TalentComJob {
    referencenumber: string;
    title: string;
    company: string;
    city: string;
    state: string;
    country: string;
    dateposted: string;
    description: string;
    expirationdate?: string;
    streetaddress?: string;
    postalcode?: string;
    jobtype?: string;
    isRemote?: string;
    benefit?: string;
    category?: string;
    logo?: string;
    experience?: TalentComExperience;
    salary?: TalentComSalary;
}

export interface TalentComExperience {
    experience_min: number;
    experience_max: number;
    period: string;
}



export interface ClassificationFigaro {
    id?: string,
    label?: string
}

export interface FigaroProperty {
    jobboardLabel?: string,
    experienceRequirements? : ClassificationFigaro,
    educationRequirements?: ClassificationFigaro,
    postalCode?: string,
    countryCode?: string,
    occupationalCategories?: ClassificationFigaro,
    industries?: ClassificationFigaro,
    hiringOrganizationLegalName?: string,
    hiringOrganizationIdentifier?: string,
    employmentType?: ClassificationFigaro,
    employmentDuration?: string,
    figaroId?: string,
    legalNameFigaro?: string,
}
export interface TalentComSalary {
    salary_min: number;
    salary_max: number;
    salary_currency: string;
    period: string;
    type: string;
    currency: string;
}

export interface WelcomeToTheJungleProperty {
    id?: string;
    status?: string;
    organization_reference: string;
    profession_reference: string;
    name: string;
    company_description?: string;
    recruitment_process?: string;
    description: string;
    profile: string;
    contract_type: string;
    language: string;
    salary_min?: string;
    salary_max?: string;
    salary_currency?: string;
    salary_period?: string;
    remote?: string;
    contract_duration_min?: number;
    contract_duration_max?: number;
    offices: WelcomeToTheJungleOffice[];
    education_level?: string;
    experience_level?: string;
    apply_url?: string;
    start_date?: string;
    external_reference?: string;
    cms_sites_references?: string;
    salary?: WelcomeToTheJungleSalary;
    office_id?: number;
}

export interface WelcomeToTheJungleOffice {
    id?: number;
    address: string;
    zip_code: string;
    city: string;
    country_code: string;
}

export interface WelcomeToTheJungleSalary {
    min?: number;
    max?: number;
    currency?: string;
    period?: string;
}

export interface FederationHospitaliereFranceProperty {
    jobboardLabel?: string;
    finess_etab?: string;
    poste?: string;
    contrat?: string;
    // contact?: string;
    // mail?: string;
    publie?: boolean;
    active?: boolean;
    source?: string;
    partenaires?: boolean;
    type?: string
    userName?: string,
    password?: string,
    fhfToken?: string,
    idOffre?: string,
    isUpdated?: boolean,
}

export interface initApecState {
    regionType: DictionaryModel[];
    salaryType: DictionaryModel[];
    pertialTimeType: DictionaryModel[];
    contractType: DictionaryModel[];
    jobStatusType: DictionaryModel[];
    experienceLevelType: DictionaryModel[];
    loading: boolean;
}
export interface ApecProperty{
    jobboardLabel?: string,
    region? : string,
    experienceLevel? : string,
    basePayAmountMax? : string,
    basePayAmountMin? : string,
    duration? : string,
    jobStatus? : string,
    displayPay? : string,
    partTime? : string,
    partTimeDuration? : string,
    jobType? : string,
    experienceLevelLabel? : string,
    numeroDossier?: string,
    industryCode?: string,
    legalId?: string,
}

export interface JobboardViewModel {
    label: string,
    type?: JobboardType,
    logo: JobboardLogo,
    isChecked: boolean,
    index: number,
    isDiffused?: boolean,
    diffusionDate?: string
}

export enum APECLabel {
    label = "APEC",
    locationLabel = 'Localisation du poste (Commune)',
    maxSalary = "Salaire maximum (En K€)",
    minSalary = "Salaire minimum (En K€)",
    typeSalary = "Type de salaire",
    selectTypeSalary = "Choisissez le type de salaire",
    partialTime = "Temps partiel",
    selectPartialTime = "Choisissez le type de temps partiel",
    contractType  = "Type de contrat",
    selectContractType  = "Choisissez le type de contrat",
    status = "Statut",
    selectStatus = "Choisissez le statut",
    duration = "Durée",
    experienceLevel = "Niveau d'expérience",
    selectExperienceLevel = "Choisissez le niveau d'expérience"
}