import { useEffect } from "react";
import { UserVM } from "../../referentiel/core/_models";
import { RootState } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchUsersByTenantId } from "../../../store/slices/tenant.slices";
import { useLocation } from "react-router-dom";

export function TenantUsers() {

    const dispatch = useAppDispatch();
    const location = useLocation();

    const entities = useAppSelector((state: RootState) => state.tenant.entities);
    const loading = useAppSelector((state: RootState) => state.tenant.loading);

    const handleLists = async () => {
        await dispatch(fetchUsersByTenantId());
    }

    useEffect(() => {
        handleLists();
    }, [location]);

    return (
        <>
            <div className="card card-custom card-flush">
                <div className="card-header">
                    <h3 className="card-title">Liste des utilisateurs</h3>
                </div>
                <div className="card-body">
                    {loading ? ("Loading ...") : (
                        <div className="table-responsive">
                            <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th className="min-w-200px">Prénom</th>
                                        <th className="min-w-200px">Nom</th>
                                        <th className="min-w-200px">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        entities.map((data: UserVM, index: any) => (
                                            <tr key={index}>
                                                <td><b>{data.firstName}</b></td>
                                                <td>{data.lastName}</td>
                                                <td><b>{data.email}</b></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}