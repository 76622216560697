import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../api/agent";
import { DictionaryModel, InitTalentComState } from "../../pages/referentiel/core/_models";

const initialState: InitTalentComState = {
    jobTypes: [],
    benefits: [],
    categories: [],
    periods: [],
    salaryTypes: [],
    loading: false,
}

export const fetchTalentComGetJobTypes = createAsyncThunk(
    'talentComReferentiel/getJobTypes',
    async () => {
        const response = await agent.talentComReferentiel.getJobTypes();
        return response as DictionaryModel[];
    }
)

export const fetchTalentComGetBenefits = createAsyncThunk(
    'talentComReferentiel/getBenefits',
    async () => {
        const response = await agent.talentComReferentiel.getBenefits();
        return response as DictionaryModel[];
    }
)

export const fetchTalentComGetCategories = createAsyncThunk(
    'talentComReferentiel/getCategories',
    async () => {
        const response = await agent.talentComReferentiel.getCategories();
        return response as DictionaryModel[];
    }
)

export const fetchTalentComGetPeriods = createAsyncThunk(
    'talentComReferentiel/getPeriods',
    async () => {
        const response = await agent.talentComReferentiel.getPeriods();
        return response as DictionaryModel[];
    }
)

export const fetchTalentComGetSalaryTypes = createAsyncThunk(
    'talentComReferentiel/getSalaryTypes',
    async () => {
        const response = await agent.talentComReferentiel.getSalaryTypes();
        return response as DictionaryModel[];
    }
)

const talentComReferentielSlice = createSlice({
    name: "talentComReferentiel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTalentComGetJobTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchTalentComGetJobTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.jobTypes = [...action.payload];
            })
            .addCase(fetchTalentComGetJobTypes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchTalentComGetBenefits.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchTalentComGetBenefits.fulfilled, (state, action) => {
                state.loading = false;
                state.benefits = [...action.payload];
            })
            .addCase(fetchTalentComGetBenefits.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchTalentComGetCategories.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchTalentComGetCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = [...action.payload];
            })
            .addCase(fetchTalentComGetCategories.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchTalentComGetPeriods.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchTalentComGetPeriods.fulfilled, (state, action) => {
                state.loading = false;
                state.periods = [...action.payload];
            })
            .addCase(fetchTalentComGetPeriods.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchTalentComGetSalaryTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchTalentComGetSalaryTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.salaryTypes = [...action.payload];
            })
            .addCase(fetchTalentComGetSalaryTypes.rejected, (state, action) => {
                state.loading = false;
            })
        }
});

export const {} = talentComReferentielSlice.actions;
export default talentComReferentielSlice.reducer