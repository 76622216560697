import {Button, ButtonGroup, Col, Row} from 'react-bootstrap'
import SelectJobboards from '../../components/SelectJobboards'
import {JobboardViewModel} from '../../components/jobboard'
import {useEffect, useState} from 'react'
import {JobVacancyDiffusionVM} from '../../../referentiel/core/_models'
import {formatJobboardLabel, formatedDate} from '../../../referentiel/core/_tools'

export const FirstStep = (props: {
  jobboardList: JobboardViewModel[]
  checkItemFunc: (_: JobboardViewModel) => void
  validStep: () => void
  diffusionInformation: JobVacancyDiffusionVM | undefined
}) => {
  const [totalChecked, setTotalChecked] = useState(0)

  const [diffusionInformation, setDiffusionInformation] = useState<
    JobVacancyDiffusionVM | undefined
  >(props.diffusionInformation)

  const [jobboardList, setJobboardList] = useState<JobboardViewModel[]>(props.jobboardList)

  const checkItem = (item: JobboardViewModel) => {
    if (jobboardList === undefined) return
    jobboardList[item.index].isChecked = !jobboardList[item.index].isChecked

    const totalChecked = jobboardList?.filter((vm) => vm.isChecked).length
    setTotalChecked(totalChecked)
    props.checkItemFunc(item)
  }

  useEffect(() => {
    getProperties()
  }, [])

  const getProperties = () => {
    const obj = diffusionInformation as any
    let updatedJobboardList = jobboardList ? [...jobboardList] : []
    for (const key in obj) {
      if (key.endsWith('Property') && obj[key] !== null) {
        let keyValue = key.toString().replace('Property', '')
        if (obj.jobboardLabel !== null) {
          obj.jobboardLabel.forEach(function (jobboard: any) {
            if (formatJobboardLabel(keyValue) === formatJobboardLabel(jobboard)) {
              updatedJobboardList.forEach((element: JobboardViewModel, index: number) => {
                if (formatJobboardLabel(element.label) === formatJobboardLabel(keyValue)) {
                  updatedJobboardList[index] = {
                    ...element,
                    isDiffused: true,
                    diffusionDate: formatedDate(diffusionInformation?.lastModifiedDate),
                  }
                }
              })
            }
          })
        }
      }
    }
    setJobboardList(updatedJobboardList)
  }

  return (
    <>
      <Row>
        <Col>
          <h2>Choisissez vos job boards</h2>
          <SelectJobboards
            totalChecked={totalChecked}
            jobboards={jobboardList}
            checkItemFunc={checkItem}
          />
        </Col>
      </Row>
      {totalChecked > 0 && (
        <ButtonGroup className='floating-group'>
          <Button variant='scopdiffusion' onClick={props.validStep}>
            Prochaine étape
            <i className='fas fa-arrow-right fs-1 action-icon right'></i>
          </Button>
        </ButtonGroup>
      )}
    </>
  )
}
