import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../../api/agent";
import { JobboardModel, InitStateT } from "../../pages/referentiel/core/_models";
import type { PayloadAction } from '@reduxjs/toolkit';


export const fetchJobboards = createAsyncThunk(
    'jobboard/fetchJobboards',
    async () => {
      const response = await agent.Jobboards.list();
      return response as JobboardModel[];
    }
  );

const initialState: InitStateT<JobboardModel> = {
    entities: [],
    loading: false,
  }

const jobboardSlice = createSlice({
    name: "jobboard",
    initialState,
    reducers: {
        jobboardAdded(state, action : PayloadAction<JobboardModel>) {
          state.entities.push(action.payload);
        },
        jobboardUpdated(state, action : PayloadAction<JobboardModel>) {
          const { id, label, code, type, status } = action.payload as JobboardModel;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            existingItem.label = label;
            existingItem.code = code;
            existingItem.type = type;
            existingItem.status = status
          }
        },
        jobboardDeleted(state, action : PayloadAction<JobboardModel>) {
          const { id } = action.payload;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            state.entities = state.entities.filter((item) => item.id !== id);
          }
        },
      },
    extraReducers:(builder) => {
        builder
        .addCase(fetchJobboards.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchJobboards.fulfilled, (state, action) => {
            state.loading = false;
            state.entities = [...action.payload];
        })
        .addCase(fetchJobboards.rejected, (state, action) => {
            state.loading = false;
        })
      }
})


export const { jobboardAdded, jobboardUpdated, jobboardDeleted } = jobboardSlice.actions;
export default jobboardSlice.reducer
