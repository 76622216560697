import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import { JobVacancyPage } from '../pages/scopdiffusion/diffusion/JobVacancyPage'
import { TenantUsers } from '../pages/scopdiffusion/Tenant/TenantUsers'
import {DiffusionFormPage} from '../pages/scopdiffusion/diffusion/DiffusionFormPage';
import {TenantJobboardPage} from "../pages/scopdiffusion/Tenant/TenantJobboardPage";
const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {/* <Route path="/my-page" element={<MyPage />} /> */}
        {/* dashboard  */}
         <Route path='dashboard' element={<JobVacancyPage />} />
         <Route path='/detail' element={<DiffusionFormPage />} />
        {/* ScopDiffusion Router  */}
        <Route path="/diffusion" element={<JobVacancyPage />} />
        {/* Tenant and subscription  */}
        <Route path='/users' element={<TenantUsers />} />
        <Route path='/jobboards' element={<TenantJobboardPage />} />
       
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='error-scopdiffusion' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
