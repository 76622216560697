import {FC} from 'react'
import { Spinner } from 'react-bootstrap'

const IsLoading: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Chargement...</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </div>
    </>
  )
}

export {IsLoading}
