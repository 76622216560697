import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../../api/agent";
import { CountryModel, InitState } from "../../pages/referentiel/core/_models";
import type { PayloadAction } from '@reduxjs/toolkit';


export const fetchCountries = createAsyncThunk(
    'country/fetchCountries',
    async () => {
      const response = await agent.Countries.list()
      return response as CountryModel[];
    }
  )


const initialState: InitState = {
    entities: [],
    loading: false,
  }
const countrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {
        countryAdded(state, action : PayloadAction<CountryModel>) {
          state.entities.push(action.payload);
        },
        countryUpdated(state, action : PayloadAction<CountryModel>) {
          const { id, label, code, designation } = action.payload as CountryModel;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            existingItem.label = label;
            existingItem.code = code;
            existingItem.designation = designation;
          }
        },
        countryDeleted(state, action : PayloadAction<CountryModel>) {
          const { id } = action.payload;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            state.entities = state.entities.filter((item) => item.id !== id);
          }
        },
      },
    extraReducers:(builder) => {
        builder
        .addCase(fetchCountries.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchCountries.fulfilled, (state, action) => {
            state.loading = false;
            state.entities = [...action.payload];
        })
        .addCase(fetchCountries.rejected, (state, action) => {
            state.loading = false;
        })
      }
})


export const { countryAdded, countryUpdated, countryDeleted } = countrySlice.actions;
export default countrySlice.reducer