import {Col, Form, Row} from 'react-bootstrap'
import {JobboardsLabel, MeteoJobLabels, MeteoJobProperty} from '../../../referentiel/core/_models'
import {useEffect, useState} from 'react'
import {
  useAppDispatch,
  useAppSelector,
  useFormInput,
  useFormSelectInput,
} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {
  fetchMeteoJobContractTypes,
  fetchMeteoJobCountries,
  fetchMeteoJobCurrencies,
  fetchMeteoJobDegrees,
  fetchMeteoJobDepartments,
  fetchMeteoJobExperienceLevels,
  fetchMeteoJobIndustries,
  fetchMeteoJobLanguages,
  fetchMeteoJobLevels,
  fetchMeteoJobLicenses,
  fetchMeteoJobPeriodUnits,
  fetchMeteoJobPeriods,
  fetchMeteoJobRegions,
  fetchMeteoJobShiftWorks,
  fetchMeteoJobTeleWorks,
  fetchMeteoJobTravels,
  fetchMeteoJobWorkSchedules,
} from '../../../../store/slices/meteoJobReferentiel.slice'
import {setToFalse, setToTrue} from '../../../../store/slices/bool.slices'
import DatePicker from 'react-datepicker'

const MeteoJobFormStep = (props: any) => {
  const dispatch = useAppDispatch()

  const entity = props.data ? props.data : undefined

  const emails = useFormInput(entity ? entity.emails : '')
  const contractTypes = useFormSelectInput(
    entity && entity.position ? entity.position.contractTypes : ''
  )
  const town = useFormInput(
    entity && entity.position && entity.position.location ? entity.position.location.town : ''
  )
  const postalCode = useFormInput(
    entity && entity.position && entity.position.location ? entity.position.location.postalCode : ''
  )

  const [expirationDate, setExpirationDate] = useState(entity ? entity.expirationDate : '')
  const [industry, setIndustry] = useState(entity && entity.company ? entity.company.industry : '')
  const [anonymous, setAnonymous] = useState(
    entity && entity.company ? entity.company.anonymous : ''
  )

  const [workSchedules, setWorkSchedules] = useState(
    entity && entity.position ? entity.position.workSchedules : ''
  )
  const [travel, setTravel] = useState(entity && entity.position ? entity.position.travel : '')
  const [telework, setTelework] = useState(
    entity && entity.position ? entity.position.telework : ''
  )
  const [shiftwork, setShiftwork] = useState(
    entity && entity.position ? entity.position.shiftwork : ''
  )

  const [department, setDepartment] = useState(
    entity && entity.position && entity.position.location
      ? entity.position.location.departement
      : ''
  )
  const [region, setRegion] = useState(
    entity && entity.position && entity.position.location ? entity.position.location.region : ''
  )
  const [country, setCountry] = useState(
    entity && entity.position && entity.position.location ? entity.position.location.country : ''
  )

  const [start, setStart] = useState(
    entity && entity.position && entity.position.availability
      ? entity.position.availability.start
      : ''
  )
  const [end, setEnd] = useState(
    entity && entity.position && entity.position.availability
      ? entity.position.availability.end
      : ''
  )
  const [periodValue, setPeriodValue] = useState(
    entity && entity.position && entity.position.availability && entity.position.availability.period
      ? entity.position.availability.period.value
      : ''
  )
  const [periodUnit, setPeriodUnit] = useState(
    entity && entity.position && entity.position.availability && entity.position.availability.period
      ? entity.position.availability.period.unit
      : ''
  )

  const [min, setMin] = useState(
    entity && entity.position && entity.position.salary ? entity.position.salary.min : ''
  )
  const [max, setMax] = useState(
    entity && entity.position && entity.position.salary ? entity.position.salary.max : ''
  )
  const [period, setPeriod] = useState(
    entity && entity.position && entity.position.salary ? entity.position.salary.period : ''
  )
  const [currency, setCurrency] = useState(
    entity && entity.position && entity.position.salary ? entity.position.salary.currency : ''
  )
  const [benefits, setBenefits] = useState(
    entity && entity.position && entity.position.salary ? entity.position.salary.benefits : ''
  )

  const [experienceLevels, setExperienceLevels] = useState(
    entity && entity.profile ? entity.profile.experienceLevels : ''
  )
  const [degrees, setDegrees] = useState(entity && entity.profile ? entity.profile.degrees : '')
  const [licences, setLicences] = useState(entity && entity.profile ? entity.profile.licences : '')
  let lan =
    entity && entity.profile && entity.profile.languages
      ? entity.profile.languages.split(':')[0]
      : ''
  const [languages, setLanguages] = useState(entity ? lan : '')
  let lev =
    entity && entity.profile && entity.profile.languages
      ? entity.profile.languages.split(':')[1]
      : ''
  const [level, setLevel] = useState(entity ? lev : '')

  const handleExpirationDate = (e: any) => setExpirationDate(e.target.value)
  const handleIndustry = (e: any) => setIndustry(e.target.value)

  const handleWorkSchedules = (e: any) => setWorkSchedules(e.target.value)
  const handleTravel = (e: any) => setTravel(e.target.value)
  const handleTelework = (e: any) => setTelework(e.target.value)
  const handleShiftwork = (e: any) => setShiftwork(e.target.value)

  const handleDepartment = (e: any) => setDepartment(e.target.value)
  const handleRegion = (e: any) => setRegion(e.target.value)
  const handleCountry = (e: any) => setCountry(e.target.value)

  const handleStart = (e: any) => setStart(e.target.value)
  const handleEnd = (e: any) => setEnd(e.target.value)
  const handlePeriodUnit = (e: any) => setPeriodUnit(e.target.value)
  const handlePeriodValue = (e: any) => setPeriodValue(e.target.value)

  const handleMin = (e: any) => setMin(e.target.value)
  const handleMax = (e: any) => setMax(e.target.value)
  const handlePeriod = (e: any) => setPeriod(e.target.value)
  const handleCurrency = (e: any) => setCurrency(e.target.value)
  const handleBenefits = (e: any) => setBenefits(e.target.value)

  const handleExperienceLevels = (e: any) => setExperienceLevels(e.target.value)
  const handleDegrees = (e: any) => setDegrees(e.target.value)
  const handleLanguages = (e: any) => setLanguages(e.target.value)
  const handleLicences = (e: any) => setLicences(e.target.value)
  const handleLevel = (e: any) => setLevel(e.target.value)

  const [anonymousValue, setAnonymousValue] = useState(false)
  const handleAnonymous = (e: any) => {
    if (e.target.checked) {
      setAnonymous(1)
      setAnonymousValue(true)
    } else {
      setAnonymous(0)
      setAnonymousValue(false)
    }
  }

  const industriesResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.industries
  )
  const contractTypesResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.contractTypes
  )
  const workSchedulesResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.workSchedules
  )
  const travelsResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.travels
  )
  const teleworksResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.teleworks
  )
  const shiftworksResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.shiftworks
  )
  const departmentsResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.departments
  )
  const regionsResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.regions
  )
  const countriesResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.countries
  )
  const periodUnitsResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.periodUnits
  )
  const periodsResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.periods
  )
  const currenciesResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.currencies
  )
  const experienceLevelsResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.experienceLevels
  )
  const degreesResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.degrees
  )
  const languagesResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.languages
  )
  const licencesResponseList = useAppSelector(
    (state: RootState) => state.meteoJobReferentiel.licences
  )
  const levelsResponseList = useAppSelector((state: RootState) => state.meteoJobReferentiel.levels)

  const handleLists = async () => {
    await dispatch(fetchMeteoJobIndustries())
    await dispatch(fetchMeteoJobContractTypes())
    await dispatch(fetchMeteoJobWorkSchedules())
    await dispatch(fetchMeteoJobTravels())
    await dispatch(fetchMeteoJobTeleWorks())
    await dispatch(fetchMeteoJobShiftWorks())
    await dispatch(fetchMeteoJobDepartments())
    await dispatch(fetchMeteoJobCountries())
    await dispatch(fetchMeteoJobRegions())
    await dispatch(fetchMeteoJobPeriodUnits())
    await dispatch(fetchMeteoJobPeriods())
    await dispatch(fetchMeteoJobCurrencies())
    await dispatch(fetchMeteoJobExperienceLevels())
    await dispatch(fetchMeteoJobDegrees())
    await dispatch(fetchMeteoJobLanguages())
    await dispatch(fetchMeteoJobLicenses())
    await dispatch(fetchMeteoJobLevels())
  }

  useEffect(() => {
    handleLists()
  }, [anonymous, anonymousValue])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const body = {
      reference: '',
      expirationDate: expirationDate,
      title: '',
      emails: emails.value,
      company: {
        industry: industry,
        anonymous: anonymous == true ? true : false,
      },
      position: {
        description: '',
        contractTypes: contractTypes.value,
        workSchedules: workSchedules,
        travel: travel,
        telework: telework,
        shiftwork: shiftwork,
        location: {
          town: town.value,
          postalCode: postalCode.value,
          departement: department,
          region: region,
          country: country,
        },
        availability: {
          start: start,
          end: end,
          period: {
            unit: periodUnit,
            value: periodValue ? +periodValue : 0,
          },
        },
        salary: {
          min: min ? +min : null,
          max: max ? +max : null,
          currency: currency,
          period: period,
          benefits: benefits,
        },
      },
      profile: {
        experienceLevels: experienceLevels,
        degrees: degrees,
        languages: languages ? `${languages}:${level}` : null,
        licences: licences,
      },
    } as MeteoJobProperty
    props.handleMeteoJobForm(body)

    if (
      !emails.error.isError &&
      emails.value &&
      !contractTypes.error.isError &&
      contractTypes.value &&
      !town.error.isError &&
      town.value &&
      !postalCode.error.isError &&
      postalCode.value
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  return (
    <div
      className='card card-bordered step-card card-meteojob'
      onBlur={handleSubmit}
      onMouseLeave={handleSubmit}
    >
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>{JobboardsLabel.METEOJOB}</div>
      </div>
      <div className='card-body'>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.ExpirationDate}</label>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              className='form-select form-select-lg'
              locale='fr'
              selected={expirationDate}
              onChange={(date: any) => setExpirationDate(date)}
            />
          </Col>
          <Col>
            <label className='form-label required'>{MeteoJobLabels.Emails}</label>
            <Form.Control
              value={emails.value}
              onChange={emails.handleChange}
              placeholder={MeteoJobLabels.Emails}
              className={`${
                !emails.error.isError
                  ? 'form-control form-control-lg form-control-solid'
                  : 'form-control form-control-lg form-control-solid border-red'
              }`}
            />
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Industry}</label>
            <Form.Select
              as='select'
              value={industry}
              onChange={handleIndustry}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.IndustrySelectLabel}</option>
              {industriesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Anonymous}</label>
            <br />
            <Form.Check
              inline
              type='checkbox'
              checked={anonymousValue}
              value={anonymous}
              onChange={handleAnonymous}
              id='flexRadioLg'
            />
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label required'>{MeteoJobLabels.ContractTypes}</label>
            <Form.Select
              as='select'
              value={contractTypes.value}
              onChange={contractTypes.handleChange}
              className={`${
                !contractTypes.error.isError
                  ? 'form-control form-control-lg form-control-solid'
                  : 'form-control form-control-lg form-control-solid border-red'
              }`}
            >
              <option>{MeteoJobLabels.ContractTypesSelectLabel}</option>
              {contractTypesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.WorkSchedules}</label>
            <Form.Select
              as='select'
              value={workSchedules}
              onChange={handleWorkSchedules}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.WorkSchedulesSelectLabel}</option>
              {workSchedulesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Travel}</label>
            <Form.Select
              as='select'
              value={travel}
              onChange={handleTravel}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.TravelsSelectLabel}</option>
              {travelsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.TeleWork}</label>
            <Form.Select
              as='select'
              value={telework}
              onChange={handleTelework}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.TeleWorkSelectLabel}</option>
              {teleworksResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.ShiftWork}</label>
            <Form.Select
              as='select'
              value={shiftwork}
              onChange={handleShiftwork}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.ShiftWorkSelectLabel}</option>
              {shiftworksResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label required'>{MeteoJobLabels.Town}</label>
            <Form.Control
              value={town.value}
              onChange={town.handleChange}
              placeholder={MeteoJobLabels.Town}
              className={`${
                !town.error.isError
                  ? 'form-control form-control-lg form-control-solid'
                  : 'form-control form-control-lg form-control-solid border-red'
              }`}
            />
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label required'>{MeteoJobLabels.PostalCode}</label>
            <Form.Control
              value={postalCode.value}
              onChange={postalCode.handleChange}
              placeholder={MeteoJobLabels.PostalCode}
              className={`${
                !postalCode.error.isError
                  ? 'form-control form-control-lg form-control-solid'
                  : 'form-control form-control-lg form-control-solid border-red'
              }`}
            />
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Department}</label>
            <Form.Select
              as='select'
              value={department}
              onChange={handleDepartment}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.DepartmentSelectLabel}</option>
              {departmentsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Region}</label>
            <Form.Select
              as='select'
              value={region}
              onChange={handleRegion}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.RegionSelectLabel}</option>
              {regionsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Country}</label>
            <Form.Select
              as='select'
              value={country}
              onChange={handleCountry}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.CountrySelectLabel}</option>
              {countriesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Start}</label>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              className='form-select form-select-lg'
              locale='fr'
              selected={start}
              onChange={(date: any) => setStart(date)}
            />
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.End}</label>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              className='form-select form-select-lg'
              locale='fr'
              selected={end}
              onChange={(date: any) => setEnd(date)}
            />
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.PeriodValue}</label>
            <Form.Control
              value={periodValue}
              onChange={handlePeriodValue}
              placeholder={MeteoJobLabels.PeriodValue}
              className='form-control form-control-lg form-control-solid'
              type='number'
              min='0'
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault()
                }
              }}
            />
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.PeriodUnit}</label>
            <Form.Select
              as='select'
              value={periodUnit}
              onChange={handlePeriodUnit}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.PeriodUnitSelectLabel}</option>
              {periodUnitsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Min}</label>
            <Form.Control
              value={min}
              onChange={handleMin}
              placeholder={MeteoJobLabels.Min}
              className='form-control form-control-lg form-control-solid'
              type='number'
              min='0'
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault()
                }
              }}
            />
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Max}</label>
            <Form.Control
              value={max}
              onChange={handleMax}
              placeholder={MeteoJobLabels.Max}
              className='form-control form-control-lg form-control-solid'
              type='number'
              min='0'
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault()
                }
              }}
            />
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Period}</label>
            <Form.Select
              as='select'
              value={period}
              onChange={handlePeriod}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.PeriodSelectLabel}</option>
              {periodsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Currency}</label>
            <Form.Select
              as='select'
              value={currency}
              onChange={handleCurrency}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.CurrencySelectLabel}</option>
              {currenciesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Benefits}</label>
            <Form.Control
              value={benefits}
              onChange={handleBenefits}
              placeholder={MeteoJobLabels.Benefits}
              className='form-control form-control-lg form-control-solid'
            />
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.ExperienceLevels}</label>
            <Form.Select
              as='select'
              value={experienceLevels}
              onChange={handleExperienceLevels}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.ExperienceLevelsSelectLabel}</option>
              {experienceLevelsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Degrees}</label>
            <Form.Select
              as='select'
              value={degrees}
              onChange={handleDegrees}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.DegreesSelectLabel}</option>
              {degreesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Languages}</label>
            <Form.Select
              as='select'
              value={languages}
              onChange={handleLanguages}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.LanguagesSelectLabel}</option>
              {languagesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Level}</label>
            <Form.Select
              as='select'
              value={level}
              onChange={handleLevel}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.LevelSelectLabel}</option>
              {levelsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{MeteoJobLabels.Licences}</label>
            <Form.Select
              as='select'
              value={licences}
              onChange={handleLicences}
              className='form-select form-select-lg form-select-solid'
            >
              <option>{MeteoJobLabels.LicencesSelectLabel}</option>
              {licencesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default MeteoJobFormStep
