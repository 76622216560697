import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import agent from "../../api/agent";
import {ActiveTenantJobboardVM, TenantJobboardVM} from "../../pages/referentiel/core/_models";

export interface InitState {
    entities: any[];
    activeEntities: any[];
    entity: any;
    loading: boolean;
}

export const fetchTenantJobboards = createAsyncThunk(
    'tenantJobboard/fetchtenantJobboards',
    async () => {
        const response = await agent.TenantJobboards.list();
        return response as TenantJobboardVM[];
    }
)

export const fetchActiveJobboardsByTenant = createAsyncThunk(
    'tenantJobboard/fetchActiveJobboardsByTenant',
    async () => {
        const response = await agent.TenantJobboards.activeJobboardlistByTenant();
        return response as ActiveTenantJobboardVM[];
    }
)

export const fetchTenantJobboardByLabel = createAsyncThunk(
    'tenantJobboard/fetchTenantJobboardByLabel',
    async (jobboardLabel: any) => {
        const response = await agent.TenantJobboards.getTenantJobboard(jobboardLabel);
        return response as TenantJobboardVM;        
    }
)

const initialState: InitState = {
    entities: [],
    activeEntities: [],
    entity: null,
    loading: false,
}
const tenantJobboardSlice = createSlice({
    name: "tenantJobboard",
    initialState,
    reducers: {
        tenantJobboardUpdated(state, action: PayloadAction<TenantJobboardVM>) {
            const { id, userName, password, credit, siretClient, idRecruteur, internetDomainName, finess_etab, fhfToken, figaroId, legalNameFigaro, numeroDossier, industryCode, legalId } = action.payload as TenantJobboardVM;
            const existingItem = state.entities.find((item) => item.id === id);
            if (existingItem) {
                existingItem.userName = userName;
                existingItem.password = password;
                existingItem.credit = credit;
                existingItem.siretClient = siretClient;
                existingItem.idRecruteur = idRecruteur;
                existingItem.internetDomainName = internetDomainName;
                existingItem.finess_etab = finess_etab;
                existingItem.fhfToken = fhfToken;
                existingItem.figaroId = figaroId;
                existingItem.legalNameFigaro = legalNameFigaro;
                existingItem.numeroDossier = numeroDossier;
                existingItem.industryCode = industryCode;
                existingItem.legalId = legalId;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTenantJobboards.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchTenantJobboards.fulfilled, (state, action) => {
                state.loading = false;
                state.entities = [...action.payload];
            })
            .addCase(fetchTenantJobboards.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchActiveJobboardsByTenant.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchActiveJobboardsByTenant.fulfilled, (state, action) => {
                state.loading = false;
                state.activeEntities = [...action.payload];
            })
            .addCase(fetchActiveJobboardsByTenant.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchTenantJobboardByLabel.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTenantJobboardByLabel.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload;
            })
            .addCase(fetchTenantJobboardByLabel.rejected, (state) => {
                state.loading = false;
            })
    }
})


export const {tenantJobboardUpdated} = tenantJobboardSlice.actions;
export default tenantJobboardSlice.reducer
