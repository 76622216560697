import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../../api/agent";
import { DictionaryModel, InitState, } from "../../pages/referentiel/core/_models";

export const fetchPoleEmploiDepartement = createAsyncThunk(
    'poleEmploiDepartement/fetchPoleEmploiDepartement',
    async () => {
      const response = await agent.poleEmploiReferentiel.getListDepartement();
      return response as DictionaryModel[];
    }
  )


const initialState: InitState = {
    entities: [],
    loading: false,
  }
const poleEmploiDepartementSlice = createSlice({
    name: "poleEmploiDepartement",
    initialState,
    reducers: {
    
      },
    extraReducers:(builder) => {
        builder
        .addCase(fetchPoleEmploiDepartement.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchPoleEmploiDepartement.fulfilled, (state, action) => {
            state.loading = false;
            state.entities = [...action.payload];
        })
        .addCase(fetchPoleEmploiDepartement.rejected, (state, action) => {
            state.loading = false;
        })
      }
})


export const {  } = poleEmploiDepartementSlice.actions;
export default poleEmploiDepartementSlice.reducer
