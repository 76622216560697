import {useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../store/hooks'
import agent from '../../../api/agent'

import {JobboardsLabel, TenantJobboardVM} from '../../referentiel/core/_models'
import {tenantJobboardUpdated} from '../../../store/slices/tenantJobboard.slices'
import {Alert, Col, Form, Row, Toast, ToastContainer} from 'react-bootstrap'
export interface NotificationMessage {
  message?: string
  isNotify?: boolean
}

export const TenantJobboardForm = (props: any) => {
  const dispatch = useAppDispatch()
  const entity = useAppSelector((state) =>
    state.tenantJobboards.entities.find((item) => item.jobboardId === props.data.jobboardId)
  )

  const [userName, setUserName] = useState(entity ? entity.userName : '')
  const [password, setPassword] = useState(entity ? entity.password : '')
  const [credit, setCredit] = useState(entity ? entity.credit : 0)
  const handleUserName = (e: React.ChangeEvent<HTMLInputElement>) => setUserName(e.target.value)
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
  const handleCredit = (e: React.ChangeEvent<HTMLInputElement>) => setCredit(e.target.value)

  const [numeroDossier, setNumeroDossier] = useState(entity ? entity.numeroDossier : '')
  const [industryCode, setIndustryCode] = useState(entity ? entity.industryCode : '')
  const [legalId, setLegalId] = useState(entity ? entity.legalIdCode : '')
  const handleNumeroDossier = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNumeroDossier(e.target.value)
  const handleIndustryCode = (e: React.ChangeEvent<HTMLInputElement>) =>
    setIndustryCode(e.target.value)
  const handleLegalIdCode = (e: React.ChangeEvent<HTMLInputElement>) => setLegalId(e.target.value)

  // PoleEmploi required fields .

  const [siretClient, setSiretClient] = useState(entity ? entity.siretClient : '')
  const [idRecruteur, setIdRecruteur] = useState(entity ? entity.idRecruteur : '')
  const [internetDomainName, setInternetDomainName] = useState(
    entity ? entity.internetDomainName : ''
  )

  const handleSiretClient = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSiretClient(e.target.value)
  const handleIdRecruteur = (e: React.ChangeEvent<HTMLInputElement>) =>
    setIdRecruteur(e.target.value)
  const handleInternetDomainName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInternetDomainName(e.target.value)

  // FHF required
  const [finess_etab, setFiness_etab] = useState(entity ? entity.finess_etab : '')
  const handleFinessEtab = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFiness_etab(e.target.value)

  const [fhfToken, setFhfToken] = useState(entity ? entity.fhfToken : '')
  const handlefhfToken = (e: React.ChangeEvent<HTMLInputElement>) => setFhfToken(e.target.value)

  const [figaroId, setFigaroId] = useState(entity ? entity.figaroId : '')
  const handleFigaroId = (e: React.ChangeEvent<HTMLInputElement>) => setFigaroId(e.target.value)

  const [legalNameFigaro, setLegalNameFigaro] = useState(entity ? entity.legalNameFigaro : '')
  const handleLegalNameFigaro = (e: React.ChangeEvent<HTMLInputElement>) =>
    setLegalNameFigaro(e.target.value)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    try {
      if (props.EditMode) {
        const body = {
          userName,
          password,
          credit,
          siretClient,
          idRecruteur,
          internetDomainName,
          finess_etab,
          fhfToken,
          figaroId,
          legalNameFigaro,
          numeroDossier,
          industryCode,
          legalId,
        } as TenantJobboardVM
        updateTenantJobboard(body)
        clearForm()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const clearForm = () => {
    setUserName('')
    setPassword('')
    setCredit('')
    setSiretClient('')
    setIdRecruteur('')
    setInternetDomainName('')
    setFiness_etab('')
    setFigaroId('')
    setLegalNameFigaro('')
    setNumeroDossier('')
    setIndustryCode('')
    setLegalId('')
  }
  const updateTenantJobboard = (model: TenantJobboardVM) => {
    const body = {
      ...entity,
      userName: model.userName,
      password: model.password,
      credit: model.credit,
      siretClient: model.siretClient,
      idRecruteur: model.idRecruteur,

      internetDomainName: model.internetDomainName,
      finess_etab: model.finess_etab,
      fhfToken: model.fhfToken,
      figaroId: model.figaroId,
      legalNameFigaro: model.legalNameFigaro,
      numeroDossier: model.numeroDossier,
      industryCode: model.industryCode,
      legalId: model.legalId,
    } as TenantJobboardVM

    if (body.jobboardId && body.userName && body.password) {
      agent.TenantJobboards.update(body.jobboardId, body).then((res: any) => {
        if (res.success) {
          props.ModalShow(false)
          setShowAlert(true)
          setAlertVariant('success')

          setAlertMessage(Object.keys(res.messages)[0])

          dispatch(tenantJobboardUpdated(body))
        }
        if (!res.success) {
          setShowAlert(true)
          setAlertVariant('warning')

          setAlertMessage(Object.keys(res.messages)[0])
        }
      })
    } else {
      const _message = 'Veuillez remplir les champs obligatoires'
      setShowAlert(true)

      setAlertVariant('warning')
      setAlertMessage(_message)
      // setError("ERROR");
    }
  }

  const [alertMessage, setAlertMessage] = useState<any>('')
  const [alertVariant, setAlertVariant] = useState<any>('')
  const [showAlert, setShowAlert] = useState(false)

  return (
    <>
      <form>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'></label>
                <input
                  type='text'
                  className='form-control'
                  placeholder="Nom d'utilisateur / Identifiant client"
                  id='label-id'
                  name='User name'
                  onChange={handleUserName}
                  value={userName || ''}
                  required
                  title='Ce champ est obligatoire'
                />
              </div>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'></label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Mot de passe / Clé secrète client'
                  id='code-id'
                  name='Password'
                  onChange={handlePassword}
                  value={password || ''}
                  required
                  title='Ce champ est obligatoire'
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Crédit</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Crédit'
                  id='code-id'
                  name='Credit'
                  onChange={handleCredit}
                  value={credit || 0}
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* Pole emploi input  */}
        {props.data.jobboardLabel &&
        props.data.jobboardLabel.toLowerCase() === JobboardsLabel.POLEEMPLOI.toLowerCase() ? (
          <>
            <h3 className='mb-3'>Section Pole-Emploi</h3>
            <div className='mb-10'>
              <Row>
                <Col>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Siret client'
                    id='siretClient-id'
                    name='siretClient'
                    onChange={handleSiretClient}
                    value={siretClient || ''}
                  />
                </Col>
                <Col>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Identifient recruteur'
                    id='code-id'
                    name='idRecruteur'
                    onChange={handleIdRecruteur}
                    value={idRecruteur || ''}
                  />
                </Col>
              </Row>
            </div>
            <div className='mb-10'>
              <Row>
                <Col>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Votre site internet DNS'
                    id='internetDomaineName-id'
                    name='internetDomaineName'
                    onChange={handleInternetDomainName}
                    value={internetDomainName || ''}
                  />
                </Col>
              </Row>
            </div>
          </>
        ) : null}
        <div className='mb-10'>
          {props.data.jobboardLabel &&
          props.data.jobboardLabel.toLowerCase() ===
            JobboardsLabel.FHF.toLowerCase()
              .split(' ')
              .map((word) => word[0])
              .join('') ? (
            <>
              <Row>
                <Col className='mb-3'>
                  <label className='form-label mb-3'>
                    Le finess géographique de l'établissemen
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Le finess'
                    id='code-id'
                    name='finess_etab'
                    onChange={handleFinessEtab}
                    value={finess_etab || ''}
                  />
                </Col>
                <Col className='mb-3'>
                  <label className='form-label mb-3'>Token</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Token'
                    id='code-id'
                    name='token'
                    onChange={handlefhfToken}
                    value={fhfToken || ''}
                  />
                </Col>
              </Row>
            </>
          ) : null}
        </div>
        {/* Figaro input tenantJobbaord  */}
        {props.data.jobboardLabel &&
        props.data.jobboardLabel.toLowerCase() === JobboardsLabel.FIGARO.toLowerCase() ? (
          <>
            <h3 className='mb-3'>Section Figaro</h3>
            <div className='mb-10'>
              <Row>
                <Col>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='L’identifiant FCMS de la société'
                    id='figaroId-id'
                    name='figaroId'
                    onChange={handleFigaroId}
                    value={figaroId || ''}
                  />
                </Col>
                <Col>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Le nom de la société'
                    id='legalNameFigaro-id'
                    name='legalNameFigaro'
                    onChange={handleLegalNameFigaro}
                    value={legalNameFigaro || ''}
                  />
                </Col>
              </Row>
            </div>
          </>
        ) : null}
        {entity?.jobboardLabel.toLowerCase() === JobboardsLabel.APEC.toLowerCase() ? (
          <>
            <div className='mb-10'>
              <Row>
                <Col>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Numéro dossier'
                    id='ats-id'
                    name='numero-id'
                    onChange={handleNumeroDossier}
                    value={numeroDossier || ''}
                  />
                </Col>
              </Row>
            </div>
            <div className='mb-10'>
              <Row>
                <Col>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Code NAF'
                    id='naf-id'
                    name='Code NAF'
                    onChange={handleIndustryCode}
                    value={industryCode || ''}
                  />
                </Col>
                <Col>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Numéro SIRET'
                    id='legalId-id'
                    name='Numéro SIRET'
                    onChange={handleLegalIdCode}
                    value={legalId || ''}
                  />
                </Col>
              </Row>
            </div>
          </>
        ) : null}
        <div className='mb-10'>
          <button
            type='submit'
            className='btn btn-primary btn-hover-rise me-5'
            onClick={handleSubmit}
          >
            {!props.EditMode ? 'Ajouter' : 'Modifier'}
          </button>
        </div>
        {showAlert ? <Alert variant='{alertVariant}'>{alertMessage}</Alert> : ''}
      </form>
    </>
  )
}
