import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import agent from "../../api/agent";
import {
    DictionaryModel, initApecState
} from "../../pages/referentiel/core/_models";


const initialState: initApecState = {
    regionType: [],
    salaryType: [],
    pertialTimeType: [],
    contractType: [],
    jobStatusType: [],
    experienceLevelType: [],
    loading: false,
}

export const fetchApecGetRegionType = createAsyncThunk(
    'apecReferential/getRegionType',
    async () => {
        const response = await agent.apecReferential.getRegionType();
        return response as DictionaryModel[];
    }
)
export const fetchApecGetSalaryType = createAsyncThunk(
    'apecReferential/getSalaryType',
    async () => {
        const response = await agent.apecReferential.getSalaryType();
        return response as DictionaryModel[];
    }
)
export const fetchApecGetPertialTimeType = createAsyncThunk(
    'apecReferential/getPertialTimeType',
    async () => {
        const response = await agent.apecReferential.getPertialTimeType();
        return response as DictionaryModel[];
    }
)
export const fetchApecGetContractType = createAsyncThunk(
    'apecReferential/getContractType',
    async () => {
        const response = await agent.apecReferential.getContractType();
        return response as DictionaryModel[];
    }
)
export const fetchApecGetJobStatusType = createAsyncThunk(
    'apecReferential/getJobStatusType',
    async () => {
        const response = await agent.apecReferential.getJobStatusType();
        return response as DictionaryModel[];
    }
)

export const fetchApecGetExperienceLevelType = createAsyncThunk(
    'apecReferential/getExperienceLevelType',
    async () => {
        const response = await agent.apecReferential.getExperienceLevelType();
        return response as DictionaryModel[];
    }
)

const apecReferentialSlice = createSlice({
    name: "apecReferential",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchApecGetRegionType.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchApecGetRegionType.fulfilled, (state, action) => {
                state.loading = false;
                state.regionType = [...action.payload];
            })
            .addCase(fetchApecGetRegionType.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchApecGetSalaryType.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchApecGetSalaryType.fulfilled, (state, action) => {
                state.loading = false;
                state.salaryType = [...action.payload];
            })
            .addCase(fetchApecGetSalaryType.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchApecGetPertialTimeType.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchApecGetPertialTimeType.fulfilled, (state, action) => {
                state.loading = false;
                state.pertialTimeType = [...action.payload];
            })
            .addCase(fetchApecGetPertialTimeType.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchApecGetContractType.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchApecGetContractType.fulfilled, (state, action) => {
                state.loading = false;
                state.contractType = [...action.payload];
            })
            .addCase(fetchApecGetContractType.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchApecGetJobStatusType.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchApecGetJobStatusType.fulfilled, (state, action) => {
                state.loading = false;
                state.jobStatusType = [...action.payload];
            })
            .addCase(fetchApecGetJobStatusType.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchApecGetExperienceLevelType.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchApecGetExperienceLevelType.fulfilled, (state, action) => {
                state.loading = false;
                state.experienceLevelType = [...action.payload];
            })
            .addCase(fetchApecGetExperienceLevelType.rejected, (state, action) => {
                state.loading = false;
            })
    },
})

export const {} = apecReferentialSlice.actions;
export default apecReferentialSlice.reducer