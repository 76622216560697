import {Button, Col, Modal, Row, Toast, ToastContainer} from 'react-bootstrap'
import {JobVacancyDiffusionVM, JobVacancyType} from '../../referentiel/core/_models'
import {useLocation, useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {getStatus} from '../../referentiel/core/_tools'
import agent from '../../../api/agent'
import moment from 'moment'

export function UpdateDiffusionPage() {
  const navigate = useNavigate()
  const location = useLocation()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const returnUrl = urlParams.get('returnUrl')

  const locationState = location.state as JobVacancyDiffusionVM

  const [diffusionInformation, setDiffusionInformation] =
    useState<JobVacancyDiffusionVM>(locationState)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [notifyMessage, setNotifyMessage] = useState<any>(null)

  const [notify, setNotify] = useState(false)
  const toggleNotify = () => setNotify(!notify)

  const handleDelete = async () => {
    try {
      await agent.JobVacancy.delete(diffusionInformation.id)
      if (returnUrl === null) {
        navigate('/dashboard')
      } else {
        window.location.href = returnUrl
      }
    } catch (error) {
      setNotifyMessage("Une erreur s'est produite lors de l'archivage de l'offre")
      toggleNotify()
    }
  }

  const handleRediffuse = async () => {
    diffusionInformation.linkedinProperty = undefined;
    diffusionInformation.talentComProperty = undefined;
    diffusionInformation.federationHospitaliereFranceProperty = undefined;
    var result = await agent.JobVacancy.rediffuse(diffusionInformation.id, diffusionInformation)
    if (result.businessObject) {
      navigate(`/diffusion/${result.businessObject.id}?returnUrl=${returnUrl}`)
    }
  }

  return (
    <>
      <ToastContainer className='p-3' position='top-end' style={{zIndex: 1}}>
        <Toast show={notify} onClose={toggleNotify} bg='Warning' delay={3000} autohide>
          <Toast.Header>
            <img src='holder.js/20x20?text=%20' className='rounded me-2' alt='' />
            <strong className='me-auto'>Notification</strong>
          </Toast.Header>
          <Toast.Body>{notifyMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <h2>Détails de la diffusion</h2>
      <br />
      <span>
        <b>Diffusée le : </b>
        {moment(diffusionInformation.postingDate).format('DD/MM/YYYY')}
      </span>
      <span>
        <b>Status : </b>
        {getStatus(diffusionInformation.status)}
      </span>
      <br />
      {diffusionInformation.status != JobVacancyType.DELETED && (
        <>
          <h2>Actions</h2>
          <br />
          <Row className='mt-3'>
            <Col className='col-2'>
              <Button
                variant='success'
                onClick={() => {
                  navigate(`/detail?returnUrl=${returnUrl}`, {
                    state: diffusionInformation,
                  })
                }}
              >
                Modifier la diffusion
              </Button>
            </Col>
            <Col className='col-2'>
              <Button variant='warning' onClick={handleRediffuse}>
                Rediffuser l'offre
              </Button>
            </Col>
            <Col className='col-2'>
              <Button
                variant='danger'
                onClick={() => {
                  setShowDeleteConfirmation(true)
                }}
              >
                Archiver la diffusion
              </Button>
            </Col>
          </Row>
          <Modal
            key={diffusionInformation?.id}
            show={showDeleteConfirmation}
            onHide={() => {
              setShowDeleteConfirmation(false)
            }}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmer l'archivage</Modal.Title>
            </Modal.Header>
            <Modal.Body>Êtes-vous sûr de vouloir archiver cette diffusion ?</Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  setShowDeleteConfirmation(false)
                }}
              >
                Annuler
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  handleDelete()
                  setShowDeleteConfirmation(false)
                }}
              >
                Archiver
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  )
}
