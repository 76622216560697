import React, {FC, useContext, useEffect, useRef, useState} from 'react'
import {} from 'formik'
import {
  useAppDispatch,
  useAppSelector,
  useFormInput,
  useFormSelectInput,
} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {
  DictionaryModel,
  PoleEmploiLabels,
  PoleEmploiProperty,
} from '../../../referentiel/core/_models'
import {Alert, Col, Form, Row} from 'react-bootstrap'
import './secondStep.scss'
import {fetchTenantJobboardByLabel} from '../../../../store/slices/tenantJobboard.slices'

import './inputValidation.scss'
import {setToFalse, setToTrue, toggleValue} from '../../../../store/slices/bool.slices'

const PoleEmploiFormStep = (props: any) => {
  const dispatch = useAppDispatch()

  const entity = props.data ? props.data : undefined
  const poleEmploiPropertyRef = useRef<PoleEmploiProperty | null>(null)

  const [inputValue, setInputValue] = useState<PoleEmploiProperty | null>(null)

  const contractTypes = useFormSelectInput(entity ? entity.contractTypes : '')
  const contractNatures = useFormSelectInput(entity ? entity.contractNatures : '')
  const salaryTypes = useFormSelectInput(entity ? entity.salaryTypes : '')
  const qualificationTypes = useFormSelectInput(entity ? entity.qualificationTypes : '')
  const codeDepartementLieuTravail = useFormInput(entity ? entity.codeDepartementLieuTravail : '')
  const codeCommuneLieuTravail = useFormInput(entity ? entity.codeCommuneLieuTravail : '')
  const libelleCommuneLieuTravail = useFormInput(entity ? entity.libelleCommuneLieuTravail : '')
  const codePaysLieuTravail = useFormInput(entity ? entity.codePaysLieuTravail : '')
  const nbPostesCrees = useFormInput(entity ? entity.nbPostesCrees : '')
  const codeExigibiliteFormation = useFormInput(entity ? entity.codeExigibiliteFormation : '')
  const dateDebutContrat = useFormInput(entity ? entity.dateDebutContrat : '')
  const nbMoisSalaire = useFormInput(entity ? entity.nbMoisSalaire : 0)
  const dateFinPublication = useFormInput(entity ? entity.dateFinPublication : '')
  const salaireMin = useFormInput(entity ? entity.salaireMin : 0)
  const salaireMax = useFormInput(entity ? entity.salaireMax : 0)
  const codeTypeExpPro = useFormSelectInput(entity ? entity.codeTypeExpPro : '')
  const dureeMinExpPro = useFormInput(entity ? entity.dureeMinExpPro : '')
  const typeDureeExpPro = useFormInput(entity ? entity.typeDureeExpPro : '')
  const postalCode = useFormInput(entity ? entity.postalCode : '')

  // la  durée de travail hebdomadaire (nbHeuresHebdo/nbMinutesHebdo)
  const [duration, setDuration] = useState(entity ? entity.duration : '')
  const [nbHeuresHebdo, setNbHeuresHebdo] = useState(entity ? entity.nbHeuresHebdo : '36')
  const [nbMinutesHebdo, setNbMinutesHebdo] = useState(entity ? entity.nbMinutesHebdo : '00')

  const contractTypesResponseList = useAppSelector(
    (state: RootState) => state.poleEmploisContractTypes.entities
  )
  const contractNaturesResponseList = useAppSelector(
    (state: RootState) => state.poleEmploiContractNatures.entities
  )
  const salaryTypesResponseList = useAppSelector(
    (state: RootState) => state.poleEmploiSalaryTypes.entities
  )
  const qualificationTypesResponseList = useAppSelector(
    (state: RootState) => state.poleEmploiQualificationTypes.entities
  )
  const codePaysResponseList = useAppSelector(
    (state: RootState) => state.poleEmploiCountry.entities
  )
  const codeDepartementResponseList = useAppSelector(
    (state: RootState) => state.poleEmploiDepartement.entities
  )

  const [companyDescription, setCompanyDescription] = useState(props.companyDescription)
  const tenantJobboard = useAppSelector((state: RootState) => state.tenantJobboards.entity)

  const handleLists = async () => {
    await dispatch(fetchTenantJobboardByLabel(props.jobboardLabel.toLowerCase()))
    await dispatch(toggleValue())
  }

  useEffect(() => {
    handleLists()
  }, [])

  const convertSalary = (value: any) => parseFloat(value).toFixed(2)

  const changeHandler = (e: any) => {
    e.preventDefault()
    setCompanyDescription(props.companyDescription)
    const body = {
      jobboardLabel: props.jobboardLabel,
      contractTypes: contractTypes.value,
      contractNatures: contractNatures.value,
      salaryTypes: salaryTypes.value,
      qualificationTypes: qualificationTypes.value,
      codeDepartementLieuTravail: (
        parseInt(codeDepartementLieuTravail.value, 10) * 1000
      ).toString(),
      codeCommuneLieuTravail: codeCommuneLieuTravail.value,
      libelleCommuneLieuTravail: libelleCommuneLieuTravail.value,
      codePaysLieuTravail: 'France',
      nbPostesCrees: nbPostesCrees.value,
      codeExigibiliteFormation: codeExigibiliteFormation.value,
      dateDebutContrat: dateDebutContrat.value,
      nbMoisSalaire: nbMoisSalaire.value,
      dateFinpublic: dateFinPublication.value,
      salaireMin: convertSalary(salaireMin.value),
      salaireMax: convertSalary(salaireMax.value),
      duration: 'PT36H00M',
      codeTypeExpPro: codeTypeExpPro.value,
      dureeMinExpPro: dureeMinExpPro.value,
      typeDureeExpPro: typeDureeExpPro.value,
      userName: tenantJobboard?.userName,
      password: tenantJobboard?.password,
      siretClient: tenantJobboard?.siretClient,
      idRecruteur: tenantJobboard?.idRecruteur,
      internetDomainName: tenantJobboard?.internetDomainName,
      postalCode: postalCode.value,
    } as PoleEmploiProperty

    if (props.isEdited && entity) {
      setInputValue(body)
    }

    props.handleForm(body)
    if (
      !postalCode.error.isError &&
      postalCode.value &&
      !contractNatures.error.isError &&
      contractNatures.value.length > 0 &&
      contractNatures.value != 0 &&
      !contractTypes.error.isError &&
      contractTypes.value.length > 0 &&
      contractTypes.value != 0 &&
      !qualificationTypes.error.isError &&
      qualificationTypes.value.length > 0 &&
      qualificationTypes.value != 0 &&
      !salaryTypes.error.isError &&
      salaryTypes.value.length > 0 &&
      salaryTypes.value != 0 &&
      !salaireMin.error.isError &&
      salaireMin.value &&
      !salaireMax.error.isError &&
      salaireMax.value &&
      !nbMoisSalaire.error.isError &&
      nbMoisSalaire.value &&
      !codeTypeExpPro.error.isError &&
      codeTypeExpPro.value.length > 0 &&
      codeTypeExpPro.value != 0 &&
      companyDescription.length &&
      companyDescription.length < 500
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }
  useEffect(() => {
    if (props.isEdited && entity) {
      const _contractNatures = contractNaturesResponseList.find(
        (x: DictionaryModel) => x.id == entity.contractNatures
      ) as DictionaryModel
      contractNatures.handleChange({
        target: {id: _contractNatures?.id},
      })
      const _contractTypes = contractTypesResponseList.find(
        (x: DictionaryModel) => x.id == entity.contractNatures
      ) as DictionaryModel
      contractTypes.handleChange({
        target: {id: _contractTypes?.id},
      })
    }

    poleEmploiPropertyRef.current = inputValue
  }, [companyDescription])
  return (
    <div className='card card-bordered step-card card-poleemploi' onClick={changeHandler}>
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>France Travail</div>
      </div>
      <div className='card-body'>
        {companyDescription.length && companyDescription.length > 500 ? (
          <>
            <Row>
              <Col>
                {' '}
                <Alert variant='danger'>
                  La description de l'entreprise est limitée à 500 caractères par France Travail, merci
                  de modifier votre texte.
                </Alert>
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {PoleEmploiLabels.postalCodeLabel}
                </label>
                {postalCode.error.isError && (
                  <div className='error-message'>{postalCode.error.errorMsg}</div>
                )}
              </div>
              <input
                className={`${
                  !postalCode.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                name='dureeMinExpPro'
                placeholder={PoleEmploiLabels.postalCodeLabel}
                value={postalCode.value}
                onChange={postalCode.handleChange}
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {PoleEmploiLabels.ContractNatureLabel}
                </label>
                {contractNatures.error.isError && (
                  <div className='error-message'>{contractNatures.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='contractNatures'
                value={contractNatures.value}
                onChange={contractNatures.handleChange}
                className={`${
                  !contractNatures.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>{PoleEmploiLabels.ContractNatureSelect}</option>
                {contractNaturesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {PoleEmploiLabels.ContractTypeLabel}
                </label>
                {contractTypes.error.isError && (
                  <div className='error-message'>{contractTypes.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='contractTypes'
                value={contractTypes.value}
                onChange={contractTypes.handleChange}
                className={`${
                  !contractTypes.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>{PoleEmploiLabels.ContractTypeSelect}</option>
                {contractTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {PoleEmploiLabels.QualificationTypeLabel}
                </label>
                {qualificationTypes.error.isError && (
                  <div className='error-message'>{qualificationTypes.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='qualificationTypes'
                value={qualificationTypes.value}
                onChange={qualificationTypes.handleChange}
                className={`${
                  !qualificationTypes.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>{PoleEmploiLabels.QualificationTypeSelect}</option>
                {qualificationTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {PoleEmploiLabels.SalaryTypeLabel}
                </label>
                {salaryTypes.error.isError && (
                  <div className='error-message'>{salaryTypes.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='salaryTypes'
                value={salaryTypes.value}
                onChange={salaryTypes.handleChange}
                className={`${
                  !salaryTypes.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>{PoleEmploiLabels.SalaryTypeSelect}</option>
                {salaryTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {PoleEmploiLabels.salaireMinSelect}
                </label>
                {salaireMin.error.isError && (
                  <div className='error-message'>{salaireMin.error.errorMsg}</div>
                )}
              </div>
              <input
                className={`${
                  !salaireMin.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                name='salaireMin'
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                placeholder={PoleEmploiLabels.salaireMinSelect}
                value={salaireMin.value}
                onChange={salaireMin.handleChange}
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {PoleEmploiLabels.salaireMaxSelect}
                </label>
                {salaireMax.error.isError && (
                  <div className='error-message'>{salaireMax.error.errorMsg}</div>
                )}
              </div>
              <input
                className={`${
                  !salaireMax.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                name='salaireMax'
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                placeholder={PoleEmploiLabels.salaireMaxSelect}
                value={salaireMax.value}
                onChange={salaireMax.handleChange}
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {PoleEmploiLabels.nbMoisSalaireSelect}
                </label>
                {nbMoisSalaire.error.isError && (
                  <div className='error-message'>{nbMoisSalaire.error.errorMsg}</div>
                )}
              </div>
              <input
                className={`${
                  !nbMoisSalaire.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                name='nbMoisSalaire'
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                placeholder={PoleEmploiLabels.nbMoisSalaireSelect}
                value={nbMoisSalaire.value}
                onChange={nbMoisSalaire.handleChange}
              />
            </Col>
          </Row>
        </div>

        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {PoleEmploiLabels.codeDureeExpProSelect}
                </label>
                {codeTypeExpPro.error.isError && (
                  <div className='error-message'>{codeTypeExpPro.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='codeTypeExpPro'
                value={codeTypeExpPro.value}
                className={`${
                  !codeTypeExpPro.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
                onChange={codeTypeExpPro.handleChange}
              >
                <option value='0'>{PoleEmploiLabels.codeDureeExpProSelect}</option>
                <option value='D'>Débutant accepté</option>
                <option value='E'>Expérience exigée</option>
              </Form.Select>
            </Col>
            <Col>
              {codeTypeExpPro.value === 'E' ? (
                <>
                  <div className='mb-3'>
                    <label className='form-label mb-3'>
                      {PoleEmploiLabels.dureeMinExpProSelect}
                    </label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      name='dureeMinExpPro'
                      value={dureeMinExpPro.value}
                      placeholder={PoleEmploiLabels.dureeMinExpProSelect}
                      onChange={dureeMinExpPro.handleChange}
                    />
                    <label className='form-label mb-3 mt-5'>
                      {PoleEmploiLabels.codeDureeExpProSelect}
                    </label>
                    <Form.Select
                      as='select'
                      name='typeDureeExpPro'
                      value={typeDureeExpPro.value}
                      onChange={typeDureeExpPro.handleChange}
                      className='form-select form-select-lg form-select-solid'
                    >
                      <option>{PoleEmploiLabels.typeDureeExpProSelect}</option>
                      <option value='AN'>Par nombre d'année</option>
                      <option value='MO'>Par nombre de mois</option>
                    </Form.Select>
                  </div>
                </>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export {PoleEmploiFormStep}
