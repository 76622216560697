import { configureStore } from "@reduxjs/toolkit";


import countryReducer from "./slices/country.slices";
import monsterDiffusionReducer from './slices/monster.slices';
import jobVacancyReducer from './slices/jobVacancy.slices';
import jobboardReducer from './slices/jobboard.slices';
import tenantReducer from './slices/tenant.slices';
import tenantJobboardReducer from './slices/tenantJobboard.slices';
import poleEmploisContractTypesReducer from './slices/poleEmploiContractTypes.slices';
import poleEmploiContractNaturesReducer from './slices/poleEmploiContractNatures.slices';
import poleEmploiSalaryTypesReducer from './slices/poleEmploiSalaryTypes.slices';
import poleEmploiQualificationTypesReducer from './slices/poleEmploiQualificationTypes.slices';
import diffusionReducer from './slices/diffusion.slices';
import poleEmploiCountryReducer from './slices/poleEmploiCountry.slices';
import poleEmploiDepartementReducer from './slices/poleEmploiDepartement.slices';
import diffusionbyidReducer from './slices/diffusionbyurl.slices';
import monsterReferentielReducer from './slices/monsterReferentiel.slices';
import poleEmploiReferentielReducer from "./slices/poleEmploiReferentiel.slices";
import leBonCoinReferentielReducer from "./slices/leBonCoinReferentiel.slice";
import linkedinReferentielReducer from "./slices/linkedinReferentiel.slice";
import helloWorkReferentielReducer from "./slices/helloWorkReferentiel.slice";
import emploiTerritorialReferentielReducer from "./slices/emploiTerritorialReferentiel.slice";
import meteoJobReferentielReducer from "./slices/meteoJobReferentiel.slice";
import talentComReferentielReducer from "./slices/talentComReferentiel.slice";
import welcomeToTheJungleReferentielReducer from './slices/welcomeToTheJungleReferentiel.slice';
import boolReducer from './slices/bool.slices';
import userReducer from './slices/user.slice';

import apecReferentialReducer from "./slices/apecReferentiel.slices";
export const store = configureStore({
    reducer: {
        country: countryReducer,
        monsterDiffusion: monsterDiffusionReducer,
        jobVacancy: jobVacancyReducer,
        jobboard: jobboardReducer,
        tenant: tenantReducer,
        tenantJobboards : tenantJobboardReducer,
        poleEmploisContractTypes : poleEmploisContractTypesReducer,
        poleEmploiContractNatures : poleEmploiContractNaturesReducer,
        poleEmploiSalaryTypes : poleEmploiSalaryTypesReducer,
        poleEmploiQualificationTypes: poleEmploiQualificationTypesReducer,
        diffusion : diffusionReducer,
        poleEmploiCountry : poleEmploiCountryReducer,
        poleEmploiDepartement: poleEmploiDepartementReducer,
        diffusionbyid : diffusionbyidReducer,
        monsterReferentiel : monsterReferentielReducer,
        poleEmploiReferentiel : poleEmploiReferentielReducer,
        leBonCoinReferentiel: leBonCoinReferentielReducer,
        linkedinReferentiel: linkedinReferentielReducer,
        helloWorkReferentiel: helloWorkReferentielReducer,
        emploiTerritorialReferentiel: emploiTerritorialReferentielReducer,
        meteoJobReferentiel: meteoJobReferentielReducer,
        talentComReferentiel: talentComReferentielReducer,
        welcomeToTheJungleReferentiel: welcomeToTheJungleReferentielReducer,
        bool : boolReducer,
        user : userReducer,
        apecReferential: apecReferentialReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

