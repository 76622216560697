import {ErrorMessage, Field} from 'formik'
import {RootState} from '../../../../store'
import {
  useAppDispatch,
  useAppSelector,
  useFormInput,
  useFormInputWithCheck,
  useFormSelectInput,
} from '../../../../store/hooks'
import {
  APECLabel,
  ApecProperty,
  MonsterLabel,
  PoleEmploiLabels,
} from '../../../referentiel/core/_models'
import React, {useEffect, useRef, useState} from 'react'
import {Col, Form, FormCheck, Row} from 'react-bootstrap'
import makeAnimated from 'react-select/animated'

import {fetchTenantJobboardByLabel} from '../../../../store/slices/tenantJobboard.slices'
import {
  fetchApecGetContractType,
  fetchApecGetExperienceLevelType,
  fetchApecGetJobStatusType,
  fetchApecGetPertialTimeType,
  fetchApecGetRegionType,
  fetchApecGetSalaryType,
} from '../../../../store/slices/apecReferentiel.slices'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import './inputValidation.scss'
import Feedback from 'react-bootstrap/esm/Feedback'
import {setToFalse, setToTrue, toggleValue} from '../../../../store/slices/bool.slices'
import {transformToScale} from '../../../referentiel/core/_tools'
const ApecFormStep = (props: any) => {
  const entity = props.data ? props.data : undefined
  const animatedComponents = makeAnimated()
  const dispatch = useAppDispatch()

  const apecRegionTypeList = useAppSelector((state: RootState) => state.apecReferential.regionType)
  const apecSalaryTypeList = useAppSelector((state: RootState) => state.apecReferential.salaryType)
  const apecPertialTimeTypeList = useAppSelector(
    (state: RootState) => state.apecReferential.pertialTimeType
  )
  const apecContractTypeList = useAppSelector(
    (state: RootState) => state.apecReferential.contractType
  )
  const apecJobStatusTypeList = useAppSelector(
    (state: RootState) => state.apecReferential.jobStatusType
  )

  const apecExperienceLevelTypeList = useAppSelector(
    (state: RootState) => state.apecReferential.experienceLevelType
  )

  const tenantJobboard = useAppSelector((state: RootState) => state.tenantJobboards.entity)

  const apecRegionValue = useFormInput(entity ? entity.region : '')
  const apecSalaryValue = useFormSelectInput(entity ? entity.displayPay : '')
  const [apecPertialTimeValue, setApecPertialTimeValue] = useState(
    entity ? entity.apecPertialTimeValue : ''
  )
  const apecContractValue = useFormSelectInput(entity ? entity.jobType : '')
  const apecJobStatusValue = useFormSelectInput(entity ? entity.jobStatus : '')
  const apecExperienceLevelValue = useFormSelectInput(entity ? entity.experienceLevel : '')
  const [apecExperienceLevelLabel, setApecExperienceLevelLabel] = useState(
    entity ? entity.apecExperienceLevelValue : ''
  )
  const apecBasePayAmountMaxValue = useFormInput(
    entity ? (entity.basePayAmountMax ? transformToScale(entity.basePayAmountMax) : '0') : ''
  )
  const apecBasePayAmountMinValue = useFormInput(
    entity ? (entity.basePayAmountMin ? transformToScale(entity.basePayAmountMin) : '0') : ''
  )
  const [apecDurationValue, setApecDurationValue] = useState(entity ? entity.apecDurationValue : '')
  const [apecPartTimeValue, setApecPartTimeValue] = useState(
    entity ? entity.apecPartTimeValue : false
  )
  const [isApecPartTimeValue, setIsApecPartTimeValue] = useState(false)

  const handleLists = async () => {
    await dispatch(fetchApecGetRegionType())
    await dispatch(fetchApecGetSalaryType())
    await dispatch(fetchApecGetPertialTimeType())
    await dispatch(fetchApecGetContractType())
    await dispatch(fetchApecGetJobStatusType())
    await dispatch(fetchApecGetExperienceLevelType())

    await dispatch(fetchTenantJobboardByLabel(props.jobboardLabel.toLowerCase()))
    await dispatch(toggleValue())
  }

  useEffect(() => {
    handleLists()
  }, [isApecPartTimeValue, apecPartTimeValue])

  const handleApecPartTime = (e: any) => {
    if (e.target.checked) {
      setApecPartTimeValue(true)
      setIsApecPartTimeValue(true)
    } else {
      setApecPartTimeValue(false)
      setIsApecPartTimeValue(false)
    }
  }
  const handleApecDuration = (e: any) => setApecDurationValue(e.target.value)
  const handleApecPertialTime = (e: any) => setApecPertialTimeValue(e.target.value)

  const getExperienceLevelById = (data: any, id: string) => {
    const item = data.find((element: any) => element.id === id)
    return item ? item.label : ''
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()

    const getExperienceLevelItem = getExperienceLevelById(
      apecExperienceLevelTypeList,
      apecExperienceLevelValue.value
    )

    setApecExperienceLevelLabel(getExperienceLevelItem)
    const _basePayAmountMax = parseInt(apecBasePayAmountMaxValue.value) * 1000
    const _basePayAmountMin = parseInt(apecBasePayAmountMinValue.value) * 1000
    const body = {
      jobboardLabel: props.jobboardLabel,
      region: apecRegionValue.value,
      experienceLevel: apecExperienceLevelValue.value,
      basePayAmountMax: apecBasePayAmountMaxValue.value ? _basePayAmountMax.toString() : '',
      basePayAmountMin: apecBasePayAmountMinValue.value ? _basePayAmountMin.toString() : '',
      duration: apecDurationValue,
      jobStatus: apecJobStatusValue.value,
      displayPay: apecSalaryValue.value,
      partTime: apecPartTimeValue.toString(),
      partTimeDuration: apecPertialTimeValue.toString(),
      jobType: apecContractValue.value,
      experienceLevelLabel: getExperienceLevelItem,
      numeroDossier: tenantJobboard.numeroDossier,
      industryCode: tenantJobboard.industryCode,
      legalId: tenantJobboard?.legalId,
    } as ApecProperty
    props.handleForm(body)

    if (
      !apecRegionValue.error.isError &&
      apecRegionValue.value &&
      !apecBasePayAmountMinValue.error.isError &&
      apecBasePayAmountMinValue.value &&
      !apecBasePayAmountMaxValue.error.isError &&
      apecBasePayAmountMaxValue.value &&
      !apecSalaryValue.error.isError &&
      apecSalaryValue.value.length > 0 &&
      apecSalaryValue.value != 0 &&
      !apecContractValue.error.isError &&
      apecContractValue.value.length > 0 &&
      apecContractValue.value != 0 &&
      !apecJobStatusValue.error.isError &&
      apecJobStatusValue.value.length > 0 &&
      apecJobStatusValue.value != 0 &&
      !apecExperienceLevelValue.error.isError &&
      apecExperienceLevelValue.value.length > 0 &&
      apecExperienceLevelValue.value != 0
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  return (
    <div
      className='card card-bordered step-card card-apec'
      onBlur={handleSubmit}
      onMouseLeave={handleSubmit}
    >
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>{APECLabel.label}</div>
      </div>
      <div className='card-body'>
        {/*region*/}
        <div className='mb-3'>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='form-label mb-3 required'>{APECLabel.locationLabel}</label>
            {apecRegionValue.error.isError && (
              <div className='error-message'>{apecRegionValue.error.errorMsg}</div>
            )}
          </div>
          <input
            className={`${
              !apecRegionValue.error.isError
                ? 'form-control form-control-lg form-control-solid'
                : 'form-control form-control-lg form-control-solid border-red'
            }`}
            placeholder='ex : 75056'
            name='salaireMin'
            value={apecRegionValue.value}
            onChange={apecRegionValue.handleChange}
          />
        </div>
        <div className='mb-3'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{APECLabel.minSalary}</label>
                {apecBasePayAmountMinValue.error.isError && (
                  <div className='error-message'>{apecBasePayAmountMinValue.error.errorMsg}</div>
                )}
              </div>
              <input
                className={`${
                  !apecBasePayAmountMinValue.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                placeholder={APECLabel.minSalary}
                name='salaireMin'
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                value={apecBasePayAmountMinValue.value}
                onChange={apecBasePayAmountMinValue.handleChange}
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{APECLabel.maxSalary}</label>
                {apecBasePayAmountMaxValue.error.isError && (
                  <div className='error-message'>{apecRegionValue.error.errorMsg}</div>
                )}
              </div>
              <input
                className={`${
                  !apecBasePayAmountMaxValue.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                placeholder={APECLabel.maxSalary}
                name='salaireMax'
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                value={apecBasePayAmountMaxValue.value}
                onChange={apecBasePayAmountMaxValue.handleChange}
              />
            </Col>
          </Row>
        </div>
        {/*type salaire */}
        <div className='mb-3'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {APECLabel.typeSalary}
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='button-tooltip-2'>
                        Affichez la rémunération proposée afin d’attirer les cadres en activité.
                        Pour un poste cadre du secteur privé, indiquez la rémunération vous permet
                        d’obtenir le pictogramme OQA (Offre Qualifiée Apec). Le salaire minimum ne
                        doit pas être inférieur à 19k€ (approximativement le minimum légal pour un
                        poste à temps plein).
                      </Tooltip>
                    }
                  >
                    <i className='fa fa-info-circle fs-1 right'></i>
                  </OverlayTrigger>
                </label>
                {apecSalaryValue.error.isError && (
                  <div className='error-message'>{apecSalaryValue.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='salaryTypes'
                value={apecSalaryValue.value}
                onChange={apecSalaryValue.handleChange}
                className={`${
                  !apecSalaryValue.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>{APECLabel.selectTypeSalary}</option>
                <option value='1'>{`A partir de ${
                  apecBasePayAmountMaxValue.value ? apecBasePayAmountMaxValue.value : 'X'
                } k€ brut annuel`}</option>
                <option value='2'>{`${
                  apecBasePayAmountMinValue.value ? apecBasePayAmountMinValue.value : 'X'
                } - ${
                  apecBasePayAmountMaxValue.value ? apecBasePayAmountMaxValue.value : 'Y'
                } k€ brut annuel`}</option>
                <option value='3'>A négocier</option>
              </Form.Select>
            </Col>
          </Row>
        </div>

        {/*le temps partiel */}
        <div className='mb-3'>
          <Row>
            <Col>
              <label className='form-label mb-3'>{APECLabel.partialTime}</label>
              <Form.Check
                type={'checkbox'}
                id={`apecPertialTime-id`}
                checked={isApecPartTimeValue}
                value={apecPartTimeValue}
                onChange={handleApecPartTime}
              />
            </Col>
            <Col>
              <label className='form-label mb-3'></label>
              {apecPartTimeValue ? (
                <Form.Select
                  name='apecPertialTimeValue'
                  className='form-select form-select-lg form-select-solid'
                  value={apecPertialTimeValue}
                  onChange={handleApecPertialTime}
                >
                  <option>{APECLabel.selectPartialTime}</option>
                  {apecPertialTimeTypeList.map((item: any, index: any) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.label}
                      </option>
                    )
                  })}
                </Form.Select>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
        {/*type de contrat */}
        <div className='mb-3'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{APECLabel.contractType}</label>
                {apecContractValue.error.isError && (
                  <div className='error-message'>{apecContractValue.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='apecContractValue'
                className={`${
                  !apecContractValue.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
                value={apecContractValue.value}
                onChange={apecContractValue.handleChange}
              >
                <option value='0'>{APECLabel.selectContractType}</option>
                {apecContractTypeList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              {apecContractValue.value === '5' || apecContractValue.value === '8' ? (
                <>
                  <label className='form-label mb-3'>{APECLabel.duration}</label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    name='salaireMin'
                    value={apecDurationValue}
                    placeholder={APECLabel.duration}
                    onChange={handleApecDuration}
                  />
                </>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
        <div className='mb-3'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{APECLabel.status}</label>
                {apecJobStatusValue.error.isError && (
                  <div className='error-message'>{apecJobStatusValue.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='apecJobStatusValue'
                className={`${
                  !apecJobStatusValue.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
                value={apecJobStatusValue.value}
                onChange={apecJobStatusValue.handleChange}
              >
                <option value='0'>{APECLabel.selectStatus}</option>
                {apecJobStatusTypeList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{APECLabel.experienceLevel}</label>
                {apecExperienceLevelValue.error.isError && (
                  <div className='error-message'>{apecExperienceLevelValue.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='apecJobStatusValue'
                className={`${
                  !apecExperienceLevelValue.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
                value={apecExperienceLevelValue.value}
                onChange={apecExperienceLevelValue.handleChange}
              >
                <option value='0'>{APECLabel.selectExperienceLevel}</option>
                {apecExperienceLevelTypeList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export {ApecFormStep}
