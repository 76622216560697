import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../../api/agent";
import { InitState, InitialState, JobVacancyModel, JobboardJobVacancyModel, ResultDiffusionVM } from "../../pages/referentiel/core/_models";
import type { PayloadAction } from '@reduxjs/toolkit';


export const fetchdiffusioninfo = createAsyncThunk(
    'diffusionbyid/fetchdiffusioninfo',
    async (id : any) => {
      const response = await agent.JobVacancy.getDiffusionById(id);
      return response as ResultDiffusionVM;
    }
  )


const initialState: InitialState = {
    entity: {},
    loading: false,
  }
const diffusionbyidSlice = createSlice({
    name: "diffusionbyid",
    initialState,
    reducers: {},
    extraReducers:(builder) => {
        builder
        .addCase(fetchdiffusioninfo.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchdiffusioninfo.fulfilled, (state, action) => {
            state.loading = false;
            state.entity = action.payload;
        })
        .addCase(fetchdiffusioninfo.rejected, (state, action) => {
            state.loading = false;
        })
      }
})


export const {  } = diffusionbyidSlice.actions;
export default diffusionbyidSlice.reducer
