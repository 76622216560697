import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitState } from "../../pages/referentiel/core/_models";

const initialState: InitState = {
    entities: [],
    loading: false
}

const monsterDiffusionSlice = createSlice({
    name: 'monsterDiffusion',
    initialState,
    reducers: {
        monsterDiffusionAdded(state, action : PayloadAction<any>) {
          state.entities.push(action.payload);
        },
        monsterDiffusionUpdated(state, action : PayloadAction<any>) {
          const { id, label, code, designation } = action.payload as any;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            existingItem.label = label;
            existingItem.code = code;
            existingItem.designation = designation;
          }
        },
        monsterDiffusionDeleted(state, action : PayloadAction<any>) {
          const { id } = action.payload;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            state.entities = state.entities.filter((item) => item.id !== id);
          }
        },
      }
})

export const { monsterDiffusionAdded, monsterDiffusionUpdated, monsterDiffusionDeleted } = monsterDiffusionSlice.actions;
// export const selectCountry = (state: RootState) => state.country.entities;
export default monsterDiffusionSlice.reducer
