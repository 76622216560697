import {RootState} from '../../../../store'
import {
  useAppDispatch,
  useAppSelector,
  useFormInput,
  useFormSelectInput,
  useSelectMultipleInput,
} from '../../../../store/hooks'
import {EmploiCollectivitesProperty} from '../../../referentiel/core/_models'
import {useEffect, useState} from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import DatePicker, {registerLocale} from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import 'react-datepicker/dist/react-datepicker.css'
import {dateFormatedDiffusion} from '../../../referentiel/core/_tools'

import './secondStep.scss'
import './inputValidation.scss'
import {setToFalse, setToTrue, toggleValue} from '../../../../store/slices/bool.slices'
const EmploiCollectivitesFormStep = (props: any) => {
  registerLocale('fr', fr)
  const entity = props.data ? props.data : undefined

  const animatedComponents = makeAnimated()
  const dispatch = useAppDispatch()

  const monsterIndustriesList = useAppSelector(
    (state: RootState) => state.monsterReferentiel.industries
  )

  const famille_de_metiers = useSelectMultipleInput()

  const filieres = useSelectMultipleInput()

  const cadre_d_emplois = useSelectMultipleInput()

  const statut = useFormSelectInput(entity ? entity.statut : '')

  const type_de_contrat = useFormSelectInput(entity ? entity.type_de_contrat : '')

  const temps_complet_partiel = useFormSelectInput(entity ? entity.temps_complet_partiel : '')

  const niveau_d_etudes = useFormSelectInput(entity ? entity.niveau_d_etudes : '')

  const experience = useFormSelectInput(entity ? entity.experience : '')

  const [date_de_debut, setdate_de_debut] = useState(
    entity?.date_de_debut ? dateFormatedDiffusion(entity?.date_de_debut) : new Date()
  )
  const [date_fin_de_publication, setdate_fin_de_publication] = useState(
    entity?.date_fin_de_publication
      ? dateFormatedDiffusion(entity?.date_fin_de_publication)
      : new Date()
  )

  const region_du_poste = useFormSelectInput(entity ? entity.region_du_poste : '')

  const departement_du_poste = useFormSelectInput(entity ? entity.departement_du_poste : '')

  const ville_du_poste = useFormInput(entity ? entity.ville_du_poste : '')

  const destinataires_des_candidatures = useFormInput(
    entity ? entity.destinataires_des_candidatures : ''
  )

  const [description_de_la_collectivite, setdescription_de_la_collectivite] = useState(
    entity ? entity.description_de_la_collectivite : ''
  )
  const handledescription_de_la_collectivite = (e: any) => {
    setdescription_de_la_collectivite(e.target.value)
  }
  const tenantJobboard = useAppSelector((state: RootState) => state.tenantJobboards.entity)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const body = {
      jobboardLabel: props.jobboardLabel,
      date_de_debut: date_de_debut,
      date_fin_de_publication: date_fin_de_publication,
      departement_du_poste: departement_du_poste.value,
      destinataires_des_candidatures: destinataires_des_candidatures.value,
      experience: experience.value,
      niveau_d_etudes: niveau_d_etudes.value,
      region_du_poste: region_du_poste.value,
      statut: statut.value,
      temps_complet_partiel: temps_complet_partiel.value,
      type_de_contrat: type_de_contrat.value,
      ville_du_poste: ville_du_poste.value,
      cadre_d_emplois: cadre_d_emplois.value?.map((item: any) => item.value),
      famille_de_metiers: famille_de_metiers.value?.map((item: any) => item.value),
      filieres: filieres.value?.map((item: any) => item.value),
      description_de_la_collectivite: description_de_la_collectivite,
    } as EmploiCollectivitesProperty
    props.handleForm(body)

    if (
      !famille_de_metiers.error.isError &&
      Object.keys(famille_de_metiers.value).length > 0 &&
      !filieres.error.isError &&
      Object.keys(filieres.value).length > 0 &&
      !cadre_d_emplois.error.isError &&
      Object.keys(cadre_d_emplois.value).length > 0 &&
      !statut.error.isError &&
      statut.value.length > 0 &&
      statut.value != 0 &&
      !type_de_contrat.error.isError &&
      type_de_contrat.value.length > 0 &&
      type_de_contrat.value != 0 &&
      !temps_complet_partiel.error.isError &&
      temps_complet_partiel.value.length > 0 &&
      temps_complet_partiel.value != 0 &&
      !niveau_d_etudes.error.isError &&
      niveau_d_etudes.value.length > 0 &&
      niveau_d_etudes.value != 0 &&
      !experience.error.isError &&
      experience.value.length > 0 &&
      experience.value != 0 &&
      !region_du_poste.error.isError &&
      region_du_poste.value.length > 0 &&
      region_du_poste.value != 0 &&
      !departement_du_poste.error.isError &&
      departement_du_poste.value.length > 0 &&
      departement_du_poste.value != 0 &&
      !ville_du_poste.error.isError &&
      ville_du_poste.value &&
      !destinataires_des_candidatures.error.isError &&
      destinataires_des_candidatures.value
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  const famille_de_metiers_options = [
    {value: '1', label: 'Divers'},
    {value: '2', label: 'Direction générale'},
    {value: '3', label: 'Population-Administration Générale'},
    {value: '4', label: 'Juridique'},
    {value: '5', label: 'Finances - Contrôle gestion'},
    {value: '7', label: 'Informatique - NTIC'},
    {value: '8', label: 'Communication'},
    {value: '9', label: 'Economie - Développement'},
    {value: '10', label: 'Environnement'},
    {value: '11', label: 'Urbanisme'},
    {value: '12', label: 'Transports'},
    {value: '13', label: 'Ressources humaines'},
    {value: '14', label: 'Logement - Habitat'},
    {value: '15', label: 'Logistique - Maintenance - Entretien'},
    {value: '16', label: 'Voirie - Travaux Publics'},
    {value: '17', label: 'Espaces verts - publics'},
    {value: '18', label: 'Bâtiment - patrimoine bâti'},
    {value: '19', label: 'Propreté et déchets'},
    {value: '20', label: 'Eau - Assainissement'},
    {value: '21', label: 'Social'},
    {value: '22', label: 'Animation - jeunesse'},
    {value: '23', label: 'Education'},
    {value: '24', label: 'Restauration'},
    {value: '25', label: 'Santé - Médico-social'},
    {value: '27', label: 'Culture - Conserv patrimoine'},
    {value: '28', label: 'Médiathèques'},
    {value: '29', label: 'Enseignements artistiques'},
    {value: '30', label: 'Sports'},
    {value: '31', label: 'Police - Sécurité'},
    {value: '32', label: 'Incendie'},
    {value: '33', label: 'Petite enfance'},
  ]

  const filieres_options = [
    {value: '1', label: 'Administratif'},
    {value: '2', label: 'Culturel'},
    {value: '4', label: 'Technique'},
    {value: '5', label: 'Animation'},
    {value: '6', label: 'Divers'},
    {value: '7', label: 'Sapeur Pompier'},
    {value: '10', label: 'Police'},
    {value: '11', label: 'Sportif'},
    {value: '12', label: 'Multiple'},
    {value: '14', label: 'Medico-social'},
    {value: '15', label: 'Emploi fonctionnel de direction'},
  ]

  const cadre_d_emplois_options = [
    {value: '1', label: 'Administrateur'},
    {value: '2', label: 'Attaché territorial'},
    {value: '3', label: 'Attaché territorial de conservation du patrimoine-ACP'},
    {value: '4', label: 'Bibliothécaire territorial'},
    {value: '5', label: 'Conservateur territorial de bibliothèque'},
    {value: '6', label: 'Conservateur territorial du patrimoine'},
    {value: '7', label: "Directeur d'établissement territorial d'enseignement artistique"},
    {value: '8', label: "Professeur territorial d'enseignement artistique-PEA"},
    {value: '9', label: 'Biologiste, vétérinaire et pharmacien territorial'},
    {value: '11', label: 'Conseiller territorial socio-éducatif -CSE'},
    {value: '12', label: 'Médecin territorial'},
    {value: '13', label: 'Psychologue territorial'},
    {value: '16', label: 'Sage-femme territoriale'},
    {value: '17', label: 'Conseiller territorial des activités physiques et sportives-CTAPS'},
    {value: '18', label: 'Directeur de police municipale'},
    {
      value: '49',
      label: 'Capitaine, commandant et lieutenant-colonel de sapeurs-pompiers professionnels',
    },
    {value: '50', label: 'Médecin et pharmacien de sapeur-pompier professionnel'},
    {value: '51', label: "Infirmier de sapeur-pompier professionnel-ISP (nouveau cadre d'emplois)"},
    {value: '52', label: 'Cadre de santé de sapeur-pompier professionnel-CSSP'},
    {value: '55', label: 'Secrétaire de mairie'},
    {value: '56', label: 'Directeur Général Adjoint (DGA)'},
    {value: '57', label: 'Directeur des Services Techniques (DST et DGST)'},
    {value: '58', label: 'Directeur Général des Services (DGS)'},
    {value: '203', label: 'Infirmier territorial en soins généraux-ISG'},
    {value: '204', label: 'Ingénieur territorial'},
    {
      value: '217',
      label:
        'Directeur Général des Etablissements publics locaux assimilés à des communes de plus de 400.000 habitants',
    },
    {value: '6000', label: 'Puéricultrice territoriale'},
    {value: '6230', label: 'Pédiatre'},
    {value: '6231', label: 'Gynécologue'},
    {value: '6268', label: 'Directeur Directrice territorial (e)'},
    {value: '6269', label: 'Directeur territorial'},
    {value: '6291', label: 'Médecin du travail (contractuel)'},
    {value: '6292', label: 'Médecin de prévention (contractuel)'},
    {value: '6505', label: 'Pédiatre territorial'},
    {value: '6506', label: 'Gynécologue territorial'},
    {value: '6522', label: 'Ingénieur en chef territorial'},
    {value: '6538', label: 'Cadre territorial de santé paramédical'},
    {value: '6539', label: 'Infirmier territorial cadre de santé'},
    {value: '6540', label: 'Ergothérapeute'},
    {value: '6541', label: 'Puéricultrice cadre territorial de santé'},
    {value: '6542', label: 'Technicien paramédical territorial cadre de santé'},
    {value: '6543', label: 'Masseur-kinésithérapeute'},
    {value: '6544', label: 'Orthophoniste'},
    {value: '6545', label: 'Psychomotricien'},
    {value: '6565', label: "Directeur départemental du service d'incendie et de secours SDIS"},
    {
      value: '6566',
      label: "Directeur départemental adjoint du service d'incendie et de secours SDIS",
    },
    {value: '6567', label: 'Conception et direction des sapeurs-pompiers professionnels'},
    {value: '6594', label: 'Assistant Territorial socio-éducatif ASE'},
    {value: '6595', label: 'Educateur territorial de jeunes enfants - EJE'},
    {value: '6701', label: "Manipulateur d'électroradiologie médicale -MEM"},
    {value: '6703', label: 'Pédicure-podologue'},
    {value: '6704', label: 'Orthoptiste'},
    {value: '20', label: 'Rédacteur territorial'},
    {value: '21', label: 'Animateur territorial'},
    {value: '22', label: "Assistant territorial d'enseignement artistique-ATEA"},
    {
      value: '23',
      label: 'Assistant territorial de conservation du patrimoine et des bibliothèques-ACPB',
    },
    {value: '29', label: 'Infirmier territorial'},
    {value: '30', label: 'Moniteur-éducateur et intervenant familial'},
    {value: '32', label: 'Educateur territorial des activités physiques et sportives-ETAPS'},
    {value: '33', label: 'Chef de service de police municipale'},
    {value: '35', label: 'Technicien territorial'},
    {value: '214', label: 'Lieutenant de sapeur pompier professionnel'},
    {value: '36', label: 'Adjoint administratif territorial-AAT'},
    {value: '37', label: "Adjoint territorial d'animation-ATA"},
    {value: '38', label: 'Adjoint territorial du patrimoine-ATP'},
    {value: '39', label: 'Agent social territorial-AST'},
    {value: '40', label: 'Agent territorial spécialisé des écoles maternelles - ATSEM'},
    {value: '41', label: 'Auxiliaire de puériculture territorial-AP'},
    {value: '42', label: 'Auxiliaire de soins territorial-AS'},
    {value: '43', label: 'Opérateur territorial des activités physiques et sportives-OTAPS'},
    {value: '44', label: 'Agent de police municipale -APM-(policier  municipal)'},
    {value: '45', label: 'Garde champêtre'},
    {value: '46', label: 'Adjoint technique territorial-ATT'},
    {
      value: '47',
      label: "Adjoint technique territorial des établissements d'enseignement-ATTEE (ex TOS )",
    },
    {value: '48', label: 'Agent de maîtrise territorial-AM'},
    {value: '195', label: 'Sapeur et caporal de sapeur-pompier professionnel'},
    {value: '196', label: 'Sous-officier de sapeur pompier professionnel'},
    {value: '26', label: 'Assistant territorial médico-technique'},
    {value: '31', label: 'Rééducateur territorial'},
    {value: '216', label: 'Technicien paramédical'},
  ]

  const statut_options = [
    {value: '0', label: 'Sélectionnez la catégorie'},
    {value: '1', label: 'Titulaire'},
    {value: '2', label: 'Vacataire'},
    {value: '3', label: 'Autre'},
    {value: '6', label: 'Contractuel'},
    {value: '7', label: 'Titulaire ou contractuel'},
  ]

  const type_de_contrat_options = [
    {value: '0', label: 'Sélectionnez le type de contrat'},
    {value: '1', label: 'CDI'},
    {value: '2', label: 'CDD'},
    {value: '4', label: 'Stage'},
    {value: '5', label: 'Titulaire'},
    {value: '6', label: 'CDD ou titulaire'},
    {value: '7', label: 'Médecin en libéral'},
    {value: '8', label: 'Alternance'},
  ]

  const temps_complet_partiel_options = [
    {value: '0', label: 'Sélectionnez le temps de travail'},
    {value: '1', label: 'tps complet'},
    {value: '2', label: 'tps partiel'},
  ]

  const niveau_d_etudes_options = [
    {value: '0', label: "Sélectionnez le niveau d'études"},
    {value: '9', label: 'BEP'},
    {value: '10', label: 'CAP'},
    {value: '11', label: 'BAC'},
    {value: '12', label: 'BAC +1'},
    {value: '13', label: 'BAC +2'},
    {value: '14', label: 'BAC +3'},
    {value: '15', label: 'BAC +4'},
    {value: '16', label: 'BAC +5'},
    {value: '17', label: 'BAC +6'},
    {value: '18', label: 'BAC +7'},
    {value: '22', label: 'Autre'},
    {value: '23', label: 'BAC +8'},
    {value: '24', label: 'BAC +9'},
    {value: '25', label: 'BAC +10'},
    {value: '26', label: 'Indifférent'},
  ]

  const experience_options = [
    {value: '0', label: "Sélectionnez l'expérience"},
    {value: '1', label: 'Jeune diplômé'},
    {value: '2', label: '1 à 2 ans'},
    {value: '3', label: '3 à 5 ans'},
    {value: '4', label: '+ 5 ans'},
    {value: '5', label: 'Autre'},
    {value: '6', label: '2 à 3 ans'},
    {value: '7', label: 'Indifférent'},
    {value: '8', label: 'Première expérience'},
    {value: '9', label: 'Expérience solide'},
  ]

  const region_du_poste_options = [
    {value: '', label: 'Sélectionnez la région'},
    {value: '1', label: "Provence-Alpes-Côte d'Azur"},
    {value: '2', label: 'Auvergne-Rhône-Alpes'},
    {value: '3', label: 'Occitanie'},
    {value: '4', label: 'Nouvelle aquitaine'},
    {value: '5', label: 'Bourgogne-Franche-Comté'},
    {value: '6', label: 'Centre-Val de Loire'},
    {value: '7', label: 'Grand Est'},
    {value: '8', label: 'Île-de-France'},
    {value: '9', label: 'Hauts-De-France'},
    {value: '10', label: 'Pays de la Loire'},
    {value: '11', label: 'Normandie'},
    {value: '12', label: 'Bretagne'},
    {value: '13', label: 'Corse'},
    {value: '14', label: 'Dom-Tom'},
    {value: '15', label: 'Non Définie'},
    {value: '16', label: 'Toutes régions'},
  ]

  const departement_du_poste_options = [
    {value: '', label: 'Sélectionnez le département'},
    {value: '1', label: 'Ain'},
    {value: '38', label: 'Isère'},
    {value: '73', label: 'Savoie'},
    {value: '74', label: 'Haute Savoie'},
    {value: '50', label: 'Manche'},
    {value: '2', label: 'Aisne'},
    {value: '3', label: 'Allier'},
    {value: '4', label: 'Alpes de Haute-Provence'},
    {value: '5', label: 'Hautes Alpes'},
    {value: '6', label: 'Alpes-Maritimes'},
    {value: '7', label: 'Ardèche'},
    {value: '8', label: 'Ardennes'},
    {value: '9', label: 'Ariège'},
    {value: '10', label: 'Aube'},
    {value: '11', label: 'Aude'},
    {value: '12', label: 'Aveyron'},
    {value: '13', label: 'Bouches-du-Rhône'},
    {value: '14', label: 'Calvados'},
    {value: '15', label: 'Cantal'},
    {value: '16', label: 'Charente'},
    {value: '17', label: 'Charente-Maritime'},
    {value: '18', label: 'Cher'},
    {value: '19', label: 'Corrèze'},
    {value: '2A', label: 'Corse-du-Sud'},
    {value: '2B', label: 'Haute-Corse'},
    {value: '21', label: "Côte-d'Or"},
    {value: '22', label: "Côtes-d'Armor"},
    {value: '23', label: 'Creuse'},
    {value: '24', label: 'Dordogne'},
    {value: '25', label: 'Doubs'},
    {value: '26', label: 'Drôme'},
    {value: '27', label: 'Eure'},
    {value: '28', label: 'Eure-et-Loire'},
    {value: '29', label: 'Finistère'},
    {value: '30', label: 'Gard'},
    {value: '31', label: 'Haute Garonne'},
    {value: '32', label: 'Gers'},
    {value: '33', label: 'Gironde'},
    {value: '34', label: 'Hérault'},
    {value: '35', label: 'Ille-et-Vilaine'},
    {value: '36', label: 'Indre'},
    {value: '37', label: 'Indre-et-Loire'},
    {value: '39', label: 'Jura'},
    {value: '40', label: 'Landes'},
    {value: '41', label: 'Loir-et-Cher'},
    {value: '42', label: 'Loire'},
    {value: '43', label: 'Haute Loire'},
    {value: '44', label: 'Loire-Atlantique'},
    {value: '45', label: 'Loiret'},
    {value: '46', label: 'Lot'},
    {value: '47', label: 'Lot-et-Garonne'},
    {value: '48', label: 'Lozère'},
    {value: '49', label: 'Maine-et-Loire'},
    {value: '51', label: 'Marne'},
    {value: '52', label: 'Haute Marne'},
    {value: '53', label: 'Mayenne'},
    {value: '54', label: 'Meurthe-et-Moselle'},
    {value: '55', label: 'Meuse'},
    {value: '56', label: 'Morbihan'},
    {value: '57', label: 'Moselle'},
    {value: '58', label: 'Nièvre'},
    {value: '59', label: 'Nord'},
    {value: '60', label: 'Oise'},
    {value: '61', label: 'Orne'},
    {value: '62', label: 'Pas-de-Calais'},
    {value: '63', label: 'Puy-de-Dôme'},
    {value: '64', label: 'Pyrénées-Atlantiques'},
    {value: '65', label: 'Hautes Pyrénées'},
    {value: '66', label: 'Pyrénées-Orientales'},
    {value: '67', label: 'Bas Rhin'},
    {value: '68', label: 'Haut Rhin'},
    {value: '69', label: 'Rhône'},
    {value: '70', label: 'Haute Saône'},
    {value: '71', label: 'Saône-et-Loire'},
    {value: '72', label: 'Sarthe'},
    {value: '75', label: 'Ville-de-Paris'},
    {value: '76', label: 'Seine-Maritime'},
    {value: '77', label: 'Seine-et-Marne'},
    {value: '78', label: 'Yvelines'},
    {value: '79', label: 'Sèvres'},
    {value: '80', label: 'Somme'},
    {value: '81', label: 'Tarn'},
    {value: '82', label: 'Tarn-et-Garonne'},
    {value: '83', label: 'Var'},
    {value: '84', label: 'Vaucluse'},
    {value: '85', label: 'Vendée'},
    {value: '86', label: 'Vienne'},
    {value: '87', label: 'Haute Vienne'},
    {value: '88', label: 'Vosges'},
    {value: '89', label: 'Yonne'},
    {value: '90', label: 'Territoire-de-Belfort'},
    {value: '91', label: 'Essonne'},
    {value: '92', label: 'Hauts-de-Seine'},
    {value: '93', label: 'Seine-Saint-Denis'},
    {value: '94', label: 'Val-de-Marne'},
    {value: '95', label: "Val-d'Oise"},
    {value: '0', label: 'Non Défini'},
    {value: '96', label: 'Tous Départements'},
    {value: '971', label: 'Guadeloupe'},
    {value: '972', label: 'Martinique'},
    {value: '973', label: 'Guyane'},
    {value: '974', label: 'La réunion'},
    {value: '975', label: 'Saint-Pierre-et-Miquelon'},
    {value: '976', label: 'Mayotte'},
    {value: '977', label: 'Saint-Barthélemy'},
    {value: '978', label: 'Saint-Martin'},
    {value: '986', label: 'Wallis-et-Futuna'},
    {value: '987', label: 'Polynésie française'},
    {value: '988', label: 'Nouvelle-Calédonie'},
  ]

  const handleLists = async () => {
    await dispatch(toggleValue())
  }

  useEffect(() => {
    handleLists()
    if (props.isEdited && entity) {
      const familleMetiersOptions = entity?.famille_de_metiers?.map((value: any) => {
        return famille_de_metiers_options.find((option) => option.value === value.toString())
      })
      famille_de_metiers.handleChange(familleMetiersOptions)

      const filieresOptions = entity?.filieres?.map((value: any) => {
        return filieres_options.find((option) => option.value === value.toString())
      })
      filieres.handleChange(filieresOptions)

      const cadreEmploisOptions = entity?.cadre_d_emplois?.map((value: any) => {
        return cadre_d_emplois_options.find((option) => option.value === value.toString())
      })
      cadre_d_emplois.handleChange(cadreEmploisOptions)
    }
  }, [props.isEdited, entity])

  return (
    <div
      className='card card-bordered step-card card-emploicollectivites'
      onBlur={handleSubmit}
      onMouseLeave={handleSubmit}
    >
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>Emploi Collectivités</div>
      </div>
      <div className='card-body'>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Famille de métiers</label>
                {famille_de_metiers.error.isError && (
                  <div className='error-message'>{famille_de_metiers.error.errorMsg}</div>
                )}
              </div>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={famille_de_metiers_options}
                onChange={famille_de_metiers.handleChange}
                value={famille_de_metiers.value}
                placeholder='Sélectionnez la famille de métiers'
                className={!famille_de_metiers.error.isError ? '' : 'border-red'}
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Filières</label>
                {filieres.error.isError && (
                  <div className='error-message'>{filieres.error.errorMsg}</div>
                )}
              </div>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={filieres_options}
                onChange={filieres.handleChange}
                value={filieres.value}
                placeholder='Sélectionnez les filières'
                className={!filieres.error.isError ? '' : 'border-red'}
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Cadres d'emploi</label>
                {cadre_d_emplois.error.isError && (
                  <div className='error-message'>{cadre_d_emplois.error.errorMsg}</div>
                )}
              </div>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={cadre_d_emplois_options}
                onChange={cadre_d_emplois.handleChange}
                value={cadre_d_emplois.value}
                placeholder="Sélectionnez le cadres d'emploi"
                className={!cadre_d_emplois.error.isError ? '' : 'border-red'}
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Catégories</label>
                {statut.error.isError && (
                  <div className='error-message'>{statut.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='statut'
                className={!statut.error.isError ? '' : 'border-red'}
                value={statut.value}
                onChange={statut.handleChange}
              >
                {statut_options.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Type de contrat</label>
                {type_de_contrat.error.isError && (
                  <div className='error-message'>{type_de_contrat.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='type_de_contrat'
                value={type_de_contrat.value}
                onChange={type_de_contrat.handleChange}
                className={
                  !type_de_contrat.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }
              >
                {type_de_contrat_options.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Temps complet/partiel</label>
                {temps_complet_partiel.error.isError && (
                  <div className='error-message'>{temps_complet_partiel.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='temps_complet_partiel'
                value={temps_complet_partiel.value}
                onChange={temps_complet_partiel.handleChange}
                className={
                  !temps_complet_partiel.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }
              >
                {temps_complet_partiel_options.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Niveau d'études</label>
                {niveau_d_etudes.error.isError && (
                  <div className='error-message'>{niveau_d_etudes.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='niveau_d_etudes'
                value={niveau_d_etudes.value}
                onChange={niveau_d_etudes.handleChange}
                className={
                  !niveau_d_etudes.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }
              >
                {niveau_d_etudes_options.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Expérience</label>
                {experience.error.isError && (
                  <div className='error-message'>{experience.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='experience'
                value={experience.value}
                onChange={experience.handleChange}
                className={
                  !experience.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }
              >
                {experience_options.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label mb-3 me-3 required'>Date de début</label>
              <DatePicker
                dateFormat='dd-MM-yyyy'
                className='form-select form-select-lg'
                locale='fr'
                selected={date_de_debut}
                onChange={(date: any) => setdate_de_debut(date)}
              />
            </Col>
            <Col>
              <label className='form-label mb-3 me-3 required'>Date fin de publication</label>
              <DatePicker
                dateFormat='dd-MM-yyyy'
                className='form-select form-select-lg'
                locale='fr'
                selected={date_fin_de_publication}
                onChange={(date: any) => setdate_fin_de_publication(date)}
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Région du poste</label>
                {region_du_poste.error.isError && (
                  <div className='error-message'>{region_du_poste.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='region_du_poste'
                className={
                  !region_du_poste.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }
                value={region_du_poste.value}
                onChange={region_du_poste.handleChange}
              >
                {region_du_poste_options.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Département du poste</label>
                {departement_du_poste.error.isError && (
                  <div className='error-message'>{departement_du_poste.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='departement_du_poste'
                className={
                  !departement_du_poste.error.isError ? 'form-select form-select-lg' : 'border-red'
                }
                value={departement_du_poste.value}
                onChange={departement_du_poste.handleChange}
              >
                {departement_du_poste_options.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Ville du poste</label>
                {ville_du_poste.error.isError && (
                  <div className='error-message'>{ville_du_poste.error.errorMsg}</div>
                )}
              </div>
              <input
                className={
                  !ville_du_poste.error.isError
                    ? 'form-control form-control-lg'
                    : 'form-control form-control-lg border-red'
                }
                name='ville_du_poste'
                value={ville_du_poste.value}
                onChange={ville_du_poste.handleChange}
                placeholder='Ville du poste'
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Destinataires des candidatures</label>
                {destinataires_des_candidatures.error.isError && (
                  <div className='error-message'>
                    {destinataires_des_candidatures.error.errorMsg}
                  </div>
                )}
              </div>
              <input
                className={
                  !destinataires_des_candidatures.error.isError
                    ? 'form-control form-control-lg'
                    : 'form-control form-control-lg border-red'
                }
                name='destinataires_des_candidatures'
                value={destinataires_des_candidatures.value}
                onChange={destinataires_des_candidatures.handleChange}
                placeholder='Destinataires des candidatures'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <label className='form-label' htmlFor='description_de_la_collectivite'>
            Description de la collectivité
          </label>
          <Form.Control
            className='form-control'
            as='textarea'
            name='description_de_la_collectivite'
            value={description_de_la_collectivite}
            onChange={handledescription_de_la_collectivite}
            style={{height: '250px'}}
            placeholder='Description de la collectivité'
          />
        </div>
      </div>
    </div>
  )
}

export {EmploiCollectivitesFormStep}
