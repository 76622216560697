import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DictionaryModel, InitMeteoJobState } from "../../pages/referentiel/core/_models";
import agent from "../../api/agent";

const initialState: InitMeteoJobState = {
    industries: [],
    contractTypes: [],
    shiftworks: [],
    teleworks: [],
    travels: [],
    workSchedules: [],
    countries: [],
    departments: [],
    regions: [],
    periodUnits: [],
    currencies: [],
    periods: [],
    degrees: [],
    experienceLevels: [],
    languages: [],
    levels: [],
    licences: [],
    loading: false,
}

export const fetchMeteoJobIndustries = createAsyncThunk(
    'meteoJobReferentiel/getIndustries',
    async () => {
        const response = await agent.meteoJobReferentiel.getIndustries();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobContractTypes = createAsyncThunk(
    'meteoJobReferentiel/getContractTypes',
    async () => {
        const response = await agent.meteoJobReferentiel.getContractTypes();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobWorkSchedules = createAsyncThunk(
    'meteoJobReferentiel/getWorkSchedules',
    async () => {
        const response = await agent.meteoJobReferentiel.getWorkSchedules();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobTravels = createAsyncThunk(
    'meteoJobReferentiel/getJobTravels',
    async () => {
        const response = await agent.meteoJobReferentiel.getJobTravels();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobTeleWorks = createAsyncThunk(
    'meteoJobReferentiel/getTeleWorks',
    async () => {
        const response = await agent.meteoJobReferentiel.getTeleWorks();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobShiftWorks = createAsyncThunk(
    'meteoJobReferentiel/getShiftWorks',
    async () => {
        const response = await agent.meteoJobReferentiel.getShiftWorks();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobDepartments = createAsyncThunk(
    'meteoJobReferentiel/getDepartments',
    async () => {
        const response = await agent.meteoJobReferentiel.getDepartments();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobRegions = createAsyncThunk(
    'meteoJobReferentiel/getRegions',
    async () => {
        const response = await agent.meteoJobReferentiel.getRegions();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobCountries = createAsyncThunk(
    'meteoJobReferentiel/getCountries',
    async () => {
        const response = await agent.meteoJobReferentiel.getCountries();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobPeriodUnits = createAsyncThunk(
    'meteoJobReferentiel/getPeriodUnits',
    async () => {
        const response = await agent.meteoJobReferentiel.getPeriodUnits();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobPeriods = createAsyncThunk(
    'meteoJobReferentiel/getPeriods',
    async () => {
        const response = await agent.meteoJobReferentiel.getPeriods();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobCurrencies = createAsyncThunk(
    'meteoJobReferentiel/getCurrencies',
    async () => {
        const response = await agent.meteoJobReferentiel.getCurrencies();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobExperienceLevels = createAsyncThunk(
    'meteoJobReferentiel/getExperienceLevels',
    async () => {
        const response = await agent.meteoJobReferentiel.getExperienceLevels();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobDegrees = createAsyncThunk(
    'meteoJobReferentiel/getDegrees',
    async () => {
        const response = await agent.meteoJobReferentiel.getDegrees();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobLanguages = createAsyncThunk(
    'meteoJobReferentiel/getLanguages',
    async () => {
        const response = await agent.meteoJobReferentiel.getLanguages();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobLicenses = createAsyncThunk(
    'meteoJobReferentiel/getLicenses',
    async () => {
        const response = await agent.meteoJobReferentiel.getLicenses();
        return response as DictionaryModel[];
    }
)

export const fetchMeteoJobLevels = createAsyncThunk(
    'meteoJobReferentiel/getLevels',
    async () => {
        const response = await agent.meteoJobReferentiel.getLevels();
        return response as DictionaryModel[];
    }
)

const meteoJobReferentielSlice = createSlice({
    name: "meteoJobReferentiel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMeteoJobIndustries.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobIndustries.fulfilled, (state, action) => {
                state.loading = false;
                state.industries = [...action.payload];
            })
            .addCase(fetchMeteoJobIndustries.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobContractTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobContractTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.contractTypes = [...action.payload];
            })
            .addCase(fetchMeteoJobContractTypes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobWorkSchedules.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobWorkSchedules.fulfilled, (state, action) => {
                state.loading = false;
                state.workSchedules = [...action.payload];
            })
            .addCase(fetchMeteoJobWorkSchedules.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobTravels.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobTravels.fulfilled, (state, action) => {
                state.loading = false;
                state.travels = [...action.payload];
            })
            .addCase(fetchMeteoJobTravels.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobTeleWorks.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobTeleWorks.fulfilled, (state, action) => {
                state.loading = false;
                state.teleworks = [...action.payload];
            })
            .addCase(fetchMeteoJobTeleWorks.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobShiftWorks.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobShiftWorks.fulfilled, (state, action) => {
                state.loading = false;
                state.shiftworks = [...action.payload];
            })
            .addCase(fetchMeteoJobShiftWorks.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobDepartments.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobDepartments.fulfilled, (state, action) => {
                state.loading = false;
                state.departments = [...action.payload];
            })
            .addCase(fetchMeteoJobDepartments.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobRegions.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobRegions.fulfilled, (state, action) => {
                state.loading = false;
                state.regions = [...action.payload];
            })
            .addCase(fetchMeteoJobRegions.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobCountries.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobCountries.fulfilled, (state, action) => {
                state.loading = false;
                state.countries = [...action.payload];
            })
            .addCase(fetchMeteoJobCountries.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobPeriodUnits.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobPeriodUnits.fulfilled, (state, action) => {
                state.loading = false;
                state.periodUnits = [...action.payload];
            })
            .addCase(fetchMeteoJobPeriodUnits.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobPeriods.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobPeriods.fulfilled, (state, action) => {
                state.loading = false;
                state.periods = [...action.payload];
            })
            .addCase(fetchMeteoJobPeriods.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobCurrencies.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobCurrencies.fulfilled, (state, action) => {
                state.loading = false;
                state.currencies = [...action.payload];
            })
            .addCase(fetchMeteoJobCurrencies.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobExperienceLevels.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobExperienceLevels.fulfilled, (state, action) => {
                state.loading = false;
                state.experienceLevels = [...action.payload];
            })
            .addCase(fetchMeteoJobExperienceLevels.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobDegrees.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobDegrees.fulfilled, (state, action) => {
                state.loading = false;
                state.degrees = [...action.payload];
            })
            .addCase(fetchMeteoJobDegrees.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobLanguages.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobLanguages.fulfilled, (state, action) => {
                state.loading = false;
                state.languages = [...action.payload];
            })
            .addCase(fetchMeteoJobLanguages.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobLevels.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobLevels.fulfilled, (state, action) => {
                state.loading = false;
                state.levels = [...action.payload];
            })
            .addCase(fetchMeteoJobLevels.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchMeteoJobLicenses.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMeteoJobLicenses.fulfilled, (state, action) => {
                state.loading = false;
                state.licences = [...action.payload];
            })
            .addCase(fetchMeteoJobLicenses.rejected, (state, action) => {
                state.loading = false;
            })
        }
});

export const {} = meteoJobReferentielSlice.actions;
export default meteoJobReferentielSlice.reducer