import {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Alert, Button, ButtonGroup, Col, Form, Row, Toast, ToastContainer} from 'react-bootstrap'
import {
  EmploiTerritorialProperty,
  HelloWorkProperty,
  EmploiCollectivitesProperty,
  IndeedProperty,
  JobVacancyDiffusionVM,
  JobboardJobVacancyModel,
  JobboardsLabel,
  LeBonCoinProperty,
  LinkedinProperty,
  MonsterProperty,
  PoleEmploiProperty,
  MeteoJobProperty,
  TalentComProperty,
  WelcomeToTheJungleProperty,
  FederationHospitaliereFranceProperty,
  ClassificationFigaro,
  FigaroProperty,
  ApecProperty,
  JobboardViewModel,
} from '../../../referentiel/core/_models'
import FloatingJobboardList from '../../components/floatingJobboardList'
import {JobboardLogo} from '../../components/jobboard'
import './secondStep.scss'
import {PoleEmploiFormStep} from './PoleEmploiFormStep'
import {MonsterFormStep} from './MonsterFormStep'
import {dateFormatedDiffusion, dateFormatedISO} from '../../../referentiel/core/_tools'

import DatePicker, {registerLocale} from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import 'react-datepicker/dist/react-datepicker.css'
import {EmploiCollectivitesFormStep} from './EmploiCollectivitesFormStep'
import LeBonCoinFromStep from './LeBonCoinFormStep'
import LinkedinFormStep from './LinkedinFormStep'
import HelloWorkFormStep from './HelloWorkFormStep'
import {IndeedFormStep} from './IndeedFormStep'
import MeteoJobFormStep from './MeteoJobFormStep'
import TalentComFormStep from './TalentComFormStep'
import WelcomeToTheJungleFormStep from './WelcomeToTheJungleFormStep'
import {FederationHospitaliereFormStep} from './FederationHospitaliereFormStep'
import {useAppDispatch, useAppSelector} from '../../../../store/hooks'
import {toggleValue} from '../../../../store/slices/bool.slices'
import {RootState} from '../../../../store'
import {FigaroFormStep} from './FigaroFormStep'
import EmploiTerritorialFormStep from './EmploiTerritorialFormStep'
import moment from 'moment'

import {ApecFormStep} from './apecFormStep'

const diffusionContext = createContext({
  diffusionForm: {},
  setDiffusionForm: () => {},
})

export const SecondStep = (props: {
  diffusionInformation: JobVacancyDiffusionVM | undefined
  updateInformationFunc: (_: JobVacancyDiffusionVM | undefined) => void
  jobboardList: JobboardViewModel[]
  previousStep: () => void
  alertMessages: string[]
  showAlert: boolean
  alertVariant: string
}) => {
  const [diffusionInformation, setDiffusionInformation] = useState<
    JobVacancyDiffusionVM | undefined
  >(props.diffusionInformation)

  const [monsterPropertiesInput, SetMonsterPropertiesInput] = useState<MonsterProperty>()
  const [dateForm, setDateForm] = useState(new Date())

  const userEmail = useAppSelector((state: RootState) => state.user.email)

  const [notify, setNotify] = useState(false)
  const [notifyMessage, setNotifyMessage] = useState<any>(null)

  const [poleEmploiPropertyInput, SetPoleEmploiPropertyInput] = useState<PoleEmploiProperty>()
  const [apecPropertyInput, setApecPropertyInput] = useState<ApecProperty>()
  const [isDisabled, setIsDisabled] = useState(false)

  const [isValidMonsterForm, setIsValidMonsterForm] = useState(false)
  const [isValidPoleEmploiForm, setIsValidPoleEmploiForm] = useState(false)
  const [isValidFhfForm, setIsValidFhfForm] = useState(false)
  const [isValidWelcomeToTheJungleForm, setIsValidWelcomeToTheJungleForm] = useState(false)
  const [isValidHelloWorkForm, setIsValidHelloWorkForm] = useState(false)
  const [isValidEmploiCollectivitesForm, setIsValidEmploiCollectivitesForm] = useState(false)
  const [isValidEmploiTerritorialForm, setIsValidEmploiTerritorialForm] = useState(false)
  const [isValidLeBonCoinForm, setIsValidLeBonCoinForm] = useState(false)
  const [isValidTalentComForm, setIsValidTalentComForm] = useState(false)
  const [isValidFigaroForm, setIsValidFigaroForm] = useState(false)
  const [isValidLinkedinForm, setIsValidLinkedinForm] = useState(false)
  const [isValidMeteoJobForm, setIsValidMeteoJobForm] = useState(false)
  const [isValidApecForm, setIsValidApecForm] = useState(false)
  const [isValidIndeedForm, setIsValidIndeedForm] = useState(false)

  const dispatch = useAppDispatch()

  const [emploiCollectivitesPropertiesInput, SetEmploiCollectivitesPropertiesInput] =
    useState<EmploiCollectivitesProperty>()

  const [indeedPropertiesInput, SetIndeedPropertiesInput] = useState<IndeedProperty>()
  const [leBonCoinProperyInput, SetLeBonCoinPropertyInput] = useState<LeBonCoinProperty>()
  const [linkedinPropertyInput, SetLinkedinPropertyInput] = useState<LinkedinProperty>()
  const [helloWorkPropertyInput, SetHelloWorkPropertyInput] = useState<HelloWorkProperty>()
  const [emploiTerritorialInput, SetEmploiTerritoralInput] = useState<EmploiTerritorialProperty>()
  const [meteoJobPropertyInput, SetMeteoJobPropertyInput] = useState<MeteoJobProperty>()
  const [talentComPropertyInput, SetTalentComPropertyInput] = useState<TalentComProperty>()
  const [welcomeToTheJunglePropertyInput, SetWelcomeToTheJunglePropertyInput] =
    useState<WelcomeToTheJungleProperty>()

  const [fhfPropertiesInput, SetfhfPropertiesInput] =
    useState<FederationHospitaliereFranceProperty>()
  const [figaroPropertyInput, SetFigaroPropertyInput] = useState<FigaroProperty>()
  // Fonction qui permet de mettre à jour les propriété de l'objet JobVacancyModel en fonction
  // de l'attribut name de l'input
  function handleChange(
    currentTarget: EventTarget & (HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement)
  ): void {
    let diffusionModified: JobVacancyDiffusionVM = {...diffusionInformation}
    let key: keyof JobVacancyDiffusionVM = currentTarget.name as keyof JobVacancyDiffusionVM
    ;(diffusionModified[key] as String) = currentTarget.value
    setDiffusionInformation(diffusionModified)
  }

  const poleEmploiPropertyRef = useRef<PoleEmploiProperty | null>(null)

  const isValidValue = useAppSelector((state) => state.bool)

  const updatedDiffusion = {
    ...diffusionInformation,
    recruiteurEmail: userEmail,
    tenantId: diffusionInformation?.tenantId,
    monsterProperty: monsterPropertiesInput
      ? monsterPropertiesInput
      : diffusionInformation?.monsterProperty
      ? {
          ...diffusionInformation?.monsterProperty,
          jobboardLabel: JobboardsLabel.MONSTER,
        }
      : null,
    emploiCollectivitesProperty: emploiCollectivitesPropertiesInput
      ? emploiCollectivitesPropertiesInput
      : diffusionInformation?.emploiCollectivitesProperty
      ? {
          ...diffusionInformation?.emploiCollectivitesProperty,
          jobboardLabel: JobboardsLabel.EMPLOICOLLECTIVITES,
        }
      : null,
    postingDate: dateFormatedISO(dateForm),
    poleEmploiProperty: poleEmploiPropertyInput
      ? poleEmploiPropertyInput
      : diffusionInformation?.poleEmploiProperty
      ? {
          ...diffusionInformation?.poleEmploiProperty,
          jobboardLabel: JobboardsLabel.POLEEMPLOI,
        }
      : null,
    leBonCoinProperty: leBonCoinProperyInput
      ? leBonCoinProperyInput
      : diffusionInformation?.leBonCoinProperty,
    linkedinProperty: linkedinPropertyInput
      ? linkedinPropertyInput
      : diffusionInformation?.linkedinProperty,
    meteoJobProperty: meteoJobPropertyInput
      ? meteoJobPropertyInput
      : diffusionInformation?.meteoJobProperty,
    talentComProperty: talentComPropertyInput
      ? talentComPropertyInput
      : diffusionInformation?.talentComProperty,
    helloWorkProperty: helloWorkPropertyInput
      ? helloWorkPropertyInput
      : diffusionInformation?.helloWorkProperty
      ? {
          ...diffusionInformation?.helloWorkProperty,
          jobboardLabel: JobboardsLabel.HELLOWORK,
        }
      : null,
    emploiTerritorialProperty: emploiTerritorialInput,
    welcomeToTheJungleProperty: welcomeToTheJunglePropertyInput
      ? welcomeToTheJunglePropertyInput
      : diffusionInformation?.welcomeToTheJungleProperty,
    indeedProperty: indeedPropertiesInput
      ? indeedPropertiesInput
      : diffusionInformation?.indeedProperty
      ? {...diffusionInformation?.indeedProperty, jobboardLabel: JobboardsLabel.INDEED}
      : null,

    federationHospitaliereFranceProperty: fhfPropertiesInput
      ? fhfPropertiesInput
      : diffusionInformation?.federationHospitaliereFranceProperty
      ? {
          ...diffusionInformation?.federationHospitaliereFranceProperty,
          jobboardLabel: JobboardsLabel.FHF,
        }
      : null,
    figaroProperty: figaroPropertyInput
      ? figaroPropertyInput
      : diffusionInformation?.figaroProperty
      ? {
          ...diffusionInformation?.figaroProperty,
          jobboardLabel: JobboardsLabel.FIGARO,
        }
      : null,
    apecProperty: apecPropertyInput
      ? apecPropertyInput
      : diffusionInformation?.apecProperty
      ? {
          ...diffusionInformation?.apecProperty,
          jobboardLabel: JobboardsLabel.APEC,
        }
      : null,
  } as JobVacancyDiffusionVM

  function handleSubmit() {
    props.updateInformationFunc(updatedDiffusion)
  }

  const handlePoleEmploiInput = (input: PoleEmploiProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      contractTypes: input.contractTypes,
      contractNatures: input.contractNatures,
      salaryTypes: input.salaryTypes,
      qualificationTypes: input.qualificationTypes,
      codeDepartementLieuTravail: input.codeDepartementLieuTravail,
      codeCommuneLieuTravail: input.codeCommuneLieuTravail,
      libelleCommuneLieuTravail: input.libelleCommuneLieuTravail,
      codePaysLieuTravail: input.codePaysLieuTravail,
      nbPostesCrees: input.nbPostesCrees,
      codeExigibiliteFormation: input.codeExigibiliteFormation,
      dateDebutContrat: input.dateDebutContrat,
      nbMoisSalaire: input.nbMoisSalaire,
      dateFinpublic: input.dateFinpublic,
      salaireMin: input.salaireMin,
      salaireMax: input.salaireMax,
      duration: input.duration, // duration
      codeTypeExpPro: input.codeTypeExpPro,
      dureeMinExpPro: input.dureeMinExpPro,
      typeDureeExpPro: input.typeDureeExpPro,
      userName: input?.userName,
      password: input?.password,
      siretClient: input?.siretClient,
      idRecruteur: input?.idRecruteur,
      internetDomainName: input?.internetDomainName,
      postalCode: input?.postalCode,
    } as PoleEmploiProperty

    if (body != null && body != undefined) {
      SetPoleEmploiPropertyInput(body)
    }
  }
  const handleMonsterInput = (input: MonsterProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      state: input.state,
      country: input.country,
      city: input.city,
      jobCategory: input.jobCategory,
      industry: input.industry,
      jobTypes: input.jobTypes,
      userName: input.userName,
      password: input.password,
    } as MonsterProperty

    if (body != null && body != undefined) {
      SetMonsterPropertiesInput(body)
    }
  }
  const handleLeBonCoin = (input: LeBonCoinProperty) => {
    const body = {
      morpheus_client_id: input.morpheus_client_id,
      partner_unique_reference: diffusionInformation?.id,
      job: {
        description: diffusionInformation?.jobDescription,
        title: diffusionInformation?.jobTitle,
        client_reference: diffusionInformation?.jobReference,
        business_sector: input.job.business_sector,
        contract_type: input.job.contract_type,
        occupation: input.job.occupation,
        start_date: input.job.start_date,
        time_type: input.job.time_type,
        contract_duration: {
          duration_type: input.job.contract_duration?.duration_type,
          min: input.job.contract_duration?.min,
          max: input.job.contract_duration?.max,
        },
        location: {
          zip_code: input.job.location.zip_code,
          city: input.job.location.city,
          country: input.job.location.country,
          street: input.job.location.street,
        },
        salary: {
          benefits: input.job.salary?.benefits,
          max: input.job.salary?.max,
          min: input.job.salary?.min,
          per: input.job.salary?.per,
        },
      },
      application: {
        contact: input.application.contact,
        mode: input.application.mode,
      },
      company: {
        name: diffusionInformation?.companyLabel,
        description: diffusionInformation?.companyDescription,
        url: diffusionInformation?.companyUrl,
        contact: {
          email: input.company?.contact?.email,
          name: input.company?.contact?.name,
          phone_number: input.company?.contact?.phone_number,
        },
        location: {
          city: input.company?.location?.city,
          country: input.company?.location?.country,
          street: input.company?.location?.street,
          zip_code: input.company?.location?.zip_code,
        },
      },
      applicant: {
        degree: input.applicant?.degree,
        experience: input.applicant?.experience,
        profile: input.applicant?.profile,
        skills: input.applicant?.skills,
      },
    } as LeBonCoinProperty
    if (body != null && body != undefined) {
      SetLeBonCoinPropertyInput(body)
    }
  }

  const handleEmploiCollectivitesInput = (input: EmploiCollectivitesProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      description_de_la_collectivite: input.description_de_la_collectivite,
      date_de_debut: input.date_de_debut,
      date_fin_de_publication: input.date_fin_de_publication,
      departement_du_poste: input.departement_du_poste,
      destinataires_des_candidatures: input.destinataires_des_candidatures,
      experience: input.experience,
      niveau_d_etudes: input.niveau_d_etudes,
      region_du_poste: input.region_du_poste,
      statut: input.statut,
      temps_complet_partiel: input.temps_complet_partiel,
      type_de_contrat: input.type_de_contrat,
      ville_du_poste: input.ville_du_poste,
      cadre_d_emplois: input.cadre_d_emplois,
      famille_de_metiers: input.famille_de_metiers,
      filieres: input.filieres,
    } as EmploiCollectivitesProperty
    if (body != null && body != undefined) {
      SetEmploiCollectivitesPropertiesInput(body)
    }
  }

  const handleLinkedin = (input: LinkedinProperty) => {
    if (input != null && input != undefined) {
      input.partnerJobId = diffusionInformation?.id!
      input.company = diffusionInformation?.companyLabel!
      input.title = diffusionInformation?.jobTitle!
      input.description = diffusionInformation?.jobDescription!

      SetLinkedinPropertyInput(input)
    }
  }

  const handleHelloWorkInput = (input: HelloWorkProperty) => {
    input.AdressesPostales[0].Pays = 'France' // A changer peut-être à l'avenir
    input.Description =
      diffusionInformation?.companyDescription + ' ' + diffusionInformation?.jobDescription
    input.DescriptionEntreprise = diffusionInformation?.companyDescription
    input.DescriptionPoste = diffusionInformation?.jobDescription
    input.Nom_Ese = diffusionInformation?.companyLabel!
    input.Poste = diffusionInformation?.jobTitle!
    input.Reference = diffusionInformation?.jobReference!
    input.DateAnnonce = diffusionInformation?.postingDate
    input.ExternalId = diffusionInformation?.id
    input.Profil = diffusionInformation?.applicantProfile
    input.Source = {
      IdOffer: diffusionInformation?.id,
      Nom: 'scoptalent',
    }

    if (input != null && input != undefined) {
      SetHelloWorkPropertyInput(input)
    }
  }
  const handleIndeedInput = (input: IndeedProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      state: input.state,
      country: input.country,
      email: input.email,
      salary: input.salary,
      education: input.education,
      jobtype: input.jobtype,
      categories: input.categories,
      experience: input.experience,
      remotetype: input.remotetype,
      paidVersion: input.paidVersion,
    } as IndeedProperty
    if (body != null && body != undefined) {
      SetIndeedPropertiesInput(body)
    }
  }

  const handleMeteoJobInput = (input: MeteoJobProperty) => {
    input.reference = diffusionInformation?.jobReference!
    input.publicationDate = diffusionInformation?.postingDate
    input.title = diffusionInformation?.jobTitle!
    input.company!.name = diffusionInformation?.companyLabel
    input.company!.description = diffusionInformation?.companyDescription
    input.position.description = diffusionInformation?.jobDescription!
    input.profile.description = diffusionInformation?.applicantProfile

    if (input != null && input != undefined) {
      SetMeteoJobPropertyInput(input)
    }
  }

  const handleTalentComInput = (input: TalentComProperty) => {
    input.job[0].company = diffusionInformation?.companyLabel!
    input.job[0].description = diffusionInformation?.jobDescription!
    input.job[0].referencenumber = diffusionInformation?.jobReference!
    input.job[0].title = diffusionInformation?.jobTitle!

    if (input != null && input != undefined) {
      SetTalentComPropertyInput(input)
    }
  }

  const handleWelcomeToTheJungleInput = (input: WelcomeToTheJungleProperty) => {
    if (input != null && input != undefined) {
      input.description = diffusionInformation?.jobDescription!
      input.company_description = diffusionInformation?.companyDescription
      input.profile = diffusionInformation?.applicantProfile!
      input.name = diffusionInformation?.jobTitle!
      input.external_reference = diffusionInformation?.jobReference

      SetWelcomeToTheJunglePropertyInput(input)
    }
  }

  const handleEmploiTerritorial = (input: EmploiTerritorialProperty) => {
    input.jobTitle = diffusionInformation?.jobTitle!
    input.vacancyDescription = diffusionInformation?.jobDescription!
    input.vacancyProfile = diffusionInformation?.applicantProfile!
    input.vacancyPublicationStartDate = moment(dateForm).format('DD/MM/yyyy');

    if (input != null && input != undefined) {
      SetEmploiTerritoralInput(input)
    }
  }

  const toggleNotify = () => setNotify(!notify)
  registerLocale('fr', fr)

  const handleFhfInput = (input: FederationHospitaliereFranceProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      finess_etab: input.finess_etab,
      poste: input.poste,
      contrat: input.contrat,
      // contact: input.contact,
      // mail : input.mail,
      publie: input.publie,
      active: input.active,
      source: input.source,
      partenaires: input.partenaires,
      // type: input.type,
      userName: input?.userName,
      password: input?.password,
      fhfToken: input?.fhfToken,
      idOffre: input?.idOffre,
      isUpdate: false,
    } as FederationHospitaliereFranceProperty
    if (body != null && body != undefined) {
      SetfhfPropertiesInput(body)
    }
  }

  const handleFigaroInput = (input: FigaroProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      experienceRequirements: input.experienceRequirements,
      educationRequirements: input.educationRequirements,
      postalCode: input.postalCode,
      countryCode: input.countryCode,
      occupationalCategories: input.occupationalCategories,
      industries: input.industries,
      hiringOrganizationLegalName: input.hiringOrganizationLegalName,
      hiringOrganizationIdentifier: input.hiringOrganizationIdentifier,
      employmentType: input.employmentType,
      employmentDuration: input.employmentDuration,
    } as FigaroProperty
    if (body != null && body != undefined) {
      SetFigaroPropertyInput(body)
    }
  }

  const handleApecInput = (input: ApecProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      region: input.region,
      experienceLevel: input.experienceLevel,
      basePayAmountMax: input.basePayAmountMax,
      basePayAmountMin: input.basePayAmountMin,
      duration: input.duration,
      jobStatus: input.jobStatus,
      displayPay: input.displayPay,
      partTime: input.partTime,
      partTimeDuration: input.partTimeDuration,
      jobType: input.jobType,
      experienceLevelLabel: input.experienceLevelLabel,
      numeroDossier: input.numeroDossier,
      industryCode: input.industryCode,
      legalId: input.legalId,
    } as ApecProperty

    if (body != null && body != undefined) {
      setApecPropertyInput(body)
    }
  }

  const handlingIsValidMonsterForm = (value: boolean) => {
    setIsValidMonsterForm(value)
  }
  const handlingIsValidPoleEmploiForm = (value: boolean) => {
    setIsValidPoleEmploiForm(value)
  }

  const handlingIsValidFhfForm = (value: boolean) => {
    setIsValidFhfForm(value)
  }

  const handlingIsValidWelcomeToTheJungleForm = (value: boolean) => {
    setIsValidWelcomeToTheJungleForm(value)
  }
  const handlingIsValidHelloWorkForm = (value: boolean) => {
    setIsValidHelloWorkForm(value)
  }
  const handlingIsValidEmploiCollectivitesForm = (value: boolean) => {
    setIsValidEmploiCollectivitesForm(value)
  }
  const handlingIsValidEmploiTerritorialForm = (value: boolean) => {
    setIsValidEmploiTerritorialForm(value)
  }
  const handlingIsValidLeBonCoinForm = (value: boolean) => {
    setIsValidLeBonCoinForm(value)
  }

  const handlingIsValidLinkedinForm = (value: boolean) => {
    setIsValidLinkedinForm(value)
  }

  const handlingIsValidTalentComForm = (value: boolean) => {
    setIsValidTalentComForm(value)
  }
  const handlingIsValidFigaroForm = (value: boolean) => {
    setIsValidFigaroForm(value)
  }

  const handlingIsValidApecForm = (value: boolean) => {
    setIsValidApecForm(value)
  }
  const handlingIsValidMeteoJobForm = (value: boolean) => {
    setIsValidMeteoJobForm(value)
  }
  const handlingIsValidIndeedForm = (value: boolean) => {
    setIsValidIndeedForm(value)
  }
  const handleLists = async () => {
    await dispatch(toggleValue())
  }

  useEffect(() => {
    handleLists()

    if (
      isValidMonsterForm ||
      isValidPoleEmploiForm ||
      isValidFhfForm ||
      isValidWelcomeToTheJungleForm ||
      isValidHelloWorkForm ||
      isValidEmploiCollectivitesForm ||
      isValidEmploiTerritorialForm ||
      isValidLeBonCoinForm ||
      isValidTalentComForm ||
      isValidFigaroForm ||
      isValidMeteoJobForm ||
      isValidLinkedinForm ||
      isValidApecForm ||
      isValidIndeedForm
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [
    isValidMonsterForm,
    isValidPoleEmploiForm,
    isValidFhfForm,
    isValidWelcomeToTheJungleForm,
    isValidHelloWorkForm,
    isValidEmploiCollectivitesForm,
    isValidEmploiTerritorialForm,
    isValidLeBonCoinForm,
    isValidTalentComForm,
    isValidFigaroForm,
    isValidLinkedinForm,
    isValidMeteoJobForm,
    isValidApecForm,
    isValidIndeedForm,
  ])
  return (
    <>
      <Row>
        <Col>
          <h2>Remplir les informations</h2>
        </Col>
      </Row>
      <Row>
        <ToastContainer className='p-3' position='bottom-end' style={{zIndex: 1}}>
          <Toast show={notify} onClose={toggleNotify} bg='Warning' delay={3000} autohide>
            <Toast.Header>
              <img src='holder.js/20x20?text=%20' className='rounded me-2' alt='' />
              <strong className='me-auto'>Notification</strong>
            </Toast.Header>
            <Toast.Body>{notifyMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row>
              {/* Informations générales */}
              <Col sm={12} md={6} lg={6}>
                <div className='card card-bordered step-card'>
                  <div className='card-header ribbon ribbon-start'>
                    <div className='ribbon-label'>Informations générales</div>
                  </div>
                  <div className='card-body'>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='postingDate'>
                        Date de diffusion
                      </label>
                      <DatePicker
                        dateFormat='dd-MM-yyyy'
                        locale='fr'
                        selected={dateForm}
                        onChange={(date: any) => setDateForm(date)}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='jobReference'>
                        Référence de l'offre
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Référence de l'offre"
                        type='text'
                        id='jobReference'
                        name='jobReference'
                        value={diffusionInformation?.jobReference}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='jobTitle'>
                        Titre de l'offre
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Titre de l'offre"
                        type='text'
                        id='jobTitle'
                        name='jobTitle'
                        value={diffusionInformation?.jobTitle}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              {/* entreprise */}
              <Col sm={12} md={6} lg={6}>
                <div className='card card-bordered step-card'>
                  <div className='card-header ribbon ribbon-start'>
                    <div className='ribbon-label required'>L'entreprise</div>
                  </div>
                  <div className='card-body'>
                    <div className='mb-10'>
                      <label className='form-label' htmlFor='companyLabel'>
                        Nom de l'entreprise
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Nom de l'entreprise"
                        type='text'
                        id='companyLabel'
                        name='companyLabel'
                        value={diffusionInformation?.companyLabel}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label' htmlFor='companyUrl'>
                        Lien vers le site internet
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder='Lien vers le site internet'
                        type='text'
                        id='companyUrl'
                        name='companyUrl'
                        value={diffusionInformation?.companyUrl}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='companyDescription'>
                        Description de l'entreprise
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Description de l'entreprise"
                        as='textarea'
                        name='companyDescription'
                        value={diffusionInformation?.companyDescription}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6}>
                {/* poste */}
                <div className='card card-bordered step-card'>
                  <div className='card-header ribbon ribbon-start'>
                    <div className='ribbon-label'>Le poste</div>
                  </div>
                  <div className='card-body'>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='jobDescription'>
                        Description du poste
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder='Description du poste'
                        as='textarea'
                        name='jobDescription'
                        value={diffusionInformation?.jobDescription}
                        onChange={(e) => handleChange(e.currentTarget)}
                        style={{height: '180px'}}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='country'>
                        Pays
                      </label>
                      <Form.Control
                        disabled
                        className='form-control-solid'
                        placeholder='Pays'
                        type='text'
                        name='country'
                        defaultValue='France'
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                {/* Le profil candidat */}
                <div className='card card-bordered step-card'>
                  <div className='card-header ribbon ribbon-start'>
                    <div className='ribbon-label'>Le profil candidat</div>
                  </div>
                  <div className='card-body'>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='applicantProfile'>
                        Description du profil
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder='Description du profil'
                        as='textarea'
                        name='applicantProfile'
                        value={diffusionInformation?.applicantProfile}
                        onChange={(e) => handleChange(e.currentTarget)}
                        style={{height: '250px'}}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.POLEEMPLOI && j.isChecked)
                .length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <PoleEmploiFormStep
                    poleEmploiPropertyRef={handlePoleEmploiInput}
                    handleForm={handlePoleEmploiInput}
                    jobboardLabel={JobboardsLabel.POLEEMPLOI}
                    handleIsValid={handlingIsValidPoleEmploiForm}
                    companyDescription={diffusionInformation?.companyDescription}
                  />
                </Col>
              )}
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.MONSTER && j.isChecked)
                .length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <MonsterFormStep
                    handleMonsterForm={handleMonsterInput}
                    jobboardLabel={JobboardsLabel.MONSTER}
                    handleIsValid={handlingIsValidMonsterForm}
                  />
                </Col>
              )}
              {props.jobboardList.find((j) => j.logo == JobboardLogo.LEBONCOIN && j.isChecked) && (
                <Col sm={12} md={6} lg={6}>
                  <LeBonCoinFromStep
                    handleLeBonCoinForm={handleLeBonCoin}
                    jobboardLabel={JobboardsLabel.LEBONCOIN}
                    handleIsValid={handlingIsValidLeBonCoinForm}
                    urlPublished={diffusionInformation?.urlPublished}
                  />
                </Col>
              )}
              {props.jobboardList.find((j) => j.logo == JobboardLogo.LINKEDIN && j.isChecked) && (
                <Col sm={12} md={6} lg={6}>
                  <LinkedinFormStep
                    handleLinkedinForm={handleLinkedin}
                    jobboardLabel={JobboardsLabel.LINKEDIN}
                    handleIsValid={handlingIsValidLinkedinForm}
                  ></LinkedinFormStep>
                </Col>
              )}
              {props.jobboardList.find((j) => j.logo == JobboardLogo.HELLOWORK && j.isChecked) && (
                <Col sm={12} md={6} lg={6}>
                  <HelloWorkFormStep
                    handleForm={handleHelloWorkInput}
                    jobboardLabel={JobboardsLabel.HELLOWORK}
                    handleIsValid={handlingIsValidHelloWorkForm}
                    urlPublished={diffusionInformation?.urlPublished}
                  ></HelloWorkFormStep>
                </Col>
              )}
              {props.jobboardList.find(
                (j) => j.logo == JobboardLogo.EMPLOITERRITORIAL && j.isChecked
              ) && (
                <Col sm={12} md={6} lg={6}>
                  <EmploiTerritorialFormStep
                    handleEmploiTerritorialForm={handleEmploiTerritorial}
                    jobboardLabel={JobboardsLabel.EMPLOITERRITORIAL}
                    handleIsValid={handlingIsValidEmploiTerritorialForm}
                    urlPublished={diffusionInformation?.urlPublished}
                  ></EmploiTerritorialFormStep>
                </Col>
              )}
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.INDEED && j.isChecked && !j.label.toLowerCase().includes("professionnel"))
                .length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <IndeedFormStep
                    handleForm={handleIndeedInput}
                    jobboardLabel={JobboardsLabel.INDEED}
                    paidVersion={false}
                    handleIsValid={handlingIsValidIndeedForm}
                  />
                </Col>
              )}
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.INDEED && j.isChecked && j.label.toLowerCase().includes("professionnel"))
                .length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <IndeedFormStep
                    handleForm={handleIndeedInput}
                    jobboardLabel={JobboardsLabel.INDEED}
                    paidVersion={true}
                    handleIsValid={handlingIsValidIndeedForm}
                  />
                </Col>
              )}
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.METEOJOB && j.isChecked)
                .length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <MeteoJobFormStep
                    handleMeteoJobForm={handleMeteoJobInput}
                    jobboardLabel={JobboardsLabel.METEOJOB}
                    urlPublished={diffusionInformation?.urlPublished}
                    handleIsValid={handlingIsValidMeteoJobForm}
                  />
                </Col>
              )}
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.TALENTCOM && j.isChecked)
                .length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <TalentComFormStep
                    handleTalentComForm={handleTalentComInput}
                    jobboardLabel={JobboardsLabel.TALENTCOM}
                    handleIsValid={handlingIsValidTalentComForm}
                    urlPublished={diffusionInformation?.urlPublished}
                  />
                </Col>
              )}
              {props.jobboardList.filter(
                (j) => j.logo == JobboardLogo.WELCOMETOTHEJUNGLE && j.isChecked
              ).length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <WelcomeToTheJungleFormStep
                    handleWelcomeToTheJungleForm={handleWelcomeToTheJungleInput}
                    jobboardLabel={JobboardsLabel.WELCOMETOTHEJUNGLE}
                    handleIsValid={handlingIsValidWelcomeToTheJungleForm}
                    urlPublished={diffusionInformation?.urlPublished}
                  />
                </Col>
              )}
              {props.jobboardList.filter(
                (j) => j.logo == JobboardLogo.EMPLOICOLLECTIVITES && j.isChecked
              ).length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <EmploiCollectivitesFormStep
                    handleForm={handleEmploiCollectivitesInput}
                    jobboardLabel={JobboardsLabel.EMPLOICOLLECTIVITES}
                    handleIsValid={handlingIsValidEmploiCollectivitesForm}
                  />
                </Col>
              )}
              {props.jobboardList.filter(
                (j) => j.logo == JobboardLogo.CHOISIRLESERVICEPUBLIC && j.isChecked
              ).length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <div className='card card-bordered step-card card-choisirleservicepublic'>
                    <div className='card-header ribbon ribbon-start'>
                      <div className='ribbon-label'>{JobboardsLabel.CHOISIRLESERVICEPUBLIC}</div>
                    </div>
                    <div className='card-body'>
                      <div className='mb-10'>
                        <label className='form-label required' htmlFor='educationLevel'>
                          Type de contrat
                        </label>
                        <Form.Select className='form-control-solid' name='type-contract'>
                          <option value='10'>Choisissez le type de contrat</option>
                          <option value='10'>BEP/CAP</option>
                          <option value='12'>BAC</option>
                          <option value='13'>BAC Professionnel</option>
                          <option value='14'>BAC+2</option>
                          <option value='15'>BAC+3</option>
                          <option value='16'>BAC+4</option>
                          <option value='17'>BAC+5</option>
                          <option value='18'>BAC+6</option>
                          <option value='19'>Doctorat</option>
                          <option value='20'>Chercheur</option>
                          <option value='99196'>Aucun</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label' htmlFor='region'>
                          Région
                        </label>
                        <Form.Select className='form-control-solid' name='region'>
                          <option value='1168'>Choisissez une région</option>
                          <option value='1168'>Alsace</option>
                          <option value='1171'>Aquitaine</option>
                          <option value='1177'>Auvergne</option>
                          <option value='1182'>Basse-Normandie</option>
                          <option value='1186'>Bourgogne</option>
                          <option value='1191'>Bretagne</option>
                          <option value='1196'>Centre</option>
                          <option value='1203'>Champagne-Ardennes</option>
                          <option value='1208'>Corse</option>
                          <option value='1211'>Franche-Comté</option>
                          <option value='1216'>Haute-Normandie</option>
                          <option value='1219'>Ile-de-France</option>
                          <option value='1228'>Languedoc-Roussillon</option>
                          <option value='1234'>Limousin</option>
                          <option value='1238'>Lorraine</option>
                          <option value='1243'>Midi-Pyrénées</option>
                          <option value='1252'>Nord-Pas-de-Calais</option>
                          <option value='1255'>Pays de la Loire</option>
                          <option value='1261'>Picardie</option>
                          <option value='1265'>Poitou-Charentes</option>
                          <option value='1270'>Provence-Alpes-Côte d'Azur</option>
                          <option value='1277'>Rhône-Alpes</option>
                          <option value='1285'>Départements d'outre-mer</option>
                          <option value='1433'>Territoires d'outre-mer</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <Row>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label required' htmlFor='periodicity'>
                                Périodicité
                              </label>
                              <Form.Select className='form-control-solid' name='periodicity'>
                                <option value='10'>Type de périodicité</option>
                                <option value='year'>Annuel</option>
                                <option value='month'>Mensuel</option>
                                <option value='daily'>Journalier</option>
                                <option value='hourly'>Horaire</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label' htmlFor='salaryMax'>
                                Salaire
                              </label>
                              <Form.Control
                                className='form-control-solid'
                                placeholder='Salaire'
                                type='number'
                                min='0'
                                onKeyPress={(event) => {
                                  if (event?.key === '-' || event?.key === '+') {
                                    event.preventDefault()
                                  }
                                }}
                                name='salaryMax'
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.APEC && j.isChecked).length >
                0 && (
                <Col sm={12} md={6} lg={6}>
                  <ApecFormStep
                    handleForm={handleApecInput}
                    jobboardLabel={JobboardsLabel.APEC}
                    handleIsValid={handlingIsValidApecForm}
                  />
                </Col>
              )}
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.SFSP && j.isChecked).length >
                0 && (
                <Col sm={12} md={6} lg={6}>
                  <div className='card card-bordered step-card card-sfsp'>
                    <div className='card-header ribbon ribbon-start'>
                      <div className='ribbon-label'>{JobboardsLabel.SFSP}</div>
                    </div>
                    <div className='card-body'>
                      <div className='mb-10'>
                        <label className='form-label required' htmlFor='educationLevel'>
                          Type de contrat
                        </label>
                        <Form.Select className='form-control-solid' name='type-contract'>
                          <option value='10'>Choisissez le type de contrat</option>
                          <option value='10'>BEP/CAP</option>
                          <option value='12'>BAC</option>
                          <option value='13'>BAC Professionnel</option>
                          <option value='14'>BAC+2</option>
                          <option value='15'>BAC+3</option>
                          <option value='16'>BAC+4</option>
                          <option value='17'>BAC+5</option>
                          <option value='18'>BAC+6</option>
                          <option value='19'>Doctorat</option>
                          <option value='20'>Chercheur</option>
                          <option value='99196'>Aucun</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label' htmlFor='region'>
                          Région
                        </label>
                        <Form.Select className='form-control-solid' name='region'>
                          <option value='1168'>Choisissez une région</option>
                          <option value='1168'>Alsace</option>
                          <option value='1171'>Aquitaine</option>
                          <option value='1177'>Auvergne</option>
                          <option value='1182'>Basse-Normandie</option>
                          <option value='1186'>Bourgogne</option>
                          <option value='1191'>Bretagne</option>
                          <option value='1196'>Centre</option>
                          <option value='1203'>Champagne-Ardennes</option>
                          <option value='1208'>Corse</option>
                          <option value='1211'>Franche-Comté</option>
                          <option value='1216'>Haute-Normandie</option>
                          <option value='1219'>Ile-de-France</option>
                          <option value='1228'>Languedoc-Roussillon</option>
                          <option value='1234'>Limousin</option>
                          <option value='1238'>Lorraine</option>
                          <option value='1243'>Midi-Pyrénées</option>
                          <option value='1252'>Nord-Pas-de-Calais</option>
                          <option value='1255'>Pays de la Loire</option>
                          <option value='1261'>Picardie</option>
                          <option value='1265'>Poitou-Charentes</option>
                          <option value='1270'>Provence-Alpes-Côte d'Azur</option>
                          <option value='1277'>Rhône-Alpes</option>
                          <option value='1285'>Départements d'outre-mer</option>
                          <option value='1433'>Territoires d'outre-mer</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <Row>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label required' htmlFor='periodicity'>
                                Périodicité
                              </label>
                              <Form.Select className='form-control-solid' name='periodicity'>
                                <option value='10'>Type de périodicité</option>
                                <option value='year'>Annuel</option>
                                <option value='month'>Mensuel</option>
                                <option value='daily'>Journalier</option>
                                <option value='hourly'>Horaire</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label' htmlFor='salaryMax'>
                                Salaire
                              </label>
                              <Form.Control
                                className='form-control-solid'
                                placeholder='Salaire'
                                type='number'
                                min='0'
                                onKeyPress={(event) => {
                                  if (event?.key === '-' || event?.key === '+') {
                                    event.preventDefault()
                                  }
                                }}
                                name='salaryMax'
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              {props.jobboardList.filter(
                (j) => j.logo == JobboardLogo.SANTEPUBLIQUEFRANCE && j.isChecked
              ).length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <div className='card card-bordered step-card card-santepubliquefrance'>
                    <div className='card-header ribbon ribbon-start'>
                      <div className='ribbon-label'>{JobboardsLabel.SANTEPUBLIQUEFRANCE}</div>
                    </div>
                    <div className='card-body'>
                      <div className='mb-10'>
                        <label className='form-label required' htmlFor='educationLevel'>
                          Type de contrat
                        </label>
                        <Form.Select className='form-control-solid' name='type-contract'>
                          <option value='10'>Choisissez le type de contrat</option>
                          <option value='10'>BEP/CAP</option>
                          <option value='12'>BAC</option>
                          <option value='13'>BAC Professionnel</option>
                          <option value='14'>BAC+2</option>
                          <option value='15'>BAC+3</option>
                          <option value='16'>BAC+4</option>
                          <option value='17'>BAC+5</option>
                          <option value='18'>BAC+6</option>
                          <option value='19'>Doctorat</option>
                          <option value='20'>Chercheur</option>
                          <option value='99196'>Aucun</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label' htmlFor='region'>
                          Région
                        </label>
                        <Form.Select className='form-control-solid' name='region'>
                          <option value='1168'>Choisissez une région</option>
                          <option value='1168'>Alsace</option>
                          <option value='1171'>Aquitaine</option>
                          <option value='1177'>Auvergne</option>
                          <option value='1182'>Basse-Normandie</option>
                          <option value='1186'>Bourgogne</option>
                          <option value='1191'>Bretagne</option>
                          <option value='1196'>Centre</option>
                          <option value='1203'>Champagne-Ardennes</option>
                          <option value='1208'>Corse</option>
                          <option value='1211'>Franche-Comté</option>
                          <option value='1216'>Haute-Normandie</option>
                          <option value='1219'>Ile-de-France</option>
                          <option value='1228'>Languedoc-Roussillon</option>
                          <option value='1234'>Limousin</option>
                          <option value='1238'>Lorraine</option>
                          <option value='1243'>Midi-Pyrénées</option>
                          <option value='1252'>Nord-Pas-de-Calais</option>
                          <option value='1255'>Pays de la Loire</option>
                          <option value='1261'>Picardie</option>
                          <option value='1265'>Poitou-Charentes</option>
                          <option value='1270'>Provence-Alpes-Côte d'Azur</option>
                          <option value='1277'>Rhône-Alpes</option>
                          <option value='1285'>Départements d'outre-mer</option>
                          <option value='1433'>Territoires d'outre-mer</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <Row>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label required' htmlFor='periodicity'>
                                Périodicité
                              </label>
                              <Form.Select className='form-control-solid' name='periodicity'>
                                <option value='10'>Type de périodicité</option>
                                <option value='year'>Annuel</option>
                                <option value='month'>Mensuel</option>
                                <option value='daily'>Journalier</option>
                                <option value='hourly'>Horaire</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label' htmlFor='salaryMax'>
                                Salaire
                              </label>
                              <Form.Control
                                className='form-control-solid'
                                placeholder='Salaire'
                                type='number'
                                min='0'
                                onKeyPress={(event) => {
                                  if (event?.key === '-' || event?.key === '+') {
                                    event.preventDefault()
                                  }
                                }}
                                name='salaryMax'
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              {props.jobboardList.filter(
                (j) => j.logo == JobboardLogo.LAGAZETTEDESCOMMUNES && j.isChecked
              ).length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <div className='card card-bordered step-card card-lagazettedescommunes'>
                    <div className='card-header ribbon ribbon-start'>
                      <div className='ribbon-label'>{JobboardsLabel.LAGAZETTEDESCOMMUNES}</div>
                    </div>
                    <div className='card-body'>
                      <div className='mb-10'>
                        <label className='form-label required' htmlFor='educationLevel'>
                          Type de contrat
                        </label>
                        <Form.Select className='form-control-solid' name='type-contract'>
                          <option value='10'>Choisissez le type de contrat</option>
                          <option value='10'>BEP/CAP</option>
                          <option value='12'>BAC</option>
                          <option value='13'>BAC Professionnel</option>
                          <option value='14'>BAC+2</option>
                          <option value='15'>BAC+3</option>
                          <option value='16'>BAC+4</option>
                          <option value='17'>BAC+5</option>
                          <option value='18'>BAC+6</option>
                          <option value='19'>Doctorat</option>
                          <option value='20'>Chercheur</option>
                          <option value='99196'>Aucun</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label' htmlFor='region'>
                          Région
                        </label>
                        <Form.Select className='form-control-solid' name='region'>
                          <option value='1168'>Choisissez une région</option>
                          <option value='1168'>Alsace</option>
                          <option value='1171'>Aquitaine</option>
                          <option value='1177'>Auvergne</option>
                          <option value='1182'>Basse-Normandie</option>
                          <option value='1186'>Bourgogne</option>
                          <option value='1191'>Bretagne</option>
                          <option value='1196'>Centre</option>
                          <option value='1203'>Champagne-Ardennes</option>
                          <option value='1208'>Corse</option>
                          <option value='1211'>Franche-Comté</option>
                          <option value='1216'>Haute-Normandie</option>
                          <option value='1219'>Ile-de-France</option>
                          <option value='1228'>Languedoc-Roussillon</option>
                          <option value='1234'>Limousin</option>
                          <option value='1238'>Lorraine</option>
                          <option value='1243'>Midi-Pyrénées</option>
                          <option value='1252'>Nord-Pas-de-Calais</option>
                          <option value='1255'>Pays de la Loire</option>
                          <option value='1261'>Picardie</option>
                          <option value='1265'>Poitou-Charentes</option>
                          <option value='1270'>Provence-Alpes-Côte d'Azur</option>
                          <option value='1277'>Rhône-Alpes</option>
                          <option value='1285'>Départements d'outre-mer</option>
                          <option value='1433'>Territoires d'outre-mer</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <Row>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label required' htmlFor='periodicity'>
                                Périodicité
                              </label>
                              <Form.Select className='form-control-solid' name='periodicity'>
                                <option value='10'>Type de périodicité</option>
                                <option value='year'>Annuel</option>
                                <option value='month'>Mensuel</option>
                                <option value='daily'>Journalier</option>
                                <option value='hourly'>Horaire</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label' htmlFor='salaryMax'>
                                Salaire
                              </label>
                              <Form.Control
                                className='form-control-solid'
                                placeholder='Salaire'
                                type='number'
                                min='0'
                                onKeyPress={(event) => {
                                  if (event?.key === '-' || event?.key === '+') {
                                    event.preventDefault()
                                  }
                                }}
                                name='salaryMax'
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              {props.jobboardList.filter(
                (j) => j.logo == JobboardLogo.OPTIONCARRIERE && j.isChecked
              ).length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <div className='card card-bordered step-card card-optioncarriere'>
                    <div className='card-header ribbon ribbon-start'>
                      <div className='ribbon-label'>{JobboardsLabel.OPTIONCARRIERE}</div>
                    </div>
                    <div className='card-body'>
                      <div className='mb-10'>
                        <label className='form-label required' htmlFor='educationLevel'>
                          Type de contrat
                        </label>
                        <Form.Select className='form-control-solid' name='type-contract'>
                          <option value='10'>Choisissez le type de contrat</option>
                          <option value='10'>BEP/CAP</option>
                          <option value='12'>BAC</option>
                          <option value='13'>BAC Professionnel</option>
                          <option value='14'>BAC+2</option>
                          <option value='15'>BAC+3</option>
                          <option value='16'>BAC+4</option>
                          <option value='17'>BAC+5</option>
                          <option value='18'>BAC+6</option>
                          <option value='19'>Doctorat</option>
                          <option value='20'>Chercheur</option>
                          <option value='99196'>Aucun</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label' htmlFor='region'>
                          Région
                        </label>
                        <Form.Select className='form-control-solid' name='region'>
                          <option value='1168'>Choisissez une région</option>
                          <option value='1168'>Alsace</option>
                          <option value='1171'>Aquitaine</option>
                          <option value='1177'>Auvergne</option>
                          <option value='1182'>Basse-Normandie</option>
                          <option value='1186'>Bourgogne</option>
                          <option value='1191'>Bretagne</option>
                          <option value='1196'>Centre</option>
                          <option value='1203'>Champagne-Ardennes</option>
                          <option value='1208'>Corse</option>
                          <option value='1211'>Franche-Comté</option>
                          <option value='1216'>Haute-Normandie</option>
                          <option value='1219'>Ile-de-France</option>
                          <option value='1228'>Languedoc-Roussillon</option>
                          <option value='1234'>Limousin</option>
                          <option value='1238'>Lorraine</option>
                          <option value='1243'>Midi-Pyrénées</option>
                          <option value='1252'>Nord-Pas-de-Calais</option>
                          <option value='1255'>Pays de la Loire</option>
                          <option value='1261'>Picardie</option>
                          <option value='1265'>Poitou-Charentes</option>
                          <option value='1270'>Provence-Alpes-Côte d'Azur</option>
                          <option value='1277'>Rhône-Alpes</option>
                          <option value='1285'>Départements d'outre-mer</option>
                          <option value='1433'>Territoires d'outre-mer</option>
                        </Form.Select>
                      </div>
                      <div className='mb-10'>
                        <Row>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label required' htmlFor='periodicity'>
                                Périodicité
                              </label>
                              <Form.Select className='form-control-solid' name='periodicity'>
                                <option value='10'>Type de périodicité</option>
                                <option value='year'>Annuel</option>
                                <option value='month'>Mensuel</option>
                                <option value='daily'>Journalier</option>
                                <option value='hourly'>Horaire</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={6} sm={6} lg={6}>
                            <div className='mb-10'>
                              <label className='form-label' htmlFor='salaryMax'>
                                Salaire
                              </label>
                              <Form.Control
                                className='form-control-solid'
                                placeholder='Salaire'
                                type='number'
                                min='0'
                                onKeyPress={(event) => {
                                  if (event?.key === '-' || event?.key === '+') {
                                    event.preventDefault()
                                  }
                                }}
                                name='salaryMax'
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.FHF && j.isChecked).length >
                0 && (
                <Col sm={12} md={6} lg={6}>
                  <FederationHospitaliereFormStep
                    handleForm={handleFhfInput}
                    jobboardLabel={JobboardsLabel.FHF}
                    handleIsValid={handlingIsValidWelcomeToTheJungleForm}
                  />
                </Col>
              )}
              {props.jobboardList.filter((j) => j.logo == JobboardLogo.FIGARO && j.isChecked)
                .length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <FigaroFormStep
                    handleForm={handleFigaroInput}
                    jobboardLabel={JobboardsLabel.FIGARO}
                    handleIsValid={handlingIsValidFigaroForm}
                  />
                </Col>
              )}
            </Row>
          </Form>
        </Col>
      </Row>
      {props.showAlert ? props.alertMessages.map(x => { return <Alert variant={props.alertVariant}>{x}</Alert> })  : ''}
      <FloatingJobboardList
        jobboards={props.jobboardList.filter((j) => j.isChecked)}
      ></FloatingJobboardList>
      <ButtonGroup className='floating-group'>
        <Button variant='scopdiffusion' onClick={(e) => props.previousStep()}>
          <i className='fas fa-arrow-left fs-1 action-icon left'></i>
          Choix des jobboards
        </Button>
        <Button variant='success' onClick={handleSubmit} disabled={isDisabled}>
          Créer la diffusion
          <i className='fas fa-check fs-1 action-icon right'></i>
        </Button>
      </ButtonGroup>
    </>
  )
}
