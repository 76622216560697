import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../../api/agent";
import { DictionaryModel, InitState, } from "../../pages/referentiel/core/_models";

export const fetchPoleEmploiQualificationTypes = createAsyncThunk(
    'poleEmploiQualificationTypes/fetchPoleEmploiQualificationTypes',
    async () => {
      const response = await agent.poleEmploiReferentiel.getListQualificationTypes()
      return response as DictionaryModel[];
    }
  )


const initialState: InitState = {
    entities: [],
    loading: false,
  }
const poleEmploiQualificationTypesSlice = createSlice({
    name: "poleEmploiQualificationTypes ",
    initialState,
    reducers: {
    
      },
    extraReducers:(builder) => {
        builder
        .addCase(fetchPoleEmploiQualificationTypes.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchPoleEmploiQualificationTypes.fulfilled, (state, action) => {
            state.loading = false;
            state.entities = [...action.payload];
        })
        .addCase(fetchPoleEmploiQualificationTypes.rejected, (state, action) => {
            state.loading = false;
        })
      }
})


export const {  } = poleEmploiQualificationTypesSlice.actions;
export default poleEmploiQualificationTypesSlice.reducer
