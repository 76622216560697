import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitLinkedinState, DictionaryModel } from "../../pages/referentiel/core/_models";
import agent from "../../api/agent";

const initialState: InitLinkedinState = {
    jobTypes: [],
    workPlaces: [],
    experienceLevels: [],
    salaryTypes: [],
    salaryPeriods: [],
    salaryCurrencies: [],
    jobFunctions: [],
    industryCodes: [],
    loading: false,
}

export const fetchLinkedinGetJobTypes = createAsyncThunk(
    'linkedinReferentiel/getJobTypes',
    async () => {
        const response = await agent.linkedinReferentiel.getJobTypes();
        return response as DictionaryModel[];
    }
)

export const fetchLinkedinGetWorkPlaces = createAsyncThunk(
    'linkedinReferentiel/getWorkPlaces',
    async () => {
        const response = await agent.linkedinReferentiel.getWorkPlaces();
        return response as DictionaryModel[];
    }
)

export const fetchLinkedinGetExperienceLevels = createAsyncThunk(
    'linkedinReferentiel/getExperienceLevels',
    async () => {
        const response = await agent.linkedinReferentiel.getExperienceLevels();
        return response as DictionaryModel[];
    }
)

export const fetchLinkedinGetSalaryTypes = createAsyncThunk(
    'linkedinReferentiel/getSalaryTypes',
    async () => {
        const response = await agent.linkedinReferentiel.getSalaryTypes();
        return response as DictionaryModel[];
    }
)

export const fetchLinkedinGetSalaryPeriods = createAsyncThunk(
    'linkedinReferentiel/getSalaryPeriods',
    async () => {
        const response = await agent.linkedinReferentiel.getSalaryPeriods();
        return response as DictionaryModel[];
    }
)

export const fetchLinkedinGetSalaryCurrencies = createAsyncThunk(
    'linkedinReferentiel/getSalaryCurrencies',
    async () => {
        const response = await agent.linkedinReferentiel.getSalaryCurrencies();
        return response as DictionaryModel[];
    }
)

export const fetchLinkedinGetJobFunctions = createAsyncThunk(
    'linkedinReferentiel/getJobFunctions',
    async () => {
        const response = await agent.linkedinReferentiel.getJobFunctions();
        return response as DictionaryModel[];
    }
)

export const fetchLinkedinGetIndustryCodes = createAsyncThunk(
    'linkedinReferentiel/getIndustryCodes',
    async () => {
        const response = await agent.linkedinReferentiel.getIndustryCodes();
        return response as DictionaryModel[];
    }
)

const linkedinReferentielSlice = createSlice({
    name: "linkedinReferentiel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLinkedinGetJobTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLinkedinGetJobTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.jobTypes = [...action.payload];
            })
            .addCase(fetchLinkedinGetJobTypes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLinkedinGetWorkPlaces.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLinkedinGetWorkPlaces.fulfilled, (state, action) => {
                state.loading = false;
                state.workPlaces = [...action.payload];
            })
            .addCase(fetchLinkedinGetWorkPlaces.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLinkedinGetExperienceLevels.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLinkedinGetExperienceLevels.fulfilled, (state, action) => {
                state.loading = false;
                state.experienceLevels = [...action.payload];
            })
            .addCase(fetchLinkedinGetExperienceLevels.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLinkedinGetSalaryTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLinkedinGetSalaryTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.salaryTypes = [...action.payload];
            })
            .addCase(fetchLinkedinGetSalaryTypes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLinkedinGetJobFunctions.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLinkedinGetJobFunctions.fulfilled, (state, action) => {
                state.loading = false;
                state.jobFunctions = [...action.payload];
            })
            .addCase(fetchLinkedinGetJobFunctions.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLinkedinGetIndustryCodes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLinkedinGetIndustryCodes.fulfilled, (state, action) => {
                state.loading = false;
                state.industryCodes = [...action.payload];
            })
            .addCase(fetchLinkedinGetIndustryCodes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLinkedinGetSalaryPeriods.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLinkedinGetSalaryPeriods.fulfilled, (state, action) => {
                state.loading = false;
                state.salaryPeriods = [...action.payload];
            })
            .addCase(fetchLinkedinGetSalaryPeriods.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLinkedinGetSalaryCurrencies.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLinkedinGetSalaryCurrencies.fulfilled, (state, action) => {
                state.loading = false;
                state.salaryCurrencies = [...action.payload];
            })
            .addCase(fetchLinkedinGetSalaryCurrencies.rejected, (state, action) => {
                state.loading = false;
            })
        }
});

export const {} = linkedinReferentielSlice.actions;
export default linkedinReferentielSlice.reducer