/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { useAuth } from '../core/Auth'
import { AuthModel } from '../core/_models'
import axios from 'axios'
import { setEmail } from '../../../store/slices/user.slice';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import agent from '../../../api/agent';

const API_URL = process.env.REACT_APP_API_URL;
export const LOGIN_URL = `${API_URL}/user/loginbytoken`

export function LoginByToken() {
  const { saveAuth, setCurrentUser } = useAuth();
  const navigate = useNavigate()
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');
  const vacancyId = urlParams.get('id');
  const returnUrl = urlParams.get('returnUrl');
  const isUpdate = urlParams.get('isUpdate');

  const dispatch = useAppDispatch()
  const [userEmail, setUserEmail] = useState("");

  const handleLogin = async () => {
    axios.post(LOGIN_URL, {
      token
    })
      .then(async (response: any) => {
        if (response.data.success) {
          let user = response.data.businessObject;
          let auth = new AuthModel()
          auth.accessToken = user.accessToken;
          saveAuth(auth);
          setCurrentUser(user);
          dispatch(setEmail(user.email));
          setUserEmail(user.email);
          if (isUpdate)
          {
            const entity = (await agent.JobVacancy.getDiffusionById(vacancyId)).jobVacancyInput;
            if (entity) {
              navigate(`/update?returnUrl=${returnUrl}`, {
                state: entity ? entity : [],
              })
            }
          }
          else 
          {
            window.location.href = '/diffusion/' + vacancyId + '?returnUrl=' + returnUrl;
          }
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  const handleLists = async () => {
    await dispatch(setEmail(userEmail))
  }

  useEffect(() => {
    handleLists()
    handleLogin()
  }, [])

  return (
    <>
      <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '50%' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </>

  )
}
