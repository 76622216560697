import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../../api/agent";
import {InitState, TenantModel, UserVM} from "../../pages/referentiel/core/_models";
import type { PayloadAction } from '@reduxjs/toolkit';


export const fetchUsersByTenantId = createAsyncThunk(
    'tenant/fetchUsersByTenantId',
    async () => {
        const response = await agent.Tenant.getUsersByTenantId();
        return response as UserVM[];
    }
)


const initialState: InitState = {
    entities: [],
    loading: false,
  }
const tenantSlice = createSlice({
    name: "tenant",
    initialState,
    reducers: {
        tenantAdded(state, action : PayloadAction<TenantModel>) {
          state.entities.push(action.payload);
        },
        tenantUpdated(state, action : PayloadAction<TenantModel>) {
          const { id, email} = action.payload as TenantModel;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            existingItem.email = email;
          }
        },
        tenantDeleted(state, action : PayloadAction<TenantModel>) {
          const { id } = action.payload;
          const existingItem = state.entities.find((item) => item.id === id);
          if (existingItem) {
            state.entities = state.entities.filter((item) => item.id !== id);
          }
        },
      },
    extraReducers:(builder) => {
        builder
        .addCase(fetchUsersByTenantId.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchUsersByTenantId.fulfilled, (state, action) => {
            state.loading = false;
            state.entities = [...action.payload];
        })
        .addCase(fetchUsersByTenantId.rejected, (state, action) => {
            state.loading = false;
        })
      }
})


export const { tenantAdded, tenantUpdated, tenantDeleted } = tenantSlice.actions;
export default tenantSlice.reducer
