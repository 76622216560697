import React, {FC, useContext, useEffect, useState} from 'react'
import {} from 'formik'
import {useAppDispatch, useAppSelector} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {IndeedLabelType, IndeedProperty, SalaryTypeIndeed} from '../../../referentiel/core/_models'
import {Col, Form, Row} from 'react-bootstrap'
import './secondStep.scss'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import {MapSalaryType, getEducation, getStates} from '../../../referentiel/core/_tools'
import {setToFalse, setToTrue} from '../../../../store/slices/bool.slices'

const IndeedFormStep = (props: any) => {
  const dispatch = useAppDispatch();

  const entity = props.data ? props.data : undefined
  const animatedComponents = makeAnimated()

  const [state, setState] = useState(entity ? entity.state : '')
  const [country, setCountry] = useState(entity ? entity.country : '')
  const [email, setEmail] = useState(entity ? entity.email : '')
  const [salary, setSalary] = useState(entity ? entity.salary : '')
  const [education, setEducation] = useState(entity ? entity.education : '')
  const [jobType, setJobType] = useState(entity ? entity.jobType : '')
  const [categories, setCategories] = useState<any>(entity ? entity.categories : [])
  const [experience, setExperience] = useState(entity ? entity.experience : '')
  const [remoteType, setRemoteType] = useState(entity ? entity.remoteType : '')
  const [salarytype, setSalarytype] = useState('');
  const [paidVersion, _] = useState(props ? props.paidVersion : false);

  const handleState = (options: any) => {
    setState(options)
  }
  const handleEmail = (e: any) => setEmail(e.target.value)
  const handleSalary = (e: any) => setSalary(e.target.value)
  const handleEducation = (options: any) => {
    setEducation(options)
  }
  const handleJobType = (e: any) => setJobType(e.target.value)
  const handleCategories = (e: any) => setJobType(e.target.value)
  const handleExperience = (e: any) => setExperience(e.target.value)
  const handleRemotetype = (e: any) => setRemoteType(e.target.value)

  const [isChecked, setIsChecked] = useState(false)

  const handleSalarytype = (e: any) => setSalarytype(e.target.value)

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked)
  }

  const educationDefaultValue = getEducation().find((item: any) => item.label === 'Indifférent');

  const stateDefaultValue = getStates().find((item: any) => item.label === 'IDF');

  const changeHandler = (e: any) => {
    e.preventDefault()

    setCountry('FR')
    const _categories = ['IT Developpement'] as any[];
    setCategories(_categories);
    const body = {
      jobboardLabel: props.jobboardLabel,
      state: state.value ? state.value : stateDefaultValue?.value,
      country: country ? country : 'FR',
      email: email,
      salary: salary + ' ' + MapSalaryType(salarytype),
      education: education ? education.label : educationDefaultValue?.label,
      jobtype: jobType,
      categories: categories,
      experience: experience,
      remotetype: remoteType,
      paidVersion: paidVersion,
    } as IndeedProperty
    props.handleForm(body);

    if (
      state.value
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  const getUpdatedEntity = () => {
     
    if (props.isEdited && entity) {
      Promise.all([
        getEducation().filter((item: any) => entity.education.includes(item.label.toString()))
        .map((item) => ({value: item.value, label: item.label})),
        
        getStates().filter((item: any) => entity.state.includes(item.value))
        .map((item) => ({value: item.value, label: item.label}))
      ]).then(([education, state]) => {
        setEducation(education);
        setState(state);
      });
    }
  }

  useEffect(() => {
  }, [getEducation(), getStates()]);

  return (
    <div className='card card-bordered step-card card-indeed' onClick={changeHandler}>
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>{ "Indeed" + (props.paidVersion ? " professionnel" : "") }</div>
      </div>
      <div className='card-body'>
        <div className='mb-10'>
          <Row>
           
            <Col>
              <label className='form-label mb-3 required'>Selectionez la région</label>
              <Select
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={getStates()}
                onChange={handleState}
                value={state}
              />
            </Col>
            <Col>
              <label className='form-label mb-3'>{IndeedLabelType.jobtype_label}</label>
              <Form.Select
                as='select'
                name='jobType'
                value={jobType}
                className='form-select form-select-lg form-select-solid'
                onChange={handleJobType}
              >
                <option>{IndeedLabelType.categories_select}</option>
                <option value='fulltime'>Temps complet</option>
                <option value='parttime'>Temps partiel</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label mb-3'>{IndeedLabelType.salary_label}</label>
              <input
                className='form-control form-control-lg form-control-solid'
                name='salary'
                placeholder='Ex €50000 par an | $4000 - $5000 par mois'
                value={salary}
                onChange={handleSalary}
              />
            </Col>
            <Col>
            <label className='form-label mb-3'></label>
              <Form.Select
                as='select'
                name='jobType'
                value={salarytype}
                className='form-select form-select-lg form-select-solid'
                onChange={handleSalarytype}
              >
                <option>Par mois ou par an !!</option>
                <option value={SalaryTypeIndeed.PER_MONTH}>Par mois</option>
                <option value={SalaryTypeIndeed.PER_YEAR}>Par an</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label mb-3'>{IndeedLabelType.education_label}</label>
              <Select
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={getEducation()}
                onChange={handleEducation}
                value={education}
              />
            </Col>
            <Col>
              <label className='form-label mb-3'>{IndeedLabelType.experience_label}</label>
              <input
                className='form-control form-control-lg form-control-solid'
                name='experience'
                placeholder='+5 ans'
                value={experience}
                onChange={handleExperience}
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label mb-3'>{IndeedLabelType.remotetype_label}</label>
              <Form.Group className='mb-3' controlId='formBasicCheckbox'>
                <Form.Check
                  type='checkbox'
                  label=' Si télétravail ou hybrid'
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                {isChecked ? (
                  <Form.Select
                    as='select'
                    name='remoteType'
                    value={remoteType}
                    className='form-select form-select-lg form-select-solid'
                    onChange={handleRemotetype}
                  >
                    <option>{IndeedLabelType.remotetype_select}</option>
                    <option value='Fully remote'>Full time</option>
                    <option value='Hybrid remote'>part time</option>
                  </Form.Select>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export {IndeedFormStep}
