import {useEffect, useState} from 'react'
import {
  JobboardsLabel,
  WelcomeToTheJungleLabels,
  WelcomeToTheJungleProperty,
} from '../../../referentiel/core/_models'
import {
  useAppDispatch,
  useAppSelector,
  useFormInput,
  useFormSelectInput,
} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {
  fetchWelcomeToTheJungleGetCmsSiteReferences,
  fetchWelcomeToTheJungleGetContractTypes,
  fetchWelcomeToTheJungleGetCountries,
  fetchWelcomeToTheJungleGetEducationLevels,
  fetchWelcomeToTheJungleGetExperienceLevels,
  fetchWelcomeToTheJungleGetLanguages,
  fetchWelcomeToTheJungleGetProfessionReferences,
  fetchWelcomeToTheJungleGetRemoteLevels,
  fetchWelcomeToTheJungleGetSalaryCurrencies,
  fetchWelcomeToTheJungleGetSalaryPeriods,
  fetchWelcomeToTheJungleGetVacancy,
} from '../../../../store/slices/welcomeToTheJungleReferentiel.slice'
import {Col, Form, Row} from 'react-bootstrap'
import {setToFalse, setToTrue, toggleValue} from '../../../../store/slices/bool.slices'
import './inputValidation.scss'
const WelcomeToTheJungleFormStep = (props: any) => {
  const dispatch = useAppDispatch()

  const entity = props.data ? props.data : undefined

  const language = useFormSelectInput(entity ? entity.language : '')
  const contractType = useFormSelectInput(entity ? entity.contractType : '')
  const professionReference = useFormSelectInput(entity ? entity.professionReference : '')
  const [cmsSiteReference, setCmsSiteReference] = useState(entity ? entity.cmsSiteReference : '')
  const [educationLevel, setEducationLevel] = useState(entity ? entity.educationLevel : '')
  const [experienceLevel, setExperienceLevel] = useState(entity ? entity.experienceLevel : '')
  const [remote, setRemote] = useState(entity ? entity.remote : '')
  const [salaryCurrency, setSalaryCurrency] = useState(entity ? entity.salaryCurrency : '')
  const [salaryPeriod, setSalaryPeriod] = useState(entity ? entity.salaryPeriod : '')

  const [salaryMin, setSalaryMin] = useState(entity ? entity.salaryMin : '')
  const [salaryMax, setSalaryMax] = useState(entity ? entity.salaryMax : '')
  const officeAddress = useFormInput(entity ? entity.officeAddress : '')
  const officeZipCode = useFormInput(entity ? entity.officeZipCode : '')
  const officeCity = useFormInput(entity ? entity.officeCity : '')
  const officeCountry = useFormSelectInput(entity ? entity.officeCountry : '')
  const [applyUrl, setApplyUrl] = useState(entity ? entity.applyUrl : props?.urlPublished)
  const [contractDurationMin, setContractDurationMin] = useState(
    entity ? entity.contractDurationMin : ''
  )
  const [contractDurationMax, setContractDurationMax] = useState(
    entity ? entity.contractDurationMax : ''
  )
  const [recruitmentProcess, setRecruitmentProcess] = useState(
    entity ? entity.recruitmentProcess : ''
  )
  const [startDate, setStartDate] = useState(entity ? entity.startDate : '')
  const [officeId, setOfficeId] = useState(entity ? entity.officeId : '')

  const languagesResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.languages
  )
  const contractTypesResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.contractTypes
  )
  const professionReferencesResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.professionReferences
  )
  const cmsSiteReferencesResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.cmsSiteReferences
  )
  const educationLevelsResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.educationLevels
  )
  const experienceLevelsResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.experienceLevels
  )
  const remoteLevelsResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.remoteLevels
  )
  const salaryCurrenciesResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.salaryCurrencies
  )
  const salaryPeriodsResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.salaryPeriods
  )
  const countriesResponseList = useAppSelector(
    (state: RootState) => state.welcomeToTheJungleReferentiel.countries
  )

  const handleCmsSiteReference = (e: any) => setCmsSiteReference(e.target.value)
  const handleEducationLevel = (e: any) => setEducationLevel(e.target.value)
  const handleExperienceLevel = (e: any) => setExperienceLevel(e.target.value)
  const handleRemote = (e: any) => setRemote(e.target.value)
  const handleSalaryCurrency = (e: any) => setSalaryCurrency(e.target.value)
  const handleSalaryPeriod = (e: any) => setSalaryPeriod(e.target.value)

  const handleSalaryMin = (e: any) => setSalaryMin(e.target.value)
  const handleSalaryMax = (e: any) => setSalaryMax(e.target.value)
  const handleApplyUrl = (e: any) => setApplyUrl(e.target.value)
  const handleContractDurationMin = (e: any) => setContractDurationMin(e.target.value)
  const handleContractDurationMax = (e: any) => setContractDurationMax(e.target.value)
  const handleRecruitmentProcess = (e: any) => setRecruitmentProcess(e.target.value)
  const handleStartDate = (e: any) => setStartDate(e.target.value)
  const handleOfficeId = (e: any) => setOfficeId(e.target.value)

  const handleLists = async () => {
    await dispatch(fetchWelcomeToTheJungleGetLanguages())
    await dispatch(fetchWelcomeToTheJungleGetContractTypes())
    await dispatch(fetchWelcomeToTheJungleGetProfessionReferences())
    await dispatch(fetchWelcomeToTheJungleGetCmsSiteReferences())
    await dispatch(fetchWelcomeToTheJungleGetEducationLevels())
    await dispatch(fetchWelcomeToTheJungleGetExperienceLevels())
    await dispatch(fetchWelcomeToTheJungleGetRemoteLevels())
    await dispatch(fetchWelcomeToTheJungleGetSalaryCurrencies())
    await dispatch(fetchWelcomeToTheJungleGetSalaryPeriods())
    await dispatch(fetchWelcomeToTheJungleGetCountries()).then(x => officeCountry.handleChange({ target: { value: 'fr' }}))
    await dispatch(toggleValue())
  }

  const handleUpdateVacancy = async () => {
    var response = (await dispatch(fetchWelcomeToTheJungleGetVacancy(props.vacancyId)))
      .payload as WelcomeToTheJungleProperty
    language.handleChange({target: {value: response.language}})
    contractType.handleChange({target: {value: response.contract_type}})
    professionReference.handleChange({target: {value: response.profession_reference}})
    handleCmsSiteReference({target: {value: response.cms_sites_references}})
    handleEducationLevel({target: {value: response.education_level}})
    handleExperienceLevel({target: {value: response.experience_level}})
    handleRemote({target: {value: response.remote}})
    handleSalaryMin({target: {value: response.salary?.min?.toString()}})
    handleSalaryMax({target: {value: response.salary?.max?.toString()}})
    handleSalaryCurrency({target: {value: response.salary?.currency}})
    handleSalaryPeriod({target: {value: response.salary?.period}})
    handleApplyUrl({target: {value: response.apply_url}})
    handleContractDurationMin({target: {value: response.contract_duration_min}})
    handleContractDurationMax({target: {value: response.contract_duration_max}})
    handleRecruitmentProcess({target: {value: response.recruitment_process}})
    handleStartDate({target: {value: response.start_date}})
    handleOfficeId({target: {value: response.office_id}})
  }

  useEffect(() => {
    handleLists()
    if (props.vacancyId) {
      handleUpdateVacancy()
    }
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const body = {
      id: props.vacancyId,
      status: 'published',
      organization_reference: 'Kp5KZYN', // A changer avec chaque référence client
      description: '',
      company_description: '',
      profile: '',
      name: '',
      external_reference: '',

      language: language.value,
      contract_type: contractType.value,
      profession_reference: professionReference.value,
      cms_sites_references: cmsSiteReference,
      education_level: educationLevel,
      experience_level: experienceLevel,
      remote: remote,

      offices: props.vacancyId
        ? null
        : [
            {
              address: officeAddress.value,
              city: officeCity.value,
              country_code: officeCountry.value,
              zip_code: officeZipCode.value,
            },
          ],
      apply_url: applyUrl,
      contract_duration_min: contractDurationMin ? +contractDurationMin : null,
      contract_duration_max: contractDurationMax ? +contractDurationMax : null,
      recruitment_process: recruitmentProcess,
      start_date: startDate,

      salary_currency: salaryCurrency ? salaryCurrency : null,
      salary_period: salaryPeriod ? salaryPeriod : null,
      salary_max: salaryMax,
      salary_min: salaryMin,
      office_id: officeId ? +officeId : null,
    } as WelcomeToTheJungleProperty
    props.handleWelcomeToTheJungleForm(body)

    if (
      !language.error.isError &&
      language.value.length > 0 &&
      language.value != 0 &&
      !contractType.error.isError &&
      contractType.value.length > 0 &&
      contractType.value != 0 &&
      !professionReference.error.isError &&
      professionReference.value.length > 0 &&
      professionReference.value != 0 &&
      (props.vacancyId ||
        (!officeAddress.error.isError &&
          officeAddress.value &&
          !officeZipCode.error.isError &&
          officeZipCode.value &&
          !officeCity.error.isError &&
          officeCity.value &&
          !officeCountry.error.isError &&
          officeCountry.value.length > 0 &&
          officeCountry.value != 0))
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  return (
    <div
      className='card card-bordered step-card card-wttj'
      onBlur={handleSubmit}
      onMouseLeave={handleSubmit}
    >
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>{JobboardsLabel.WELCOMETOTHEJUNGLE}</div>
      </div>
      <div className='card-body'>
        <div>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {WelcomeToTheJungleLabels.LanguageLabel}
                </label>
                {language.error.isError && (
                  <div className='error-message'>{language.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={language.value}
                onChange={language.handleChange}
                className={`mb-10 ${
                  !language.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              >
                <option value='0'>{WelcomeToTheJungleLabels.LanguageSelectLabel}</option>
                {languagesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {WelcomeToTheJungleLabels.ContractTypeLabel}
                </label>
                {contractType.error.isError && (
                  <div className='error-message'>{contractType.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={contractType.value}
                onChange={contractType.handleChange}
                className={`mb-10 ${
                  !contractType.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              >
                <option value='0'>{WelcomeToTheJungleLabels.ContractTypeSelectLabel}</option>
                {contractTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {WelcomeToTheJungleLabels.ProfessionReferenceLabel}
                </label>
                {professionReference.error.isError && (
                  <div className='error-message'>{professionReference.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={professionReference.value}
                onChange={professionReference.handleChange}
                className={`mb-10 ${
                  !contractType.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              >
                <option value='0'>{WelcomeToTheJungleLabels.ProfessionReferenceSelectLabel}</option>
                {professionReferencesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.CmsSiteReferenceLabel}</label>
              <Form.Select
                as='select'
                value={cmsSiteReference}
                onChange={handleCmsSiteReference}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{WelcomeToTheJungleLabels.CmsSiteReferenceSelectLabel}</option>
                {cmsSiteReferencesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.EducationLevelLabel}</label>
              <Form.Select
                as='select'
                value={educationLevel}
                onChange={handleEducationLevel}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{WelcomeToTheJungleLabels.EducationLevelSelectLabel}</option>
                {educationLevelsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.ExperienceLevelLabel}</label>
              <Form.Select
                as='select'
                value={experienceLevel}
                onChange={handleExperienceLevel}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{WelcomeToTheJungleLabels.ExperienceLevelSelectLabel}</option>
                {experienceLevelsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.RemoteLevelLabel}</label>
              <Form.Select
                as='select'
                value={remote}
                onChange={handleRemote}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{WelcomeToTheJungleLabels.RemoteLevelSelectLabel}</option>
                {remoteLevelsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.SalaryMin}</label>
              <Form.Control
                value={salaryMin}
                placeholder={WelcomeToTheJungleLabels.SalaryMin}
                onChange={handleSalaryMin}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.SalaryMax}</label>
              <Form.Control
                value={salaryMax}
                placeholder={WelcomeToTheJungleLabels.SalaryMax}
                onChange={handleSalaryMax}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.SalaryCurrencyLabel}</label>
              <Form.Select
                as='select'
                value={salaryCurrency}
                onChange={handleSalaryCurrency}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{WelcomeToTheJungleLabels.SalaryCurrencySelectLabel}</option>
                {salaryCurrenciesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.SalaryPeriodLabel}</label>
              <Form.Select
                as='select'
                value={salaryPeriod}
                onChange={handleSalaryPeriod}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{WelcomeToTheJungleLabels.SalaryPeriodSelectLabel}</option>
                {salaryPeriodsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        {!props.vacancyId && (
          <>
            <div>
              <Row>
                <Col>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='form-label mb-3 required'>
                      {WelcomeToTheJungleLabels.OfficeAddress}
                    </label>
                    {officeAddress.error.isError && (
                      <div className='error-message'>{officeAddress.error.errorMsg}</div>
                    )}
                  </div>
                  <Form.Control
                    value={officeAddress.value}
                    placeholder={WelcomeToTheJungleLabels.OfficeAddress}
                    onChange={officeAddress.handleChange}
                    className={`mb-10 ${
                      !officeAddress.error.isError
                        ? 'form-control form-control-lg form-control-solid'
                        : 'form-control form-control-lg form-control-solid border-red'
                    }`}
                  />
                </Col>
                <Col>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='form-label mb-3 required'>
                      {WelcomeToTheJungleLabels.OfficeZipCode}
                    </label>
                    {officeZipCode.error.isError && (
                      <div className='error-message'>{officeZipCode.error.errorMsg}</div>
                    )}
                  </div>
                  <Form.Control
                    value={officeZipCode.value}
                    placeholder={WelcomeToTheJungleLabels.OfficeZipCode}
                    onChange={officeZipCode.handleChange}
                    className={`mb-10 ${
                      !officeZipCode.error.isError
                        ? 'form-control form-control-lg form-control-solid'
                        : 'form-control form-control-lg form-control-solid border-red'
                    }`}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='form-label mb-3 required'>
                      {WelcomeToTheJungleLabels.OfficeCity}
                    </label>
                    {officeCity.error.isError && (
                      <div className='error-message'>{officeCity.error.errorMsg}</div>
                    )}
                  </div>
                  <Form.Control
                    value={officeCity.value}
                    placeholder={WelcomeToTheJungleLabels.OfficeCity}
                    onChange={officeCity.handleChange}
                    className={`mb-10 ${
                      !officeCity.error.isError
                        ? 'form-control form-control-lg form-control-solid'
                        : 'form-control form-control-lg form-control-solid border-red'
                    }`}
                  />
                </Col>
                <Col>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label className='form-label mb-3 required'>
                      {WelcomeToTheJungleLabels.OfficeCountry}
                    </label>
                    {officeCountry.error.isError && (
                      <div className='error-message'>{officeCountry.error.errorMsg}</div>
                    )}
                  </div>
                  <Form.Select
                    as='select'
                    value={officeCountry.value}
                    onChange={officeCountry.handleChange}
                    className={`mb-10 ${
                      !officeCountry.error.isError
                        ? 'form-control form-control-lg form-control-solid'
                        : 'form-control form-control-lg form-control-solid border-red'
                    }`}
                  >
                    <option value='0'>{WelcomeToTheJungleLabels.CountrySelectLabel}</option>
                    {countriesResponseList.map((item: any, index: any) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.label}
                        </option>
                      )
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </div>
          </>
        )}
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.ApplyUrl}</label>
              <Form.Control
                value={applyUrl}
                placeholder={WelcomeToTheJungleLabels.ApplyUrl}
                onChange={handleApplyUrl}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.ContractDurationMin}</label>
              <Form.Control
                value={contractDurationMin}
                placeholder={WelcomeToTheJungleLabels.ContractDurationMin}
                onChange={handleContractDurationMin}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.ContractDurationMax}</label>
              <Form.Control
                value={contractDurationMax}
                placeholder={WelcomeToTheJungleLabels.ContractDurationMax}
                onChange={handleContractDurationMax}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.RecruitmentProcess}</label>
              <Form.Control
                value={recruitmentProcess}
                placeholder={WelcomeToTheJungleLabels.RecruitmentProcess}
                onChange={handleRecruitmentProcess}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{WelcomeToTheJungleLabels.StartDate}</label>
              <Form.Control
                value={startDate}
                placeholder={WelcomeToTheJungleLabels.StartDate}
                onChange={handleStartDate}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default WelcomeToTheJungleFormStep
