import {Col, Form, Row} from 'react-bootstrap'
import {JobboardsLabel, TalentComLabels, TalentComProperty} from '../../../referentiel/core/_models'
import {useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector, useFormInput} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {
  fetchTalentComGetBenefits,
  fetchTalentComGetCategories,
  fetchTalentComGetJobTypes,
  fetchTalentComGetPeriods,
  fetchTalentComGetSalaryTypes,
} from '../../../../store/slices/talentComReferentiel.slice'
import {setToFalse, setToTrue, toggleValue} from '../../../../store/slices/bool.slices'
import {formatedTalentComDate} from '../../../referentiel/core/_tools'

const TalentComFormStep = (props: any) => {
  const dispatch = useAppDispatch()

  const entity = props.data ? props.data : undefined

  const city = useFormInput(entity ? entity.city : '')
  const state = useFormInput(entity ? entity.state : '')
  const country = useFormInput(entity ? entity.country : '')
  const [streetAddress, setStreetAddress] = useState(entity ? entity.streetaddress : '')
  const [postalCode, setPostalCode] = useState(entity ? entity.postalcode : '')
  const [expirationDate, setExpirationDate] = useState(entity ? entity.expirationdate : null)
  const [isRemote, setIsRemote] = useState<any>(entity ? entity.isRemote : '')
  const [isRemoteValue, setIsRemoteValue] = useState(entity ? entity.isRemote : '')
  const [jobType, setJobType] = useState(entity ? entity.jobtype : '')
  const [category, setCategory] = useState(entity ? entity.category : '')
  const [benefit, setBenefit] = useState(entity ? entity.benefit : '')
  const [logo, setLogo] = useState(entity ? entity.logo : '')
  const [experienceMin, setExperienceMin] = useState(entity ? entity.experience.experience_min : 0)
  const [experienceMax, setExperienceMax] = useState(entity ? entity.experience.experience_max : 0)
  const [experiencePeriod, setExperiencePeriod] = useState(entity ? entity.experience.period : '')
  const [salaryMin, setSalaryMin] = useState(entity ? entity.salary.salary_min : 0)
  const [salaryMax, setSalaryMax] = useState(entity ? entity.salary.salary_max : 0)
  const [currency, setCurrency] = useState(entity ? entity.salary.currency : '')
  const [salaryPeriod, setSalaryPeriod] = useState(entity ? entity.salary.period : '')
  const [salaryType, setSalaryType] = useState(entity ? entity.salary.type : '')

  const handleStreetAddress = (e: any) => setStreetAddress(e.target.value)
  const handlePostalCode = (e: any) => setPostalCode(e.target.value)
  const handleExpirationDate = (e: any) => setExpirationDate(e.target.value)
  const handleIsRemote = (e: any) => {
    if (e.target.checked) {
      setIsRemote('yes')
      setIsRemoteValue(true)
    } else {
      setIsRemote('no')
      setIsRemoteValue(false)
    }
  }
  const handleJobType = (e: any) => setJobType(e.target.value)
  const handleCategory = (e: any) => setCategory(e.target.value)
  const handleBenefit = (e: any) => setBenefit(e.target.value)
  const handleLogo = (e: any) => setLogo(e.target.value)
  const handleExperienceMin = (e: any) => setExperienceMin(e.target.value)
  const handleExperienceMax = (e: any) => setExperienceMax(e.target.value)
  const handleExperiencePeriod = (e: any) => setExperiencePeriod(e.target.value)
  const handleSalaryMin = (e: any) => setSalaryMin(e.target.value)
  const handleSalaryMax = (e: any) => setSalaryMax(e.target.value)
  const handleCurrency = (e: any) => setCurrency(e.target.value)
  const handleSalaryPeriod = (e: any) => setSalaryPeriod(e.target.value)
  const handleSalaryType = (e: any) => setSalaryType(e.target.value)

  const jobTypesResponseList = useAppSelector(
    (state: RootState) => state.talentComReferentiel.jobTypes
  )
  const benefitsResponseList = useAppSelector(
    (state: RootState) => state.talentComReferentiel.benefits
  )
  const categoriesResponseList = useAppSelector(
    (state: RootState) => state.talentComReferentiel.categories
  )
  const periodsResponseList = useAppSelector(
    (state: RootState) => state.talentComReferentiel.periods
  )
  const salaryTypesResponseList = useAppSelector(
    (state: RootState) => state.talentComReferentiel.salaryTypes
  )

  const handleLists = async () => {
    await dispatch(fetchTalentComGetJobTypes())
    await dispatch(fetchTalentComGetBenefits())
    await dispatch(fetchTalentComGetCategories())
    await dispatch(fetchTalentComGetPeriods())
    await dispatch(fetchTalentComGetSalaryTypes())
    await dispatch(toggleValue())
  }

  useEffect(() => {
    handleLists()
  }, [isRemoteValue, isRemote])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const body = {
      publisher: 'ScopTalent',
      publisherurl: 'https://scoptalent.com/',
      lastbuilddate: new Date().toString(),
      job: [
        {
          company: '',
          description: '',
          referencenumber: '',
          title: '',
          state: state.value,
          city: city.value,
          country: country.value,
          dateposted: formatedTalentComDate(new Date().toString()),
          benefit: benefit,
          category: category,
          experience: {
            experience_max: +experienceMax,
            experience_min: +experienceMin,
            period: experiencePeriod,
          },
          expirationdate:
            expirationDate != null && expirationDate.length > 0
              ? formatedTalentComDate(expirationDate)
              : '',
          isRemote: isRemote,
          jobtype: jobType,
          logo: logo,
          postalcode: postalCode,
          salary: {
            salary_min: +salaryMin,
            salary_max: +salaryMax,
            period: salaryPeriod,
            type: salaryType,
            currency: currency,
          },
          streetaddress: streetAddress,
        },
      ],
    } as TalentComProperty

    props.handleTalentComForm(body)

    if (
      !city.error.isError &&
      city.value &&
      !state.error.isError &&
      state.value &&
      !country.error.isError &&
      country.value
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  return (
    <div
      className='card card-bordered step-card card-talentcom'
      onBlur={handleSubmit}
      onMouseLeave={handleSubmit}
    >
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>{JobboardsLabel.TALENTCOM}</div>
      </div>
      <div className='card-body'>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{TalentComLabels.City}</label>
                {city.error.isError && <div className='error-message'>{city.error.errorMsg}</div>}
              </div>
              <Form.Control
                value={city.value}
                onChange={city.handleChange}
                placeholder={TalentComLabels.City}
                className={`${
                  !city.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{TalentComLabels.State}</label>
                {state.error.isError && <div className='error-message'>{state.error.errorMsg}</div>}
              </div>
              <Form.Control
                value={state.value}
                onChange={state.handleChange}
                placeholder={TalentComLabels.State}
                className={`${
                  !state.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{TalentComLabels.Country}</label>
                {country.error.isError && (
                  <div className='error-message'>{country.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={country.value}
                onChange={country.handleChange}
                placeholder={TalentComLabels.Country}
                className={`${
                  !country.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              />
            </Col>
            <Col>
              <label className='form-label'>{TalentComLabels.PostalCode}</label>
              <Form.Control
                value={postalCode}
                onChange={handlePostalCode}
                placeholder={TalentComLabels.PostalCode}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{TalentComLabels.StreetAddress}</label>
              <Form.Control
                value={streetAddress}
                onChange={handleStreetAddress}
                placeholder={TalentComLabels.StreetAddress}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{TalentComLabels.ExpirationDate}</label>
              <Form.Control
                value={expirationDate}
                onChange={handleExpirationDate}
                placeholder={TalentComLabels.ExpirationDate}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{TalentComLabels.IsRemote}</label>
              <br />
              <Form.Check
                inline
                type='checkbox'
                checked={isRemoteValue}
                value={isRemote}
                onChange={handleIsRemote}
                id='flexRadioLg'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{TalentComLabels.JobType}</label>
              <Form.Select
                as='select'
                value={jobType}
                onChange={handleJobType}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{TalentComLabels.JobTypesSelectLabel}</option>
                {jobTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{TalentComLabels.Benefit}</label>
              <Form.Select
                as='select'
                value={benefit}
                onChange={handleBenefit}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{TalentComLabels.BenefitsSelectLabel}</option>
                {benefitsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{TalentComLabels.Category}</label>
              <Form.Select
                as='select'
                value={category}
                onChange={handleCategory}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{TalentComLabels.CategoriesSelectLabel}</option>
                {categoriesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{TalentComLabels.Logo}</label>
              <Form.Control
                value={logo}
                onChange={handleLogo}
                placeholder={TalentComLabels.Logo}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{TalentComLabels.ExperienceMin}</label>
              <Form.Control
                value={experienceMin}
                onChange={handleExperienceMin}
                placeholder={TalentComLabels.ExperienceMin}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{TalentComLabels.ExperienceMax}</label>
              <Form.Control
                value={experienceMax}
                onChange={handleExperienceMax}
                placeholder={TalentComLabels.ExperienceMax}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{TalentComLabels.Period}</label>
              <Form.Select
                as='select'
                value={experiencePeriod}
                onChange={handleExperiencePeriod}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{TalentComLabels.PeriodsSelectLabel}</option>
                {periodsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{TalentComLabels.SalaryMin}</label>
              <Form.Control
                value={salaryMin}
                onChange={handleSalaryMin}
                placeholder={TalentComLabels.SalaryMin}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{TalentComLabels.SalaryMax}</label>
              <Form.Control
                value={salaryMax}
                onChange={handleSalaryMax}
                placeholder={TalentComLabels.SalaryMax}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{TalentComLabels.SalaryCurrency}</label>
              <Form.Control
                value={currency}
                onChange={handleCurrency}
                placeholder={TalentComLabels.SalaryCurrency}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{TalentComLabels.Period}</label>
              <Form.Select
                as='select'
                value={salaryPeriod}
                onChange={handleSalaryPeriod}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{TalentComLabels.PeriodsSelectLabel}</option>
                {periodsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{TalentComLabels.SalaryType}</label>
              <Form.Select
                as='select'
                value={salaryType}
                onChange={handleSalaryType}
                placeholder={TalentComLabels.SalaryType}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{TalentComLabels.SalaryTypesSelectLabel}</option>
                {salaryTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default TalentComFormStep
