import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { InitLeBonCoinState, DictionaryModel } from "../../pages/referentiel/core/_models";
import agent from "../../api/agent";

const initialState: InitLeBonCoinState = {
    timeTypes: [],
    contractTypes: [],
    businessSectors: [],
    occupations: [],
    durationTypes: [],
    perSalaries: [],
    contactModes: [],
    degrees: [],
    experiences: [],
    loading: false,
}

export const fetchLeBonCoinGetTimeTypes = createAsyncThunk(
    'leBonCoinReferentiel/getTimeTypes',
    async () => {
        const response = await agent.leBonCoinReferentiel.getTimeTypes();
        return response as DictionaryModel[];
    }
)

export const fetchLeBonCoinGetContractTypes = createAsyncThunk(
    'leBonCoinReferentiel/getContractTypes',
    async () => {
        const response = await agent.leBonCoinReferentiel.getContractTypes();
        return response as DictionaryModel[];
    }
)

export const fetchLeBonCoinGetBusinessSectors = createAsyncThunk(
    'leBonCoinReferentiel/getBusinessSectors',
    async () => {
        const response = await agent.leBonCoinReferentiel.getBusinessSectors();
        return response as DictionaryModel[];
    }
)

export const fetchLeBonCoinGetOccupations = createAsyncThunk(
    'leBonCoinReferentiel/getOccupations',
    async () => {
        const response = await agent.leBonCoinReferentiel.getOccupations();
        return response as DictionaryModel[];
    }
)

export const fetchLeBonCoinGetDurationTypes = createAsyncThunk(
    'leBonCoinReferentiel/getDurationTypes',
    async () => {
        const response = await agent.leBonCoinReferentiel.getDurationTypes();
        return response as DictionaryModel[];
    }
)

export const fetchLeBonCoinGetPerSalaries = createAsyncThunk(
    'leBonCoinReferentiel/getPerSalaries',
    async () => {
        const response = await agent.leBonCoinReferentiel.getPerSalaries();
        return response as DictionaryModel[];
    }
)

export const fetchLeBonCoinGetContactModes = createAsyncThunk(
    'leBonCoinReferentiel/getContactModes',
    async () => {
        const response = await agent.leBonCoinReferentiel.getContactModes();
        return response as DictionaryModel[];
    }
)

export const fetchLeBonCoinGetDegrees = createAsyncThunk(
    'leBonCoinReferentiel/getDegrees',
    async () => {
        const response = await agent.leBonCoinReferentiel.getDegrees();
        return response as DictionaryModel[];
    }
)

export const fetchLeBonCoinGetExperiences = createAsyncThunk(
    'leBonCoinReferentiel/getExperiences',
    async () => {
        const response = await agent.leBonCoinReferentiel.getExperiences();
        return response as DictionaryModel[];
    }
)

const leBonCoinReferentielSlice = createSlice({
    name: "leBonCoinReferentiel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeBonCoinGetTimeTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLeBonCoinGetTimeTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.timeTypes = [...action.payload];
            })
            .addCase(fetchLeBonCoinGetTimeTypes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLeBonCoinGetContractTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLeBonCoinGetContractTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.contractTypes = [...action.payload];
            })
            .addCase(fetchLeBonCoinGetContractTypes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLeBonCoinGetBusinessSectors.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLeBonCoinGetBusinessSectors.fulfilled, (state, action) => {
                state.loading = false;
                state.businessSectors = [...action.payload];
            })
            .addCase(fetchLeBonCoinGetBusinessSectors.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLeBonCoinGetOccupations.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLeBonCoinGetOccupations.fulfilled, (state, action) => {
                state.loading = false;
                state.occupations = [...action.payload];
            })
            .addCase(fetchLeBonCoinGetOccupations.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLeBonCoinGetDurationTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLeBonCoinGetDurationTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.durationTypes = [...action.payload];
            })
            .addCase(fetchLeBonCoinGetDurationTypes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLeBonCoinGetPerSalaries.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLeBonCoinGetPerSalaries.fulfilled, (state, action) => {
                state.loading = false;
                state.perSalaries = [...action.payload];
            })
            .addCase(fetchLeBonCoinGetPerSalaries.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLeBonCoinGetContactModes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLeBonCoinGetContactModes.fulfilled, (state, action) => {
                state.loading = false;
                state.contactModes = [...action.payload];
            })
            .addCase(fetchLeBonCoinGetContactModes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLeBonCoinGetDegrees.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLeBonCoinGetDegrees.fulfilled, (state, action) => {
                state.loading = false;
                state.degrees = [...action.payload];
            })
            .addCase(fetchLeBonCoinGetDegrees.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchLeBonCoinGetExperiences.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLeBonCoinGetExperiences.fulfilled, (state, action) => {
                state.loading = false;
                state.experiences = [...action.payload];
            })
            .addCase(fetchLeBonCoinGetExperiences.rejected, (state, action) => {
                state.loading = false;
            })
    },
})

export const {} = leBonCoinReferentielSlice.actions;
export default leBonCoinReferentielSlice.reducer