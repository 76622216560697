import { useEffect, useState } from "react";
import { TenantJobboardVM } from "../../referentiel/core/_models";
import { RootState } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useLocation } from "react-router-dom";
import { fetchTenantJobboards } from "../../../store/slices/tenantJobboard.slices";
import { TenantJobboardForm } from "./TenantJobboardForm";
import { Button, Modal } from "react-bootstrap";

export function TenantJobboardPage() {

    const dispatch = useAppDispatch();
    const location = useLocation();
    const [editedMode, setEditedMode] = useState(false);
    const [lgShow, setLgShow] = useState(false);

    const [tenantJobboardEnteredValue, setTenantJobboardEnteredValue] = useState<TenantJobboardVM | undefined>({});

    const entities = useAppSelector((state: RootState) => state.tenantJobboards.entities);
    const loading = useAppSelector((state: RootState) => state.tenantJobboards.loading);
    const handleLists = async () => {
        await dispatch(fetchTenantJobboards());
    }
    useEffect(() => {
        handleLists();
    }, [location]);

    const modalHandler = (modalValue: boolean) => setLgShow(modalValue);
    const handleCancel = () => {
        setTenantJobboardEnteredValue({});
        setLgShow(false);
    }

    const handleUpdate = (data: any) => {
        try {
            const entity = entities.find((result) => result?.jobboardId === data?.jobboardId);
            if (data) {
                setEditedMode(true);
                setTenantJobboardEnteredValue(entity);
            }
            setLgShow(true);
        } catch (error) {

        }
    };

    return (
        <>

            <div className="card card-custom card-flush">
                <div className="card-header">
                    <h3 className="card-title">Liste des job boards</h3>
                    <div className="card-toolbar">
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={handleCancel}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    {!editedMode ? "Ajouter" : "Modifier"}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <TenantJobboardForm
                                    ModalShow={modalHandler}
                                    EditMode={editedMode}
                                    data={tenantJobboardEnteredValue} />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className="card-body">
                {loading ? ("Loading ...") : (
                    <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 gy-7">
                            <thead>
                                <tr className="fw-bolder fs-6 text-gray-800">
                                    <th className="min-w-200px">Libellé </th>
                                    <th className="min-w-100px">Identifiant</th>
                                    <th className="min-w-100px">Mot de passe</th>
                                    <th className="min-w-200px">Crédit</th>
                                    <th className="min-w-100px">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    entities.map((data: TenantJobboardVM, index: any) => (
                                        <tr key={index}>
                                            <td><b>{data.jobboardLabel}</b></td>
                                            <td className="min-w-100px max-w-100px" style={{ maxWidth: '450px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{data.userName === null
                                                ? "Pas d'info"
                                                : data.userName}
                                            </td>
                                            <td className="min-w-100px max-w-100px" >
                                                {data.password === null ? "Pas d'info" : '★★★★★★★'}
                                            </td>
                                            <td>{data?.credit === 0
                                                ? <span className='badge badge-warning'>0 crédit</span>
                                                : <span className='badge badge-success'>{data.credit}</span>}
                                            </td>
                                            <td>
                                                <Button variant="link" size="sm" onClick={() => handleUpdate(data)}>
                                                    Modifier
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    )}
                </div>
            </div>
        </>
    )
}