import { createSlice } from '@reduxjs/toolkit';

const boolSlice  = createSlice({
  name: 'bool',
  initialState: false,
  reducers: {
    toggleValue: (state) => {
      return state;
    },
    setToTrue: (state) => {
      return true;
    },
    setToFalse: (state) => {
      return false;
    },
  },
});

export const { toggleValue, setToTrue, setToFalse } = boolSlice .actions;

export default boolSlice.reducer;
