
import React, { ReactNode } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Field, ErrorMessage } from 'formik'
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import Jobboard, { JobboardViewModel } from "./jobboard";
import './floatingJobboardList.scss';
import { getImageFromLogo } from "./getLogo";

class FloatingJobboardList extends React.Component<{ jobboards: JobboardViewModel[] | undefined}>
{
    render(): ReactNode {
        return (
            <div className="jobboard-floating-list">
                {this.props.jobboards?.map((item) => {
                    return (<img src={getImageFromLogo(item.logo)} key={item.index} />);
                })}
            </div>
        )
    }
}

export default FloatingJobboardList;