/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import { DiffusionByUrlPage } from '../pages/scopdiffusion/diffusion/DiffusionByUrlPage'
import { ErrorsScopDiffusionPage } from '../modules/errors/ErrorsScopDiffusionPage'
import { LoginByToken } from '../modules/auth/components/LoginByToken'
import { UpdateDiffusionPage } from '../pages/scopdiffusion/diffusion/UpdateDiffusionPage'
import { DiffusionFormPage } from '../pages/scopdiffusion/diffusion/DiffusionFormPage'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth();
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='/loginbytoken' element={<LoginByToken />} />
          <Route path='/diffusion/:id' element={<DiffusionByUrlPage />} />
          <Route path='/update' element={<UpdateDiffusionPage />} />
          <Route path='/detail' element={<DiffusionFormPage />} />
          <Route path='error-scopdiffusion/*' element={<ErrorsScopDiffusionPage />} />
          <Route path='error/*' element={<ErrorsScopDiffusionPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
