import {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  Modal,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import '../diffusion/steps/secondStep.scss'
import {useAppDispatch, useAppSelector} from '../../../store/hooks'
import {MonsterFormStep} from './steps/MonsterFormStep'
import {
  ApecProperty,
  EmploiCollectivitesProperty,
  EmploiTerritorialProperty,
  FederationHospitaliereFranceProperty,
  FigaroProperty,
  HelloWorkProperty,
  IndeedProperty,
  JobVacancyDiffusionVM,
  JobVacancyModel,
  JobVacancyType,
  JobboardJobVacancyModel,
  JobboardsLabel,
  LeBonCoinProperty,
  LinkedinProperty,
  MeteoJobProperty,
  MonsterProperty,
  PoleEmploiProperty,
  TalentComProperty,
  WelcomeToTheJungleProperty,
} from '../../referentiel/core/_models'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {fetchJobVacancyById, jobVacancyUpdated} from '../../../store/slices/jobVacancy.slices'
import {
  fetchMonsterGetIndustriesType,
  fetchMonsterGetJobCategoriesTypes,
  fetchMonsterGetJobTypes,
  fetchMonsterLocationsType,
} from '../../../store/slices/monsterReferentiel.slices'
import './DiffusionFormPage.scss'
import agent from '../../../api/agent'

import {dateFormatedDiffusion, dateFormatedISO} from '../../referentiel/core/_tools'
import DatePicker, {registerLocale} from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import fr from 'date-fns/locale/fr'
import {RootState} from '../../../store'
import {IndeedFormStep} from './steps/IndeedFormStep'
import {FederationHospitaliereFormStep} from './steps/FederationHospitaliereFormStep'
import {EmploiCollectivitesFormStep} from './steps/EmploiCollectivitesFormStep'
import {PoleEmploiFormStep} from './steps/PoleEmploiFormStep'
import {fetchPoleEmploiContractTypes} from '../../../store/slices/poleEmploiContractTypes.slices'
import {fetchPoleEmploiContractNatures} from '../../../store/slices/poleEmploiContractNatures.slices'
import {fetchPoleEmploiSalaryTypes} from '../../../store/slices/poleEmploiSalaryTypes.slices'
import {fetchPoleEmploiQualificationTypes} from '../../../store/slices/poleEmploiQualificationTypes.slices'
import {toggleValue} from '../../../store/slices/bool.slices'
import {FigaroFormStep} from './steps/FigaroFormStep'
import HelloWorkFormStep from './steps/HelloWorkFormStep'
import {ApecFormStep} from './steps/apecFormStep'
import WelcomeToTheJungleFormStep from './steps/WelcomeToTheJungleFormStep'
import {fetchTenantJobboardByLabel} from '../../../store/slices/tenantJobboard.slices'
import LeBonCoinFromStep from './steps/LeBonCoinFormStep'
import LinkedinFormStep from './steps/LinkedinFormStep'
import EmploiTerritorialFormStep from "./steps/EmploiTerritorialFormStep"
import TalentComFormStep from './steps/TalentComFormStep'
import MeteoJobFormStep from './steps/MeteoJobFormStep'

export function DiffusionFormPage() {
  const [queryParameters] = useSearchParams()
  let returnUrl = queryParameters.get('returnUrl')
  const location = useLocation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isEdited, setIsEdited] = useState(false)
  const locationState = location.state as JobVacancyDiffusionVM
  const [diffusionInformation, setDiffusionInformation] =
    useState<JobVacancyDiffusionVM>(locationState)
  const [monsterPropertiesInput, SetMonsterPropertiesInput] = useState<MonsterProperty>()
  const [dateForm, setDateForm] = useState(
    diffusionInformation?.postingDate
      ? dateFormatedDiffusion(diffusionInformation?.postingDate)
      : new Date()
  )
  const userEmail = useAppSelector((state: RootState) => state.user.email)

  const [emploiCollectivitesInput, SetEmploiCollectivitesInput] =
    useState<EmploiCollectivitesProperty>()

  const [leBonCoinProperyInput, SetLeBonCoinPropertyInput] = useState<LeBonCoinProperty>()
  const [linkedinPropertyInput, SetLinkedinPropertyInput] = useState<LinkedinProperty>()
  const [helloWorkPropertyInput, SetHelloWorkPropertyInput] = useState<HelloWorkProperty>()
  const [emploiTerritorialInput, SetEmploiTerritoralInput] = useState<EmploiTerritorialProperty>()
  const [meteoJobPropertyInput, SetMeteoJobPropertyInput] = useState<MeteoJobProperty>()
  const [talentComPropertyInput, SetTalentComPropertyInput] = useState<TalentComProperty>()
  const [welcomeToTheJunglePropertyInput, SetWelcomeToTheJunglePropertyInput] =
    useState<WelcomeToTheJungleProperty>()
  const [poleEmploiPropertyInput, SetPoleEmploiPropertyInput] = useState<PoleEmploiProperty>()
  const [emploiCollectivitesPropertiesInput, SetEmploiCollectivitesPropertiesInput] =
    useState<EmploiCollectivitesProperty>()

  const [indeedPropertiesInput, SetIndeedPropertiesInput] = useState<IndeedProperty>()
  const [fhfPropertiesInput, SetfhfPropertiesInput] =
    useState<FederationHospitaliereFranceProperty>()

  const [figaroPropertiesInput, setFigaroPropertiesInput] =
    useState<FederationHospitaliereFranceProperty>()
  const [apecPropertyInput, setApecPropertyInput] = useState<ApecProperty>()

  const [alertMessage, setAlertMessage] = useState<any>('')
  const [alertVariant, setAlertVariant] = useState<any>('')
  const [showAlert, setShowAlert] = useState(false)

  const [isDisabled, setIsDisabled] = useState(false)

  const [isValidMonsterForm, setIsValidMonsterForm] = useState(false)
  const [isValidPoleEmploiForm, setIsValidPoleEmploiForm] = useState(false)
  const [isValidFhfForm, setIsValidFhfForm] = useState(false)
  const [isValidEmploiCollectiviteForm, setIsValidEmploiCollectiviteForm] = useState(false)
  const [isValidFigaroForm, setIsValidFigaroForm] = useState(false)
  const [isValidHelloWorkForm, setIsValidHelloWorkForm] = useState(false)
  const [isValidApecForm, setIsValidApecForm] = useState(false)
  const [isValidWelcomeToTheJungleForm, setIsValidWelcomeToTheJungleForm] = useState(false)
  const [isValidLeBonCoinForm, setIsValidLeBonCoinForm] = useState(false)
  const [isValidEmploiTerritorialForm, setIsValidEmploiTerritorialForm] = useState(false)
  const [isValidLinkedInForm, setIsValidLinkedInForm] = useState(false)
  const [isValidTalentComForm, setIsValidTalentComForm] = useState(false)
  const [isValidMeteoJobForm, setIsValidMeteoJobForm] = useState(false)

  const poleEmploiPropertyRef = useRef<PoleEmploiProperty | null>(null)

  function handleChange(
    currentTarget: EventTarget & (HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement)
  ): void {
    let diffusionModified: JobVacancyDiffusionVM = {...diffusionInformation}
    let key: keyof JobVacancyDiffusionVM = currentTarget.name as keyof JobVacancyDiffusionVM
    ;(diffusionModified[key] as String) = currentTarget.value
    setDiffusionInformation(diffusionModified)
  }

  function handleSubmit() {
    const updatedDiffusion = {
      ...diffusionInformation,
      recruiteurEmail: userEmail,
      tenantId: diffusionInformation?.tenantId,
      monsterProperty: monsterPropertiesInput
        ? monsterPropertiesInput
        : diffusionInformation?.monsterProperty
        ? {
            ...diffusionInformation?.monsterProperty,
            jobboardLabel: JobboardsLabel.MONSTER,
          }
        : null,
      emploiCollectivitesProperty: emploiCollectivitesPropertiesInput
        ? emploiCollectivitesPropertiesInput
        : diffusionInformation?.emploiCollectivitesProperty
        ? {
            ...diffusionInformation?.emploiCollectivitesProperty,
            jobboardLabel: JobboardsLabel.EMPLOICOLLECTIVITES,
          }
        : null,
      postingDate: dateFormatedISO(dateForm),
      poleEmploiProperty: poleEmploiPropertyInput
        ? poleEmploiPropertyInput
        : diffusionInformation?.poleEmploiProperty
        ? {
            ...diffusionInformation?.poleEmploiProperty,
            jobboardLabel: JobboardsLabel.POLEEMPLOI,
          }
        : null,
      leBonCoinProperty: leBonCoinProperyInput
        ? leBonCoinProperyInput
        : diffusionInformation?.leBonCoinProperty,
      linkedinProperty: linkedinPropertyInput
        ? linkedinPropertyInput
        : diffusionInformation?.linkedinProperty,
      meteoJobProperty: meteoJobPropertyInput
        ? meteoJobPropertyInput
        : diffusionInformation?.meteoJobProperty,
      talentComProperty: talentComPropertyInput
        ? talentComPropertyInput
        : diffusionInformation?.talentComProperty,
      helloWorkProperty: helloWorkPropertyInput
        ? helloWorkPropertyInput
        : diffusionInformation?.helloWorkProperty,
      emploiTerritorialProperty: emploiTerritorialInput,
      welcomeToTheJungleProperty: welcomeToTheJunglePropertyInput
        ? welcomeToTheJunglePropertyInput
        : diffusionInformation?.welcomeToTheJungleProperty,
      indeedProperty: indeedPropertiesInput
        ? indeedPropertiesInput
        : diffusionInformation?.indeedProperty
        ? {...diffusionInformation?.indeedProperty, jobboardLabel: JobboardsLabel.INDEED}
        : null,

      federationHospitaliereFranceProperty: fhfPropertiesInput
        ? fhfPropertiesInput
        : diffusionInformation?.federationHospitaliereFranceProperty
        ? {
            ...diffusionInformation?.federationHospitaliereFranceProperty,
            jobboardLabel: JobboardsLabel.FHF,
          }
        : null,

      figaroProperty: figaroPropertiesInput
        ? figaroPropertiesInput
        : diffusionInformation?.figaroProperty
        ? {...diffusionInformation?.figaroProperty, jobboardLabel: JobboardsLabel.FIGARO}
        : null,
      apecProperty: apecPropertyInput
        ? apecPropertyInput
        : diffusionInformation?.apecProperty
        ? {...diffusionInformation?.apecProperty, jobboardLabel: JobboardsLabel.APEC}
        : null,
    } as JobVacancyDiffusionVM
    setDiffusionInformation(updatedDiffusion)
    try {
      updateDiffusion(updatedDiffusion)
    } catch {
      console.log('ERROR')
    }
  }

  const updateDiffusion = (model: any) => {
    if (model) {
      agent.JobVacancy.update(diffusionInformation.id, model)
        .then((res: any) => {
          if (res.success) {
            dispatch(jobVacancyUpdated(model))
            setAlertMessage(Object.keys(res.messages)[0])
            setAlertVariant('success')
            setShowAlert(true)
            if (returnUrl === null) {
              navigate('/dashboard')
            } else {
              window.location.href = returnUrl
            }
          }
          if (!res.success) {
            setAlertMessage(Object.keys(res.messages)[0])
            setAlertVariant('warning')
            setShowAlert(true)
          }
        })
        .catch((error: any) => console.log(error))
    }
  }
  const handlePoleEmploiInput = (input: PoleEmploiProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      contractTypes: input.contractTypes,
      contractNatures: input.contractNatures,
      salaryTypes: input.salaryTypes,
      qualificationTypes: input.qualificationTypes,
      codeDepartementLieuTravail: input.codeDepartementLieuTravail,
      codeCommuneLieuTravail: input.codeCommuneLieuTravail,
      libelleCommuneLieuTravail: input.libelleCommuneLieuTravail,
      codePaysLieuTravail: input.codePaysLieuTravail,
      nbPostesCrees: input.nbPostesCrees,
      codeExigibiliteFormation: input.codeExigibiliteFormation,
      dateDebutContrat: input.dateDebutContrat,
      nbMoisSalaire: input.nbMoisSalaire,
      dateFinpublic: input.dateFinpublic,
      salaireMin: input.salaireMin,
      salaireMax: input.salaireMax,
      duration: input.duration, // duration
      codeTypeExpPro: input.codeTypeExpPro,
      dureeMinExpPro: input.dureeMinExpPro,
      typeDureeExpPro: input.typeDureeExpPro,
      userName: input?.userName,
      password: input?.password,
      siretClient: input?.siretClient,
      idRecruteur: input?.idRecruteur,
      internetDomainName: input?.internetDomainName,
      postalCode: input?.postalCode,
    } as PoleEmploiProperty
    if (body != null && body != undefined) {
      SetPoleEmploiPropertyInput(body)
    }
  }

  const handleMonsterInput = (input: MonsterProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      location: input.location,
      state: input.state,
      country: input.country,
      city: input.city,
      jobCategory: input.jobCategory,
      industry: input.industry,
      jobTypes: input.jobTypes,
      userName: input.userName,
      password: input.password,
    } as MonsterProperty

    if (body != null && body != undefined) {
      SetMonsterPropertiesInput(body)
    }
  }

  const handleIndeedInput = (input: IndeedProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      state: input.state,
      country: input.country,
      email: input.email,
      salary: input.salary,
      education: input.education,
      jobtype: input.jobtype,
      categories: input.categories,
      experience: input.experience,
      remotetype: input.remotetype,
      paidVersion : input.paidVersion,
    } as IndeedProperty

    if (body != null && body != undefined) {
      SetIndeedPropertiesInput(body)
    }
  }

  const handleFigaroInput = (input: FigaroProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      experienceRequirements: input.experienceRequirements,
      educationRequirements: input.educationRequirements,
      postalCode: input.postalCode,
      countryCode: input.countryCode,
      occupationalCategories: input.occupationalCategories,
      industries: input.industries,
      hiringOrganizationLegalName: input.hiringOrganizationLegalName,
      hiringOrganizationIdentifier: input.hiringOrganizationIdentifier,
      employmentType: input.employmentType,
      employmentDuration: input.employmentDuration,
    } as FigaroProperty

    if (body != null && body != undefined) {
      setFigaroPropertiesInput(body)
    }
  }

  const handleHelloWorkInput = (input: HelloWorkProperty) => {
    input.AdressesPostales[0].Pays = 'France' // A changer peut-être à l'avenir
    input.Description =
      diffusionInformation?.companyDescription + ' ' + diffusionInformation?.jobDescription
    input.DescriptionEntreprise = diffusionInformation?.companyDescription
    input.DescriptionPoste = diffusionInformation?.jobDescription
    input.Nom_Ese = diffusionInformation?.companyLabel!
    input.Poste = diffusionInformation?.jobTitle!
    input.Reference = diffusionInformation?.jobReference!
    input.DateAnnonce = diffusionInformation?.postingDate
    input.ExternalId = diffusionInformation?.id
    input.Profil = diffusionInformation?.applicantProfile
    input.Source = {
      IdOffer: diffusionInformation?.id,
      Nom: 'scoptalent',
    }

    if (input != null && input != undefined) {
      SetHelloWorkPropertyInput(input)
    }
  }

  const handleLists = async () => {
    await dispatch(fetchJobVacancyById(locationState?.id))
    await dispatch(fetchMonsterGetIndustriesType())
    await dispatch(fetchMonsterGetJobTypes())
    await dispatch(fetchMonsterGetJobCategoriesTypes())
    await dispatch(fetchMonsterLocationsType())
    await dispatch(fetchPoleEmploiContractTypes())
    await dispatch(fetchPoleEmploiContractNatures())
    await dispatch(fetchPoleEmploiSalaryTypes())
    await dispatch(fetchPoleEmploiQualificationTypes())
    await dispatch(toggleValue())
  }

  useEffect(() => {
    registerLocale('fr', fr)
    handleLists()
    if (locationState) {
      setIsEdited(true)
    }
  }, [location])

  const handleEmploiCollectivitesInput = (input: EmploiCollectivitesProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      description_de_la_collectivite: input.description_de_la_collectivite,
      date_de_debut: input.date_de_debut,
      date_fin_de_publication: input.date_fin_de_publication,
      departement_du_poste: input.departement_du_poste,
      destinataires_des_candidatures: input.destinataires_des_candidatures,
      experience: input.experience,
      niveau_d_etudes: input.niveau_d_etudes,
      region_du_poste: input.region_du_poste,
      statut: input.statut,
      temps_complet_partiel: input.temps_complet_partiel,
      type_de_contrat: input.type_de_contrat,
      ville_du_poste: input.ville_du_poste,
      cadre_d_emplois: input.cadre_d_emplois,
      famille_de_metiers: input.famille_de_metiers,
      filieres: input.filieres,
    } as EmploiCollectivitesProperty
    if (body != null && body != undefined) {
      SetEmploiCollectivitesInput(body)
    }
  }

  const handleFhfInput = (input: FederationHospitaliereFranceProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      finess_etab: input.finess_etab,
      poste: input.poste,
      contrat: input.contrat,
      // contact: input.contact,
      // mail : input.mail,
      publie: input.publie,
      active: input.active,
      source: input.source,
      partenaires: input.partenaires,
      // type: input.type,
      userName: input?.userName,
      password: input?.password,
      fhfToken: input?.fhfToken,
      idOffre: input?.idOffre,
      isUpdated: input?.isUpdated,
    } as FederationHospitaliereFranceProperty

    if (body != null && body != undefined) {
      SetfhfPropertiesInput(body)
    }
  }

  const handleApecInput = (input: ApecProperty) => {
    const body = {
      jobboardLabel: input.jobboardLabel,
      region: input.region,
      experienceLevel: input.experienceLevel,
      basePayAmountMax: input.basePayAmountMax,
      basePayAmountMin: input.basePayAmountMin,
      duration: input.duration,
      jobStatus: input.jobStatus,
      displayPay: input.displayPay,
      partTime: input.partTime,
      partTimeDuration: input.partTimeDuration,
      jobType: input.jobType,
      experienceLevelLabel: input.experienceLevelLabel,
      numeroDossier: input.numeroDossier,
      industryCode: input.industryCode,
      legalId: input.legalId,
    } as ApecProperty

    if (body != null && body != undefined) {
      setApecPropertyInput(body)
    }
  }

  const handleWelcomeToTheJungleInput = (input: WelcomeToTheJungleProperty) => {
    if (input != null && input != undefined) {
      input.description = diffusionInformation?.jobDescription!
      input.company_description = diffusionInformation?.companyDescription
      input.profile = diffusionInformation?.applicantProfile!
      input.name = diffusionInformation?.jobTitle!
      input.external_reference = diffusionInformation?.jobReference

      SetWelcomeToTheJunglePropertyInput(input)
    }
  }

  const handleLeBonCoinInput = (input: LeBonCoinProperty) => {
    if (input) {
      input.partner_unique_reference = diffusionInformation.id!
      input.job.description = diffusionInformation.jobDescription!
      input.job.title = diffusionInformation.jobTitle!
      input.job.client_reference = diffusionInformation?.jobReference
      if (input.company) {
        input.company.name = diffusionInformation.companyLabel!
        input.company.description = diffusionInformation.companyDescription!
        input.company.url = diffusionInformation.companyUrl!
      }
      SetLeBonCoinPropertyInput(input)
    }
  }

  const handleLinkedInInput = (input: LinkedinProperty) => {
    if (input != null && input != undefined) {
      input.partnerJobId = diffusionInformation?.id!
      input.company = diffusionInformation?.companyLabel!
      input.title = diffusionInformation?.jobTitle!
      input.description = diffusionInformation?.jobDescription!

      SetLinkedinPropertyInput(input)
    }
  }

  const handleMeteoJobInput = (input: MeteoJobProperty) => {
    input.reference = diffusionInformation?.jobReference!
    input.publicationDate = diffusionInformation?.postingDate
    input.title = diffusionInformation?.jobTitle!
    input.company!.name = diffusionInformation?.companyLabel
    input.company!.description = diffusionInformation?.companyDescription
    input.position.description = diffusionInformation?.jobDescription!
    input.profile.description = diffusionInformation?.applicantProfile

    if (input != null && input != undefined) {
      SetMeteoJobPropertyInput(input)
    }
  }

  const handleEmploiTerritorialInput = (input: EmploiTerritorialProperty) => {
    input.jobTitle = diffusionInformation?.jobTitle!
    input.vacancyDescription = diffusionInformation?.jobDescription!
    input.vacancyProfile = diffusionInformation?.applicantProfile!
    input.vacancyPublicationStartDate = diffusionInformation?.postingDate!

    if (input != null && input != undefined) {
      SetEmploiTerritoralInput(input)
    }
  }

  const handleTalentComInput = (input: TalentComProperty) => {
    input.job[0].company = diffusionInformation?.companyLabel!
    input.job[0].description = diffusionInformation?.jobDescription!
    input.job[0].referencenumber = diffusionInformation?.jobReference!
    input.job[0].title = diffusionInformation?.jobTitle!

    if (input != null && input != undefined) {
      SetTalentComPropertyInput(input)
    }
  }

  const handlingIsValidMonsterForm = (value: boolean) => {
    setIsValidMonsterForm(value)
  }
  const handlingIsValidPoleEmploiForm = (value: boolean) => {
    setIsValidPoleEmploiForm(value)
  }
  const handlingIsValidFhfForm = (value: boolean) => {
    setIsValidFhfForm(value)
  }
  const handlingIsValidEmploiCollectivitesForm = (value: boolean) => {
    setIsValidEmploiCollectiviteForm(value)
  }
  const handlingIsValidFigaroForm = (value: boolean) => {
    setIsValidFigaroForm(value)
  }
  const handlingIsValidHelloWorkForm = (value: boolean) => {
    setIsValidHelloWorkForm(value)
  }
  const handlingIsValidApecForm = (value: boolean) => {
    setIsValidApecForm(value)
  }
  const handlingIsValidWelcomeToTheJungleForm = (value: boolean) => {
    setIsValidWelcomeToTheJungleForm(value)
  }

  const handlingIsValidLeBonCoinForm = (value: boolean) => {
    setIsValidLeBonCoinForm(value)
  }

  const handlingIsValidLinkedInForm = (value: boolean) => {
    setIsValidLinkedInForm(value)
  }

  const handlingIsValidMeteoJobForm = (value: boolean) => {
    setIsValidMeteoJobForm(value)
  }

  const handlingIsValidEmploiTerritorialForm = (value: boolean) => {
    setIsValidEmploiTerritorialForm(value);
  }

  const handlingIsValidTalentComForm = (value: boolean) => {
    setIsValidTalentComForm(value)
  }

  useEffect(() => {
    if (
      (diffusionInformation.monsterProperty && isValidMonsterForm) ||
      (diffusionInformation.poleEmploiProperty && isValidPoleEmploiForm) ||
      (diffusionInformation.federationHospitaliereFranceProperty && isValidFhfForm) ||
      (diffusionInformation.emploiCollectivitesProperty && isValidEmploiCollectiviteForm) ||
      (diffusionInformation.figaroProperty && isValidFigaroForm) ||
      (diffusionInformation.helloWorkProperty && isValidHelloWorkForm) ||
      (diffusionInformation.apecProperty && isValidApecForm) ||
      (diffusionInformation.welcomeToTheJungleProperty && isValidWelcomeToTheJungleForm) ||
      (diffusionInformation.linkedinProperty && isValidLinkedInForm) ||
      (diffusionInformation.leBonCoinProperty && isValidLeBonCoinForm) || 
      (diffusionInformation.emploiTerritorialProperty && isValidEmploiTerritorialForm) ||
      (diffusionInformation.talentComProperty && isValidTalentComForm) ||
      (diffusionInformation.meteoJobProperty && isValidMeteoJobForm) 
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [
    isValidMonsterForm,
    isValidPoleEmploiForm,
    isValidFhfForm,
    isValidEmploiCollectiviteForm,
    isValidFigaroForm,
    isValidHelloWorkForm,
    isValidApecForm,
    isValidWelcomeToTheJungleForm,
    isValidLeBonCoinForm,
    isValidLinkedInForm, 
    isValidEmploiTerritorialForm,
    isValidTalentComForm,
    isValidMeteoJobForm
  ])

  return (
    <>
      <Row>
        <Col>
          <h2>Remplir les informations</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row>
              {/* Informations générales */}
              <Col sm={12} md={6} lg={6}>
                <div className='card card-bordered step-card'>
                  <div className='card-header ribbon ribbon-start'>
                    <div className='ribbon-label'>Informations générales</div>
                  </div>
                  <div className='card-body'>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='postingDate'>
                        Date de diffusion
                      </label>
                      <DatePicker
                        locale='fr'
                        dateFormat='dd-MM-yyyy'
                        selected={dateForm}
                        onChange={(date: any) => setDateForm(date)}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='jobReference'>
                        Référence de l'offre
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Référence de l'offre"
                        type='text'
                        id='jobReference'
                        name='jobReference'
                        value={diffusionInformation?.jobReference}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='jobTitle'>
                        Titre de l'offre
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Titre de l'offre"
                        type='text'
                        id='jobTitle'
                        name='jobTitle'
                        value={diffusionInformation?.jobTitle}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              {/* entreprise */}
              <Col sm={12} md={6} lg={6}>
                <div className='card card-bordered step-card'>
                  <div className='card-header ribbon ribbon-start'>
                    <div className='ribbon-label required'>L'entreprise</div>
                  </div>
                  <div className='card-body'>
                    <div className='mb-10'>
                      <label className='form-label' htmlFor='companyLabel'>
                        Nom de l'entreprise
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Nom de l'entreprise"
                        type='text'
                        id='companyLabel'
                        name='companyLabel'
                        value={diffusionInformation?.companyLabel}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label' htmlFor='companyUrl'>
                        Lien vers le site internet
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Lien vers le site internet"
                        type='text'
                        id='companyUrl'
                        name='companyUrl'
                        value={diffusionInformation?.companyUrl}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='companyDescription'>
                        Description de l'entreprise
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Description de l'entreprise"
                        as='textarea'
                        name='companyDescription'
                        value={diffusionInformation?.companyDescription}
                        onChange={(e) => handleChange(e.currentTarget)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6}>
                {/* poste */}
                <div className='card card-bordered step-card'>
                  <div className='card-header ribbon ribbon-start'>
                    <div className='ribbon-label'>Le poste</div>
                  </div>
                  <div className='card-body'>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='jobDescription'>
                        Description du poste
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Description du poste"
                        as='textarea'
                        name='jobDescription'
                        value={diffusionInformation?.jobDescription}
                        onChange={(e) => handleChange(e.currentTarget)}
                        style={{height: '180px'}}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='country'>
                        Pays
                      </label>
                      <Form.Control
                        disabled
                        className='form-control-solid'
                        placeholder="Pays"
                        type='text'
                        name='country'
                        defaultValue='France'
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                {/* Le profil candidat */}
                <div className='card card-bordered step-card'>
                  <div className='card-header ribbon ribbon-start'>
                    <div className='ribbon-label'>Le profil candidat</div>
                  </div>
                  <div className='card-body'>
                    <div className='mb-10'>
                      <label className='form-label required' htmlFor='applicantProfile'>
                        Description du profil
                      </label>
                      <Form.Control
                        className='form-control-solid'
                        placeholder="Description du profil"
                        as='textarea'
                        name='applicantProfile'
                        value={diffusionInformation?.applicantProfile}
                        onChange={(e) => handleChange(e.currentTarget)}
                        style={{height: '250px'}}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* jobboard diffusion form  */}
            <Row>
              {diffusionInformation?.monsterProperty &&
                Object.keys(diffusionInformation?.monsterProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <MonsterFormStep
                      handleMonsterForm={handleMonsterInput}
                      jobboardLabel={JobboardsLabel.MONSTER}
                      data={diffusionInformation?.monsterProperty}
                      isEdited={isEdited}
                      handleIsValid={handlingIsValidMonsterForm}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.indeedProperty &&
                Object.keys(diffusionInformation?.indeedProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <IndeedFormStep
                      handleForm={handleIndeedInput}
                      jobboardLabel={JobboardsLabel.INDEED}
                      data={diffusionInformation?.indeedProperty}
                      isEdited={isEdited}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.emploiCollectivitesProperty &&
                Object.keys(diffusionInformation?.emploiCollectivitesProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <EmploiCollectivitesFormStep
                      handleForm={handleEmploiCollectivitesInput}
                      jobboardLabel={JobboardsLabel.EMPLOICOLLECTIVITES}
                      data={diffusionInformation?.emploiCollectivitesProperty}
                      isEdited={isEdited}
                      handleIsValid={handlingIsValidEmploiCollectivitesForm}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.federationHospitaliereFranceProperty &&
                Object.keys(diffusionInformation?.federationHospitaliereFranceProperty).length >
                  0 && (
                  <Col sm={12} md={6} lg={6}>
                    <FederationHospitaliereFormStep
                      handleForm={handleFhfInput}
                      jobboardLabel={JobboardsLabel.FHF}
                      data={diffusionInformation?.federationHospitaliereFranceProperty}
                      isEdited={isEdited}
                      handleIsValid={handlingIsValidFhfForm}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.poleEmploiProperty &&
                Object.keys(diffusionInformation?.poleEmploiProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <PoleEmploiFormStep
                      handleForm={handlePoleEmploiInput}
                      jobboardLabel={JobboardsLabel.POLEEMPLOI}
                      data={diffusionInformation?.poleEmploiProperty}
                      isEdited={isEdited}
                      poleEmploiPropertyRef={poleEmploiPropertyRef}
                      handleIsValid={handlingIsValidPoleEmploiForm}
                      companyDescription={diffusionInformation?.companyDescription}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.figaroProperty &&
                Object.keys(diffusionInformation?.figaroProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <FigaroFormStep
                      handleForm={handleFigaroInput}
                      jobboardLabel={JobboardsLabel.FIGARO}
                      data={diffusionInformation?.figaroProperty}
                      isEdited={isEdited}
                      handleIsValid={handlingIsValidFigaroForm}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.helloWorkProperty &&
                Object.keys(diffusionInformation?.helloWorkProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <HelloWorkFormStep
                      handleForm={handleHelloWorkInput}
                      jobboardLabel={JobboardsLabel.HELLOWORK}
                      data={diffusionInformation?.helloWorkProperty}
                      isEdited={isEdited}
                      handleIsValid={handlingIsValidHelloWorkForm}
                      urlPublished={diffusionInformation?.urlPublished}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.apecProperty &&
                Object.keys(diffusionInformation?.apecProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <ApecFormStep
                      handleForm={handleApecInput}
                      jobboardLabel={JobboardsLabel.APEC}
                      data={diffusionInformation?.apecProperty}
                      isEdited={isEdited}
                      handleIsValid={handlingIsValidApecForm}
                      urlPublished={diffusionInformation?.urlPublished}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.welcomeToTheJungleProperty &&
                Object.keys(diffusionInformation?.welcomeToTheJungleProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <WelcomeToTheJungleFormStep
                      handleWelcomeToTheJungleForm={handleWelcomeToTheJungleInput}
                      jobboardLabel={JobboardsLabel.WELCOMETOTHEJUNGLE}
                      handleIsValid={handlingIsValidWelcomeToTheJungleForm}
                      urlPublished={diffusionInformation?.urlPublished}
                      vacancyId={diffusionInformation?.welcomeToTheJungleProperty?.id}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.leBonCoinProperty &&
                Object.keys(diffusionInformation?.leBonCoinProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <LeBonCoinFromStep
                      handleLeBonCoinForm={handleLeBonCoinInput}
                      jobboardLabel={JobboardsLabel.LEBONCOIN}
                      handleIsValid={handlingIsValidLeBonCoinForm}
                      urlPublished={diffusionInformation?.urlPublished}
                      data={diffusionInformation?.leBonCoinProperty}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.linkedinProperty &&
                Object.keys(diffusionInformation?.linkedinProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <LinkedinFormStep
                      handleLinkedinForm={handleLinkedInInput}
                      jobboardLabel={JobboardsLabel.LINKEDIN}
                      handleIsValid={handlingIsValidLinkedInForm}
                      urlPublished={diffusionInformation?.urlPublished}
                      data={diffusionInformation?.linkedinProperty}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.talentComProperty &&
                Object.keys(diffusionInformation?.talentComProperty).length > 0 && (
                  <Col sm={12} md={6} lg={6}>
                    <TalentComFormStep
                      handleTalentComForm={handleTalentComInput}
                      jobboardLabel={JobboardsLabel.TALENTCOM}
                      handleIsValid={handlingIsValidTalentComForm}
                      urlPublished={diffusionInformation?.urlPublished}
                      data={diffusionInformation?.talentComProperty}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              {diffusionInformation?.emploiTerritorialProperty &&
                Object.keys(diffusionInformation?.emploiTerritorialProperty).length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <EmploiTerritorialFormStep
                    handleEmploiTerritorialForm={handleEmploiTerritorialInput}
                    jobboardLabel={JobboardsLabel.EMPLOITERRITORIAL}
                    handleIsValid={handlingIsValidEmploiTerritorialForm}
                    urlPublished={diffusionInformation?.urlPublished}
                    data={diffusionInformation?.emploiTerritorialProperty}
                    vacancyId={diffusionInformation?.emploiTerritorialProperty.id}
                  />
                </Col>
              )}
            </Row>
            <Row>
              {diffusionInformation?.meteoJobProperty &&
                Object.keys(diffusionInformation?.meteoJobProperty).length > 0 && (
                <Col sm={12} md={6} lg={6}>
                  <MeteoJobFormStep
                    handleMeteoJobForm={handleMeteoJobInput}
                    jobboardLabel={JobboardsLabel.METEOJOB}
                    handleIsValid={handlingIsValidMeteoJobForm}
                    urlPublished={diffusionInformation?.urlPublished}
                    data={diffusionInformation?.meteoJobProperty}
                  />
                </Col>
              )}
            </Row>
          </Form>
        </Col>
      </Row>
      {showAlert ? <Alert variant='{alertVariant}'>{alertMessage}</Alert> : ''}
      <ButtonGroup className='floating-group'>
        <Button variant='success' onClick={handleSubmit} disabled={isDisabled}>
          Mettre à jour la diffusion
          <i className='fas fa-check fs-1 action-icon right'></i>
        </Button>
      </ButtonGroup>
    </>
  )
}
