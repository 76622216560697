import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DictionaryModel, InitHelloWorkState } from "../../pages/referentiel/core/_models";
import agent from "../../api/agent";

const initialState: InitHelloWorkState = {
    currencies: [],
    periodicities: [],
    contracts: [],
    experiences: [],
    levels: [],
    managements: [],
    sectors: [],
    durationUnits: [],
    shifts: [],
    loading: false,
}

export const fetchHelloWorkGetCurrencies = createAsyncThunk(
    'helloWorkReferentiel/getCurrencies',
    async () => {
        const response = await agent.helloWorkReferentiel.getCurrencies();
        return response as DictionaryModel[];
    }
)

export const fetchHelloWorkGetPeriodicities = createAsyncThunk(
    'helloWorkReferentiel/getPeriodicities',
    async () => {
        const response = await agent.helloWorkReferentiel.getPeriodicities();
        return response as DictionaryModel[];
    }
)

export const fetchHelloWorkGetSectors = createAsyncThunk(
    'helloWorkReferentiel/getSectors',
    async () => {
        const response = await agent.helloWorkReferentiel.getSectors();
        return response as DictionaryModel[];
    }
)

export const fetchHelloWorkGetExperiences = createAsyncThunk(
    'helloWorkReferentiel/getExperiences',
    async () => {
        const response = await agent.helloWorkReferentiel.getExperiences();
        return response as DictionaryModel[];
    }
)

export const fetchHelloWorkGetContracts = createAsyncThunk(
    'helloWorkReferentiel/getContracts',
    async () => {
        const response = await agent.helloWorkReferentiel.getContracts();
        return response as DictionaryModel[];
    }
)

export const fetchHelloWorkGetLevels = createAsyncThunk(
    'helloWorkReferentiel/getLevels',
    async () => {
        const response = await agent.helloWorkReferentiel.getLevels();
        return response as DictionaryModel[];
    }
)

export const fetchHelloWorkGetManagements = createAsyncThunk(
    'helloWorkReferentiel/getManagements',
    async () => {
        const response = await agent.helloWorkReferentiel.getManagements();
        return response as DictionaryModel[];
    }
)

export const fetchHelloWorkGetDurationUnits = createAsyncThunk(
    'helloWorkReferentiel/getDurationUnits',
    async () => {
        const response = await agent.helloWorkReferentiel.getDurationUnits();
        return response as DictionaryModel[];
    }
)

export const fetchHelloWorkGetShifts = createAsyncThunk(
    'helloWorkReferentiel/getShifts',
    async () => {
        const response = await agent.helloWorkReferentiel.getShifts();
        return response as DictionaryModel[];
    }
)

const helloWorkReferentielSlice = createSlice({
    name: "helloWorkReferentiel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHelloWorkGetCurrencies.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchHelloWorkGetCurrencies.fulfilled, (state, action) => {
                state.loading = false;
                state.currencies = [...action.payload];
            })
            .addCase(fetchHelloWorkGetCurrencies.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchHelloWorkGetPeriodicities.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchHelloWorkGetPeriodicities.fulfilled, (state, action) => {
                state.loading = false;
                state.periodicities = [...action.payload];
            })
            .addCase(fetchHelloWorkGetPeriodicities.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchHelloWorkGetSectors.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchHelloWorkGetSectors.fulfilled, (state, action) => {
                state.loading = false;
                state.sectors = [...action.payload];
            })
            .addCase(fetchHelloWorkGetSectors.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchHelloWorkGetExperiences.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchHelloWorkGetExperiences.fulfilled, (state, action) => {
                state.loading = false;
                state.experiences = [...action.payload];
            })
            .addCase(fetchHelloWorkGetExperiences.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchHelloWorkGetContracts.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchHelloWorkGetContracts.fulfilled, (state, action) => {
                state.loading = false;
                state.contracts = [...action.payload];
            })
            .addCase(fetchHelloWorkGetContracts.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchHelloWorkGetLevels.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchHelloWorkGetLevels.fulfilled, (state, action) => {
                state.loading = false;
                state.levels = [...action.payload];
            })
            .addCase(fetchHelloWorkGetLevels.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchHelloWorkGetManagements.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchHelloWorkGetManagements.fulfilled, (state, action) => {
                state.loading = false;
                state.managements = [...action.payload];
            })
            .addCase(fetchHelloWorkGetManagements.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchHelloWorkGetDurationUnits.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchHelloWorkGetDurationUnits.fulfilled, (state, action) => {
                state.loading = false;
                state.durationUnits = [...action.payload];
            })
            .addCase(fetchHelloWorkGetDurationUnits.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchHelloWorkGetShifts.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchHelloWorkGetShifts.fulfilled, (state, action) => {
                state.loading = false;
                state.shifts = [...action.payload];
            })
            .addCase(fetchHelloWorkGetShifts.rejected, (state, action) => {
                state.loading = false;
            })
        }
});

export const {} = helloWorkReferentielSlice.actions;
export default helloWorkReferentielSlice.reducer