import {useEffect, useState} from 'react'
import {JobboardsLabel, LeBonCoinLabels, LeBonCoinProperty} from '../../../referentiel/core/_models'
import {Col, Form, Row} from 'react-bootstrap'
import {
  useAppDispatch,
  useAppSelector,
  useFormInput,
  useFormSelectInput,
} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {
  fetchLeBonCoinGetBusinessSectors,
  fetchLeBonCoinGetContactModes,
  fetchLeBonCoinGetContractTypes,
  fetchLeBonCoinGetDegrees,
  fetchLeBonCoinGetDurationTypes,
  fetchLeBonCoinGetExperiences,
  fetchLeBonCoinGetOccupations,
  fetchLeBonCoinGetPerSalaries,
  fetchLeBonCoinGetTimeTypes,
} from '../../../../store/slices/leBonCoinReferentiel.slice'
import './secondStep.scss'
import {setToFalse, setToTrue, toggleValue} from '../../../../store/slices/bool.slices'

const LeBonCoinFromStep = (props: any) => {
  const dispatch = useAppDispatch()

  const entity = props.data ? props.data : undefined

  const tenantJobboard = useAppSelector((state: RootState) => state.tenantJobboards.entity)

  const [startDate, setStartDate] = useState(entity ? entity.jobStartDate : '')
  const timeTypes = useFormSelectInput(entity ? entity.jobTimeType : '')
  const contractTypes = useFormSelectInput(entity ? entity.jobContractType : '')
  const [businessSectors, setBusinessSectors] = useState(entity ? entity.jobBusinessSector : '')
  const [occupations, setOccupations] = useState(entity ? entity.jobOccupation : '')
  const [minDurationContract, setMinDurationContract] = useState(
    entity ? entity.jobContractDurationMin : 0
  )
  const [maxDurationContract, setMaxDurationContract] = useState(
    entity ? entity.jobContractDurationMax : 0
  )
  const [durationTypeContract, setDurationTypeContract] = useState(
    entity ? entity.jobContractDurationDurationType : ''
  )
  const [jobLocationStreet, setJobLocationStreet] = useState(entity ? entity.jobLocationStreet : '')
  const jobLocationZipCode = useFormSelectInput(entity ? entity.jobLocationZipCode : '')
  const jobLocationCity = useFormSelectInput(entity ? entity.jobLocationCity : '')
  const [jobLocationCountry, setJobLocationCountry] = useState(
    entity ? entity.jobLocationCountry : ''
  )
  const [minSalary, setMinSalary] = useState(entity ? entity.jobSalaryMin : '')
  const [maxSalary, setMaxSalary] = useState(entity ? entity.jobSalaryMax : '')
  const [perSalary, setPerSalary] = useState(entity ? entity.jobSalaryPer : '')
  const [benefitsSalary, setBenefitsSalary] = useState(entity ? entity.jobSalaryBenefits : '')
  const contactMode = useFormSelectInput(entity ? entity.applicationMode : '')
  const contact = useFormInput(entity ? entity.applicationContact : '')
  const [companyLocationStreet, setCompanyLocationStreet] = useState(
    entity ? entity.companyLocationStreet : ''
  )
  const companyLocationZipCode = useFormInput(entity ? entity.companyLocationZipCode : '')
  const [companyLocationCity, setCompanyLocationCity] = useState(
    entity ? entity.companyLocationCity : ''
  )
  const [companyLocationCountry, setCompanyLocationCountry] = useState(
    entity ? entity.companyLocationCountry : ''
  )
  const [companyContactName, setCompanyContactName] = useState(
    entity ? entity.companyContactName : ''
  )
  const [companyContactPhoneNumber, setCompanyContactPhoneNumber] = useState(
    entity ? entity.companyContactPhoneNumber : ''
  )
  const [companyContactEmail, setCompanyContactEmail] = useState(
    entity ? entity.companyContactEmail : ''
  )
  const [applicantProfile, setApplicantProfile] = useState(entity ? entity.applicantProfile : '')
  const [applicantSkills, setApplicantSkills] = useState(entity ? entity.applicantSkills : '')
  const [degree, setDegree] = useState(entity ? entity.applicantDegree : '')
  const [experience, setExperience] = useState(entity ? entity.applicantExperience : '')

  const timeTypesResponseList = useAppSelector(
    (state: RootState) => state.leBonCoinReferentiel.timeTypes
  )
  const contractTypesResponseList = useAppSelector(
    (state: RootState) => state.leBonCoinReferentiel.contractTypes
  )
  const businessSectorsResponseList = useAppSelector(
    (state: RootState) => state.leBonCoinReferentiel.businessSectors
  )
  const occupationsResponseList = useAppSelector(
    (state: RootState) => state.leBonCoinReferentiel.occupations
  )
  const durationTypesResponseList = useAppSelector(
    (state: RootState) => state.leBonCoinReferentiel.durationTypes
  )
  const perSalariesResponseList = useAppSelector(
    (state: RootState) => state.leBonCoinReferentiel.perSalaries
  )
  const contactModesResponseList = useAppSelector(
    (state: RootState) => state.leBonCoinReferentiel.contactModes
  )
  const degreesResponseList = useAppSelector(
    (state: RootState) => state.leBonCoinReferentiel.degrees
  )
  const experiencesResponseList = useAppSelector(
    (state: RootState) => state.leBonCoinReferentiel.experiences
  )

  const handleStartDate = (e: any) => setStartDate(e.target.value)
  const handleBusinessSectors = (e: any) => setBusinessSectors(e.target.value)
  const handleOccupations = (e: any) => setOccupations(e.target.value)
  const handleMinDurationContract = (e: any) => setMinDurationContract(e.target.value)
  const handleMaxDurationContract = (e: any) => setMaxDurationContract(e.target.value)
  const handleDurationTypeContract = (e: any) => setDurationTypeContract(e.target.value)
  const handleJobLocationStreet = (e: any) => setJobLocationStreet(e.target.value)
  const handleJobLocationCountry = (e: any) => setJobLocationCountry(e.target.value)
  const handleMinSalary = (e: any) => setMinSalary(e.target.value)
  const handleMaxSalary = (e: any) => setMaxSalary(e.target.value)
  const handlePerSalary = (e: any) => setPerSalary(e.target.value)
  const handleBenefitsSalary = (e: any) => setBenefitsSalary(e.target.value)
  const handleCompanyLocationStreet = (e: any) => setCompanyLocationStreet(e.target.value)
  const handleCompanyLocationCity = (e: any) => setCompanyLocationCity(e.target.value)
  const handleCompanyLocationCountry = (e: any) => setCompanyLocationCountry(e.target.value)
  const handleCompanyContactName = (e: any) => setCompanyContactName(e.target.value)
  const handleCompanyContactPhoneNumber = (e: any) => setCompanyContactPhoneNumber(e.target.value)
  const handleCompanyContactEmail = (e: any) => setCompanyContactEmail(e.target.value)
  const handleApplicantProfile = (e: any) => setApplicantProfile(e.target.value)
  const handleApplicantSkills = (e: any) => setApplicantSkills(e.target.value)
  const handleDegree = (e: any) => setDegree(e.target.value)
  const handleExperience = (e: any) => setExperience(e.target.value)

  const handleLists = async () => {
    await dispatch(fetchLeBonCoinGetTimeTypes())
    await dispatch(fetchLeBonCoinGetContractTypes())
    await dispatch(fetchLeBonCoinGetBusinessSectors())
    await dispatch(fetchLeBonCoinGetOccupations())
    await dispatch(fetchLeBonCoinGetDurationTypes())
    await dispatch(fetchLeBonCoinGetPerSalaries())
    await dispatch(fetchLeBonCoinGetContactModes())
    await dispatch(fetchLeBonCoinGetDegrees())
    await dispatch(fetchLeBonCoinGetExperiences())
    await dispatch(toggleValue())
  }

  useEffect(() => {
    handleLists()
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const body = {
      morpheus_client_id: 39728, // a changer en non-dur
      partner_unique_reference: '', // idem
      job: {
        description: '', // affectée dans secondStep
        title: '', // affectée dans secondStep
        client_reference: '', // affectée dans secondStep
        business_sector: businessSectors ? businessSectors : undefined,
        contract_type: contractTypes.value ? contractTypes.value : undefined,
        occupation: occupations ? occupations : undefined,
        start_date: startDate ? startDate : undefined,
        time_type: timeTypes.value ? timeTypes.value : undefined,
        contract_duration: {
          duration_type: durationTypeContract ? durationTypeContract : 'month',
          min: minDurationContract ? +minDurationContract : undefined,
          max: maxDurationContract ? +maxDurationContract : undefined,
        },
        location: {
          zip_code: jobLocationZipCode.value,
          city: jobLocationCity.value,
          country: jobLocationCountry,
          street: jobLocationStreet,
        },
        salary: {
          benefits: benefitsSalary,
          max: +maxSalary,
          min: +minSalary,
          per: perSalary ? perSalary : 'year',
        },
      },
      application: {
        contact: contact.value,
        mode: contactMode.value,
      },
      company: {
        name: '', // affectée dans secondStep
        description: '', // affectée dans secondStep
        url: '', // affectée dans secondStep
        contact: {
          email: companyContactEmail,
          name: companyContactName,
          phone_number: companyContactPhoneNumber,
        },
        location: {
          city: companyLocationCity,
          country: companyLocationCountry,
          street: companyLocationStreet,
          zip_code: companyLocationZipCode.value,
        },
      },
      applicant: {
        degree: degree ? degree : undefined,
        experience: experience ? experience : undefined,
        profile: applicantProfile ? applicantProfile : undefined,
        skills: applicantSkills ? applicantSkills : undefined,
      },
    } as LeBonCoinProperty
    props.handleLeBonCoinForm(body)

    if (
      !timeTypes.error.isError &&
      timeTypes.value.length > 0 &&
      timeTypes.value != 0 &&
      !contractTypes.error.isError &&
      contractTypes.value.length > 0 &&
      contractTypes.value != 0 &&
      !jobLocationZipCode.error.isError &&
      jobLocationZipCode.value.length > 0 &&
      jobLocationZipCode.value != 0 &&
      !jobLocationCity.error.isError &&
      jobLocationCity.value.length > 0 &&
      jobLocationCity.value != 0 &&
      !contactMode.error.isError &&
      contactMode.value.length > 0 &&
      contactMode.value != 0 &&
      !contact.error.isError &&
      contact.value &&
      !companyLocationZipCode.error.isError &&
      companyLocationZipCode.value
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  return (
    <div
      className='card card-bordered step-card card-leboncoin'
      onBlur={handleSubmit}
      onMouseLeave={handleSubmit}
    >
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>{JobboardsLabel.LEBONCOIN}</div>
      </div>
      <div className='card-body'>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.StartDateLabel}</label>
              <Form.Control
                type='date'
                value={startDate}
                placeholder={LeBonCoinLabels.StartDateLabel}
                onChange={handleStartDate}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {LeBonCoinLabels.TimeTypeLabel} :
                </label>
                {timeTypes.error.isError && (
                  <div className='error-message'>{timeTypes.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={timeTypes.value}
                onChange={timeTypes.handleChange}
                className={`mb-10 ${
                  !timeTypes.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              >
                <option value='0'>{LeBonCoinLabels.TimeTypeSelectLabel}</option>
                {timeTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {LeBonCoinLabels.ContractTypeLabel} :
                </label>
                {contractTypes.error.isError && (
                  <div className='error-message'>{contractTypes.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={contractTypes.value}
                onChange={contractTypes.handleChange}
                className={`mb-10 ${
                  !contractTypes.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              >
                <option value='0'>{LeBonCoinLabels.ContractTypeSelectLabel}</option>
                {contractTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.BusinessSectorLabel}</label>
              <Form.Select
                as='select'
                value={businessSectors}
                onChange={handleBusinessSectors}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{LeBonCoinLabels.BusinessSectorSelectLabel}</option>
                {businessSectorsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.OccupationLabel}</label>
              <Form.Select
                as='select'
                value={occupations}
                onChange={handleOccupations}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{LeBonCoinLabels.OccupationSelectLabel}</option>
                {occupationsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.MinDurationLabel}</label>
              <Form.Control
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                id='minDurationContract'
                value={minDurationContract}
                placeholder={LeBonCoinLabels.MinDurationLabel}
                onChange={handleMinDurationContract}
                className='form-control form-control-lg form-control-solid'
                step='1'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.MaxDurationLabel}</label>
              <Form.Control
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                id='maxDurationContract'
                value={maxDurationContract}
                placeholder={LeBonCoinLabels.MaxDurationLabel}
                onChange={handleMaxDurationContract}
                className='form-control form-control-lg form-control-solid'
                step='1'
              />
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.DurationTypeLabel}</label>
              <Form.Select
                as='select'
                value={durationTypeContract}
                onChange={handleDurationTypeContract}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{LeBonCoinLabels.DurationTypeSelectLabel}</option>
                {durationTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.JobLocationStreetLabel}</label>
              <Form.Control
                value={jobLocationStreet}
                placeholder={LeBonCoinLabels.JobLocationStreetLabel}
                onChange={handleJobLocationStreet}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {LeBonCoinLabels.JobLocationZipCodeLabel}
                </label>
                {jobLocationZipCode.error.isError && (
                  <div className='error-message'>{jobLocationZipCode.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={jobLocationZipCode.value}
                placeholder={LeBonCoinLabels.JobLocationZipCodeLabel}
                onChange={jobLocationZipCode.handleChange}
                className={`mb-10 ${
                  !jobLocationZipCode.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {LeBonCoinLabels.JobLocationCityLabel}
                </label>
                {jobLocationCity.error.isError && (
                  <div className='error-message'>{jobLocationCity.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={jobLocationCity.value}
                placeholder={LeBonCoinLabels.JobLocationCityLabel}
                onChange={jobLocationCity.handleChange}
                className={`mb-10 ${
                  !jobLocationCity.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              />
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.JobLocationCountryLabel}</label>
              <Form.Control
                value={jobLocationCountry}
                placeholder={LeBonCoinLabels.JobLocationCountryLabel}
                onChange={handleJobLocationCountry}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.MinSalaryLabel}</label>
              <Form.Control
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                placeholder={LeBonCoinLabels.MinSalaryLabel}
                value={minSalary}
                onChange={handleMinSalary}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.MaxSalaryLabel}</label>
              <Form.Control
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                placeholder={LeBonCoinLabels.MaxSalaryLabel}
                value={maxSalary}
                onChange={handleMaxSalary}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.PerSalaryLabel}</label>
              <Form.Select
                as='select'
                value={perSalary}
                onChange={handlePerSalary}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{LeBonCoinLabels.PerSalarySelectLabel}</option>
                {perSalariesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.BenefitsSalaryLabel}</label>
              <Form.Control
                value={benefitsSalary}
                placeholder={LeBonCoinLabels.BenefitsSalaryLabel}
                onChange={handleBenefitsSalary}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {LeBonCoinLabels.ContactModeLabel} :
                </label>
                {contactMode.error.isError && (
                  <div className='error-message'>{contactMode.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={contactMode.value}
                onChange={contactMode.handleChange}
                className={`mb-10 ${!contactMode.error.isError ? '' : 'border-red'}`}
              >
                <option value='0'>{LeBonCoinLabels.ContactModeSelectLabel}</option>
                {contactModesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{LeBonCoinLabels.ContactLabel}</label>
                {contact.error.isError && (
                  <div className='error-message'>{contact.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={contactMode.value === 'url' ? props?.urlPublished : contact.value}
                onChange={contact.handleChange}
                className={`mb-10 ${
                  !contact.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                placeholder={
                  contactMode.value === 'email'
                    ? "Format d'adresse email"
                    : contactMode.value === 'phone'
                    ? 'Numéro de téléphone'
                    : contactMode.value === 'url'
                    ? props?.urlPublished ?? 'http://'
                    : 'candidature directe'
                }
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.CompanyLocationStreetLabel}</label>
              <Form.Control
                value={companyLocationStreet}
                placeholder={LeBonCoinLabels.CompanyLocationStreetLabel}
                onChange={handleCompanyLocationStreet}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {LeBonCoinLabels.CompanyLocationZipCodeLabel}
                </label>
                {companyLocationZipCode.error.isError && (
                  <div className='error-message'>{companyLocationZipCode.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={companyLocationZipCode.value}
                placeholder={LeBonCoinLabels.CompanyLocationZipCodeLabel}
                onChange={companyLocationZipCode.handleChange}
                className={`mb-10 ${
                  !companyLocationZipCode.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.CompanyLocationCityLabel}</label>
              <Form.Control
                value={companyLocationCity}
                placeholder={LeBonCoinLabels.CompanyLocationCityLabel}
                onChange={handleCompanyLocationCity}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.CompanyContactNameLabel}</label>
              <Form.Control
                value={companyContactName}
                placeholder={LeBonCoinLabels.CompanyContactNameLabel}
                onChange={handleCompanyContactName}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.CompanyContactPhoneNumberLabel}</label>
              <Form.Control
                value={companyContactPhoneNumber}
                placeholder={LeBonCoinLabels.CompanyContactPhoneNumberLabel}
                onChange={handleCompanyContactPhoneNumber}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.CompanyContactEmailLabel}</label>
              <Form.Control
                value={companyContactEmail}
                placeholder={LeBonCoinLabels.CompanyContactEmailLabel}
                onChange={handleCompanyContactEmail}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.CompanyLocationCountryLabel}</label>
              <Form.Control
                value={companyLocationCountry}
                placeholder={LeBonCoinLabels.CompanyLocationCountryLabel}
                onChange={handleCompanyLocationCountry}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.ApplicantProfileLabel}</label>
              <Form.Control
                value={applicantProfile}
                placeholder={LeBonCoinLabels.ApplicantProfileLabel}
                onChange={handleApplicantProfile}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.ApplicantSkillsLabel}</label>
              <Form.Control
                value={applicantSkills}
                placeholder={LeBonCoinLabels.ApplicantSkillsLabel}
                onChange={handleApplicantSkills}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.DegreeLabel}</label>
              <Form.Select
                as='select'
                value={degree}
                onChange={handleDegree}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{LeBonCoinLabels.DegreeSelectLabel}</option>
                {degreesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{LeBonCoinLabels.ExperienceLabel}</label>
              <Form.Select
                as='select'
                value={experience}
                onChange={handleExperience}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{LeBonCoinLabels.ExperienceSelectLabel}</option>
                {experiencesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default LeBonCoinFromStep
