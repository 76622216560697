import React, {FC, useContext, useEffect, useState} from 'react'
import {} from 'formik'
import {
  useAppDispatch,
  useAppSelector,
  useFormInput,
  useFormSelectInput,
} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {
  FigaroProperty,
  IndeedLabelType,
  IndeedProperty,
  SalaryTypeIndeed,
} from '../../../referentiel/core/_models'
import {Col, Form, Row} from 'react-bootstrap'
import './secondStep.scss'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import {
  MapSalaryType,
  getContractFigaro,
  getEducation,
  getEducationFigaro,
  getExperienceFigaro,
  getFonctionsFigaro,
  getIndustriesFigaro,
  getStates,
  monthToISO8601,
} from '../../../referentiel/core/_tools'
import {setToFalse, setToTrue, toggleValue} from '../../../../store/slices/bool.slices'
import {fetchTenantJobboardByLabel} from '../../../../store/slices/tenantJobboard.slices'

export const FigaroFormStep = (props: any) => {
  const entity = props.data ? props.data : undefined
  const animatedComponents = makeAnimated()
  const dispatch = useAppDispatch()

  const tenantJobboard = useAppSelector((state: RootState) => state.tenantJobboards.entity)

  const [partnerId, setPartnerId] = useState(entity ? entity.partnerId : '')
  const handlePartner = (e: any) => setPartnerId(e.target.value)

  const experienceRequirements = useFormSelectInput(entity ? entity.experienceRequirements : '')

  const educationRequirements = useFormSelectInput(entity ? entity.educationRequirements : '')

  const postalCode = useFormInput(entity ? entity.postalCode : '')

  const [countryCode, setCountryCode] = useState(entity ? entity.countryCode : '')
  const handleCountryCode = (e: any) => setCountryCode(e.target.value)

  const occupationalCategories = useFormSelectInput(entity ? entity.occupationalCategories : '')

  const industries = useFormSelectInput(entity ? entity.industries : '')

  const [hiringOrganizationLegalName, setHiringOrganizationLegalName] = useState(
    entity ? entity.hiringOrganizationLegalName : ''
  )
  const handleHiringOrganizationLegalNamer = (e: any) =>
    setHiringOrganizationLegalName(e.target.value)

  const [hiringOrganizationIdentifier, setHiringOrganizationIdentifier] = useState(
    entity ? entity.hiringOrganizationIdentifier : ''
  )
  const handleHiringOrganizationIdentifier = (e: any) =>
    setHiringOrganizationIdentifier(e.target.value)

  const employmentType = useFormSelectInput(entity ? entity.employmentType : '')

  const [employmentDuration, setEmploymentduration] = useState(
    entity ? entity.employmentDuration : ''
  )
  const handleEmploymentDuration = (e: any) => setEmploymentduration(e.target.value)

  const changeHandler = (e: any) => {
    e.preventDefault()

    setCountryCode('FR')
    const body = {
      jobboardLabel: props.jobboardLabel,
      experienceRequirements: experienceRequirements.value ? experienceRequirements?.value : '', // ref experience
      educationRequirements: educationRequirements.value ? educationRequirements?.value : '', // ref education
      postalCode: postalCode.value,
      countryCode: countryCode,
      occupationalCategories: occupationalCategories.value ? occupationalCategories?.value : '',
      industries: industries.value ? industries?.value : '',
      hiringOrganizationLegalName: hiringOrganizationLegalName,
      hiringOrganizationIdentifier: hiringOrganizationIdentifier,
      employmentType: employmentType.value ? employmentType?.value : '', // ref contrat
      employmentDuration: monthToISO8601(employmentDuration),
      figaroId: tenantJobboard?.figaroId,
      legalNameFigaro: tenantJobboard?.legalNameFigaro,
    } as FigaroProperty
    props.handleForm(body)

    if (
      !postalCode.error.isError &&
      postalCode.value &&
      !employmentType.error.isError &&
      employmentType.value.length > 0 &&
      employmentType.value != 0 &&
      !experienceRequirements.error.isError &&
      experienceRequirements.value.length > 0 &&
      experienceRequirements.value != 0 &&
      !educationRequirements.error.isError &&
      educationRequirements.value.length > 0 &&
      educationRequirements.value != 0 &&
      !occupationalCategories.error.isError &&
      occupationalCategories.value.length > 0 &&
      occupationalCategories.value != 0 &&
      !industries.error.isError &&
      industries.value.length > 0 &&
      industries.value != 0
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  const handleLists = async () => {
    await dispatch(fetchTenantJobboardByLabel(props.jobboardLabel.toLowerCase()))
    await dispatch(toggleValue())
  }
  useEffect(() => {
    handleLists()
  }, [
    getEducation(),
    getStates(),
    getIndustriesFigaro(),
    getFonctionsFigaro(),
    getContractFigaro(),
    employmentType,
  ])

  return (
    <div className='card card-bordered step-card card-figaro' onClick={changeHandler}>
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>Le Figaro</div>
      </div>
      <div className='card-body'>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Code postal</label>
                {postalCode.error.isError && (
                  <div className='error-message'>{postalCode.error.errorMsg}</div>
                )}
              </div>
              <input
                className={`${
                  !postalCode.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                name='salary'
                placeholder='Code postal'
                value={postalCode.value}
                onChange={postalCode.handleChange}
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Contrat</label>
                {employmentType.error.isError && (
                  <div className='error-message'>{employmentType.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='contractNatures'
                value={employmentType.value}
                onChange={employmentType.handleChange}
                className={`${
                  !employmentType.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>Choisissez la nature du contrat</option>
                {getContractFigaro().map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        {employmentType.value.label === 'CDD' ? (
          <>
            <div className='mb-10'>
              <Row>
                <Col>
                  <label className='form-label mb-3'>Durée du contrat</label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    name='employmentDuration'
                    placeholder='La durée du contrat en mois '
                    value={employmentDuration}
                    onChange={handleEmploymentDuration}
                  />
                </Col>
              </Row>
            </div>
          </>
        ) : (
          ''
        )}
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Niveau d'étude</label>
                {educationRequirements.error.isError && (
                  <div className='error-message'>{educationRequirements.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='contractNatures'
                value={educationRequirements.value}
                onChange={educationRequirements.handleChange}
                className={`${
                  !educationRequirements.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>Choisissez le niveau d'étude</option>
                {getEducationFigaro().map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Expérience</label>
                {experienceRequirements.error.isError && (
                  <div className='error-message'>{experienceRequirements.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='contractNatures'
                value={experienceRequirements.value}
                onChange={experienceRequirements.handleChange}
                className={`${
                  !experienceRequirements.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>Choisissez l'expérience</option>
                {getExperienceFigaro().map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Fonction</label>
                {occupationalCategories.error.isError && (
                  <div className='error-message'>{occupationalCategories.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='contractNatures'
                value={occupationalCategories.value}
                onChange={occupationalCategories.handleChange}
                className={`${
                  !occupationalCategories.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>Choisissez la fonction</option>
                {getFonctionsFigaro().map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Secteur d'activité</label>
                {industries.error.isError && (
                  <div className='error-message'>{industries.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                name='contractNatures'
                value={industries.value}
                onChange={industries.handleChange}
                className={`${
                  !industries.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>Choisissez le secteur d'activité</option>
                {getIndustriesFigaro().map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
