import {useEffect, useState} from 'react'
import {
  Alert,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Pagination,
  Toast,
  ToastContainer,
} from 'react-bootstrap'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import agent from '../../../api/agent'
import {useAppDispatch, useAppSelector} from '../../../store/hooks'
import {
  fetchJobVacanciesPaged,
  fetchJobVacancyById,
  jobVacancyDeleted,
} from '../../../store/slices/jobVacancy.slices'
import {
  InputSearch,
  JobVacancyDiffusionVM,
  JobVacancyType,
  JobboardsLabel,
  ResultDiffusionVM,
  TenantJobboardVM,
} from '../../referentiel/core/_models'
import {formatedDate, getStatus, getTooltip} from '../../referentiel/core/_tools'
import {useNavigate, useLocation} from 'react-router-dom'
import {fetchActiveJobboardsByTenant, fetchTenantJobboardByLabel} from '../../../store/slices/tenantJobboard.slices'
import { RootState } from '../../../store'

export function JobVacancyPage() {
  let dispatch = useAppDispatch()
  const navigate = useNavigate()

  const location = useLocation()

  const [page, setPage] = useState(1)

  const [notify, setNotify] = useState(false)
  const [notifyMessage, setNotifyMessage] = useState<any>(null)
  const entities = useAppSelector((state) => state.jobVacancy.entities)
  const tenantJobboard = useAppSelector((state: RootState) => state.tenantJobboards.entity);
  
  const [jobboardLabel, setJobboardLabel] = useState('');
  const [tenantJobboardApec, setTenantJobboardApec] = useState<any>({});
  const input = {page: page, pageSize: 10} as InputSearch

  const handleLists = async () => {
    await dispatch(fetchJobVacanciesPaged(input))
    await dispatch(fetchActiveJobboardsByTenant())
  }

  const tenantJobboardLabel = async (label: any) => {
    await dispatch(fetchTenantJobboardByLabel(label))
  }

  useEffect(() => {
    handleLists();
  }, [jobboardLabel])

  const handleDelete = (id: any) => {
    try {
      agent.JobVacancy.delete(id)

      dispatch(jobVacancyDeleted({id}))

      setNotifyMessage('La suppression a été faite avec succès')
      toggleNotify()
    } catch (error) {
      setNotifyMessage("Une erreur s'est produite!")
      toggleNotify()
    }
  }  

  const toggleNotify = () => setNotify(!notify)

  const handleUpdate = (id: any) => {
    try {
      const entity = entities?.items.find((result: any) => result?.id === id)
      if (entity) {
        navigate('/detail', {
          state: entity ? entity : [],
        })
      }
    } catch (error) {}
  }

  const handledPrevPage = (currentPage: number) => {
    setPage(currentPage)
    dispatch(fetchJobVacanciesPaged(input))
  }
  const handledNextPage = (currentPage: number) => {
    setPage(currentPage)
    dispatch(fetchJobVacanciesPaged(input))
  }
  const handledLastPage = (currentPage: number) => {
    setPage(currentPage)
    dispatch(fetchJobVacanciesPaged(input))
  }
  const handleDiffusion = (id: string) => {
    try {
      agent.JobVacancy.getDiffusionById(id)
        .then((result: ResultDiffusionVM) => {
          if (result && result.isAccess) {
            navigate(`/diffusion/${result?.id}`, {
              state: result ? result : [],
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {}
  }
  const refrech = async () => {
    await dispatch(fetchJobVacanciesPaged(input))
  }

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [jobVacancyIdToDelete, setJobVacancyIdToDelete] = useState(null)

  return (
    <>
      <ToastContainer className='p-3' position='bottom-end' style={{zIndex: 1}}>
        <Toast show={notify} onClose={toggleNotify} bg='Warning' delay={3000} autohide>
          <Toast.Header>
            <img src='holder.js/20x20?text=%20' className='rounded me-2' alt='' />
            <strong className='me-auto'>Notification</strong>
          </Toast.Header>
          <Toast.Body>{notifyMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className='card card-custom card-flush'>
        <div className='card-header align-items-center'>
          <h3 className='card-title'>Liste des offres diffusées sur les job boards</h3>

          <Button variant='success' size='sm' onClick={refrech}>
            Actualiser
          </Button>
        </div>
        <div className='card-body'>
          {entities && entities?.items ? (
            <table className='table table-row-dashed table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>Référence</th>
                  <th>Titre</th>
                  <th>Publiée le</th>
                  <th>Job board</th>
                  <th>Statut</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {entities?.items.map((data: any, index: any) => {
                  return (
                    <>
                      <tr key={data?.id}>
                        <td>{data.jobReference}</td>
                        <td>{data.jobTitle}</td>
                        <td>{formatedDate(data?.postingDate)}</td>
                        <td>{data.jobboardLabel ? data.jobboardLabel.join(', ') : ''}</td>
                        <td>
                          {getStatus(data.status)}{' '}
                          <i
                            className='bi bi-info-circle tooltip-scopdiffusion'
                            title={getTooltip(data.status)}
                          ></i>
                        </td>
                        <td>
                          <ButtonGroup size='sm'>
                            <DropdownButton
                              as={ButtonGroup}
                              size='sm'
                              title='Action'
                              id='bg-nested-dropdown'
                            >
                              <Dropdown.Item eventKey='1' onClick={() => handleDiffusion(data.id)}>
                                Rediffuser
                              </Dropdown.Item>
                              <Dropdown.Item eventKey='1' onClick={() => handleUpdate(data.id)}>
                                Modifier
                              </Dropdown.Item>
                              {data.status !== JobVacancyType.PUBLISHED && (
                                <Dropdown.Item
                                  hidden={data.status == JobVacancyType.DELETED}
                                  eventKey='2'
                                  onClick={() => {
                                    setJobVacancyIdToDelete(data.id)
                                    setShowDeleteConfirmation(true)
                                  }}
                                >
                                  Supprimer
                                </Dropdown.Item>
                              )}
                            </DropdownButton>
                          </ButtonGroup>
                        </td>
                        <Modal
                          key={data?.id}
                          show={showDeleteConfirmation && jobVacancyIdToDelete === data?.id}
                          onHide={() => {
                            setJobVacancyIdToDelete(null)
                            setShowDeleteConfirmation(false)
                          }}
                          backdrop='static'
                          keyboard={false}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Confirmer la suppression</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>Êtes-vous sûr de vouloir supprimer cette offre ?</Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant='secondary'
                              onClick={() => {
                                setJobVacancyIdToDelete(null)
                                setShowDeleteConfirmation(false)
                              }}
                            >
                              Annuler
                            </Button>
                            <Button
                              variant='danger'
                              onClick={() => {
                                handleDelete(data.id)
                                setJobVacancyIdToDelete(null)
                                setShowDeleteConfirmation(false)
                              }}
                            >
                              Supprimer
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <Alert variant='info'>Loading page</Alert>
          )}
          <PaginationJobVacancy
            page={entities?.page}
            pageSize={entities?.pageSize}
            totalCount={entities?.totalCount}
            handlingPrevPage={handledPrevPage}
            handlingNextPage={handledNextPage}
            handlingLastPage={handledLastPage}
          />
        </div>
      </div>
    </>
  )
}

const PaginationJobVacancy = (props: {
  page: any
  pageSize: any
  totalCount: any
  handlingPrevPage: (_: any) => void
  handlingNextPage: (_: any) => void
  handlingLastPage: (_: any) => void
}) => {
  const totalPages = Math.ceil(props.totalCount / props.pageSize)

  const [currentPage, setCurrentPage] = useState(props.page == undefined ? 1 : props.page)

  const handlePrevPage = (e: any) => {
    e.preventDefault()

    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1)
    }
    props.handlingPrevPage(currentPage)
  }

  const handleNextPage = (e: any) => {
    e.preventDefault()
    setCurrentPage(currentPage + 1)
    props.handlingNextPage(currentPage)
  }

  const handleLastPage = () => {
    if (currentPage < Math.ceil(props.totalCount / props.pageSize)) {
      setCurrentPage(Math.ceil(props.totalCount / props.pageSize))
    }
    props.handlingLastPage(currentPage)
  }

  return (
    <Pagination>
      <Pagination.First />
      <Pagination.Prev onClick={handlePrevPage} />
      {Array.from({length: totalPages}).map((_, index) => (
        <Pagination.Item key={index + 1} active={index + 1 === props.page}>
          {index + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next onClick={handleNextPage} />
      <Pagination.Last onClick={handleLastPage} />
    </Pagination>
  )
}
