import {Col, Form, Row} from 'react-bootstrap'
import {HelloWorkLabels, HelloWorkProperty, JobboardsLabel} from '../../../referentiel/core/_models'
import {useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector, useFormInput} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {
  fetchHelloWorkGetContracts,
  fetchHelloWorkGetCurrencies,
  fetchHelloWorkGetDurationUnits,
  fetchHelloWorkGetExperiences,
  fetchHelloWorkGetLevels,
  fetchHelloWorkGetManagements,
  fetchHelloWorkGetPeriodicities,
  fetchHelloWorkGetSectors,
  fetchHelloWorkGetShifts,
} from '../../../../store/slices/helloWorkReferentiel.slice'
import './inputValidation.scss'
import {setToFalse, setToTrue, toggleValue} from '../../../../store/slices/bool.slices'

const HelloWorkFormStep = (props: any) => {
  const dispatch = useAppDispatch()
  const entity = props.data ? props.data : undefined

  const [agency, setAgency] = useState(entity ? entity.agence : '')
  const city = useFormInput(entity ? entity.adressePostaleVille : '')
  const [advantages, setAdvantages] = useState(entity ? entity.salaire : '')
  const [email, setEmail] = useState(entity ? entity.email : '')

  const [addressNumber, setAddressNumber] = useState(entity ? entity.adressePostaleNumero : '')
  const [addressStreet, setAddressStreet] = useState(entity ? entity.adressePostaleVoie : '')
  const [addressPostalCode, setAddressPostalCode] = useState(
    entity ? entity.adressePostaleCodePostal : ''
  )
  const [addressDistrict, setAddressDistrict] = useState(
    entity ? entity.adressePostaleQuartier_Arrondissement : ''
  )
  const [addressDepartment, setAddressDepartment] = useState(
    entity ? entity.adressePostaleDepartement : ''
  )
  const [addressRegion, setAddressRegion] = useState(entity ? entity.adressePostaleRegion : '')
  const [addressLatitude, setAddressLatitude] = useState(
    entity ? entity.adressePostaleLatitude : ''
  )
  const [addressLongitude, setAddressLongitude] = useState(
    entity ? entity.adressePostaleLongitude : ''
  )

  const [salaryDisplay, setSalaryDisplay] = useState(entity ? entity.niveauSalaireAffichable : '')
  const [salaryCurrency, setSalaryCurrency] = useState(entity ? entity.niveauSalaireDevise : '')
  const [salaryPeriodicity, setSalaryPeriodicity] = useState(
    entity ? entity.niveauSalairePeriodicite : ''
  )
  const [salaryMin, setSalaryMin] = useState(entity ? entity.niveauSalaireSalaireMin : '')
  const [salaryMax, setSalaryMax] = useState(entity ? entity.niveauSalaireSalaireMax : '')
  const [salaryText, setSalaryText] = useState(entity ? entity.niveauSalaireSalaireTexte : '')

  const [criteriaSector, setCriteriaSector] = useState(entity ? entity.criteresCritereSecteur : '')
  const [criteriaExperience, setCriteriaExperience] = useState(
    entity ? entity.criteresCritereExperience : ''
  )
  const [criteriaContract, setCriteriaContract] = useState(
    entity ? entity.criteresCritereContrat : ''
  )
  const [criteriaLevel, setCriteriaLevel] = useState(entity ? entity.criteresCritereNiveau : '')
  const [criteriaManagement, setCriteriaManagement] = useState(
    entity ? entity.criteresCritereEncadrement : ''
  )
  const [criteriaIsFullTime, setCriteriaIsFullTime] = useState(
    entity ? entity.criteresTempsComplet : ''
  )
  const [criteriaIsRemote, setCriteriaIsRemote] = useState(entity ? entity.criteresTeletravail : '')
  const [criteriaDuration, setCriteriaDuration] = useState(
    entity ? entity.criteresDureeContrat : ''
  )

  const [emailCV, setEmailCV] = useState(entity ? entity.emailSuggestionCv : '')

  const [missionStartDate, setMissionStartDate] = useState(entity ? entity.missionStartDate : '')
  const [missionEndDate, setMissionEndDate] = useState(entity ? entity.missionEndDate : '')
  const [missionDurationValue, setMissionDurationValue] = useState(
    entity ? entity.missionDurationValue : ''
  )
  const [missionDurationUnit, setMissionDurationUnit] = useState(
    entity ? entity.missionDurationUnit : ''
  )
  const [missionShift, setMissionShift] = useState(entity ? entity.missionDurationShift : '')

  const currenciesResponseList = useAppSelector(
    (state: RootState) => state.helloWorkReferentiel.currencies
  )
  const periodicitiesResponseList = useAppSelector(
    (state: RootState) => state.helloWorkReferentiel.periodicities
  )

  const criteriaSectorsResponseList = useAppSelector(
    (state: RootState) => state.helloWorkReferentiel.sectors
  )
  const criteriaExperiencesResponseList = useAppSelector(
    (state: RootState) => state.helloWorkReferentiel.experiences
  )
  const criteriaContractsResponseList = useAppSelector(
    (state: RootState) => state.helloWorkReferentiel.contracts
  )
  const criteriaLevelsResponseList = useAppSelector(
    (state: RootState) => state.helloWorkReferentiel.levels
  )
  const criteriaManagementsResponseList = useAppSelector(
    (state: RootState) => state.helloWorkReferentiel.managements
  )

  const missionDurationUnitsResponseList = useAppSelector(
    (state: RootState) => state.helloWorkReferentiel.durationUnits
  )
  const missionShiftsResponseList = useAppSelector(
    (state: RootState) => state.helloWorkReferentiel.shifts
  )

  const handleAgency = (e: any) => setAgency(e.target.value)
  const handleAdvantages = (e: any) => setAdvantages(e.target.value)
  const handleEmail = (e: any) => setEmail(e.target.value)

  const handleAddressNumber = (e: any) => setAddressNumber(e.target.value)
  const handleAddressStreet = (e: any) => setAddressStreet(e.target.value)
  const handleAddressPostalCode = (e: any) => setAddressPostalCode(e.target.value)
  const handleAddressDistrict = (e: any) => setAddressDistrict(e.target.value)
  const handleAddressDepartment = (e: any) => setAddressDepartment(e.target.value)
  const handleAddressRegion = (e: any) => setAddressRegion(e.target.value)
  const handleAddressLatitude = (e: any) => setAddressLatitude(e.target.value)
  const handleAddressLongitude = (e: any) => setAddressLongitude(e.target.value)

  const handleSalaryDisplay = (e: any) => setSalaryDisplay(e.target.checked)
  const handleSalaryCurrency = (e: any) => setSalaryCurrency(e.target.value)
  const handleSalaryPeriodicity = (e: any) => setSalaryPeriodicity(e.target.value)
  const handleSalaryMin = (e: any) => setSalaryMin(e.target.value)
  const handleSalaryMax = (e: any) => setSalaryMax(e.target.value)
  const handleSalaryText = (e: any) => setSalaryText(e.target.value)

  const handleCriteriaSector = (e: any) => setCriteriaSector(e.target.value)
  const handleCriteriaExperience = (e: any) => setCriteriaExperience(e.target.value)
  const handleCriteriaContract = (e: any) => setCriteriaContract(e.target.value)
  const handleCriteriaLevel = (e: any) => setCriteriaLevel(e.target.value)
  const handleCriteriaManagement = (e: any) => setCriteriaManagement(e.target.value)
  const handleCriteriaIsFullTime = (e: any) => setCriteriaIsFullTime(e.target.checked)
  const handleCriteriaIsRemote = (e: any) => setCriteriaIsRemote(e.target.checked)
  const handleCriteriaDuration = (e: any) => setCriteriaDuration(e.target.value)

  const handleEmailCV = (e: any) => setEmailCV(e.target.value)

  const handleMissionStartDate = (e: any) => setMissionStartDate(e.target.value)
  const handleMissionEndDate = (e: any) => setMissionEndDate(e.target.value)
  const handleMissionDurationValue = (e: any) => setMissionDurationValue(e.target.value)
  const handleMissionDurationUnit = (e: any) => setMissionDurationUnit(e.target.value)
  const handleMissionShift = (e: any) => setMissionShift(e.target.value)

  const handleLists = async () => {
    await dispatch(fetchHelloWorkGetCurrencies())
    await dispatch(fetchHelloWorkGetPeriodicities())
    await dispatch(fetchHelloWorkGetSectors())
    await dispatch(fetchHelloWorkGetExperiences())
    await dispatch(fetchHelloWorkGetContracts())
    await dispatch(fetchHelloWorkGetLevels())
    await dispatch(fetchHelloWorkGetManagements())
    await dispatch(fetchHelloWorkGetDurationUnits())
    await dispatch(fetchHelloWorkGetShifts())
    await dispatch(toggleValue())
  }

  useEffect(() => {
    handleLists()
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const body = {
      AdressesPostales: [
        {
          CodePostal: addressPostalCode,
          Departement: addressDepartment,
          Latitude: addressLatitude,
          Longitude: addressLongitude,
          Numero: addressNumber,
          Quartier_Arrondissement: addressDistrict,
          Region: addressRegion,
          Ville: city.value,
          Voie: addressStreet,
          Pays: '', // A définir dans la second Step
        },
      ],
      Criteres: {
        CritereContrat: criteriaContract,
        CritereEncadrement: criteriaManagement,
        CritereExperience: criteriaExperience,
        CritereNiveau: criteriaLevel,
        CritereSecteur: criteriaSector,
        DureeContrat: criteriaDuration,
        Teletravail: criteriaIsRemote === true ? 'true' : 'false',
        TempsComplet: criteriaIsFullTime === true ? 'true' : 'false',
      },
      Ville: city.value,
      Agence: agency,
      Aveugle: 'false',
      Email: email,
      EmailContact: email,
      EmailSuggestionCv: emailCV,
      Mission: {
        Duration: {
          Shift: missionShift,
          Unit: missionDurationUnit,
          Value: missionDurationValue,
        },
        EndDate: missionEndDate,
        StartDate: missionStartDate,
      },
      NiveauSalaire: {
        Affichable: salaryDisplay === true ? 'true' : 'false',
        Devise: salaryCurrency,
        Periodicite: salaryPeriodicity,
        SalaireMax: salaryMax,
        SalaireMin: salaryMin,
        SalaireTexte: salaryText,
      },
      Salaire: advantages,
      Description: '', // A définir dans la second step
      Nom_Ese: '', // A définir dans la second step
      Poste: '', // A définir dans la second step
      Reference: '', // A définir dans la second step
      DateAnnonce: '', // A définir dans la second step
      DescriptionEntreprise: '', // A définir dans la second step
      DescriptionPoste: '', // A définir dans la second step
      ExternalId: '', // A définir dans la second step
      Profil: '', // A définir dans la second step
      Source: undefined, // A définir dans la second step
      jobboardLabel: props.jobboardLabel,
    } as HelloWorkProperty
    props.handleForm(body)

    if (
      !city.error.isError &&
      city.value 
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  return (
    <div
      className='card card-bordered step-card card-hello-work'
      onBlur={handleSubmit}
      onMouseLeave={handleSubmit}
    >
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>{JobboardsLabel.HELLOWORK}</div>
      </div>
      <div className='card-body'>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.AgencyLabel}</label>
              <Form.Control
                value={agency}
                placeholder={HelloWorkLabels.AgencyLabel}
                onChange={handleAgency}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>{HelloWorkLabels.City}</label>
                {city.error.isError && <div className='error-message'>{city.error.errorMsg}</div>}
              </div>
              <Form.Control
                value={city.value}
                placeholder={HelloWorkLabels.City}
                onChange={city.handleChange}
                className={
                  !city.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.Advantages}</label>
              <Form.Control
                value={advantages}
                placeholder={HelloWorkLabels.Advantages}
                onChange={handleAdvantages}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.Email}</label>
              <Form.Control
                value={email}
                placeholder={HelloWorkLabels.Email}
                onChange={handleEmail}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {HelloWorkLabels.CriteriaContract}
                </label>
              </div>
              <Form.Select
                as='select'
                value={criteriaContract}
                onChange={handleCriteriaContract}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{HelloWorkLabels.CriteriaContractSelectLabel}</option>
                {criteriaContractsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.AddressNumber}</label>
              <Form.Control
                value={addressNumber}
                placeholder={HelloWorkLabels.AddressNumber}
                onChange={handleAddressNumber}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.AddressStreet}</label>
              <Form.Control
                value={addressStreet}
                onChange={handleAddressStreet}
                placeholder={HelloWorkLabels.AddressStreet}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.AddressPostalCode}</label>
              <Form.Control
                value={addressPostalCode}
                onChange={handleAddressPostalCode}
                placeholder={HelloWorkLabels.AddressPostalCode}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.AddressDistrict}</label>
              <Form.Control
                value={addressDistrict}
                placeholder={HelloWorkLabels.AddressDistrict}
                onChange={handleAddressDistrict}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.AddressDepartment}</label>
              <Form.Control
                value={addressDepartment}
                placeholder={HelloWorkLabels.AddressDepartment}
                onChange={handleAddressDepartment}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.AddressRegion}</label>
              <Form.Control
                value={addressRegion}
                placeholder={HelloWorkLabels.AddressRegion}
                onChange={handleAddressRegion}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.AddressLatitude}</label>
              <Form.Control
                value={addressLatitude}
                placeholder={HelloWorkLabels.AddressLatitude}
                onChange={handleAddressLatitude}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.AddressLongitude}</label>
              <Form.Control
                value={addressLongitude}
                placeholder={HelloWorkLabels.AddressLongitude}
                onChange={handleAddressLongitude}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.SalaryDisplay}</label>
              <br />
              <input
                className='form-check-input'
                placeholder={HelloWorkLabels.SalaryDisplay}
                type='checkbox'
                checked={salaryDisplay}
                onClick={handleSalaryDisplay}
                id='flexRadioLg'
              />
            </Col>
          </Row>
        </div>

        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.SalaryCurrency}</label>
              <Form.Select
                as='select'
                value={salaryCurrency}
                onChange={handleSalaryCurrency}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{HelloWorkLabels.CurrencySelectLabel}</option>
                {currenciesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.SalaryPeriodicity}</label>
              <Form.Select
                as='select'
                value={salaryPeriodicity}
                onChange={handleSalaryPeriodicity}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{HelloWorkLabels.PeriodicitySelectLabel}</option>
                {periodicitiesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.SalaryMin}</label>
              <Form.Control
                value={salaryMin}
                placeholder={HelloWorkLabels.SalaryMin}
                onChange={handleSalaryMin}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.SalaryMax}</label>
              <Form.Control
                value={salaryMax}
                placeholder={HelloWorkLabels.SalaryMax}
                onChange={handleSalaryMax}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.SalaryText}</label>
              <Form.Control
                value={salaryText}
                placeholder={HelloWorkLabels.SalaryText}
                onChange={handleSalaryText}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.CriteriaLevel}</label>
              <Form.Select
                as='select'
                value={criteriaLevel}
                onChange={handleCriteriaLevel}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{HelloWorkLabels.CriteriaLevelSelectLabel}</option>
                {criteriaLevelsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.CriteriaSector}</label>
              <Form.Select
                as='select'
                value={criteriaSector}
                onChange={handleCriteriaSector}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{HelloWorkLabels.CriteriaSectorSelectLabel}</option>
                {criteriaSectorsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.CriteriaExperience}</label>
              <Form.Select
                as='select'
                value={criteriaExperience}
                onChange={handleCriteriaExperience}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{HelloWorkLabels.CriteriaExperienceSelectLabel}</option>
                {criteriaExperiencesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.CriteriaManagement}</label>
              <Form.Select
                as='select'
                value={criteriaManagement}
                onChange={handleCriteriaManagement}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{HelloWorkLabels.CriteriaManagementSelectLabel}</option>
                {criteriaManagementsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.CriteriaIsFullTime}</label>
              <br />
              <input
                className='form-check-input'
                placeholder={HelloWorkLabels.CriteriaIsFullTime}
                type='checkbox'
                checked={criteriaIsFullTime}
                onClick={handleCriteriaIsFullTime}
                id='flexRadioLg'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.CriteriaIsRemote}</label>
              <br />
              <input
                className='form-check-input'
                placeholder={HelloWorkLabels.CriteriaIsRemote}
                type='checkbox'
                checked={criteriaIsRemote}
                onClick={handleCriteriaIsRemote}
                id='flexRadioLg'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.CriteriaDuration}</label>
              <Form.Control
                value={criteriaDuration}
                placeholder={HelloWorkLabels.CriteriaDuration}
                onChange={handleCriteriaDuration}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.EmailCV}</label>
              <Form.Control
                value={emailCV}
                placeholder={HelloWorkLabels.EmailCV}
                onChange={handleEmailCV}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.MissionStartDate}</label>
              <Form.Control
                value={missionStartDate}
                placeholder={HelloWorkLabels.MissionStartDate}
                onChange={handleMissionStartDate}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.MissionEndDate}</label>
              <Form.Control
                value={missionEndDate}
                placeholder={HelloWorkLabels.MissionEndDate}
                onChange={handleMissionEndDate}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.MissionDurationValue}</label>
              <Form.Control
                value={missionDurationValue}
                placeholder={HelloWorkLabels.MissionDurationValue}
                onChange={handleMissionDurationValue}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label'>{HelloWorkLabels.MissionDurationUnit}</label>
              <Form.Select
                as='select'
                value={missionDurationUnit}
                onChange={handleMissionDurationUnit}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{HelloWorkLabels.MissionDurationUnitSelectLabel}</option>
                {missionDurationUnitsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>{HelloWorkLabels.MissionShift}</label>
              <Form.Select
                as='select'
                value={missionShift}
                onChange={handleMissionShift}
                className='form-select form-select-lg form-select-solid'
              >
                <option>{HelloWorkLabels.MissionShiftSelectLabel}</option>
                {missionShiftsResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
export default HelloWorkFormStep
