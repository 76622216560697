import moment from 'moment';
import { JobVacancyType, SalaryTypeIndeed } from './_models';

export function formatedDate(date: any): any {
    const formatedDate = moment(date).format("DD-MM-YYYY");
    return formatedDate;
  }

  export function dateFormatedDiffusion(date: any): any {
    const formatedDate = moment(date).toDate();
    return formatedDate;
  }

  export function dateFormatedISO(date: any){
  const formatedDate = moment(date).toISOString();
  return formatedDate;
  }


export function getStatus(type: any) {
  switch (type) {
    case JobVacancyType.TOCREATE:
      return 'En cours de création';
    case JobVacancyType.CREATED:
      return 'Créée';
    case JobVacancyType.UPDATE:
      return 'Mise à jour';
    case JobVacancyType.PUBLISHED:
      return 'Publiée';
    case JobVacancyType.DELETED:
      return 'Supprimée';
    case JobVacancyType.INPROGRESS:
      return 'En cours';
    default:
      return '--';
  }
}  

export function getTooltip(type: any) {
  switch (type) {
    case JobVacancyType.TOCREATE:
      return 'L\'annonce est en ébauche';
    case JobVacancyType.CREATED:
      return 'L\'annonce a été créée et en attente de validation par nos partenaires';
    case JobVacancyType.UPDATE:
      return 'L\'annonce a été modifiée';
    case JobVacancyType.PUBLISHED:
      return 'L\'annonce a été publiée';
    case JobVacancyType.DELETED:
      return 'L\'annonce a été supprimée';
    case JobVacancyType.INPROGRESS:
      return 'L\'annonce est en ébauche';
    default:
      return '--';
  }
}

export function getStates(){
  const stateData = [
    { value: '', label: 'Sélectionnez' },
    {value: 'ARA' , label: 'Auvergne-Rhône-Alpes', },
    { value: 'BFC' , label: 'Bourgogne-Franche-Comté'},
    {value: 'BRE', label: 'Brittany' },
    { value: 'CVL', label: 'Centre'},
    {value: 'COR', label: 'Corsica' },
    { value: 'GES', label: 'Grand Est' },
    { value: 'HDF', label: 'Hauts-de-France'  },
    { value: 'IDF' ,label: 'Île-de-France'},
    {  value: 'NOR', label: 'Normandy'},
    { value: 'NAQ', label: 'Nouvelle-Aquitaine'},
    {value: 'OCC', label: 'Occitanie' },
    {  value: 'PDL', label: 'Pays de la Loire'},
    { value: 'PAC' , label: 'Provence-Alpes-Côte d\'Azur'}
  ];
  return stateData;
}
  
export function getEducation(){
  const education = [
    { value: '', label: 'Sélectionnez' },
    { value: '9', label: 'BEP' },
    { value: '10', label: 'CAP' },
    { value: '11', label: 'BAC' },
    { value: '12', label: 'BAC +1' },
    { value: '13', label: 'BAC +2' },
    { value: '14', label: 'BAC +3' },
    { value: '15', label: 'BAC +4' },
    { value: '16', label: 'BAC +5' },
    { value: '17', label: 'BAC +6' },
    { value: '18', label: 'BAC +7' },
    { value: '22', label: 'Autre' },
    { value: '23', label: 'BAC +8' },
    { value: '24', label: 'BAC +9' },
    { value: '25', label: 'BAC +10' },
    { value: '26', label: 'Indifférent' }
  ];

  return education;
}

export function MapSalaryType(salaryType: any): any {
  if (salaryType == SalaryTypeIndeed.PER_MONTH) return 'par mois';
  else if(salaryType == SalaryTypeIndeed.PER_YEAR) return 'par an';
  else return 'par an'
  // switch (salaryType) {
  //     case SalaryTypeIndeed.PER_MONTH:
  //         return 'par mois';
  //     case SalaryTypeIndeed.PER_YEAR:
  //         return 'par an';
  //     default:
  //         return 'par an';
  // }
}
// Niveau d’expérience == ExperienceRequirements Figaro
export function getExperienceFigaro(){
  const experienceFigaro = [
    {value: "1", label: "Premier emploi"},
    {value: "2", label: "1 à 2 ans d'expérience"},
    {value: "3", label: "3 à 5 ans d'expérience"},
    {value: "4", label: "6 à 10 ans d'expérience"},
    {value: "5", label: "Plus de 10 ans d'expérience"}
]

return experienceFigaro;
}
// Niveau d’étude == EducationRequirements Figaro
export function getEducationFigaro(){
  const educationFigaro = [
    {value: "1", label: "Non qualifié"},
    {value: "2", label: "Brevet des collèges ou équivalent"},
    {value: "3", label: "CAP / BEP ou équivalent"},
    {value: "4", label: "Baccalauréat"},
    {value: "5", label: "Bac +2 ou équivalent"},
    {value: "6", label: "Bac +3 / Bac +4 ou équivalent"},
    {value: "7", label: "Bac +5 ou équivalent"},
    {value: "8", label: "Bac +8 ou équivalent"}
]

return educationFigaro;
}
// Contrat == EmploymentType Figaro
export function getContractFigaro(){
  const contracts = [
    {value : "1",	label :"CDI"},
    {value : "2",	label :"Statutaire"},
    {value : "3",	label :"Contractuel"},
    {value : "4",	label :"Franchisé"},
    {value : "5",	label :"Indépendant / Freelance / Autoentrepreneur"},
    {value : "6",	label :"Apprentissage/Alternance"},
    {value : "7",	label :"VIE"},
    {value : "8",	label :"Stage"},
    {value : "9",	label :"Saisonnier"},
    {value : "10", label :"Intérim"},
    {value : "11",	label :"CDD"},
    {value : "12",	label :"Avis de concours"}
   ];
   return contracts
}
// Secteurs d’activité == Industries Figaro
export function getIndustriesFigaro(){
  const secteurs = [
      {value : 100, parelentValue : null, isDisabled: true	, label :"Agriculture"},
      {value : 101, parelentValue : 100	, label :"Agriculture / Horticulture / Élevage / Chasse"},
      {value : 102, parelentValue : 100	, label :"Aquaculture / Pêche"},
      {value : 103, parelentValue : 100	, label :"Sylviculture / Exploitation forestière"},
      {value : 104, parelentValue : 100	, label :"Viticulture / Viniculture"},
      {value : 200, parelentValue : null, isDisabled: true	, label :"Associatif / Art / Culture"},
      {value : 201, parelentValue : 200	, label :"Spectacle / Culture / Loisirs"},
      {value : 202, parelentValue : 200	, label :"Association / Humanitaire"},
      {value : 300, parelentValue : null, isDisabled: true	, label :"Banque / Assurance / Finance"},
      {value : 301, parelentValue : 300	, label :"Banque / Etablissement financier / Crédit / Holding"},
      {value : 302, parelentValue : 300	, label :"Assurance / Fonds de Retraite"},
      {value : 303, parelentValue : 300	, label :"Finance de marchés / Bourse / Courtage / Gestion de fonds"},
      {value : 400, parelentValue : null, isDisabled: true	, label :"Commerce / Distribution"},
      {value : 401, parelentValue : 400	, label :"Automobile/ Motocycles"},
      {value : 402, parelentValue : 400	, label :"Commerce / Distribution / Grande Distribution Alimentaire"},
      {value : 403, parelentValue : 400	, label :"Commerce / Distribution / Grande Distribution Spécialisée"},
      {value : 404, parelentValue : 400	, label :"Centrale d'achats / Commerce de gros / Négoce"},
      {value : 405, parelentValue : 400	, label :"Marchés"},
      {value : 406, parelentValue : 400	, label :"E-Commerce / V.P.C."},
      {value : 500, parelentValue : null, isDisabled: true	, label :"Service aux entreprises"},
      {value : 501, parelentValue : 500	, label :"Cabinet Comptable / Audit"},
      {value : 502, parelentValue : 500	, label :"Emploi / Recrutement"},
      {value : 503, parelentValue : 500	, label :"Conseil stratégique aux entreprises"},
      {value : 504, parelentValue : 500	, label :"Conseil juridique / Cabinet d'avocats / Notaire"},
      {value : 505, parelentValue : 500	, label :"Institut d'études / Centre d'appels"},
      {value : 506, parelentValue : 500	, label :"Publicité / Marketing Direct / Communication"},
      {value : 507, parelentValue : 500	, label :"Salons / Congrès / Manifestations"},
      {value : 508, parelentValue : 500	, label :"Maintenance/ Installation industrielle"},
      {value : 509, parelentValue : 500	, label :"Nettoyage industriel / Aménagement paysager"},
      {value : 510, parelentValue : 500	, label :"Recherche & Développement (R&D)"},
      {value : 511, parelentValue : 500	, label :"Autres services aux entreprises"},
      {value : 600, parelentValue : null, isDisabled: true	, label :"Contruction / BTP"},
      {value : 601, parelentValue : 600	, label :"Architecture / Ingénierie / Bureau d'études"},
      {value : 602, parelentValue : 600	, label :"Génie Civil / Bâtiment et Travaux Publics (BTP)"},
      {value : 603, parelentValue : 600	, label :"Gros œuvre / Construction"},
      {value : 604, parelentValue : 600	, label :"Forage / Terrassement / Démolition / Second œuvre et finitions"},
      {value : 700, parelentValue : null, isDisabled: true	, label :"Environnement / Énergies"},
      {value : 701, parelentValue : 700	, label :"Electricité / Gaz / Chauffage"},
      {value : 702, parelentValue : 700	, label :"Pétrole"},
      {value : 703, parelentValue : 700	, label :"Déchets ménagers et industriels / Dépollution"},
      {value : 704, parelentValue : 700	, label :"Traitement de l'eau"},
      {value : 800, parelentValue : null, isDisabled: true	, label :"Immobilier"},
      {value : 801, parelentValue : 800	, label :"Société immobilière / Gestion locative / Syndic"},
      {value : 802, parelentValue : 800	, label :"Promotion immobilière"},
      {value : 900, parelentValue : null, isDisabled: true	, label :"Industries"},
      {value : 901, parelentValue : 900	, label :"Agroalimentaire / Boissons"},
      {value : 902, parelentValue : 900	, label :"Aéronautique / Aérospatiale"},
      {value : 903, parelentValue : 900	, label :"Industrie Automobile / Équipementiers"},
      {value : 904, parelentValue : 900	, label :"Construction navale"},
      {value : 905, parelentValue : 900	, label :"Ferroviaire"},
      {value : 906, parelentValue : 900	, label :"Matériel de transport / Moto / Cycles"},
      {value : 907, parelentValue : 900	, label :"Extraction / Mines"},
      {value : 908, parelentValue : 900	, label :"Cokéfaction / Raffinage / Pétrochimie"},
      {value : 909, parelentValue : 900	, label :"Chimie / Parachimie"},
      {value : 910, parelentValue : 900	, label :"Industrie pharmaceutique / Biotechnologie"},
      {value : 911, parelentValue : 900	, label :"Ameublement / Industrie du Bois"},
      {value : 912, parelentValue : 900	, label :"Métallurgie"},
      {value : 913, parelentValue : 900	, label :"Produits métalliques / Armement"},
      {value : 914, parelentValue : 900	, label :"Machines et biens d'équipement industriel / agricole"},
      {value : 915, parelentValue : 900	, label :"Instrumentation scientifique et médicale"},
      {value : 916, parelentValue : 900	, label :"Produits électroniques / informatique / optique / horlogerie"},
      {value : 917, parelentValue : 900	, label :"Joaillerie / Bijouterie"},
      {value : 918, parelentValue : 900	, label :"Instruments de musique"},
      {value : 919, parelentValue : 900	, label :"Produits de Sport / Loisirs / Jouets / Jeux"},
      {value : 920, parelentValue : 900	, label :"Industrie de l'édition / Imprimerie"},
      {value : 921, parelentValue : 900	, label :"Tabac"},
      {value : 922, parelentValue : 900	, label :"Textile / Cuir / Prêt à porter"},
      {value : 923, parelentValue : 900	, label :"Papier / Carton"},
      {value : 924, parelentValue : 900	, label :"Plastique / Plasturgie / Caoutchouc / Composite"},
      {value : 925, parelentValue : 900	, label :"Verre / Céramique"},
      {value : 926, parelentValue : 900	, label :"Autres produits manufacturés"},
      {value : 1000, parelentValue :null, isDisabled: true, label:	"Informatique / Télécom / Internet"},
      {value : 1001, parelentValue : 1000, label :"Télécommunications / Infogérance / Hébergeurs / FAI"},
      {value : 1002, parelentValue : 1000, label :"Édition informatique / Logiciels"},
      {value : 1003, parelentValue : 1000, label :"Internet / Multimedia"},
      {value : 1004, parelentValue : 1000, label :"Services et Ingénieries informatiques (SSII)"},
      {value : 1100, parelentValue : null, isDisabled: true, label :"Presse / Média"},
      {value : 1101, parelentValue : 1100, label :"Presse / Edition"},
      {value : 1102, parelentValue : 1100, label :"Photo"},
      {value : 1103, parelentValue : 1100, label :"Production cinéma / audiovisuelle"},
      {value : 1104, parelentValue : 1100, label :"Diffusion radio / télévision"},
      {value : 1200, parelentValue : null, isDisabled: true, label :"Santé / Protection sociale"},
      {value : 1201, parelentValue : 1200, label :"Activités de santé / soins"},
      {value : 1202, parelentValue : 1200, label :"Établissements de santé / Cliniques / Hébergement social"},
      {value : 1203, parelentValue : 1200, label :"Services santé / Action sociale"},
      {value : 1204, parelentValue : 1200, label :"Vétérinaire"},
      {value : 1300, parelentValue : null, isDisabled: true, label :"Services"},
      {value : 1301, parelentValue : 1300, label :"Location / Bail"},
      {value : 1302, parelentValue : 1300, label :"Expertise et intervention technique"},
      {value : 1303, parelentValue : 1300, label :"Réparation de biens domestiques"},
      {value : 1304, parelentValue : 1300, label :"Services à la personne"},
      {value : 1305, parelentValue : 1300, label :"Sécurité"},
      {value : 1400, parelentValue : null, isDisabled: true, label :"Services publics / Administrations"},
      {value : 1401, parelentValue : 1400, label :"Administrations / Institutions publiques"},
      {value : 1402, parelentValue : 1400, label :"Enseignement / Recherche / Formation"},
      {value : 1403, parelentValue : 1400, label :"Culture / Musées / Bibliothèques / Réserves naturelles"},
      {value : 1404, parelentValue : 1400, label :"Ambassades / Consulats / Activités extra-territoriales"},
      {value : 1500, parelentValue : null, isDisabled: true, label :"Tourisme / Hotelerie / Restauration / Loisirs"},
      {value : 1501, parelentValue : 1500, label :"Hôtellerie / Hébergement"},
      {value : 1502, parelentValue : 1500, label :"Restauration"},
      {value : 1503, parelentValue : 1500, label :"Tourisme / Voyages"},
      {value : 1504, parelentValue : 1500, label :"Loisirs / Activités sportives et récréatives"},
      {value : 1505, parelentValue : 1500, label :"Jeux de hasard / Jeux d'argent"},
      {value : 1600, parelentValue : null, isDisabled: true, label :"Transport / Stockage logistique"},
      {value : 1601, parelentValue : 1600, label :"Transport routier / ferroviaire"},
      {value : 1602, parelentValue : 1600, label :"Transport maritime / fluvial"},
      {value : 1603, parelentValue : 1600, label :"Transport aérien"},
      {value : 1604, parelentValue : 1600, label :"Entreposage / Stockage / Manutention"},
      {value : 1605, parelentValue : 1600, label :"Poste et courrier / Livraison"}  
   ];
   return secteurs
}
// Fonctions == OccupationnalCategories Figaro
export function getFonctionsFigaro(){
  const fonctions = [
    {value : "K2111", label: "Formation professionnelle"},
{value : "K2112", label: "Orientation scolaire et professionnelle"},
{value : "K2201", label: "Blanchisserie industrielle"},
{value : "K2202", label: "Lavage de vitres"},
{value : "K2203", label: "Management et inspection en propreté de locaux"},
{value : "K2204", label: "Nettoyage de locaux"},
{value : "K2301", label: "Distribution et assainissement d'eau"},
{value : "K2302", label: "Management et inspection en environnement urbain"},
{value : "K2303", label: "Nettoyage des espaces urbains"},
{value : "K2304", label: "Revalorisation de produits industriels"},
{value : "K2305", label: "Salubrité et traitement de nuisibles"},
{value : "K2306", label: "Supervision d'exploitation éco-industrielle"},
{value : "K2401", label: "Recherche en sciences de l'homme et de la société"},
{value : "K2402", label: "Recherche en sciences de l'univers, de la matière et du vivant"},
{value : "K2501", label: "Gardiennage de locaux"},
{value : "K2502", label: "Management de sécurité privée"},
{value : "K2503", label: "Sécurité et surveillance privées"},
{value : "K2601", label: "Conduite d'opérations funéraires"},
{value : "K2602", label: "Conseil en services funéraires"},
{value : "K2603", label: "Thanatopraxie"},
{value : "L1101", label: "Animation musicale et scénique"},
{value : "L1102", label: "Mannequinat et pose artistique"},
{value : "L1103", label: "Présentation de spectacles ou d'émissions"},
{value : "L1201", label: "Danse"},
{value : "L1202", label: "Musique et chant"},
{value : "L1203", label: "Art dramatique"},
{value : "L1204", label: "Arts du cirque et arts visuels"},
{value : "L1301", label: "Mise en scène de spectacles vivants"},
{value : "L1302", label: "Production et administration spectacle, cinéma et audiovisuel"},
{value : "L1303", label: "Promotion d'artistes et de spectacles"},
{value : "L1304", label: "Réalisation cinématographique et audiovisuelle"},
{value : "L1401", label: "Sportif professionnel"},
{value : "L1501", label: "Coiffure et maquillage spectacle"},
{value : "L1502", label: "Costume et habillage spectacle"},
{value : "L1503", label: "Décor et accessoires spectacle"},
{value : "L1504", label: "Éclairage spectacle"},
{value : "L1505", label: "Image cinématographique et télévisuelle"},
{value : "L1506", label: "Machinerie spectacle"},
{value : "L1507", label: "Montage audiovisuel et post-production"},
{value : "L1508", label: "Prise de son et sonorisation"},
{value : "L1509", label: "Régie générale"},
{value : "L1510", label: "Films d'animation et effets spéciaux"},
{value : "M1101", label: "Achats"},
{value : "M1102", label: "Direction des achats"},
{value : "M1201", label: "Analyse et ingénierie financière"},
{value : "M1202", label: "Audit et contrôle comptables et financiers"},
{value : "M1203", label: "Comptabilité"},
{value : "M1204", label: "Contrôle de gestion"},
{value : "M1205", label: "Direction administrative et financière"},
{value : "M1206", label: "Management de groupe ou de service comptable"},
{value : "M1207", label: "Trésorerie et financement"},
{value : "M1301", label: "Direction de grande entreprise ou d'établissement public"},
{value : "M1302", label: "Direction de petite ou moyenne entreprise"},
{value : "M1401", label: "Conduite d'enquêtes"},
{value : "M1402", label: "Conseil en organisation et management d'entreprise"},
{value : "M1403", label: "Études et prospectives socio-économiques"},
{value : "M1404", label: "Management et gestion d'enquêtes"},
{value : "M1501", label: "Assistanat en ressources humaines"},
{value : "M1502", label: "Développement des ressources humaines"},
{value : "M1503", label: "Management des ressources humaines"},
{value : "M1601", label: "Accueil et renseignements"},
{value : "M1602", label: "Opérations administratives"},
{value : "M1603", label: "Distribution de documents"},
{value : "M1604", label: "Assistanat de direction"},
{value : "M1605", label: "Assistanat technique et administratif"},
{value : "M1606", label: "Saisie de données"},
{value : "M1607", label: "Secrétariat"},
{value : "M1608", label: "Secrétariat comptable"},
{value : "M1609", label: "Secrétariat et assistanat médical ou médico-social"},
{value : "M1701", label: "Administration des ventes"},
{value : "M1702", label: "Analyse de tendance"},
{value : "M1703", label: "Management et gestion de produit"},
{value : "M1704", label: "Management relation clientèle"},
{value : "M1705", label: "Marketing"},
{value : "M1706", label: "Promotion des ventes"},
{value : "M1707", label: "Stratégie commerciale"},
{value : "M1801", label: "Administration de systèmes d'information"},
{value : "M1802", label: "Expertise et support en systèmes d'information"},
{value : "M1803", label: "Direction des systèmes d'information"},
{value : "M1804", label: "Études et développement de réseaux de télécoms"},
{value : "M1805", label: "Études et développement informatique"},
{value : "M1806", label: "Conseil et maîtrise d'ouvrage en systèmes d'information"},
{value : "M1807", label: "Exploitation de systèmes de communication et de commandement"},
{value : "M1808", label: "Information géographique"},
{value : "M1809", label: "Information météorologique"},
{value : "M1810", label: "Production et exploitation de systèmes d'information"},
{value : "N1101", label: "Conduite d'engins de déplacement des charges"},
{value : "N1102", label: "Déménagement"},
{value : "N1103", label: "Magasinage et préparation de commandes"},
{value : "N1104", label: "Manoeuvre et conduite d'engins lourds de manutention"},
{value : "N1105", label: "Manutention manuelle de charges"},
{value : "N1201", label: "Affrètement transport"},
{value : "N1202", label: "Gestion des opérations de circulation internationale des marchandises"},
{value : "N1301", label: "Conception et organisation de la chaîne logistique"},
{value : "N1302", label: "Direction de site logistique"},
{value : "N1303", label: "Intervention technique d'exploitation logistique"},
{value : "N2101", label: "Navigation commerciale aérienne"},
{value : "N2102", label: "Pilotage et navigation technique aérienne"},
{value : "N2201", label: "Personnel d'escale aéroportuaire"},
{value : "N2202", label: "Contrôle de la navigation aérienne"},
{value : "N2203", label: "Exploitation des pistes aéroportuaires"},
{value : "N2204", label: "Préparation des vols"},
{value : "N2205", label: "Direction d'escale et exploitation aéroportuaire"},
{value : "N3101", label: "Encadrement de la navigation maritime"},
{value : "N3102", label: "Équipage de la navigation maritime"},
{value : "N3103", label: "Navigation fluviale"},
{value : "N3201", label: "Exploitation des opérations portuaires et du transport maritime"},
{value : "N3202", label: "Exploitation du transport fluvial"},
{value : "N3203", label: "Manutention portuaire"},
{value : "N4101", label: "Conduite de transport de marchandises sur longue distance"},
{value : "N4102", label: "Conduite de transport de particuliers"},
{value : "N4103", label: "Conduite de transport en commun sur route"},
{value : "N4104", label: "Courses et livraisons express"},
{value : "N4105", label: "Conduite et livraison par tournées sur courte distance"},
{value : "N4201", label: "Direction d'exploitation des transports routiers de marchandises"},
{value : "N4202", label: "Direction d'exploitation des transports routiers de personnes"},
{value : "N4203", label: "Intervention technique d'exploitation des transports routiers de marchandises"},
{value : "N4204", label: "Intervention technique d'exploitation des transports routiers de personnes"},
{value : "N4301", label: "Conduite sur rails"},
{value : "N4302", label: "Contrôle des transports en commun"},
{value : "N4401", label: "Circulation du réseau ferré"},
{value : "N4402", label: "Exploitation et manoeuvre des remontées mécaniques"},
{value : "N4403", label: "Manoeuvre du réseau ferré"},
{value : "A1101", label: "Conduite d'engins agricoles et forestiers"},
{value : "A1201", label: "Bûcheronnage et élagage"},
{value : "A1202", label: "Entretien des espaces naturels"},
{value : "A1203", label: "Aménagement et entretien des espaces verts"},
{value : "A1204", label: "Protection du patrimoine naturel"},
{value : "A1205", label: "Sylviculture"},
{value : "A1301", label: "Conseil et assistance technique en agriculture et environnement naturel"},
{value : "A1302", label: "Contrôle et diagnostic technique en agriculture"},
{value : "A1303", label: "Ingénierie en agriculture et environnement naturel"},
{value : "A1401", label: "Aide agricole de production fruitière ou viticole"},
{value : "A1402", label: "Aide agricole de production légumière ou végétale"},
{value : "A1403", label: "Aide d'élevage agricole et aquacole"},
{value : "A1404", label: "Aquaculture"},
{value : "A1405", label: "Arboriculture et viticulture"},
{value : "A1406", label: "Encadrement équipage de la pêche"},
{value : "A1407", label: "Élevage bovin ou équin"},
{value : "A1408", label: "Élevage d'animaux sauvages ou de compagnie"},
{value : "A1409", label: "Élevage de lapins et volailles"},
{value : "A1410", label: "Élevage ovin ou caprin"},
{value : "A1411", label: "Élevage porcin"},
{value : "A1412", label: "Fabrication et affinage de fromages"},
{value : "A1413", label: "Fermentation de boissons alcoolisées"},
{value : "A1414", label: "Horticulture et maraîchage"},
{value : "A1415", label: "Équipage de la pêche"},
{value : "A1416", label: "Polyculture, élevage"},
{value : "A1417", label: "Saliculture"},
{value : "A1501", label: "Aide aux soins animaux"},
{value : "A1502", label: "Podologie animale"},
{value : "A1503", label: "Toilettage des animaux"},
{value : "A1504", label: "Santé animale"},
{value : "B1101", label: "Création en arts plastiques"},
{value : "B1201", label: "Réalisation d'objets décoratifs et utilitaires en céramique et matériaux de synthèse"},
{value : "B1301", label: "Décoration d'espaces de vente et d'exposition"},
{value : "B1302", label: "Décoration d'objets d'art et artisanaux"},
{value : "B1303", label: "Gravure - ciselure"},
{value : "B1401", label: "Réalisation d'objets en lianes, fibres et brins végétaux"},
{value : "B1402", label: "Reliure et restauration de livres et archives"},
{value : "B1501", label: "Fabrication et réparation d'instruments de musique"},
{value : "B1601", label: "Métallerie d'art"},
{value : "B1602", label: "Réalisation d'objets artistiques et fonctionnels en verre"},
{value : "B1603", label: "Réalisation d'ouvrages en bijouterie, joaillerie et orfèvrerie"},
{value : "B1604", label: "Réparation - montage en systèmes horlogers"},
{value : "B1701", label: "Conservation et reconstitution d'espèces animales"},
{value : "B1801", label: "Réalisation d'articles de chapellerie"},
{value : "B1802", label: "Réalisation d'articles en cuir et matériaux souples (hors vêtement)"},
{value : "B1803", label: "Réalisation de vêtements sur mesure ou en petite série"},
{value : "B1804", label: "Réalisation d'ouvrages d'art en fils"},
{value : "B1805", label: "Stylisme"},
{value : "B1806", label: "Tapisserie - décoration en ameublement"},
{value : "C1101", label: "Conception - développement produits d'assurances"},
{value : "C1102", label: "Conseil clientèle en assurances"},
{value : "C1103", label: "Courtage en assurances"},
{value : "C1104", label: "Direction d'exploitation en assurances"},
{value : "C1105", label: "Études actuarielles en assurances"},
{value : "C1106", label: "Expertise risques en assurances"},
{value : "C1107", label: "Indemnisations en assurances"},
{value : "C1108", label: "Management de groupe et de service en assurances"},
{value : "C1109", label: "Rédaction et gestion en assurances"},
{value : "C1110", label: "Souscription d'assurances"},
{value : "C1201", label: "Accueil et services bancaires"},
{value : "C1202", label: "Analyse de crédits et risques bancaires"},
{value : "C1203", label: "Relation clients banque/finance"},
{value : "C1204", label: "Conception et expertise produits bancaires et financiers"},
{value : "C1205", label: "Conseil en gestion de patrimoine financier"},
{value : "C1206", label: "Gestion de clientèle bancaire"},
{value : "C1207", label: "Management en exploitation bancaire"},
{value : "C1301", label: "Front office marchés financiers"},
{value : "C1302", label: "Gestion back et middle-office marchés financiers"},
{value : "C1303", label: "Gestion de portefeuilles sur les marchés financiers"},
{value : "C1401", label: "Gestion en banque et assurance"},
{value : "C1501", label: "Gérance immobilière"},
{value : "C1502", label: "Gestion locative immobilière"},
{value : "C1503", label: "Management de projet immobilier"},
{value : "C1504", label: "Transaction immobilière"},
{value : "D1101", label: "Boucherie"},
{value : "D1102", label: "Boulangerie - viennoiserie"},
{value : "D1103", label: "Charcuterie - traiteur"},
{value : "D1104", label: "Pâtisserie, confiserie, chocolaterie et glacerie"},
{value : "D1105", label: "Poissonnerie"},
{value : "D1106", label: "Vente en alimentation"},
{value : "D1107", label: "Vente en gros de produits frais"},
{value : "D1201", label: "Achat vente d'objets d'art, anciens ou d'occasion"},
{value : "D1202", label: "Coiffure"},
{value : "D1203", label: "Hydrothérapie"},
{value : "D1204", label: "Location de véhicules ou de matériel de loisirs"},
{value : "D1205", label: "Nettoyage d'articles textiles ou cuirs"},
{value : "D1206", label: "Réparation d'articles en cuir et matériaux souples"},
{value : "D1207", label: "Retouches en habillement"},
{value : "D1208", label: "Soins esthétiques et corporels"},
{value : "D1209", label: "Vente de végétaux"},
{value : "D1210", label: "Vente en animalerie"},
{value : "D1211", label: "Vente en articles de sport et loisirs"},
{value : "D1212", label: "Vente en décoration et équipement du foyer"},
{value : "D1213", label: "Vente en gros de matériel et équipement"},
{value : "D1214", label: "Vente en habillement et accessoires de la personne"},
{value : "D1301", label: "Management de magasin de détail"},
{value : "D1401", label: "Assistanat commercial"},
{value : "D1402", label: "Relation commerciale grands comptes et entreprises"},
{value : "D1403", label: "Relation commerciale auprès de particuliers"},
{value : "D1404", label: "Relation commerciale en vente de véhicules"},
{value : "D1405", label: "Conseil en information médicale"},
{value : "D1406", label: "Management en force de vente"},
{value : "D1407", label: "Relation technico-commerciale"},
{value : "D1408", label: "Téléconseil et télévente"},
{value : "D1501", label: "Animation de vente"},
{value : "D1502", label: "Management/gestion de rayon produits alimentaires"},
{value : "D1503", label: "Management/gestion de rayon produits non alimentaires"},
{value : "D1504", label: "Direction de magasin de grande distribution"},
{value : "D1505", label: "Personnel de caisse"},
{value : "D1506", label: "Marchandisage"},
{value : "D1507", label: "Mise en rayon libre-service"},
{value : "D1508", label: "Encadrement du personnel de caisses"},
{value : "D1509", label: "Management de département en grande distribution"},
{value : "E1101", label: "Animation de site multimédia"},
{value : "E1102", label: "Écriture d'ouvrages, de livres"},
{value : "E1103", label: "Communication"},
{value : "E1104", label: "Conception de contenus multimédias"},
{value : "E1105", label: "Coordination d'édition"},
{value : "E1106", label: "Journalisme et information média"},
{value : "E1107", label: "Organisation d'évènementiel"},
{value : "E1108", label: "Traduction, interprétariat"},
{value : "E1201", label: "Photographie"},
{value : "E1202", label: "Production en laboratoire cinématographique"},
{value : "E1203", label: "Production en laboratoire photographique"},
{value : "E1204", label: "Projection cinéma"},
{value : "E1205", label: "Réalisation de contenus multimédias"},
{value : "E1301", label: "Conduite de machines d'impression"},
{value : "E1302", label: "Conduite de machines de façonnage routage"},
{value : "E1303", label: "Encadrement des industries graphiques"},
{value : "E1304", label: "Façonnage et routage"},
{value : "E1305", label: "Préparation et correction en édition et presse"},
{value : "E1306", label: "Prépresse"},
{value : "E1307", label: "Reprographie"},
{value : "E1308", label: "Intervention technique en industrie graphique"},
{value : "E1401", label: "Développement et promotion publicitaire"},
{value : "E1402", label: "Élaboration de plan média"},
{value : "F1101", label: "Architecture du BTP et du paysage"},
{value : "F1102", label: "Conception - aménagement d'espaces intérieurs"},
{value : "F1103", label: "Contrôle et diagnostic technique du bâtiment"},
{value : "F1104", label: "Dessin BTP et paysage"},
{value : "F1105", label: "Études géologiques"},
{value : "F1106", label: "Ingénierie et études du BTP"},
{value : "F1107", label: "Mesures topographiques"},
{value : "F1108", label: "Métré de la construction"},
{value : "F1201", label: "Conduite de travaux du BTP et de travaux paysagers"},
{value : "F1202", label: "Direction de chantier du BTP"},
{value : "F1203", label: "Direction et ingénierie d'exploitation de gisements et de carrières"},
{value : "F1204", label: "Qualité Sécurité Environnement et protection santé du BTP"},
{value : "F1301", label: "Conduite de grue"},
{value : "F1302", label: "Conduite d'engins de terrassement et de carrière"},
{value : "F1401", label: "Extraction liquide et gazeuse"},
{value : "F1402", label: "Extraction solide"},
{value : "F1501", label: "Montage de structures et de charpentes bois"},
{value : "F1502", label: "Montage de structures métalliques"},
{value : "F1503", label: "Réalisation - installation d'ossatures bois"},
{value : "F1601", label: "Application et décoration en plâtre, stuc et staff"},
{value : "F1602", label: "Électricité bâtiment"},
{value : "F1603", label: "Installation d'équipements sanitaires et thermiques"},
{value : "F1604", label: "Montage d'agencements"},
{value : "F1605", label: "Montage de réseaux électriques et télécoms"},
{value : "F1606", label: "Peinture en bâtiment"},
{value : "F1607", label: "Pose de fermetures menuisées"},
{value : "F1608", label: "Pose de revêtements rigides"},
{value : "F1609", label: "Pose de revêtements souples"},
{value : "F1610", label: "Pose et restauration de couvertures"},
{value : "F1611", label: "Réalisation et restauration de façades"},
{value : "F1612", label: "Taille et décoration de pierres"},
{value : "F1613", label: "Travaux d'étanchéité et d'isolation"},
{value : "F1701", label: "Construction en béton"},
{value : "F1702", label: "Construction de routes et voies"},
{value : "F1703", label: "Maçonnerie"},
{value : "F1704", label: "Préparation du gros oeuvre et des travaux publics"},
{value : "F1705", label: "Pose de canalisations"},
{value : "F1706", label: "Préfabrication en béton industriel"},
{value : "G1101", label: "Accueil touristique"},
{value : "G1102", label: "Promotion du tourisme local"},
{value : "G1201", label: "Accompagnement de voyages, d'activités culturelles ou sportives"},
{value : "G1202", label: "Animation d'activités culturelles ou ludiques"},
{value : "G1203", label: "Animation de loisirs auprès d'enfants ou d'adolescents"},
{value : "G1204", label: "Éducation en activités sportives"},
{value : "G1205", label: "Personnel d'attractions ou de structures de loisirs"},
{value : "G1206", label: "Personnel technique des jeux"},
{value : "G1301", label: "Conception de produits touristiques"},
{value : "G1302", label: "Optimisation de produits touristiques"},
{value : "G1303", label: "Vente de voyages"},
{value : "G1401", label: "Assistance de direction d'hôtel-restaurant"},
{value : "G1402", label: "Management d'hôtel-restaurant"},
{value : "G1403", label: "Gestion de structure de loisirs ou d'hébergement touristique"},
{value : "G1404", label: "Management d'établissement de restauration collective"},
{value : "G1501", label: "Personnel d'étage"},
{value : "G1502", label: "Personnel polyvalent d'hôtellerie"},
{value : "G1503", label: "Management du personnel d'étage"},
{value : "G1601", label: "Management du personnel de cuisine"},
{value : "G1602", label: "Personnel de cuisine"},
{value : "G1603", label: "Personnel polyvalent en restauration"},
{value : "G1604", label: "Fabrication de crêpes ou pizzas"},
{value : "G1605", label: "Plonge en restauration"},
{value : "G1701", label: "Conciergerie en hôtellerie"},
{value : "G1702", label: "Personnel du hall"},
{value : "G1703", label: "Réception en hôtellerie"},
{value : "G1801", label: "Café, bar brasserie"},
{value : "G1802", label: "Management du service en restauration"},
{value : "G1803", label: "Service en restauration"},
{value : "G1804", label: "Sommellerie"},
{value : "H1101", label: "Assistance et support technique client"},
{value : "H1102", label: "Management et ingénierie d'affaires"},
{value : "H1201", label: "Expertise technique couleur en industrie"},
{value : "H1202", label: "Conception et dessin de produits électriques et électroniques"},
{value : "H1203", label: "Conception et dessin produits mécaniques"},
{value : "H1204", label: "Design industriel"},
{value : "H1205", label: "Études - modèles en industrie des matériaux souples"},
{value : "H1206", label: "Management et ingénierie études, recherche et développement industriel"},
{value : "H1207", label: "Rédaction technique"},
{value : "H1208", label: "Intervention technique en études et conception en automatisme"},
{value : "H1209", label: "Intervention technique en études et développement électronique"},
{value : "H1210", label: "Intervention technique en études, recherche et développement"},
{value : "H1301", label: "Inspection de conformité"},
{value : "H1302", label: "Management et ingénierie Hygiène Sécurité Environnement -HSE- industriels"},
{value : "H1303", label: "Intervention technique en Hygiène Sécurité Environnement -HSE- industriel"},
{value : "H1401", label: "Management et ingénierie gestion industrielle et logistique"},
{value : "H1402", label: "Management et ingénierie méthodes et industrialisation"},
{value : "H1403", label: "Intervention technique en gestion industrielle et logistique"},
{value : "H1404", label: "Intervention technique en méthodes et industrialisation"},
{value : "H1501", label: "Direction de laboratoire d'analyse industrielle"},
{value : "H1502", label: "Management et ingénierie qualité industrielle"},
{value : "H1503", label: "Intervention technique en laboratoire d'analyse industrielle"},
{value : "H1504", label: "Intervention technique en contrôle essai qualité en électricité et électronique"},
{value : "H1505", label: "Intervention technique en formulation et analyse sensorielle"},
{value : "H1506", label: "Intervention technique qualité en mécanique et travail des métaux"},
{value : "H2101", label: "Abattage et découpe des viandes"},
{value : "H2102", label: "Conduite d'équipement de production alimentaire"},
{value : "H2201", label: "Assemblage d'ouvrages en bois"},
{value : "H2202", label: "Conduite d'équipement de fabrication de l'ameublement et du bois"},
{value : "H2203", label: "Conduite d'installation de production de panneaux bois"},
{value : "H2204", label: "Encadrement des industries de l'ameublement et du bois"},
{value : "H2205", label: "Première transformation de bois d'oeuvre"},
{value : "H2206", label: "Réalisation de menuiserie bois et tonnellerie"},
{value : "H2207", label: "Réalisation de meubles en bois"},
{value : "H2208", label: "Réalisation d'ouvrages décoratifs en bois"},
{value : "H2209", label: "Intervention technique en ameublement et bois"},
{value : "H2301", label: "Conduite d'équipement de production chimique ou pharmaceutique"},
{value : "H2401", label: "Assemblage - montage d'articles en cuirs, peaux"},
{value : "H2402", label: "Assemblage - montage de vêtements et produits textiles"},
{value : "H2403", label: "Conduite de machine de fabrication de produits textiles"},
{value : "H2404", label: "Conduite de machine de production et transformation des fils"},
{value : "H2405", label: "Conduite de machine de textiles nontissés"},
{value : "H2406", label: "Conduite de machine de traitement textile"},
{value : "H2407", label: "Conduite de machine de transformation et de finition des cuirs et peaux"},
{value : "H2408", label: "Conduite de machine d'impression textile"},
{value : "H2409", label: "Coupe cuir, textile et matériaux souples"},
{value : "H2410", label: "Mise en forme, repassage et finitions en industrie textile"},
{value : "H2411", label: "Montage de prototype cuir et matériaux souples"},
{value : "H2412", label: "Patronnage - gradation"},
{value : "H2413", label: "Préparation de fils, montage de métiers textiles"},
{value : "H2414", label: "Préparation et finition d'articles en cuir et matériaux souples"},
{value : "H2415", label: "Contrôle en industrie du cuir et du textile"},
{value : "H2501", label: "Encadrement de production de matériel électrique et électronique"},
{value : "H2502", label: "Management et ingénierie de production"},
{value : "H2503", label: "Pilotage d'unité élémentaire de production mécanique ou de travail des métaux"},
{value : "H2504", label: "Encadrement d'équipe en industrie de transformation"},
{value : "H2505", label: "Encadrement d'équipe ou d'atelier en matériaux souples"},
{value : "H2601", label: "Bobinage électrique"},
{value : "H2602", label: "Câblage électrique et électromécanique"},
{value : "H2603", label: "Conduite d'installation automatisée de production électrique, électronique et microélectronique"},
{value : "H2604", label: "Montage de produits électriques et électroniques"},
{value : "H2605", label: "Montage et câblage électronique"},
{value : "H2701", label: "Pilotage d'installation énergétique et pétrochimique"},
{value : "H2801", label: "Conduite d'équipement de transformation du verre"},
{value : "H2802", label: "Conduite d'installation de production de matériaux de construction"},
{value : "H2803", label: "Façonnage et émaillage en industrie céramique"},
{value : "H2804", label: "Pilotage de centrale à béton prêt à l'emploi, ciment, enrobés et granulats"},
{value : "H2805", label: "Pilotage d'installation de production verrière"},
{value : "H2901", label: "Ajustement et montage de fabrication"},
{value : "H2902", label: "Chaudronnerie - tôlerie"},
{value : "H2903", label: "Conduite d'équipement d'usinage"},
{value : "H2904", label: "Conduite d'équipement de déformation des métaux"},
{value : "H2905", label: "Conduite d'équipement de formage et découpage des matériaux"},
{value : "H2906", label: "Conduite d'installation automatisée ou robotisée de fabrication mécanique"},
{value : "H2907", label: "Conduite d'installation de production des métaux"},
{value : "H2908", label: "Modelage de matériaux non métalliques"},
{value : "H2909", label: "Montage-assemblage mécanique"},
{value : "H2910", label: "Moulage sable"},
{value : "H2911", label: "Réalisation de structures métalliques"},
{value : "H2912", label: "Réglage d'équipement de production industrielle"},
{value : "H2913", label: "Soudage manuel"},
{value : "H2914", label: "Réalisation et montage en tuyauterie"},
{value : "H3101", label: "Conduite d'équipement de fabrication de papier ou de carton"},
{value : "H3102", label: "Conduite d'installation de pâte à papier"},
{value : "H3201", label: "Conduite d'équipement de formage des plastiques et caoutchoucs"},
{value : "H3202", label: "Réglage d'équipement de formage des plastiques et caoutchoucs"},
{value : "H3203", label: "Fabrication de pièces en matériaux composites"},
{value : "H3301", label: "Conduite d'équipement de conditionnement"},
{value : "H3302", label: "Opérations manuelles d'assemblage, tri ou emballage"},
{value : "H3303", label: "Préparation de matières et produits industriels (broyage, mélange, ...)"},
{value : "H3401", label: "Conduite de traitement d'abrasion de surface"},
{value : "H3402", label: "Conduite de traitement par dépôt de surface"},
{value : "H3403", label: "Conduite de traitement thermique"},
{value : "H3404", label: "Peinture industrielle"},
{value : "I1101", label: "Direction et ingénierie en entretien infrastructure et bâti"},
{value : "I1102", label: "Management et ingénierie de maintenance industrielle"},
{value : "I1103", label: "Supervision d'entretien et gestion de véhicules"},
{value : "I1201", label: "Entretien d'affichage et mobilier urbain"},
{value : "I1202", label: "Entretien et surveillance du tracé routier"},
{value : "I1203", label: "Maintenance des bâtiments et des locaux"},
{value : "I1301", label: "Installation et maintenance d'ascenseurs"},
{value : "I1302", label: "Installation et maintenance d'automatismes"},
{value : "I1303", label: "Installation et maintenance de distributeurs automatiques"},
{value : "I1304", label: "Installation et maintenance d'équipements industriels et d'exploitation"},
{value : "I1305", label: "Installation et maintenance électronique"},
{value : "I1306", label: "Installation et maintenance en froid, conditionnement d'air"},
{value : "I1307", label: "Installation et maintenance télécoms et courants faibles"},
{value : "I1308", label: "Maintenance d'installation de chauffage"},
{value : "I1309", label: "Maintenance électrique"},
{value : "I1310", label: "Maintenance mécanique industrielle"},
{value : "I1401", label: "Maintenance informatique et bureautique"},
{value : "I1402", label: "Réparation de biens électrodomestiques et multimédia"},
{value : "I1501", label: "Intervention en grande hauteur"},
{value : "I1502", label: "Intervention en milieu subaquatique"},
{value : "I1503", label: "Intervention en milieux et produits nocifs"},
{value : "I1601", label: "Installation et maintenance en nautisme"},
{value : "I1602", label: "Maintenance d'aéronefs"},
{value : "I1603", label: "Maintenance d'engins de chantier, levage, manutention et de machines agricoles"},
{value : "I1604", label: "Mécanique automobile et entretien de véhicules"},
{value : "I1605", label: "Mécanique de marine"},
{value : "I1606", label: "Réparation de carrosserie"},
{value : "I1607", label: "Réparation de cycles, motocycles et motoculteurs de loisirs"},
{value : "J1101", label: "Médecine de prévention"},
{value : "J1102", label: "Médecine généraliste et spécialisée"},
{value : "J1103", label: "Médecine dentaire"},
{value : "J1104", label: "Suivi de la grossesse et de l'accouchement"},
{value : "J1201", label: "Biologie médicale"},
{value : "J1202", label: "Pharmacie"},
{value : "J1301", label: "Personnel polyvalent des services hospitaliers"},
{value : "J1302", label: "Analyses médicales"},
{value : "J1303", label: "Assistance médico-technique"},
{value : "J1304", label: "Aide en puériculture"},
{value : "J1305", label: "Conduite de véhicules sanitaires"},
{value : "J1306", label: "Imagerie médicale"},
{value : "J1307", label: "Préparation en pharmacie"},
{value : "J1401", label: "Audioprothèses"},
{value : "J1402", label: "Diététique"},
{value : "J1403", label: "Ergothérapie"},
{value : "J1404", label: "Kinésithérapie"},
{value : "J1405", label: "Optique - lunetterie"},
{value : "J1406", label: "Orthophonie"},
{value : "J1407", label: "Orthoptique"},
{value : "J1408", label: "Ostéopathie et chiropraxie"},
{value : "J1409", label: "Pédicurie et podologie"},
{value : "J1410", label: "Prothèses dentaires"},
{value : "J1411", label: "Prothèses et orthèses"},
{value : "J1412", label: "Rééducation en psychomotricité"},
{value : "J1501", label: "Soins d'hygiène, de confort du patient"},
{value : "J1502", label: "Coordination de services médicaux ou paramédicaux"},
{value : "J1503", label: "Soins infirmiers spécialisés en anesthésie"},
{value : "J1504", label: "Soins infirmiers spécialisés en bloc opératoire"},
{value : "J1505", label: "Soins infirmiers spécialisés en prévention"},
{value : "J1506", label: "Soins infirmiers généralistes"},
{value : "J1507", label: "Soins infirmiers spécialisés en puériculture"},
{value : "K1101", label: "Accompagnement et médiation familiale"},
{value : "K1102", label: "Aide aux bénéficiaires d'une mesure de protection juridique"},
{value : "K1103", label: "Développement personnel et bien-être de la personne"},
{value : "K1104", label: "Psychologie"},
{value : "K1201", label: "Action sociale"},
{value : "K1202", label: "Éducation de jeunes enfants"},
{value : "K1203", label: "Encadrement technique en insertion professionnelle"},
{value : "K1204", label: "Médiation sociale et facilitation de la vie en société"},
{value : "K1205", label: "Information sociale"},
{value : "K1206", label: "Intervention socioculturelle"},
{value : "K1207", label: "Intervention socioéducative"},
{value : "K1301", label: "Accompagnement médicosocial"},
{value : "K1302", label: "Assistance auprès d'adultes"},
{value : "K1303", label: "Assistance auprès d'enfants"},
{value : "K1304", label: "Services domestiques"},
{value : "K1305", label: "Intervention sociale et familiale"},
{value : "K1401", label: "Conception et pilotage de la politique des pouvoirs publics"},
{value : "K1402", label: "Conseil en Santé Publique"},
{value : "K1403", label: "Management de structure de santé, sociale ou pénitentiaire"},
{value : "K1404", label: "Mise en oeuvre et pilotage de la politique des pouvoirs publics"},
{value : "K1405", label: "Représentation de l'État sur le territoire national ou international"},
{value : "K1501", label: "Application des règles financières publiques"},
{value : "K1502", label: "Contrôle et inspection des Affaires Sociales"},
{value : "K1503", label: "Contrôle et inspection des impôts"},
{value : "K1504", label: "Contrôle et inspection du Trésor Public"},
{value : "K1505", label: "Protection des consommateurs et contrôle des échanges commerciaux"},
{value : "K1601", label: "Gestion de l'information et de la documentation"},
{value : "K1602", label: "Gestion de patrimoine culturel"},
{value : "K1701", label: "Personnel de la Défense"},
{value : "K1702", label: "Direction de la sécurité civile et des secours"},
{value : "K1703", label: "Direction opérationnelle de la défense"},
{value : "K1704", label: "Management de la sécurité publique"},
{value : "K1705", label: "Sécurité civile et secours"},
{value : "K1706", label: "Sécurité publique"},
{value : "K1707", label: "Surveillance municipale"},
{value : "K1801", label: "Conseil en emploi et insertion socioprofessionnelle"},
{value : "K1802", label: "Développement local"},
{value : "K1901", label: "Aide et médiation judiciaire"},
{value : "K1902", label: "Collaboration juridique"},
{value : "K1903", label: "Défense et conseil juridique"},
{value : "K1904", label: "Magistrature"},
{value : "K2101", label: "Conseil en formation"},
{value : "K2102", label: "Coordination pédagogique"},
{value : "K2103", label: "Direction d'établissement et d'enseignement"},
{value : "K2104", label: "Éducation et surveillance au sein d'établissements d'enseignement"},
{value : "K2105", label: "Enseignement artistique"},
{value : "K2106", label: "Enseignement des écoles"},
{value : "K2107", label: "Enseignement général du second degré"},
{value : "K2108", label: "Enseignement supérieur"},
{value : "K2109", label: "Enseignement technique et professionnel"},
{value : "K2110", label: "Formation en conduite de véhicules"}
  ]
  return fonctions;
}

export function monthToISO8601(months : any) {
  const annees = Math.floor(months / 12);
  const moisRestants = months % 12;

  let iso8601String = "P";
  if (annees > 0) {
    iso8601String += annees + "Y";
  }
  if (moisRestants > 0) {
    iso8601String += moisRestants + "M";
    }
  return iso8601String;
}

export function getFhfPoste(){
const posteData= [
  {
    value: 126,
    type: "ADM/DIR",
    label: "Adjoint | Agent administratif"
  },
  {
    value: 127,
    type: "ADM/DIR",
    label: "Adjoint des cadres hospitaliers"
  },
  {
    value: 129,
    type: "ADM/DIR",
    label: "Attaché d'administration hospitalière"
  },
  {
    value: 202,
    type: "ADM/DIR",
    label: "Chargé de communication"
  },
  {
    value: 146,
    type: "ADM/DIR",
    label: "Chef de projet | Chargé de mission"
  },
  {
    value: 131,
    type: "ADM/DIR",
    label: "Comptable | Aide comptable"
  },
  {
    value: 200,
    type: "ADM/DIR",
    label: "Contrôleur de gestion"
  },
  {
    value: 145,
    type: "ADM/DIR",
    label: "Directeur (autre)"
  },
  {
    value: 132,
    type: "ADM/DIR",
    label: "Directeur | Resp. administratif"
  },
  {
    value: 133,
    type: "ADM/DIR",
    label: "Directeur | Resp. Communication"
  },
  {
    value: 134,
    type: "ADM/DIR",
    label: "Directeur | Resp. Formation"
  },
  {
    value: 135,
    type: "ADM/DIR",
    label: "Directeur | Resp. Affaires Médicales"
  },
  {
    value: 136,
    type: "ADM/DIR",
    label: "Directeur | Resp. Ressources Humaines"
  },
  {
    value: 137,
    type: "ADM/DIR",
    label: "Directeur | Resp. Financier"
  },
  {
    value: 138,
    type: "ADM/DIR",
    label: "Directeur | Resp. Logistique"
  },
  {
    value: 139,
    type: "ADM/DIR",
    label: "Directeur | Resp. Qualité"
  },
  {
    value: 128,
    type: "ADM/DIR",
    label: "Directeur adjoint"
  },
  {
    value: 130,
    type: "ADM/DIR",
    label: "Directeur d'établissement | Directeur général"
  },
  {
    value: 140,
    type: "ADM/DIR",
    label: "Directeur de(s) laboratoire(s)"
  },
  {
    value: 141,
    type: "ADM/DIR",
    label: "Directeur des soins"
  },
  {
    value: 197,
    type: "ADM/DIR",
    label: "Directeur DES-DESS"
  },
  {
    value: 147,
    type: "ADM/DIR",
    label: "Hôtesse d'accueil"
  },
  {
    value: 148,
    type: "ADM/DIR",
    label: "Directeur | Resp. Achat"
  },
  {
    value: 149,
    type: "ADM/DIR",
    label: "Directeur | Resp. Juridique"
  },
  {
    value: 150,
    type: "ADM/DIR",
    label: "Secrétaire | Secrétaire de direction"
  },
  {
    value: 151,
    type: "ADM/DIR",
    label: "Secrétaire médicale"
  },
  {
    value: 152,
    type: "ADM/DIR",
    label: "Standardiste"
  },
  {
    value: 192,
    type: "ADM/ENS",
    label: "Directeur d'école paramédicale"
  },
  {
    value: 194,
    type: "ADM/ENS",
    label: "Enseignant | Formateur"
  },
  {
    value: 193,
    type: "ADM/ENS",
    label: "Monitrice d'école paramédicale"
  },
  {
    value: 166,
    type: "ADM/INF",
    label: "Chef de projet"
  },
  {
    value: 167,
    type: "ADM/INF",
    label: "Directeur | Resp. SI (systèmes d'information)"
  },
  {
    value: 170,
    type: "ADM/INF",
    label: "Ingénieur informatique"
  },
  {
    value: 169,
    type: "ADM/INF",
    label: "Technicien informatique"
  },
  {
    value: 204,
    type: "ADM/INF",
    label: "Webmestre"
  },
  {
    value: 173,
    type: "ADM/LAB",
    label: "Attaché de recherche clinique"
  },
  {
    value: 174,
    type: "ADM/LAB",
    label: "Biostatisticien"
  },
  {
    value: 175,
    type: "ADM/LAB",
    label: "Chercheur"
  },
  {
    value: 196,
    type: "ADM/SGT",
    label: "Adjoint technique"
  },
  {
    value: 159,
    type: "ADM/SGT",
    label: "Agent | ASH"
  },
  {
    value: 160,
    type: "ADM/SGT",
    label: "Ambulancier"
  },
  {
    value: 211,
    type: "ADM/SGT",
    label: "Cuisinier"
  },
  {
    value: 161,
    type: "ADM/SGT",
    label: "Directeur technique"
  },
  {
    value: 165,
    type: "ADM/SGT",
    label: "Ingénieur"
  },
  {
    value: 162,
    type: "ADM/SGT",
    label: "Ouvrier"
  },
  {
    value: 163,
    type: "ADM/SGT",
    label: "Ouvrier qualifié | spécialisé"
  },
  {
    value: 203,
    type: "ADM/SGT",
    label: "Technicien | Technicien supérieur"
  },
  {
    value: 120,
    type: "MED/PHA",
    label: "Aide de pharmacie"
  },
  {
    value: 121,
    type: "MED/PHA",
    label: "Pharmacien"
  },
  {
    value: 207,
    type: "MED/SPE",
    label: "Addictologie"
  },
  {
    value: 1,
    type: "MED/SPE",
    label: "Algologie"
  },
  {
    value: 2,
    type: "MED/SPE",
    label: "Anatomie-pathologie"
  },
  {
    value: 3,
    type: "MED/SPE",
    label: "Anesthésie-réanimation"
  },
  {
    value: 4,
    type: "MED/SPE",
    label: "Biologie"
  },
  {
    value: 42,
    type: "MED/SPE",
    label: "Cancérologie"
  },
  {
    value: 5,
    type: "MED/SPE",
    label: "Cardiologie"
  },
  {
    value: 6,
    type: "MED/SPE",
    label: "Chirurgie Digestive"
  },
  {
    value: 7,
    type: "MED/SPE",
    label: "Chirurgie Polyvalente"
  },
  {
    value: 8,
    type: "MED/SPE",
    label: "Chirurgie Vasculaire"
  },
  {
    value: 9,
    type: "MED/SPE",
    label: "Dermatologie"
  },
  {
    value: 11,
    type: "MED/SPE",
    label: "Endocrinologie"
  },
  {
    value: 12,
    type: "MED/SPE",
    label: "Gastro-entérologie"
  },
  {
    value: 14,
    type: "MED/SPE",
    label: "Gériatrie"
  },
  {
    value: 15,
    type: "MED/SPE",
    label: "Gynéco-obstétrique"
  },
  {
    value: 16,
    type: "MED/SPE",
    label: "Hématologie Clinique"
  },
  {
    value: 17,
    type: "MED/SPE",
    label: "Hygiène"
  },
  {
    value: 10,
    type: "MED/SPE",
    label: "Information médicale"
  },
  {
    value: 18,
    type: "MED/SPE",
    label: "Interne"
  },
  {
    value: 21,
    type: "MED/SPE",
    label: "Médecine du travail"
  },
  {
    value: 13,
    type: "MED/SPE",
    label: "Médecine générale"
  },
  {
    value: 19,
    type: "MED/SPE",
    label: "Médecine Interne"
  },
  {
    value: 212,
    type: "MED/SPE",
    label: "Médecine nucléaire"
  },
  {
    value: 34,
    type: "MED/SPE",
    label: "Médecine physique et de réadaptation"
  },
  {
    value: 20,
    type: "MED/SPE",
    label: "Médecine Polyvalente"
  },
  {
    value: 41,
    type: "MED/SPE",
    label: "Néphrologie"
  },
  {
    value: 22,
    type: "MED/SPE",
    label: "Neurochirurgie"
  },
  {
    value: 23,
    type: "MED/SPE",
    label: "Neurologie"
  },
  {
    value: 210,
    type: "MED/SPE",
    label: "Odontologie"
  },
  {
    value: 24,
    type: "MED/SPE",
    label: "Oncologie"
  },
  {
    value: 25,
    type: "MED/SPE",
    label: "Ophtalmologie"
  },
  {
    value: 45,
    type: "MED/SPE",
    label: "ORL"
  },
  {
    value: 26,
    type: "MED/SPE",
    label: "Orthopédie-traumatologie"
  },
  {
    value: 27,
    type: "MED/SPE",
    label: "Pédiatrie"
  },
  {
    value: 28,
    type: "MED/SPE",
    label: "Pédo-psychiatrie"
  },
  {
    value: 29,
    type: "MED/SPE",
    label: "Pharmacie"
  },
  {
    value: 30,
    type: "MED/SPE",
    label: "Pneumologie"
  },
  {
    value: 31,
    type: "MED/SPE",
    label: "Psychiatrie"
  },
  {
    value: 32,
    type: "MED/SPE",
    label: "Radiologie"
  },
  {
    value: 33,
    type: "MED/SPE",
    label: "Radiothérapie"
  },
  {
    value: 43,
    type: "MED/SPE",
    label: "Réanimation polyvalente"
  },
  {
    value: 35,
    type: "MED/SPE",
    label: "Rhumatologie"
  },
  {
    value: 199,
    type: "MED/SPE",
    label: "Sage-Femme"
  },
  {
    value: 36,
    type: "MED/SPE",
    label: "Santé Publique"
  },
  {
    value: 37,
    type: "MED/SPE",
    label: "Soins Palliatifs"
  },
  {
    value: 38,
    type: "MED/SPE",
    label: "Stomatologie"
  },
  {
    value: 39,
    type: "MED/SPE",
    label: "Urgentiste"
  },
  {
    value: 40,
    type: "MED/SPE",
    label: "Urologie"
  },
  {
    value: 172,
    type: "SOI/MET",
    label: "Aide de laboratoire"
  },
  {
    value: 205,
    type: "SOI/MET",
    label: "Ergonome"
  },
  {
    value: 123,
    type: "ADM/LAB",
    label: "Ingénieur biomédical"
  },
  {
    value: 171,
    type: "SOI/MET",
    label: "Laborantin"
  },
  {
    value: 125,
    type: "SOI/MET",
    label: "Manipulateur d'électroradiologie médicale"
  },
  {
    value: 122,
    type: "SOI/MET",
    label: "Préparateur en pharmacie"
  },
  {
    value: 201,
    type: "SOI/MET",
    label: "Radio-physicien"
  },
  {
    value: 176,
    type: "SOI/MET",
    label: "Technicien | Tech. chef de laboratoire"
  },
  {
    value: 124,
    type: "SOI/MET",
    label: "Technicien biomédical"
  },
  {
    value: 177,
    type: "SOI/MET",
    label: "Technicien de recherche"
  },
  {
    value: 208,
    type: "SOI/PAR",
    label: "Aide Médico-Psychologique"
  },
  {
    value: 101,
    type: "SOI/PAR",
    label: "Aide Soignant(e)"
  },
  {
    value: 110,
    type: "SOI/PAR",
    label: "Audioprothésiste"
  },
  {
    value: 109,
    type: "SOI/PAR",
    label: "Auxiliaire Puéricultrice"
  },
  {
    value: 103,
    type: "SOI/PAR",
    label: "Cadre de santé | Cadre supérieur"
  },
  {
    value: 111,
    type: "SOI/PAR",
    label: "Diététicien"
  },
  {
    value: 112,
    type: "SOI/PAR",
    label: "Ergothérapeute"
  },
  {
    value: 104,
    type: "SOI/PAR",
    label: "Infirmier(e) anesthésiste (IADE)"
  },
  {
    value: 105,
    type: "SOI/PAR",
    label: "Infirmier(e) anesthésiste (IADE) Cadre"
  },
  {
    value: 106,
    type: "SOI/PAR",
    label: "Infirmier(e) de bloc opératoire (IBODE)"
  },
  {
    value: 107,
    type: "SOI/PAR",
    label: "Infirmier(e) de bloc opératoire (IBODE) Cadre"
  },
  {
    value: 102,
    type: "SOI/PAR",
    label: "Infirmier(e) Diplomé(e) d'Etat (IDE)"
  },
  {
    value: 206,
    type: "SOI/PAR",
    label: "Infirmier(e) Diplomé(e) d'Etat (IDE) Cadre"
  },
  {
    value: 113,
    type: "SOI/PAR",
    label: "Masseur-kinésithérapeute"
  },
  {
    value: 114,
    type: "SOI/PAR",
    label: "Opticien-lunetier"
  },
  {
    value: 115,
    type: "SOI/PAR",
    label: "Orthophoniste"
  },
  {
    value: 213,
    type: "SOI/PAR",
    label: "Orthoprothésiste"
  },
  {
    value: 116,
    type: "SOI/PAR",
    label: "Orthoptiste"
  },
  {
    value: 117,
    type: "SOI/PAR",
    label: "Pédicure-podologue"
  },
  {
    value: 118,
    type: "SOI/PAR",
    label: "Psychologue"
  },
  {
    value: 119,
    type: "SOI/PAR",
    label: "Psychomotricien"
  },
  {
    value: 108,
    type: "SOI/PAR",
    label: "Puéricultrice"
  },
  {
    value: 195,
    type: "SOI/SED",
    label: "Animateur"
  },
  {
    value: 153,
    type: "SOI/SED",
    label: "Assistant | Cadre socio-éducatif"
  },
  {
    value: 209,
    type: "SOI/SED",
    label: "Assistant(e) maternelle | familiale"
  },
  {
    value: 154,
    type: "SOI/SED",
    label: "Assistante sociale"
  },
  {
    value: 155,
    type: "SOI/SED",
    label: "Conseiller en économie sociale et familiale"
  },
  {
    value: 156,
    type: "SOI/SED",
    label: "Educateur spécialisé"
  },
  {
    value: 157,
    type: "SOI/SED",
    label: "Educateur technique spécialisé"
  },
  {
    value: 158,
    type: "SOI/SED",
    label: "Moniteur d'atelier | Moniteur éducateur"
  },
  {
    value: 214,
    type: "SOI/SED",
    label: "Socio-esthéticien(ne)"
  },
  {
    value: 215,
    type: "MED/SPE",
    label: "Infectiologie"
  },
  {
    value: 216,
    type: "SOI/PAR",
    label: "Art-thérapeute"
  },
  {
    value: 217,
    type: "ADM/DIR",
    label: "Assistant de régulation médical | PARM"
  }
 ]
return posteData;
}

export function getFhfContact() {

  const contactData = [
    {
      value: 41,
      label: "PH temps plein"
    },
    {
      value: 42,
      label: "PAC"
    },
    {
      value: 43,
      label: "Praticien contractuel"
    },
    {
      value: 44,
      label: "Assistant généraliste associé"
    },
    {
      value: 46,
      label: "Assistant spécialiste"
    },
    {
      value: 70,
      label: "Mutation"
    },
    {
      value: 48,
      label: "FFI"
    },
    {
      value: 50,
      label: "Interne"
    },
    {
      value: 62,
      label: "AHU / CCU-AH"
    },
    {
      value: 54,
      label: "PH temps partiel"
    },
    {
      value: 55,
      label: "Attaché"
    },
    {
      value: 56,
      label: "Remplaçant"
    },
    {
      value: 58,
      label: "Assistant généraliste"
    },
    {
      value: 60,
      label: "Assistant spécialiste associé"
    },
    {
      value: 61,
      label: "Attaché associé"
    },
    {
      value: 63,
      label: "CDD"
    },
    {
      value: 64,
      label: "CDI"
    },
    {
      value: 65,
      label: "Détachement"
    },
    {
      value: 66,
      label: "Mutation"
    },
    {
      value: 67,
      label: "Stage"
    },
    {
      value: 68,
      label: "Vacation"
    },
    {
      value: 69,
      label: "Emploi aidé"
    }
   ]
   return contactData;
}

export function getFhtTypePoste(){
  const typePostData = [
    {
      value: "SOI/PAR",
      label: "Paramédical"
    },
    {
      value: "MED/PHA",
      label: "Pharmacie"
    },
    {
      value: "SOI/MET",
      label: "Médico-techniques"
    },
    {
      value: "ADM/DIR",
      label: "Administration - Direction"
    },
    {
      value: "SOI/SED",
      label: "Socio-éducatif"
    },
    {
      value: "ADM/SGT",
      label: "Services généraux et techniques"
    },
    {
      value: "ADM/INF",
      label: "Informatique"
    },
    {
      value: "ADM/LAB",
      label: "Laboratoire - recherche"
    },
    {
      value: "ADM/ENS",
      label: "Enseignement"
    },
    {
      value: "MED/SPE",
      label: "Medical"
    }
   ];
   return typePostData
}

export function abbreviateString(item: string) : string {
  const words = item.split(' ');
  const acronym = words.map(word => word[0].toLowerCase()).join('');
  return acronym;
}

enum IsValueOption {
  TRUE = '1',
  FALSE = '0',
}

export function  convertIsValue(value: string | any) {
  if (value != null && value === IsValueOption.TRUE) return true
  if (value != null && value === IsValueOption.FALSE) return false
}

export function formatedTalentComDate(date: any): any {
  const formatedDate = moment(date).format("yyyy-MM-DDTHH:mm:ssZ");
  return formatedDate;
}

export function transformToScale(inputValue: string) {
  if (typeof inputValue === 'string' && inputValue.trim() !== '') {
    const numericValue = parseFloat(inputValue);

    if (!isNaN(numericValue)) {
      const transformedValue = numericValue / 1000;
      return transformedValue.toString();
    }
  }

  return inputValue;
}

export function formatJobboardLabel(item: string) {
  return item.replace(/\s/g, "").toLocaleLowerCase();
}