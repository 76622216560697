import React, {useEffect, useState} from 'react'
import {} from 'formik'
import {useAppDispatch, useAppSelector, useFormSelectInput} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {FederationHospitaliereFranceProperty} from '../../../referentiel/core/_models'
import {Col, Form, Row} from 'react-bootstrap'
import './secondStep.scss'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import {abbreviateString, getFhfContact, getFhfPoste} from '../../../referentiel/core/_tools'
import {fetchTenantJobboardByLabel} from '../../../../store/slices/tenantJobboard.slices'
import {setToFalse, setToTrue, toggleValue} from '../../../../store/slices/bool.slices'
import './inputValidation.scss'

const FederationHospitaliereFormStep = (props: any) => {
  const entity = props.data ? props.data : undefined

  const animatedComponents = makeAnimated()
  const dispatch = useAppDispatch()

  const [state, setState] = useState(entity ? entity.state : '')
  const tenantJobboard = useAppSelector((state: RootState) => state.tenantJobboards.entity)

  const poste = useFormSelectInput(entity ? entity.poste : '')
  const contrat = useFormSelectInput(entity ? entity.contrat : '')

  const handleLists = async () => {
    const _jobboardLabel = abbreviateString(props.jobboardLabel)
    await dispatch(fetchTenantJobboardByLabel(_jobboardLabel.toLowerCase()))
    await dispatch(toggleValue())
  }

  const changeHandler = (e: any) => {
    e.preventDefault()
    const body = {
      jobboardLabel: abbreviateString(props.jobboardLabel),
      finess_etab: tenantJobboard?.finess_etab,
      poste: poste?.value,
      contrat: contrat?.value,
      publie: true,
      active: true,
      source: 'scoptalent.com',
      partenaires: false,
      userName: tenantJobboard?.userName,
      password: tenantJobboard?.password,
      fhfToken: tenantJobboard?.fhfToken,
      idOffre: entity?.idOffre,
      isUpdated: props.isEdited ? props.isEdited : false,
    } as FederationHospitaliereFranceProperty

    props.handleForm(body)

    if (
      !poste.error.isError &&
      poste.value.length > 0 &&
      poste.value != 0 &&
      !contrat.error.isError &&
      contrat.value.length > 0 &&
      contrat.value != 0
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  useEffect(() => {
    if (props.isEdited && entity) {
      const selectedContactOption = getFhfContact().find(
        (item) => item.value === Number(entity.contrat)
      )

      contrat.handleChange({
        target: {value: selectedContactOption?.value},
      })

      const selectedPosteOption = getFhfPoste().find((item) => item.value === Number(entity.poste))
      poste.handleChange({
        target: {value: selectedPosteOption?.value},
      })
    }
  }, [props.isEdited, entity])

  useEffect(() => {
    handleLists()
  }, [])

  return (
    <div className='card card-bordered step-card card-fhf' onClick={changeHandler}>
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>Fédération Hospitalière de France</div>
      </div>
      <div className='card-body'>
        <div className='mb-10'>
          <Row className='mt-3'>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Type de contrat</label>
                {contrat.error.isError && (
                  <div className='error-message'>{contrat.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                name='contrat'
                className={`mb-10 ${
                  !contrat.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'form-select form-select-lg form-select-solid border-red'
                }`}
                value={contrat.value}
                onChange={contrat.handleChange}
              >
                <option value='0'>Sélectionnez le type de contrat</option>
                {getFhfContact().map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.label}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>Type de poste</label>
                {poste.error.isError && <div className='error-message'>{poste.error.errorMsg}</div>}
              </div>
              <Form.Select
                name='poste'
                className={`mb-10 ${
                  !poste.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'form-select form-select-lg form-select-solid border-red'
                }`}
                value={poste.value}
                onChange={poste.handleChange}
              >
                <option value='0'>Sélectionnez le type de poste</option>
                {getFhfPoste().map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export {FederationHospitaliereFormStep}
