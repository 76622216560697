import {Col, Form, Row} from 'react-bootstrap'
import {JobboardsLabel, LinkedinLabels, LinkedinProperty} from '../../../referentiel/core/_models'
import {useEffect, useState} from 'react'
import {
  useAppDispatch,
  useAppSelector,
  useFormInput,
} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {
  fetchLinkedinGetJobTypes,
  fetchLinkedinGetExperienceLevels,
  fetchLinkedinGetWorkPlaces,
  fetchLinkedinGetSalaryTypes,
  fetchLinkedinGetJobFunctions,
  fetchLinkedinGetIndustryCodes,
  fetchLinkedinGetSalaryPeriods,
  fetchLinkedinGetSalaryCurrencies,
} from '../../../../store/slices/linkedinReferentiel.slice'
import {setToFalse, setToTrue} from '../../../../store/slices/bool.slices'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const LinkedinFormStep = (props: any) => {
  const dispatch = useAppDispatch()

  const entity = props.data ? props.data : undefined

  const city = useFormInput(entity ? entity.city : '')
  const state = useFormInput(entity ? entity.state : '')
  const country = useFormInput(entity ? entity.country : '')

  const [postalCode, setPostalCode] = useState(entity ? entity.postalCode : '')
  const [skills, setSkills] = useState(entity ? entity.skills : '')
  const [salaryHighEndAmount, setSalaryHighEndAmount] = useState(
    entity ? entity.salaryHighEndAmount : ''
  )
  const [salaryLowEndAmount, setSalaryLowEndAmount] = useState(
    entity ? entity.salaryLowEndAmount : ''
  )
  const [expirationDate, setExpirationDate] = useState(entity ? entity.expirationDate : '')

  const [jobType, setJobType] = useState(entity ? entity.jobType : '')
  const [workPlace, setWorkPlace] = useState(entity ? entity.workplaceTypes : '')
  const [experienceLevel, setExperienceLevel] = useState(entity ? entity.experienceLevel : '')
  const [salaryType, setSalaryType] = useState(entity ? entity.salaryType : '')
  const [salaryCurrency, setSalaryCurrency] = useState(entity ? entity.salaryCurrencyCode : '')
  const [salaryPeriod, setSalaryPeriod] = useState(entity ? entity.salaryPeriod : '')
  const [jobFunction, setJobFunction] = useState(entity ? entity.jobFunctions : '')
  const [industryCode, setIndustryCode] = useState(entity ? entity.industryCodes : '')

  const jobTypesResponseList = useAppSelector(
    (state: RootState) => state.linkedinReferentiel.jobTypes
  )
  const workPlacesResponseList = useAppSelector(
    (state: RootState) => state.linkedinReferentiel.workPlaces
  )
  const experienceLevelsResponseList = useAppSelector(
    (state: RootState) => state.linkedinReferentiel.experienceLevels
  )
  const salaryPeriodsResponseList = useAppSelector(
    (state: RootState) => state.linkedinReferentiel.salaryPeriods
  )
  const salaryTypesResponseList = useAppSelector(
    (state: RootState) => state.linkedinReferentiel.salaryTypes
  )
  const salaryCurrenciesResponseList = useAppSelector(
    (state: RootState) => state.linkedinReferentiel.salaryCurrencies
  )
  const jobFunctionsResponseList = useAppSelector(
    (state: RootState) => state.linkedinReferentiel.jobFunctions
  )
  const industryCodesResponseList = useAppSelector(
    (state: RootState) => state.linkedinReferentiel.industryCodes
  )

  const handlePostalCode = (e: any) => setPostalCode(e.target.value)
  const handleSkills = (e: any) => setSkills(e.target.value)
  const handleSalaryHighEndAmount = (e: any) => setSalaryHighEndAmount(e.target.value)
  const handleSalaryLowEndAmount = (e: any) => setSalaryLowEndAmount(e.target.value)
  const handleExpirationDate = (e: any) => setExpirationDate(e.target.value)

  const handleJobType = (e: any) => setJobType(e.target.value)
  const handleWorkPlace = (e: any) => setWorkPlace(e.target.value)
  const handleExperienceLevel = (e: any) => setExperienceLevel(e.target.value)
  const handleSalaryType = (e: any) => setSalaryType(e.target.value)
  const handleSalaryCurrency = (e: any) => setSalaryCurrency(e.target.value)
  const handleSalaryPeriod = (e: any) => setSalaryPeriod(e.target.value)
  const handleJobFunction = (e: any) => setJobFunction(e.target.value)
  const handleIndustryCode = (e: any) => setIndustryCode(e.target.value)

  const handleLists = async () => {
    await dispatch(fetchLinkedinGetJobTypes())
    await dispatch(fetchLinkedinGetWorkPlaces())
    await dispatch(fetchLinkedinGetExperienceLevels())
    await dispatch(fetchLinkedinGetSalaryTypes())
    await dispatch(fetchLinkedinGetSalaryPeriods())
    await dispatch(fetchLinkedinGetSalaryCurrencies())
    await dispatch(fetchLinkedinGetJobFunctions())
    await dispatch(fetchLinkedinGetIndustryCodes())
  }

  useEffect(() => {
    handleLists();
    if (
        !city.error.isError &&
        city.value &&
        !state.error.isError &&
        state.value &&
        !country.error.isError &&
        country.value
      ) {
        props.handleIsValid(true)
        dispatch(setToTrue())
      } else {
        props.handleIsValid(false)
        dispatch(setToFalse())
      }
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const body = {
      location: `${city.value}, ${country.value}`,
      city: city.value,
      state: state.value,
      country: country.value,
      postalCode: postalCode,
      workplaceTypes: workPlace,
      industryCodes: [{industryCode: industryCode}],
      experienceLevel: experienceLevel,
      jobFunctions: [{jobFunction: jobFunction}],
      jobType: jobType,
      skills: skills.split(',').map((x: string) => {
        return {skill: x}
      }),
      salaries: [
        {
          highEnd: {
            amount: salaryHighEndAmount,
            currencyCode: salaryCurrency,
          },
          lowEnd: {
            amount: salaryLowEndAmount,
            currencyCode: salaryCurrency,
          },
          period: salaryPeriod,
          type: salaryType,
        },
      ],
      expirationDate: expirationDate ? moment(expirationDate).format('MM/DD/yyyy') : undefined,
    } as LinkedinProperty
    props.handleLinkedinForm(body)

    if (
      !city.error.isError &&
      city.value &&
      !state.error.isError &&
      state.value &&
      !country.error.isError &&
      country.value
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  return (
      <div className="card card-bordered step-card card-linkedin" onBlur={handleSubmit} onMouseLeave={handleSubmit}>
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>{JobboardsLabel.LINKEDIN}</div>
      </div>
      <div className='card-body'>
        <Row>
          <Col>
            <label className='form-label required'>{LinkedinLabels.CityLabel}</label>
            <Form.Control
              value={city.value}
              onChange={city.handleChange}
              placeholder={LinkedinLabels.CityLabel}
              className={`mb-10 ${
                !city.error.isError
                  ? 'form-control form-control-lg form-control-solid'
                  : 'form-control form-control-lg form-control-solid border-red'
              }`}
            />
          </Col>
          <Col>
            <label className='form-label'>{LinkedinLabels.PostalCodeLabel}</label>
            <Form.Control
              value={postalCode}
              onChange={handlePostalCode}
              placeholder={LinkedinLabels.PostalCodeLabel}
              className='form-control form-control-lg form-control-solid'
            />
          </Col>
          <Col>
            <label className='form-label required'>{LinkedinLabels.StateLabel}</label>
            <Form.Control
              value={state.value}
              onChange={state.handleChange}
              placeholder={LinkedinLabels.StateLabel}
              className={`mb-10 ${
                !state.error.isError
                  ? 'form-control form-control-lg form-control-solid'
                  : 'form-control form-control-lg form-control-solid border-red'
              }`}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='form-label required'>{LinkedinLabels.CountryLabel}</label>
            <Form.Control
              value={country.value}
              onChange={country.handleChange}
              placeholder={LinkedinLabels.CountryLabel}
              className={`mb-10 ${
                !country.error.isError
                  ? 'form-control form-control-lg form-control-solid'
                  : 'form-control form-control-lg form-control-solid border-red'
              }`}
            />
          </Col>
          <Col>
            <label className='form-label'>{LinkedinLabels.WorkPlaceLabel}</label>
            <Form.Select
              as='select'
              value={workPlace}
              onChange={handleWorkPlace}
              className='mb-10 form-select form-select-lg form-select-solid'
            >
              <option>{LinkedinLabels.WorkPlaceSelectLabel}</option>
              {workPlacesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='form-label'>{LinkedinLabels.IndustryLabel}</label>
            <Form.Select
              as='select'
              value={industryCode}
              onChange={handleIndustryCode}
              className='mb-10 form-select form-select-lg form-select-solid'
            >
              <option>{LinkedinLabels.IndustrySelectLabel}</option>
              {industryCodesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{LinkedinLabels.ExperienceLevelLabel}</label>
            <Form.Select
              as='select'
              value={experienceLevel}
              onChange={handleExperienceLevel}
              className='mb-10 form-select form-select-lg form-select-solid'
            >
              <option>{LinkedinLabels.ExperienceLevelSelectLabel}</option>
              {experienceLevelsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='form-label'>{LinkedinLabels.JobFunctionLabel}</label>
            <Form.Select
              as='select'
              value={jobFunction}
              onChange={handleJobFunction}
              className='mb-10 form-select form-select-lg form-select-solid'
            >
              <option>{LinkedinLabels.JobFunctionSelectLabel}</option>
              {jobFunctionsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{LinkedinLabels.JobTypeLabel}</label>
            <Form.Select
              as='select'
              value={jobType}
              onChange={handleJobType}
              className='mb-10 form-select form-select-lg form-select-solid'
            >
              <option>{LinkedinLabels.JobTypeSelectLabel}</option>
              {jobTypesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='form-label'>{LinkedinLabels.SkillsLabel}</label>
            <Form.Control
              value={skills}
              onChange={handleSkills}
              placeholder={LinkedinLabels.SkillsLabel}
              className='mb-10 form-control form-control-lg form-control-solid'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='form-label'>{LinkedinLabels.SalaryLowEndAmountLabel}</label>
            <Form.Control
              value={salaryLowEndAmount}
              onChange={handleSalaryLowEndAmount}
              placeholder={LinkedinLabels.SalaryLowEndAmountLabel}
              className='mb-10 form-control form-control-lg form-control-solid'
              type='number'
              min='0'
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault()
                }
              }}
            />
          </Col>
          <Col>
            <label className='form-label'>{LinkedinLabels.SalaryHighEndAmountLabel}</label>
            <Form.Control
              value={salaryHighEndAmount}
              onChange={handleSalaryHighEndAmount}
              placeholder={LinkedinLabels.SalaryHighEndAmountLabel}
              className='mb-10 form-control form-control-lg form-control-solid'
              type='number'
              min='0'
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault()
                }
              }}
            />
          </Col>
          <Col>
            <label className='form-label'>{LinkedinLabels.SalaryCurrencyLabel}</label>
            <Form.Select
              as='select'
              value={salaryCurrency}
              onChange={handleSalaryCurrency}
              className='mb-10 form-select form-select-lg form-select-solid'
            >
              <option>{LinkedinLabels.SalaryCurrencySelectLabel}</option>
              {salaryCurrenciesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='form-label'>{LinkedinLabels.SalaryPeriodLabel}</label>
            <Form.Select
              as='select'
              value={salaryPeriod}
              onChange={handleSalaryPeriod}
              className='mb-10 form-select form-select-lg form-select-solid'
            >
              <option>{LinkedinLabels.SalaryPeriodSelectLabel}</option>
              {salaryPeriodsResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col>
            <label className='form-label'>{LinkedinLabels.SalaryTypeLabel}</label>
            <Form.Select
              as='select'
              value={salaryType}
              onChange={handleSalaryType}
              className='mb-10 form-select form-select-lg form-select-solid'
            >
              <option>{LinkedinLabels.SalaryTypeSelectLabel}</option>
              {salaryTypesResponseList.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='form-label'>{LinkedinLabels.ExpirationDateLabel}</label>
            &nbsp;&nbsp;
            <DatePicker
              dateFormat='MM/dd/yyyy'
              className='form-select form-select-lg'
              locale='fr'
              selected={expirationDate}
              onChange={(date: any) => setExpirationDate(date)}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default LinkedinFormStep
