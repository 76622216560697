/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>
      <AsideMenuItem to='/diffusion' icon='/media/icons/duotune/maps/map004.svg' title='Diffusions' hasBullet={true} />
      <AsideMenuItem to='/users' icon='/media/icons/duotune/communication/com006.svg' title='Utilisateurs' hasBullet={true} />
      <AsideMenuItem to='/jobboards' icon='/media/icons/duotune/files/fil003.svg' title='Job Boards' hasBullet={true} />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
