import axios, { AxiosResponse } from 'axios';
import {
    ActiveTenantJobboardVM,
    CountryModel,
    DictionaryModel,
    IJobvacancyPaged,
    JobboardModel,
    JobVacancyDiffusionVM,
    JobVacancyModel,
    MonsterCountryModel,
    MonsterJobCategoryVM, MonsterLocationVM,
    MonsterStateModel,
    ReferentielModel,
    ResultDiffusionVM,
    SubscriptionJobboardModel,
    TenantJobboardVM,
    TenantModel,
    UserVM
} from '../pages/referentiel/core/_models';

const API_URL = process.env.REACT_APP_API_URL
axios.defaults.baseURL = API_URL;

const responseBody = <T>(respose: AxiosResponse<T>) => respose.data;

const request = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    getparams: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    deleteByBody: <T>(url: string, body: {}, headers: {}) =>
            axios.delete<T>(url, { data: body, headers }).then(responseBody),
}
const JobVacancy = {
    list: () => request.get<JobVacancyModel[]>('/JobVacancy/all'),
    paged:(page:number, pageSize:number) => request.get<IJobvacancyPaged>(`/JobVacancy/paged?orderby=Id&page=${page}&pageSize=${pageSize}`),
    add: (body: any) => request.post<JobVacancyModel>('/JobVacancy/create', body),
    update: (id: any, body: JobVacancyDiffusionVM) => request.put<JobVacancyDiffusionVM>(`/JobVacancy/update/${id}`, body),
    delete: (id: any) =>
            request.delete<JobVacancyDiffusionVM>(`/JobVacancy/delete/${id}`),
    getDiffusionById: (id : any) => request.get<ResultDiffusionVM>(`/JobVacancy/get-diffusion-by-id/${id}`),
    create: (id: any, body: JobVacancyDiffusionVM) => request.post<JobVacancyDiffusionVM>(`/JobVacancy/create/${id}`, body),
    rediffuse: (id: any, body: JobVacancyDiffusionVM) => request.post<any>(`/JobVacancy/rediffuse/${id}`, body),
}
const Jobboards = {
    list: () => request.get<JobboardModel[]>('/Jobboard/all'),
    add: (body: any) => request.post<JobboardModel>('/Jobboard/create', body),
    update: (body: any) => request.put<JobboardModel>('/Jobboard/update', body),
    delete: (id: any) => request.delete<JobboardModel>(`/Jobboard/delete?Id=${id}`),
}

const Countries = {
    list: () => request.get<CountryModel[]>('/Country/all')
}

// Configuration tenant and subscription

const Tenant = {
    list: () => request.get<TenantModel[]>('/Tenant/list'),
    get: (id: any) => request.get('/Tenant/'+{id}),
    add: (body: any) => request.post<TenantModel>('/Tenant/create', body),
    update: (id: any, body: any) => request.put<TenantModel>(`/Tenant/update/id?id=${id}`,body),
    delete: (id: any) => request.delete<TenantModel>(`/Tenant/delete/id?id=${id}`),
    getUsersByTenantId : ()=> request.get<UserVM[]>(`/Tenant/get-tenant-users`)
}
const TenantJobboards = {
    list: (id?: any) => request.get<TenantJobboardVM[]>(`/TenantJobboard/all`),
    activeJobboardlistByTenant: (id?: any) => request.get<ActiveTenantJobboardVM[]>(`/TenantJobboard/all-active-jobboard-by-tenant`),
    update: (id: any, body: TenantJobboardVM) => request.put<TenantJobboardVM>(`/TenantJobboard/add-or-update-tenant-jobboard/${id}`, body),
    getTenantJobboard: (jobboardLabel: string) => request.get(`/TenantJobboard/get-tenant-jobboard/${jobboardLabel}`),
    get: (id: any) => request.get('/TenantJobboard/get/'+{id})
}

const poleEmploiReferentiel = {
        getListContractTypes: () => request.get<any>('PoleEmploiReferentiel/get-contract-types'),
        getListContractNatures: () => request.get<any>('PoleEmploiReferentiel/get-contract-natures'),
        getListSalaryTypes: () => request.get<any>('PoleEmploiReferentiel/get-salary-types'),
        getListQualificationTypes: () => request.get<any>('PoleEmploiReferentiel/get-qualification-types'),
        getListDepartement: () => request.get<any>('PoleEmploiReferentiel/get-departement'),
        getListCountryDic: () => request.get<any>('PoleEmploiReferentiel/get-country'),
        
    }

const monsterReferentiel = {
    getIndustriesType: () => request.get<DictionaryModel[]>('MonsterReferentiel/get-industries-type'),
    getJobTypes: () => request.get<DictionaryModel[]>('MonsterReferentiel/get-job-types'),
    getJobCategoriesTypes: () => request.get<MonsterJobCategoryVM[]>('MonsterReferentiel/get-job-categories-Types'),
    getLocationsTypes: () => request.get<MonsterLocationVM[]>('MonsterReferentiel/get-locations-Types'),
}

const apecReferential = {
    getRegionType: () => request.get<DictionaryModel[]>('ApecReferential/get-region-type'),
    getSalaryType: () => request.get<DictionaryModel[]>('ApecReferential/get-salary-type'),
    getPertialTimeType: () => request.get<DictionaryModel[]>('ApecReferential/get-partial-time-type'),
    getContractType: () => request.get<DictionaryModel[]>('ApecReferential/get-contract-type'),
    getJobStatusType: () => request.get<DictionaryModel[]>('ApecReferential/get-job-status-type'),
    getExperienceLevelType: () => request.get<DictionaryModel[]>('ApecReferential/get-experience-level-type'),
}

const leBonCoinReferentiel = {
    getTimeTypes: () => request.get<DictionaryModel[]>('LeBonCoinReferentiel/get-time-types'),
    getContractTypes: () => request.get<DictionaryModel[]>('LeBonCoinReferentiel/get-contract-types'),
    getBusinessSectors: () => request.get<DictionaryModel[]>('LeBonCoinReferentiel/get-business-sectors'),
    getOccupations: () => request.get<DictionaryModel[]>('LeBonCoinReferentiel/get-occupations'),
    getDurationTypes: () => request.get<DictionaryModel[]>('LeBonCoinReferentiel/get-duration-types'),
    getPerSalaries: () => request.get<DictionaryModel[]>('LeBonCoinReferentiel/get-per-salaries'),
    getContactModes: () => request.get<DictionaryModel[]>('LeBonCoinReferentiel/get-contact-modes'),
    getDegrees: () => request.get<DictionaryModel[]>('LeBonCoinReferentiel/get-degrees'),
    getExperiences: () => request.get<DictionaryModel[]>('LeBonCoinReferentiel/get-experiences'),
}

const linkedinReferentiel = {
    getJobTypes: () => request.get<DictionaryModel[]>('LinkedinReferentiel/get-job-types'),
    getWorkPlaces: () => request.get<DictionaryModel[]>('LinkedinReferentiel/get-work-places'),
    getExperienceLevels: () => request.get<DictionaryModel[]>('LinkedinReferentiel/get-experience-levels'),
    getSalaryTypes: () => request.get<DictionaryModel[]>('LinkedinReferentiel/get-salary-types'),
    getSalaryPeriods: () => request.get<DictionaryModel[]>('LinkedinReferentiel/get-salary-periods'),
    getSalaryCurrencies: () => request.get<DictionaryModel[]>('LinkedinReferentiel/get-salary-currencies'),
    getJobFunctions: () => request.get<DictionaryModel[]>('LinkedinReferentiel/get-job-functions'),
    getIndustryCodes: () => request.get<DictionaryModel[]>('LinkedinReferentiel/get-industry-codes'),
}

const helloWorkReferentiel = {
    getCurrencies: () => request.get<DictionaryModel[]>('HelloWorkReferentiel/get-currencies'),
    getPeriodicities: () => request.get<DictionaryModel[]>('HelloWorkReferentiel/get-periodicities'),
    getSectors: () => request.get<DictionaryModel[]>('HelloWorkReferentiel/get-sectors'),
    getExperiences: () => request.get<DictionaryModel[]>('HelloWorkReferentiel/get-experiences'),
    getContracts: () => request.get<DictionaryModel[]>('HelloWorkReferentiel/get-contracts'),
    getLevels: () => request.get<DictionaryModel[]>('HelloWorkReferentiel/get-levels'),
    getManagements: () => request.get<DictionaryModel[]>('HelloWorkReferentiel/get-managements'),
    getDurationUnits: () => request.get<DictionaryModel[]>('HelloWorkReferentiel/get-duration-units'),
    getShifts: () => request.get<DictionaryModel[]>('HelloWorkReferentiel/get-shifts'),
}

const emploiTerritorialReferentiel = {
    getEmploymentTypes: () => request.get<DictionaryModel[]>('EmploiTerritorialReferentiel/get-employment-types'),
    getDepartementsCodes: () => request.get<DictionaryModel[]>('EmploiTerritorialReferentiel/get-departement-codes'),
    getMetiersCodes: () => request.get<DictionaryModel[]>('EmploiTerritorialReferentiel/get-metiers-codes'),
    getVacancyCategoryCodes: () => request.get<DictionaryModel[]>('EmploiTerritorialReferentiel/get-vacancy-category-codes'),
    getGradesCodes: () => request.get<DictionaryModel[]>('EmploiTerritorialReferentiel/get-grades-codes'),
    getCategoriesJuridiquesCodes: () => request.get<DictionaryModel[]>('EmploiTerritorialReferentiel/get-categories-juridiques-codes'),
    getMotifDeclarationCodes: (codeTypeEmploi: string) => request.get<DictionaryModel[]>(`EmploiTerritorialReferentiel/get-motif-declaration-codes?codeTypeEmploi=${codeTypeEmploi}`),
    getFondementJuridiqueCodes: (codeTypeEmploi: string) => request.get<DictionaryModel[]>(`EmploiTerritorialReferentiel/get-fondement-juridique-codes?codeTypeEmploi=${codeTypeEmploi}`),
    getVacancy: (id: string) => request.get<any>(`EmploiTerritorialReferentiel/get-vacancy/${id}`),
}

const meteoJobReferentiel = {
    getIndustries: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-industries'),
    getContractTypes: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-contract-types'),
    getWorkSchedules: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-work-schedules'),
    getJobTravels: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-travels'),
    getTeleWorks: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-teleworks'),
    getShiftWorks: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-shiftworks'),
    getDepartments: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-departments'),
    getRegions: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-regions'),
    getCountries: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-countries'),
    getPeriodUnits: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-period-units'),
    getPeriods: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-periods'),
    getCurrencies: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-currencies'),
    getExperienceLevels: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-experience-levels'),
    getDegrees: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-degrees'),
    getLanguages: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-languages'),
    getLevels: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-levels'),
    getLicenses: () => request.get<DictionaryModel[]>('MeteoJobReferentiel/get-licenses'),
}

const talentComReferentiel = {
    getJobTypes: () => request.get<DictionaryModel[]>('TalentComReferentiel/get-job-types'),
    getBenefits: () => request.get<DictionaryModel[]>('TalentComReferentiel/get-benefits'),
    getCategories: () => request.get<DictionaryModel[]>('TalentComReferentiel/get-categories'),
    getPeriods: () => request.get<DictionaryModel[]>('TalentComReferentiel/get-periods'),
    getSalaryTypes: () => request.get<DictionaryModel[]>('TalentComReferentiel/get-salary-types'),
}

const welcomeToTheJungleReferentiel = {
    getLanguages: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-languages'),
    getContractTypes: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-contract-types'),
    getProfessionReferences: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-profession-references'),
    getCmsSiteReferences: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-cms-site-references'),
    getEducationLevels: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-education-levels'),
    getExperienceLevels: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-experience-levels'),
    getRemoteLevels: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-remote-levels'),
    getSalaryCurrencies: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-salary-currencies'),
    getSalaryPeriods: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-salary-periods'),
    getCountries: () => request.get<DictionaryModel[]>('WelcomeToTheJungleReferentiel/get-countries'),
    getVacancy: (id: string) => request.get<any>(`WelcomeToTheJungleReferentiel/get-vacancy/${id}`),
}

const agent = {
    Jobboards,
    Countries,
    JobVacancy,
    Tenant, 
    TenantJobboards,
    poleEmploiReferentiel,
    monsterReferentiel,
    leBonCoinReferentiel,
    linkedinReferentiel,
    helloWorkReferentiel,
    emploiTerritorialReferentiel,
    meteoJobReferentiel,
    talentComReferentiel,
    welcomeToTheJungleReferentiel,
    apecReferential
}

export default agent;
