import {useDispatch, useSelector} from 'react-redux'
import type {TypedUseSelectorHook} from 'react-redux'
import type {RootState, AppDispatch} from './index'
import {useState} from 'react'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// validation all input boostrap event simple select
export function useFormInput(initialValue: any) {
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState({
    isError: false,
    errorMsg: '',
  })

  function handleChange(event: any) {
    const selectedValue = !event.getDate ? event.target.value : event;

    if (!selectedValue) {
      setError({
        isError: true,
        errorMsg: 'Le champ est requis.',
      })
    } else {
      if (selectedValue.length > 3 && event.target.type === 'number') {
        setError({
          isError: true,
          errorMsg: 'Les salaires doivent être complétés en K€.',
        })
      } else {
        setError({
          isError: false,
          errorMsg: '',
        })
      }
    }

    setValue(selectedValue)
  }

  return {
    value,
    error,
    handleChange,
  }
}

// validation select-react, multiple
export function useSelectMultipleInput() {
  const [value, setValue] = useState<any>([])

  const [error, setError] = useState({
    isError: false,
    errorMsg: '',
  })

  function handleChange(options: any) {
    if (options && options.length > 0) {
      setValue(options)
      setError({
        isError: false,
        errorMsg: '',
      })
    } else {
      setError({
        isError: true,
        errorMsg: 'Le champ est requis.',
      })
      setValue(null)
    }
  }

  return {
    value,
    error,
    handleChange,
  }
}

// validation all input boostrap event simple select
export function useFormSelectInput(initialValue: any) {
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState({
    isError: false,
    errorMsg: '',
  })

  function handleChange(event: any) {
    const selectedValue = event.target.value

    if (selectedValue === '' || selectedValue === '0') {
      setError({
        isError: true,
        errorMsg: 'Le champ est requis.',
      })
    } else {
      setError({
        isError: false,
        errorMsg: '',
      })
    }

    setValue(selectedValue)
  }

  return {
    value,
    error,
    handleChange,
  }
}
// validation all check boostrap
export function useFormInputWithCheck(initialValue: any) {
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState({
    isError: false,
    errorMsg: '',
  })

  function handleChange(event: any) {
    const selectedValue = event.target.id

    if (!selectedValue) {
      setError({
        isError: true,
        errorMsg: 'Le champ est requis.',
      })
    } else {
      setError({
        isError: false,
        errorMsg: '',
      })
    }

    setValue(selectedValue)
  }

  return {
    value,
    error,
    handleChange,
  }
}
