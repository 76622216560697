import { JobboardLogo } from "./jobboard";

import MonsterLogo from '../../../../assets/images/monster-logo.png';
import LinkedInLogo from '../../../../assets/images/linkedin-logo.png';
import IndeedLogo from '../../../../assets/images/indeed-logo.png';
import PoleEmploiLogo from '../../../../assets/images/logo-france-travail.jpg';
import CadreEmploiLogo from '../../../../assets/images/cadreemploi-logo.svg';
import ApecLogo from '../../../../assets/images/APECLogo.jpg';
import RegionJobLogo from '../../../../assets/images/indeed-logo.png';
import HelloWorkLogo from '../../../../assets/images/hellowork-logo.svg';
import EmploiCollectivitesLogo from '../../../../assets/images/EmploiCollectivitesLogo.png'
import EmploiTerritorialLogo from '../../../../assets/images/EmploiTerritorialLogo.png'
import ChoisirLeServicePublicLogo from '../../../../assets/images/ChoisirLeServicePublicLogo.png'
import LeBonCoinLogo from '../../../../assets/images/LeBonCoinLogo.svg';
import SFSPLOGO from '../../../../assets/images/SfspLogo.jpg';
import SANTEPUBLIQUEFRANCELOGO from '../../../../assets/images/SantePubliqueFranceLogo.png';
import LAGAZETTEDESCOMMUNESLOGO from '../../../../assets/images/lagazettedescommunesLogo.jpg';
import OPTIONCARRIERELOGO from '../../../../assets/images/OptioncarriereLogo.jpg';
import METEOJOBLOGO from '../../../../assets/images/meteojobLogo.jpg';
import FHFLOGO from '../../../../assets/images/FhfLogo.jpg';
import TALENTCOMLOGO from '../../../../assets/images/talent_com_logo.png';
import WELCOMETOTHEJUNGLELOGO from '../../../../assets/images/WTTJ_LOGO_2020.png';
import FIGAROLOGO from '../../../../assets/images/FigaroLogo.png';

export const getImageFromLogo = (logo: JobboardLogo) => {
        switch (logo) {
            case JobboardLogo.MONSTER:
                return MonsterLogo;
            case JobboardLogo.POLEEMPLOI:
                return PoleEmploiLogo;
            case JobboardLogo.INDEED:
                return IndeedLogo;
            case JobboardLogo.LINKEDIN:
                return LinkedInLogo;
            case JobboardLogo.CADREEMPLOI:
                return CadreEmploiLogo;
            case JobboardLogo.REGIONJOB:
                return RegionJobLogo;
            case JobboardLogo.APEC:
                return ApecLogo;
            case JobboardLogo.HELLOWORK:
                return HelloWorkLogo;
            case JobboardLogo.EMPLOICOLLECTIVITES:
                return EmploiCollectivitesLogo;
            case JobboardLogo.EMPLOITERRITORIAL:
                return EmploiTerritorialLogo;
            case JobboardLogo.CHOISIRLESERVICEPUBLIC:
                return ChoisirLeServicePublicLogo;
            case JobboardLogo.LEBONCOIN:
                return LeBonCoinLogo;
            case JobboardLogo.SFSP:
                return SFSPLOGO;
            case JobboardLogo.SANTEPUBLIQUEFRANCE:
                return SANTEPUBLIQUEFRANCELOGO;
            case JobboardLogo.LAGAZETTEDESCOMMUNES:
                return LAGAZETTEDESCOMMUNESLOGO;
            case JobboardLogo.OPTIONCARRIERE:
                return OPTIONCARRIERELOGO;
            case JobboardLogo.METEOJOB:
                return METEOJOBLOGO;
            case JobboardLogo.FHF:
                return FHFLOGO;
            case JobboardLogo.TALENTCOM:
                return TALENTCOMLOGO;
            case JobboardLogo.WELCOMETOTHEJUNGLE:
                return WELCOMETOTHEJUNGLELOGO;
            case JobboardLogo.FIGARO:
                return FIGAROLOGO;
            default:
                return "";
        }
}