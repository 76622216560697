/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Link, Routes, Outlet } from 'react-router-dom'
import { Error500 } from './components/Error500'
import { Error404 } from './components/Error404'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { IsLoading } from './components/IsLoading'
import { IsAccess } from './components/IsAccess'

const ErrorsScopDiffusionLayout = (props: any) => {

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')` }}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <div className='pt-lg-10 mb-10'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

const ErrorsScopDiffusionPage = (props: any) => (
  <Routes>
    <>
      {props.isLoading && !props.isAccess
        ?
        <Route element={<ErrorsScopDiffusionLayout />}>
          <Route index element={<IsLoading />} />
        </Route>
        :
        <Route element={<ErrorsScopDiffusionLayout />}>
          <Route index element={<IsAccess />} />
        </Route>}
    </>
  </Routes>
)

export { ErrorsScopDiffusionPage }
