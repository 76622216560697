import {ErrorMessage, Field} from 'formik'
import {RootState} from '../../../../store'
import {useAppDispatch, useAppSelector, useFormSelectInput, useSelectMultipleInput} from '../../../../store/hooks'
import {
  MonsterProperty,
  MonsterLabel,
  MonsterJobCategoryVM,
  MonsterLocationVM,
} from '../../../referentiel/core/_models'
import {useEffect, useRef, useState} from 'react'
import {Form} from 'react-bootstrap'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  fetchMonsterGetIndustriesType,
  fetchMonsterGetJobCategoriesTypes,
  fetchMonsterLocationsType,
} from '../../../../store/slices/monsterReferentiel.slices'
import {fetchTenantJobboardByLabel} from '../../../../store/slices/tenantJobboard.slices'

import './inputValidation.scss'
import { setToFalse, setToTrue, toggleValue } from '../../../../store/slices/bool.slices'

const MonsterFormStep = (props: any) => {
  const entity = props.data ? props.data : undefined

  const animatedComponents = makeAnimated()
  const dispatch = useAppDispatch()

  const monsterIndustriesList = useAppSelector(
    (state: RootState) => state.monsterReferentiel.industries
  )
  const monsterJobCategoriesList = useAppSelector(
    (state: RootState) => state.monsterReferentiel.monsterJobCategory
  )
  const monsterJobTypesList = useAppSelector(
    (state: RootState) => state.monsterReferentiel.jobTypes
  )
  const monsterLocationList = useAppSelector(
    (state: RootState) => state.monsterReferentiel.monsterlocations
  )
  const tenantJobboard = useAppSelector((state: RootState) => state.tenantJobboards.entity)

  const [monsterLocationValue, setMonsterLocation] = useState(entity ? entity.location : '')

  const [monsterStateValue, setMonsterState] = useState(entity ? entity.state : '')

  const [monsterCountryValue, setMonsterCountry] = useState(entity ? entity.country : '')
  const [monsterCityValue, setMonsterCity] = useState(entity ? entity.cityName : '')

  const [monsterIndustryValue, setMonsterIndustries] = useState<any>([])
  const [monsterJobCategoryValue, setMonsterJobCategories] = useState<any>([])
  const [monsterJobTypesValue, setMonsterJobTypes] = useState(entity ? entity.jobTypes : '')


  const monsterLocation = useFormSelectInput(entity ? entity.location : '');
  const monsterJobType = useFormSelectInput(entity ? entity.jobTypes : '');

  const monsterJobCategory = useSelectMultipleInput();
  const monsterIndustry = useSelectMultipleInput();

  const handleLists = async () => {
    await dispatch(fetchMonsterGetJobCategoriesTypes())
    await dispatch(fetchMonsterGetIndustriesType())
    await dispatch(fetchMonsterLocationsType())
    await dispatch(fetchTenantJobboardByLabel(props.jobboardLabel.toLowerCase()))
    await dispatch(toggleValue());
  }
  const getUpdatedEntity = () => {
    if (entity != null && entity.state) {
      const _monsterLocation = monsterLocationList.find(
        (x: MonsterLocationVM) => x.stateAbbrev == entity.state
      ) as MonsterLocationVM
      setMonsterLocation(_monsterLocation?.locationId)
    }

    if (props.isEdited && entity) {
      Promise.all([
        monsterJobCategoriesList
          .filter((item: any) => entity.jobCategory.includes(item.occupationId.toString()))
          .map((item) => ({value: item.occupationId, label: item.occupationLabel})),

        monsterIndustriesList
          .filter((item: any) => entity.industry.includes(item.id))
          .map((item) => ({value: item.id, label: item.label})),
      ]).then(([jobCategories, industries]) => {
        monsterJobCategory.handleChange(jobCategories)
        monsterIndustry.handleChange(industries)
      })
    }
  }

  useEffect(() => {
    handleLists()
  }, [])

  useEffect(() => {
    getUpdatedEntity()
  }, [monsterJobCategoriesList, monsterIndustriesList])

  
    const handleSubmit = (e: any) => {
    e.preventDefault()
    const monsterJobCategories: any[] = []

    const monsterIndustries: any[] = []

    monsterJobCategory.value?.forEach((element: any) => {
      const monsterJobCategoriesFormated = element.value.toString()
      monsterJobCategories.push(monsterJobCategoriesFormated)
    })

    monsterIndustry.value?.forEach((element: any) => {
      const monsterIndustriesFormated = element.value
      monsterIndustries.push(monsterIndustriesFormated)
    })
    const _monsterLocation = monsterLocationList.find(
      (x: MonsterLocationVM) => x.locationId == monsterLocation.value
    ) as MonsterLocationVM

    setMonsterCountry('France')

    setMonsterLocation(
      monsterLocation.value && _monsterLocation ? _monsterLocation.locationId?.toString() : '719'
    )
    setMonsterCity(
      monsterLocation.value && _monsterLocation ? _monsterLocation.cityName : 'Île-de-France'
    )
    setMonsterState(monsterLocation.value && _monsterLocation ? _monsterLocation.stateAbbrev : 'IDF')

    const body = {
      jobboardLabel: props.jobboardLabel,
      location: monsterLocation.value,
      state: monsterStateValue,
      country: monsterCountryValue,
      city: monsterCityValue,
      jobCategory: monsterJobCategories,
      industry: monsterIndustries,
      jobTypes: monsterJobType.value,
      userName: tenantJobboard?.userName,
      password: tenantJobboard?.password,
    } as MonsterProperty

    props.handleMonsterForm(body);

    if ((!monsterLocation.error.isError && monsterLocation.value.length > 0 && monsterLocation.value != 0) 
        && (!monsterJobType.error.isError && monsterJobType.value.length > 0 && monsterJobType.value != 0) 
        && (!monsterJobCategory.error.isError && Object.keys(monsterJobCategory.value).length > 0)
        && (!monsterIndustry.error.isError && Object.keys(monsterIndustry.value).length > 0)) {
      props.handleIsValid(true);
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false);
      dispatch(setToFalse());
    }
    
  }

  var _monsterJobCategoryOption: any[] = []
  if (monsterJobCategoriesList.length > 0 && monsterJobCategoriesList !== undefined) {
    _monsterJobCategoryOption = monsterJobCategoriesList.map((item) => ({
      value: item?.occupationId,
      label: item.occupationLabel,
    }))
  }

  var _monsterIndustriesOption: any[] = []
  if (monsterIndustriesList.length > 0 && monsterIndustriesList !== undefined) {
    _monsterIndustriesOption = monsterIndustriesList.map((item) => ({
      value: item.id,
      label: item.label,
    }))
  }

  return (
    <div className='card card-bordered step-card card-monster' onBlur={handleSubmit} onMouseLeave={handleSubmit}>
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>Monster</div>
      </div>
      <div className='card-body'>
        <div className='mb-10'>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='form-label mb-3 required'>{MonsterLabel.MonsterRegion}</label>
            {monsterLocation.error.isError && (
              <div className='error-message'>{monsterLocation.error.errorMsg}</div>
            )}
          </div>
          <Form.Select
            name='monsterLocationValue'
            className={`mb-10 ${
              !monsterLocation.error.isError
                ? 'form-select form-select-lg form-select-solid'
                : 'form-select form-select-lg form-select-solid border-red'
            }`}
            value={monsterLocation.value}
            onChange={monsterLocation.handleChange}
          >
            <option value='0'>{MonsterLabel.MonsterRegionLabel}</option>
            {monsterLocationList.map((item: any, index: any) => {
              return (
                <option key={index} value={item.locationId}>
                  {item.cityName}
                </option>
              )
            })}
          </Form.Select>
        </div>
        <div className='mb-10'>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='form-label mb-3 required'>{MonsterLabel.MonsterContrat}</label>
            {monsterJobType.error.isError && <div className='error-message'>{monsterJobType.error.errorMsg}</div>}
          </div>
          <Form.Select
            name='monsterJobTypes'
            className={`mb-10 ${
              !monsterJobType.error.isError ? 'form-select form-select-lg form-select-solid' : 'border-red'
            }`}
            value={monsterJobType.value}
            onChange={monsterJobType.handleChange}
          >
            <option value='0'>{MonsterLabel.MonsterContratSelet}</option>
            {monsterJobTypesList.map((item: any, index: any) => {
              return (
                <option key={index} value={item.id}>
                  {item.label}
                </option>
              )
            })}
          </Form.Select>
        </div>
        <div className='mb-10'>
        <div className='d-flex justify-content-between align-items-center'>
            <label className='form-label mb-3 required'>{MonsterLabel.MonsterMetier}</label>
            {monsterJobCategory.error.isError && (
              <div className='error-message'>{monsterJobCategory.error.errorMsg}</div>
            )}
          </div>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={_monsterJobCategoryOption}
            onChange={monsterJobCategory.handleChange}
            value={monsterJobCategory.value}
            placeholder={MonsterLabel.MonsterMetierSelect}
            className={`mb-10 ${
              !monsterJobCategory.error.isError
                ? 'form-select form-select-lg form-select-solid'
                : 'border-red'
            }`}
          />
        </div>
        <div className='mb-10'>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='form-label mb-3 required'>{MonsterLabel.MonsterSecteurActivite}</label>
            {monsterIndustry.error.isError && (
              <div className='error-message'>{monsterIndustry.error.errorMsg}</div>
            )}
          </div>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={_monsterIndustriesOption}
            onChange={monsterIndustry.handleChange}
            value={monsterIndustry.value}
            placeholder={MonsterLabel.MonsterSecteurActiviteSelect}      
            className={`mb-10 ${
              !monsterIndustry.error.isError
                ? 'form-select form-select-lg form-select-solid'
                : 'border-red'
            }`}      
          />
        </div>
      </div>
    </div>
  )
}

export {MonsterFormStep}

