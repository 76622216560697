import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitWelcomeToTheJungleState } from "../../pages/referentiel/core/_models";
import agent from "../../api/agent";

const initialState: InitWelcomeToTheJungleState = {
    languages: [],
    contractTypes: [],
    professionReferences: [],
    cmsSiteReferences: [],
    educationLevels: [],
    experienceLevels: [],
    remoteLevels: [],
    salaryCurrencies: [],
    salaryPeriods: [],
    countries: [],
    loading: false,
}

export const fetchWelcomeToTheJungleGetLanguages = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getLanguages',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getLanguages();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetContractTypes = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getContractTypes',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getContractTypes();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetProfessionReferences = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getProfessionReferences',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getProfessionReferences();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetCmsSiteReferences = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getCmsSiteReferences',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getCmsSiteReferences();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetEducationLevels = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getEducationLevels',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getEducationLevels();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetExperienceLevels = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getExperienceLevels',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getExperienceLevels();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetRemoteLevels = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getRemoteLevels',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getRemoteLevels();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetSalaryCurrencies = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getSalaryCurrencies',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getSalaryCurrencies();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetSalaryPeriods = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getSalaryPeriods',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getSalaryPeriods();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetCountries = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getCountries',
    async () => {
        const response = await agent.welcomeToTheJungleReferentiel.getCountries();
        return response as [];
    }
)

export const fetchWelcomeToTheJungleGetVacancy = createAsyncThunk(
    'welcomeToTheJungleReferentiel/getVacancy',
    async (id: string) => {
        const response = await agent.welcomeToTheJungleReferentiel.getVacancy(id);
        return response;
    }
)

const welcomeToTheJungleReferentielSlice = createSlice({
    name: "welcomeToTheJungleReferentiel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWelcomeToTheJungleGetLanguages.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetLanguages.fulfilled, (state, action) => {
                state.loading = false;
                state.languages = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetLanguages.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchWelcomeToTheJungleGetContractTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetContractTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.contractTypes = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetContractTypes.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchWelcomeToTheJungleGetProfessionReferences.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetProfessionReferences.fulfilled, (state, action) => {
                state.loading = false;
                state.professionReferences = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetProfessionReferences.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchWelcomeToTheJungleGetCmsSiteReferences.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetCmsSiteReferences.fulfilled, (state, action) => {
                state.loading = false;
                state.cmsSiteReferences = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetCmsSiteReferences.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchWelcomeToTheJungleGetEducationLevels.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetEducationLevels.fulfilled, (state, action) => {
                state.loading = false;
                state.educationLevels = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetEducationLevels.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchWelcomeToTheJungleGetExperienceLevels.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetExperienceLevels.fulfilled, (state, action) => {
                state.loading = false;
                state.experienceLevels = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetExperienceLevels.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchWelcomeToTheJungleGetRemoteLevels.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetRemoteLevels.fulfilled, (state, action) => {
                state.loading = false;
                state.remoteLevels = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetRemoteLevels.rejected, (state, action) => {
                state.loading = false;
            })
            
            .addCase(fetchWelcomeToTheJungleGetSalaryCurrencies.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetSalaryCurrencies.fulfilled, (state, action) => {
                state.loading = false;
                state.salaryCurrencies = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetSalaryCurrencies.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchWelcomeToTheJungleGetSalaryPeriods.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetSalaryPeriods.fulfilled, (state, action) => {
                state.loading = false;
                state.salaryPeriods = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetSalaryPeriods.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchWelcomeToTheJungleGetCountries.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchWelcomeToTheJungleGetCountries.fulfilled, (state, action) => {
                state.loading = false;
                state.countries = [...action.payload];
            })
            .addCase(fetchWelcomeToTheJungleGetCountries.rejected, (state, action) => {
                state.loading = false;
            })
        }
});

export const {} = welcomeToTheJungleReferentielSlice.actions;
export default welcomeToTheJungleReferentielSlice.reducer