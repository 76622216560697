import {Col, Form, Row} from 'react-bootstrap'
import {
  EmploiTerritorialLabels,
  EmploiTerritorialProperty,
  JobboardsLabel,
} from '../../../referentiel/core/_models'
import {useEffect, useState} from 'react'
import {
  useAppDispatch,
  useAppSelector,
  useFormInput,
  useFormSelectInput,
} from '../../../../store/hooks'
import {RootState} from '../../../../store'
import {
  fetchEmploiTerritorialGetEmploymentTypes,
  fetchEmploiTerritorialGetFondementJuridiqueCodes,
  fetchEmploiTerritorialGetGradesCodes,
  fetchEmploiTerritorialGetMetiersCodes,
  fetchEmploiTerritorialGetMotifDeclarationCodes,
  fetchEmploiTerritorialGetVacancy,
} from '../../../../store/slices/emploiTerritorialReferentiel.slice'
import './inputValidation.scss'
import {setToTrue, setToFalse, toggleValue} from '../../../../store/slices/bool.slices'
import moment from 'moment'
import DatePicker from 'react-datepicker'

const EmploiTerritorialFormStep = (props: any) => {
  const dispatch = useAppDispatch()

  const entity = props.data ? props.data : undefined

  const numberOfVacancies = useFormInput(entity ? entity.C3 : '')
  const vacancyMissions = useFormInput(entity ? entity.C5 : '')
  const employmentType = useFormSelectInput(entity ? entity.C7 : '')
  const workDurationHours = useFormInput(entity ? entity.C9 : '')
  const workDurationMinutes = useFormInput(entity ? entity.C10 : '')
  const [serviceDirectionPoste, setServiceDirectionPoste] = useState(entity ? entity.serviceDirectionPoste : '')
  const [emailCandidature, setEmailCandidature] = useState(entity ? entity.emailCandidature : '')
  const [lettreMotivation, setLettreMotivation] = useState(entity ? entity.lettreMotivation : '')
  const [emailContact, setEmailContact] = useState(entity ? entity.emailContact : '')
  const [nomContact, setNomContact] = useState(entity ? entity.nomContact : '')
  const [prenomContact, setPrenomContact] = useState(entity ? entity.prenomContact : '')
  const [telContact, setTelContact] = useState(entity ? entity.telContact : '')
  const motifDeclarationCode = useFormInput(entity ? entity.motifDeclarationCode : '')
  const contactDescription = useFormInput(entity ? entity.C19 : '')
  const [jobAddress, setJobAddress] = useState(entity ? entity.C20 : '')
  const [jobPostalCode, setJobPostalCode] = useState(entity ? entity.C21 : '')
  const [jobCity, setJobCity] = useState(entity ? entity.C22 : '')
  const [isFullTime, setIsFullTime] = useState(entity ? entity.C8 : '')
  const [ouvertContractuels, setOuvertContractuels] = useState(entity ? entity.ouvertContractuels : '')
  const [vacancyApplicationURL, setVacancyApplicationURL] = useState(
    entity ? entity.C23 : props?.urlPublished
  )
  const vacancyPublicationEndDate = useFormInput(entity ? entity.C26 : new Date().setDate(new Date().getDate() + 60))
  const vacancyApplicationFinalDate = useFormInput(entity ? entity.C27 : '')

  const metiersCode = useFormSelectInput(entity ? entity.C30 : '')
  const vacancyDate = useFormInput(entity ? entity.C35 : '')
  const gradesCode1 = useFormSelectInput(entity ? entity.C37 : '')
  const fondementJuridiquesCode = useFormSelectInput(entity ? entity.C42 : '')
  const missionDurationMonths = useFormInput(entity ? entity.C43 : '')
  const [fullTimeValue, setFullTimeValue] = useState(false)
  const [ouvertContractuelsValue, setOuvertContractuelsValue] = useState(false)
  const [lettreMotivationValue, setLettreMotivationValue] = useState(false)

  const handleIsFullTime = (e: any) => {
    if (e.target.checked) {
      setIsFullTime(1)
      setFullTimeValue(true)
    } else {
      setIsFullTime(0)
      setFullTimeValue(false)
    }
  }

  const handleOuvertContractuels = (e: any) => {
    if (e.target.checked) {
      setOuvertContractuels(1)
      setOuvertContractuelsValue(true)
    } else {
      setOuvertContractuels(0)
      setOuvertContractuelsValue(false)
    }
  }

  const handleLettreMotivation = (e: any) => {
    if (e.target.checked) {
      setLettreMotivation(1)
      setLettreMotivationValue(true)
    } else {
      setLettreMotivation(0)
      setLettreMotivationValue(false)
    }
  }

  const handleServiceDirectionPoste = (e: any) => setServiceDirectionPoste(e.target.value)
  const handleEmailCandidature = (e: any) => setEmailCandidature(e.target.value)
  const handleJobAddress = (e: any) => setJobAddress(e.target.value)
  const handleJobPostalCode = (e: any) => setJobPostalCode(e.target.value)
  const handleJobCity = (e: any) => setJobCity(e.target.value)
  const handleVacancyApplicationURL = (e: any) => setVacancyApplicationURL(e.target.value)
  const handleEmailContact = (e: any) => setEmailContact(e.target.value)
  const handleTelContact = (e: any) => setTelContact(e.target.value)
  const handleNomContact = (e: any) => setNomContact(e.target.value)
  const handlePrenomContact = (e: any) => setPrenomContact(e.target.value)

  const employmentTypesResponseList = useAppSelector(
    (state: RootState) => state.emploiTerritorialReferentiel.employmentTypes
  )

  const metiersCodesResponseList = useAppSelector(
    (state: RootState) => state.emploiTerritorialReferentiel.metiersCodes
  )

  const gradesCodesResponseList = useAppSelector(
    (state: RootState) => state.emploiTerritorialReferentiel.gradesCodes
  )

  const motifDeclarationCodeResponseList = useAppSelector(
    (state: RootState) => state.emploiTerritorialReferentiel.motifDeclarationCodes
  )

  const fondementJuridiquesCodesResponseList = useAppSelector(
    (state: RootState) => state.emploiTerritorialReferentiel.fondementJuridiqueCodes
  )

  const handleLists = async () => {
    await dispatch(fetchEmploiTerritorialGetEmploymentTypes())
    await dispatch(fetchEmploiTerritorialGetMetiersCodes())
    await dispatch(fetchEmploiTerritorialGetGradesCodes())
    await dispatch(fetchEmploiTerritorialGetMotifDeclarationCodes(''))
    await dispatch(fetchEmploiTerritorialGetFondementJuridiqueCodes(''))
    await dispatch(toggleValue())
  }

  const handleUpdateVacancy = async () => {
    var response = (await dispatch(fetchEmploiTerritorialGetVacancy(props.vacancyId))).payload.success
    numberOfVacancies.handleChange({target: {value: response.rows[0].nbre_poste_op}})
    handleServiceDirectionPoste({ target: {value: response.rows[0].sce_op}})
    handleIsFullTime({target: { checked: response.rows[0].tps_trav_op == 'Temps complet'} })
    workDurationHours.handleChange({target: {value: response.rows[0].duree_tps_trav_hh}})
    workDurationMinutes.handleChange({target: {value: response.rows[0].duree_tps_trav_mm}})
    employmentType.handleChange({target: {value: response.rows[0].cod_type_op}})
    missionDurationMonths.handleChange({target: {value: response.rows[0].duree_mission }})
    handleOuvertContractuels({target: {value: response.rows[0].code_tc}})
    gradesCode1.handleChange({target: {value: response.rows[0].cod_grade1_of}})
    vacancyMissions.handleChange({target: {value: response.rows[0].missions_of}})
    contactDescription.handleChange({target: {value: response.rows[0].infos_compl_of}})
    handleJobAddress({target: {value: response.rows[0].adr_lieu_trav}})
    handleJobPostalCode({target: {value: response.rows[0].cp_lieu_trav}})
    handleJobCity({target: {value: response.rows[0].ville_lieu_trav}})
    handleVacancyApplicationURL({target: {value: response.rows[0].url_of_cand}})
    handleEmailCandidature({target: {value: response.rows[0].email_dest_cand_of}})
    vacancyPublicationEndDate.handleChange({target: {value: response.rows[0].date_fin_pub}})
    vacancyApplicationFinalDate.handleChange({target: {value: response.rows[0].date_limit_cand}})
    vacancyDate.handleChange({target: {value: response.rows[0].date_poste_lib}})
    metiersCode.handleChange({target: {value: response.rows[0].cod_metier1_op}})
    handleEmailContact({target: {value: response.rows[0].email_contact}})
    handleNomContact({target: {value: response.rows[0].nom_contact.split(',')[0]}})
    handlePrenomContact({target: {value: response.rows[0].nom_contact.split(',')[1].trim()}})
    handleTelContact({target: {value: response.rows[0].tel_contact}})
    fondementJuridiquesCode.handleChange({target: {value: response.rows[0].cod_fj}})
  }

  useEffect(() => {
    handleLists()
    if (props.vacancyId) {
      handleUpdateVacancy()
    }
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const body = {
      jobTitle: '',
      numberOfVacancies: numberOfVacancies.value,
      vacancyDescription: '',
      vacancyMissions: vacancyMissions.value,
      vacancyProfile: '',
      typeEmploisCode: employmentType.value, 
      workTimeType: isFullTime ? 1 : 0,
      workDurationHours: workDurationHours.value,
      workDurationMinutes: workDurationMinutes.value,
      contactDescription: contactDescription.value, 
      jobAddress: jobAddress,
      jobPostalCode: jobPostalCode,
      jobCity: jobCity,
      vacancyApplicationURL: vacancyApplicationURL,
      vacancyPublicationStartDate: moment(vacancyDate.value).format('DD/MM/yyyy'),
      vacancyPublicationEndDate: moment(vacancyPublicationEndDate.value).format('DD/MM/yyyy'),
      vacancyApplicationFinalDate: moment(vacancyApplicationFinalDate.value).format('DD/MM/yyyy'),
      metiersCode: metiersCode.value,
      gradesCode1: gradesCode1.value ? [gradesCode1.value] : null,
      categoriesjuridiquesCode: fondementJuridiquesCode.value,
      missionDurationMonths: missionDurationMonths.value,
      serviceDirectionPoste: serviceDirectionPoste,
      motifDeclarationCode: motifDeclarationCode.value,
      emailCandidature: emailCandidature,
      datePoste: vacancyDate.value,
      lettreMotivation: lettreMotivation ? 1 : 0,
      emailContact: emailContact,
      nomContact: nomContact,
      prenomContact: prenomContact,
      telContact: telContact,
      ouvertContractuels: ouvertContractuels ? "00" : "01",
    } as EmploiTerritorialProperty
    props.handleEmploiTerritorialForm(body)
    if (
      !numberOfVacancies.error.isError &&
      numberOfVacancies.value &&
      !vacancyMissions.error.isError &&
      vacancyMissions.value &&
      !workDurationHours.error.isError &&
      workDurationHours.value &&
      !workDurationMinutes.error.isError &&
      workDurationMinutes.value &&
      !employmentType.error.isError &&
      employmentType.value != 0 &&
      !metiersCode.error.isError &&
      metiersCode.value.length > 0 &&
      metiersCode.value != 0 &&
      !gradesCode1.error.isError &&
      gradesCode1.value.length > 0 &&
      gradesCode1.value != 0 &&
      (!props.vacancy || (!motifDeclarationCode.error.isError &&
      motifDeclarationCode.value.length > 0 &&
      motifDeclarationCode.value != 0)) &&
      !vacancyPublicationEndDate.error.isError &&
      vacancyPublicationEndDate.value.length > 0 &&
      vacancyPublicationEndDate.value != 0 &&
      !vacancyApplicationFinalDate.error.isError &&
      vacancyApplicationFinalDate.value.length > 0 &&
      vacancyApplicationFinalDate.value != 0 &&
      !vacancyDate.error.isError &&
      vacancyDate.value.length > 0 &&
      vacancyDate.value != 0 &&
      !fondementJuridiquesCode.error.isError &&
      fondementJuridiquesCode.value.length > 0 &&
      fondementJuridiquesCode.value != 0 &&
      !missionDurationMonths.error.isError &&
      missionDurationMonths.value.length > 0 &&
      missionDurationMonths.value != 0 &&
      !contactDescription.error.isError &&
      contactDescription.value.length > 0 &&
      contactDescription.value != 0
    ) {
      props.handleIsValid(true)
      dispatch(setToTrue())
    } else {
      props.handleIsValid(false)
      dispatch(setToFalse())
    }
  }

  return (
    <div
      className='card card-bordered step-card card-emploiterritorial'
      onBlur={handleSubmit}
      onMouseLeave={handleSubmit}
    >
      <div className='card-header ribbon ribbon-start'>
        <div className='ribbon-label'>{JobboardsLabel.EMPLOITERRITORIAL}</div>
      </div>
      <div className='card-body'>
        <div className='mb-2'>
          <Row>
          <Col>
              <label className='form-label'>
                {EmploiTerritorialLabels.ServiceDirectionPosteLabel}
              </label>
              <Form.Control
                value={serviceDirectionPoste}
                placeholder={EmploiTerritorialLabels.ServiceDirectionPosteLabel}
                onChange={handleServiceDirectionPoste}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {EmploiTerritorialLabels.NumberOfVacanciesLabel}
                </label>
                {numberOfVacancies.error.isError && (
                  <div className='error-message'>{numberOfVacancies.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={numberOfVacancies.value}
                placeholder={EmploiTerritorialLabels.NumberOfVacanciesLabel}
                onChange={numberOfVacancies.handleChange}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className={`mb-2 ${
                  !numberOfVacancies.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                id='numberOfVacancies'
                step='1'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-2'>
          <Row>
            <Col>
              <label className='form-label'>
                {EmploiTerritorialLabels.IsFullTimeLabel}
              </label>
              <br />
              <Form.Check
                inline
                type='checkbox'
                checked={fullTimeValue}
                value={isFullTime}
                onChange={handleIsFullTime}
                id='flexRadioLg'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-2'>
          <h5 className='mb-3'>Durée du temps de travail</h5>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {EmploiTerritorialLabels.WorkDurationHoursLabel}
                </label>
                {workDurationHours.error.isError && (
                  <div className='error-message'>{workDurationHours.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={workDurationHours.value}
                placeholder={EmploiTerritorialLabels.WorkDurationHoursLabel}
                onChange={workDurationHours.handleChange}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className={`mb-2 ${
                  !workDurationHours.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                id='workDurationHours'
                step='1'
              />
            </Col>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {EmploiTerritorialLabels.WorkDurationMinutesLabel}
                </label>
                {workDurationMinutes.error.isError && (
                  <div className='error-message'>{workDurationMinutes.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={workDurationMinutes.value}
                placeholder={EmploiTerritorialLabels.WorkDurationMinutesLabel}
                onChange={workDurationMinutes.handleChange}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className={`mb-2 ${
                  !workDurationMinutes.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                id='workDurationMinutes'
                step='1'
              />
            </Col>
          </Row>
          <hr />
        </div>
        { !props.vacancyId &&
        <div className='mb-2'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {EmploiTerritorialLabels.EmploymentTypeLabel}
                </label>
                {employmentType.error.isError && (
                  <div className='error-message'>{employmentType.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={employmentType.value}
                onChange={async (e) => { 
                  employmentType.handleChange(e);
                  await dispatch(fetchEmploiTerritorialGetFondementJuridiqueCodes(e.target.value))
                  await dispatch(fetchEmploiTerritorialGetMotifDeclarationCodes(e.target.value))
                }}
                className={`mb-2 ${
                  !employmentType.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'form-select form-select-lg form-select-solid border-red'
                }`}
              >
                <option value='0'>{EmploiTerritorialLabels.EmploymentTypeSelectLabel}</option>
                {employmentTypesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        }
        <div className='mb-2'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                {EmploiTerritorialLabels.MissionDurationMonthsLabel}
                </label>
                {missionDurationMonths.error.isError && (
                  <div className='error-message'>{missionDurationMonths.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={missionDurationMonths.value}
                placeholder={EmploiTerritorialLabels.MissionDurationMonthsLabel}
                onChange={missionDurationMonths.handleChange}
                type='number'
                min='0'
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault()
                  }
                }}
                className={`mb-2 ${
                  !missionDurationMonths.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
                id='missionDurationMonths'
                step='1'
              />
            </Col>
            <Col>
              <label className='form-label'>
                {EmploiTerritorialLabels.OuvertContractuelsLabel}
              </label>
              <br />
              <Form.Check
                inline
                type='checkbox'
                checked={ouvertContractuelsValue}
                value={ouvertContractuels}
                onChange={handleOuvertContractuels}
                id='flexRadioLg'
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col>
              <label className='form-label required'>
                {EmploiTerritorialLabels.GradesCode1Label}
              </label>
              <Form.Select
                as='select'
                value={gradesCode1.value}
                onChange={gradesCode1.handleChange}
                className={`mb-2 ${
                  !gradesCode1.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>{EmploiTerritorialLabels.GradesCode1SelectLabel}</option>
                {gradesCodesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {EmploiTerritorialLabels.MotifDeclarationCodeLabel}:
                </label>
                {motifDeclarationCode.error.isError && (
                  <div className='error-message'>{motifDeclarationCode.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={motifDeclarationCode.value}
                onChange={motifDeclarationCode.handleChange}
                className={`mb-2 ${
                  !motifDeclarationCode.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>{EmploiTerritorialLabels.MotifDeclarationCodeSelectLabel}</option>
                {motifDeclarationCodeResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {EmploiTerritorialLabels.VacancyMissionsLabel}
                </label>
                {vacancyMissions.error.isError && (
                  <div className='error-message'>{vacancyMissions.error.errorMsg}</div>
                )}
              </div>
              <Form.Control
                value={vacancyMissions.value}
                placeholder={EmploiTerritorialLabels.VacancyMissionsLabel}
                onChange={vacancyMissions.handleChange}
                className={`mb-2 ${
                  !vacancyMissions.error.isError
                    ? 'form-control form-control-lg form-control-solid'
                    : 'form-control form-control-lg form-control-solid border-red'
                }`}
              />
            </Col>
          </Row>
        </div>
        <div className='mb-2'>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='form-label mb-3 required'>
              {EmploiTerritorialLabels.ContactDescriptionLabel}
            </label>
            {contactDescription.error.isError && (
              <div className='error-message'>{contactDescription.error.errorMsg}</div>
            )}
          </div>
          <Form.Control
            value={contactDescription.value}
            placeholder={EmploiTerritorialLabels.ContactDescriptionLabel}
            onChange={contactDescription.handleChange}
            className={`mb-2 ${
              !contactDescription.error.isError
                ? 'form-control form-control-lg form-control-solid'
                : 'form-control form-control-lg form-control-solid border-red'
            }`}
          />
        </div>
        <div className='mb-2'>
          <h5 className='mb-3'>
            Autres informations liées au lieu de travail si différent de celui de la collectivité
            employeur.
          </h5>
          <label className='form-label'>{EmploiTerritorialLabels.JobAddressLabel}</label>
          <Form.Control
            value={jobAddress}
            placeholder={EmploiTerritorialLabels.JobAddressLabel}
            onChange={handleJobAddress}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        <div className='mb-2'>
          <Row>
            <Col>
              <label className='form-label'>{EmploiTerritorialLabels.JobPostalCodeLabel}</label>
              <Form.Control
                value={jobPostalCode}
                placeholder={EmploiTerritorialLabels.JobPostalCodeLabel}
                onChange={handleJobPostalCode}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{EmploiTerritorialLabels.JobCityLabel}</label>
              <Form.Control
                value={jobCity}
                placeholder={EmploiTerritorialLabels.JobCityLabel}
                onChange={handleJobCity}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <hr />
        <div className='mb-2'>
          <Row>
            <Col>
              <label className='form-label'>
                {EmploiTerritorialLabels.VacancyApplicationURLLabel}
              </label>
              <Form.Control
                value={vacancyApplicationURL}
                placeholder={EmploiTerritorialLabels.VacancyApplicationURLLabel}
                onChange={handleVacancyApplicationURL}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>{EmploiTerritorialLabels.EmailCandidatureLabel}</label>
              <Form.Control
                value={emailCandidature}
                placeholder={EmploiTerritorialLabels.EmailCandidatureLabel}
                onChange={handleEmailCandidature}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-2'>
          <label className='form-label required'>
            {EmploiTerritorialLabels.VacancyPublicationEndDateLabel}
          </label>
          <DatePicker
              dateFormat='dd/MM/yyyy'
              className='form-select form-select-lg'
              locale='fr'
              selected={vacancyPublicationEndDate.value}
              onChange={(date: any) => vacancyApplicationFinalDate.handleChange(date)}
            />
        </div>
        <div className='mb-2'>
          <label className='form-label required'>
            {EmploiTerritorialLabels.VacancyApplicationFinalDateLabel}
          </label>
          <DatePicker
              dateFormat='dd/MM/yyyy'
              className='form-select form-select-lg'
              locale='fr'
              selected={vacancyApplicationFinalDate.value}
              onChange={(date: any) => vacancyApplicationFinalDate.handleChange(date)}
            />
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <label className='form-label mb-3 required'>
            {EmploiTerritorialLabels.VacancyDateLabel}
          </label>
          {vacancyDate.error.isError && (
            <div className='error-message'>{vacancyDate.error.errorMsg}</div>
          )}
        </div>
        <DatePicker
              dateFormat='dd/MM/yyyy'
              className='form-select form-select-lg'
              locale='fr'
              selected={vacancyDate.value}
              onChange={(date: any) => vacancyDate.handleChange(date)}
            />
        <div className='mb-2'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {EmploiTerritorialLabels.MetiersCodeLabel}:
                </label>
                {metiersCode.error.isError && (
                  <div className='error-message'>{metiersCode.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={metiersCode.value}
                onChange={metiersCode.handleChange}
                className={`mb-2 ${
                  !metiersCode.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'border-red'
                }`}
              >
                <option value='0'>{EmploiTerritorialLabels.MetiersCodeSelectLabel}</option>
                {metiersCodesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col>
              <label className='form-label'>
                {EmploiTerritorialLabels.LettreMotivationLabel}
              </label>
              <br />
              <Form.Check
                inline
                type='checkbox'
                checked={lettreMotivationValue}
                value={lettreMotivation}
                onChange={handleLettreMotivation}
                id='flexRadioLg'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-2'>
          <Row>
            <Col>
              <label className='form-label'>
                {EmploiTerritorialLabels.EmailContactLabel}
              </label>
              <Form.Control
                value={emailContact}
                placeholder={EmploiTerritorialLabels.EmailContactLabel}
                onChange={handleEmailContact}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>
                {EmploiTerritorialLabels.TelContactLabel}
              </label>
              <Form.Control
                value={telContact}
                placeholder={EmploiTerritorialLabels.TelContactLabel}
                onChange={handleTelContact}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-2'>
          <Row>
            <Col>
              <label className='form-label'>
                {EmploiTerritorialLabels.NomContactLabel}
              </label>
              <Form.Control
                value={nomContact}
                placeholder={EmploiTerritorialLabels.NomContactLabel}
                onChange={handleNomContact}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
            <Col>
              <label className='form-label'>
                {EmploiTerritorialLabels.PrenomContactLabel}
              </label>
              <Form.Control
                value={prenomContact}
                placeholder={EmploiTerritorialLabels.PrenomContactLabel}
                onChange={handlePrenomContact}
                className='form-control form-control-lg form-control-solid'
              />
            </Col>
          </Row>
        </div>
        <div className='mb-2'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='form-label mb-3 required'>
                  {EmploiTerritorialLabels.FondementJuridiqueLabel}
                </label>
                {fondementJuridiquesCode.error.isError && (
                  <div className='error-message'>{fondementJuridiquesCode.error.errorMsg}</div>
                )}
              </div>
              <Form.Select
                as='select'
                value={fondementJuridiquesCode.value}
                onChange={fondementJuridiquesCode.handleChange}
                className={`mb-2 ${
                  !fondementJuridiquesCode.error.isError
                    ? 'form-select form-select-lg form-select-solid'
                    : 'form-select form-select-lg form-select-solid border-red'
                }`}
              >
                <option value='0'>{EmploiTerritorialLabels.FondementJuridiqueSelectLabel}</option>
                {fondementJuridiquesCodesResponseList.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default EmploiTerritorialFormStep
